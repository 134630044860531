import { createAsyncThunk } from '@reduxjs/toolkit';
import { showSnackbar, hideSnackbar, setIsTippingMoxie } from './globalStateSlice';

export const displaySnackbar = createAsyncThunk(
  'app/displaySnackbar',
  async (message, { dispatch }) => {
    console.log('message', message);  

    if(message?.cast || message?.snackLink){
      dispatch(showSnackbar(message));
      setTimeout(() => {
        dispatch(hideSnackbar());
      }, 6000);
      return
    }

    dispatch(showSnackbar(message));
    setTimeout(() => {
      dispatch(hideSnackbar());
    }, 3000);
  }
);

export const TippingMoxie = createAsyncThunk(
  'app/isTippingMoxie',
  async (isTipping, { dispatch }) => {
    dispatch(setIsTippingMoxie(isTipping));
  }
)