import { ArrowLeft, ClockCountdown } from '@phosphor-icons/react/dist/ssr';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/UI/Spinner';
import { getQuests } from '../../services/api';
import { getSelectedCurrencyLocalStorage } from '../../utils/constants';
import { getQuestDescription, getQuestTitle, highlightWord, updateTimer } from '../../utils/helper';

const QuestMoreDetails = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const currentUrl = window.location.href;
    const segments = currentUrl.split("/");
    const questId = segments[segments.length - 1];
    const [timeLeft, setTimeLeft] = useState('');
    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || 'WILD';

    const { data: questsData = [], isLoading: questsLoading } = useQuery({
        queryKey: ['questsDetail', selectedCurrency],
        queryFn: () => getQuests(selectedCurrency),
    });

    const questToShow = questsData?.data?.find((quest) => quest?.quest_user_record?.id == questId);

    useEffect(() => {
        const expiryDate = new Date(questToShow?.quest_spec?.expiring_at);
        updateTimer(expiryDate, setTimeLeft);
        const intervalId = setInterval(() => updateTimer(expiryDate, setTimeLeft), 1000);

        return () => clearInterval(intervalId);
    }, [questToShow]);

    if (questsLoading) {
        return <Spinner />;
    }

    const { quest_user_record, quest_spec } = questToShow;
    const { status, progress, id, completed_at } = quest_user_record;
    const { quest_type, num_steps, reward, currency, expiring_at } = quest_spec;

    return (
        <div className='flex-1'>
            <div className='mx-5 mt-5 text-black dark:text-onBackground'>
                <ArrowLeft size={24}  onClick={() => { navigate(-1) }} role='button' />
                <h1 className='prose-DisplaySmall mt-5 mb-4'>
                    {getQuestTitle(quest_type, num_steps)}
                </h1>
            </div>
            <p className='mx-5 prose-BodyLarge text-black dark:text-onBackgroundII'>
                {highlightWord(getQuestDescription(quest_type, num_steps))}
            </p>
            <div className='max-h-[168px] text-subTxtOnLightBg dark:text-onBackgroundII mx-5 my-6 p-4 bg-gray-200 dark:bg-surface bg-opacity-80 flex flex-col gap-3 justify-between'>
                <div className='flex justify-between align-center'>
                    <p className='prose-BodyLarge'>Reward</p>
                    {status !== "completed" && status !== "expired" && <p className='prose-BodyLargeBold  text-primary'>{reward} {currency}</p>}
                    {status === "completed" && <p className='prose-BodyLargeBold  text-primary'>{reward} {currency} Credited</p>}
                    {status === "expired" && <p className='line-through'>{reward} {currency}</p>}
                </div>
                <hr className='border-0 h-[2px] bg-gray-300 dark:bg-background' />
                <div className='flex justify-between align-center'>
                    <p>Progress</p>
                    {status === "not_started" && <p>Not started</p>}
                    {status === "in_progress" && <p className='text-txtOnLightBg dark:text-white'>{progress}/{num_steps} done</p>}
                    {status === "completed" && <p className='font-semibold  text-primary'>Completed</p>}
                    {status == 'expired' && <p className='text-error font-semibold'>Expired</p>}
                </div>
                {
                    status != 'completed' && status != 'expired' && <><hr className='border-0 h-[2px] bg-gray-300 dark:bg-background' />
                        <div className='flex justify-between align-center'>
                            <p>Time Period</p>
                            {!expiring_at && <p>No Limit</p>}
                            {expiring_at && <div className='flex items-center gap-1 text-orange font-semibold text-warning'>
                                <ClockCountdown size={18} />
                                <p>{timeLeft}</p>
                            </div>}
                        </div>
                    </>}
            </div>
            {/* {
                quest_type === "invite_quest" && <div className='border dark:border-outline p-3 pr-[10px] mt-4 mx-5 mb-4'>
                    <p className='text-onBackgroundII mb-3'>Invite Code</p>
                    <div className='bg-gray-300 dark:bg-[#121214] text-txtOnLightBg dark:text-onBackground flex justify-between items-center px-2 py-1'>
                        <div className='prose-LabelLarge'>{myInvite?.code}</div>
                        <Copy size={14} onClick={copyCodeToClipboard} className='cursor-pointer  fill-primary' />
                    </div>
                </div>
            } */}
            {/* <p className='prose-BodyMedium text-onBackgroundIII mx-5'>Note: Reward will be credited within 24h of quest completion. Terms and conditions don’t apply. It is what it is, no complications :P</p> */}            
        </div>

    );
};

export default QuestMoreDetails;
