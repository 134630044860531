import { CaretRight, Check, ClockCountdown, Sword } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getQuestTitle, highlightWord, updateTimer } from '../../../utils/helper';

const QuestCard = ({ quest, i }) => {
    const navigate = useNavigate();

    const { quest_user_record, quest_spec } = quest;
    const { status, progress, id, completed_at } = quest_user_record;
    const { quest_type, num_steps, reward, currency, expiring_at } = quest_spec;

    const [timeLeft, setTimeLeft] = useState('');
    const [expiringSoon, setExpiringSoon] = useState(false);

    useEffect(() => {
        const expiryDate = new Date(expiring_at);
        updateTimer(expiryDate, setTimeLeft);
        const intervalId = setInterval(()=>updateTimer(expiryDate, setTimeLeft, setExpiringSoon), 1000);
        return () => clearInterval(intervalId);
    }, [expiring_at]);

    function handleClick() {
        navigate(`/quests/${id}`)
    }

    return (
        <div
            key={i}
            className="w-full bg-gray-200 dark:bg-transparent md:bg-transparent md:hover:bg-surface/20 dark:md:hover:bg-surface py-4 px-5 min-h-[81px] cursor-pointer relative border-b border-outline" onClick={handleClick}
        >
            <div className="flex gap-1 prose-BodyMediumBold">
                {
                    status == "in_progress" && !expiringSoon && <>
                        <Sword size={16} className=' fill-primary'/>
                        <p className="prose-BodyMediumBold  text-primary">Active Quest</p>
                    </>
                }
                {
                    status == 'completed' && <>
                        <Check size={16} className=' fill-primary'/>
                        <p className="prose-BodyMediumBold  text-primary">Completed</p>
                    </>
                }
                {
                   expiringSoon && status!='completed' &&  status!='expired' && expiring_at && <div className="flex items-center gap-1">
                    <ClockCountdown size={16} color="orange" />
                    <p className="prose-BodyMediumBold text-warning">{timeLeft}</p>
                </div>
                }
                {
                    status == 'expired' && <p className="prose-BodyMediumBold text-error">Expired</p>                  
                }
            </div>
            <h2 className="prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground">{highlightWord(getQuestTitle(quest_type, num_steps))}</h2>
            <div className="flex flex-row mt-1 justify-between items-baseline text-subTxtOnLightBg dark:text-onBackgroundII prose-BodyMedium">
                <div>
                    {
                        status !== "completed" &&
                        <span className="">{reward} {currency}</span>
                    }
                    {
                        status === "completed" &&
                        <span className=" text-primary">{reward} {currency} Credited</span>
                    }
                </div>
                <div className="flex items-center gap-[6px]">
                    {
                        status === "in_progress" && <p>
                            {progress}/{num_steps}
                            <span> done</span>
                        </p>
                    }
                    {
                        status === "not_started" &&
                        <span>Not Started</span>
                    }
                    {
                        status === "completed" &&
                        <span className=" text-primary">Completed</span>
                    }
                    {
                        status === "expired" &&
                        <span className="text-error">Expired</span>
                    }
                    <CaretRight size={12} color="#ffffff" />
                </div>
            </div>
        </div>

    )
}

export default QuestCard