import { useEffect, useState } from "react";

const useInstallPWA = () => {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);
    const [isInstalled, setisInstalled] = useState(false)

    useEffect(() => {
        const checkPWAStatus = () => {
            const isApp = window.matchMedia('(display-mode: standalone)').matches;
            setisInstalled(isApp);
        };

        checkPWAStatus();
        window.addEventListener('appinstalled', checkPWAStatus); // Listen for PWA installation changes

        const handler = (e) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => {
            window.removeEventListener('appinstalled', checkPWAStatus); // Clean up event listener
            window.removeEventListener("beforeinstallprompt", handler);
        }
    }, []);

    const installApp = () => {
        if (promptInstall) {
            promptInstall.prompt();
        } else {
            const toast = document.createElement('div');
            toast.textContent = 'Open the Wildcard app in your device';

            toast.style.position = 'fixed';
            toast.style.top = '0';
            toast.style.left = '50%';
            toast.style.width = '100%';
            toast.style.backgroundColor = '#fff';
            toast.style.transform = 'translateX(-50%)';
            toast.style.color = '#161719';
            toast.style.borderBottom = '1px solid rgba(255, 255, 255, 0.08)';
            toast.style.padding = '10px 20px';
            toast.style.zIndex = '1000';
            toast.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
            toast.style.fontFamily = "Inter";
            toast.style.fontSize = "1rem"; // 16px
            toast.style.fontWeight = "normal";
            toast.style.lineHeight = "1.5"; // 150%
            toast.style.letterSpacing = "0.005em"; // 0.5%
            toast.style.textAlign = "center";

            document.body.appendChild(toast);

            setTimeout(() => {
                document.body.removeChild(toast);
            }, 5000);
        }
    };

    return { supportsPWA, installApp, isInstalled };
};

export default useInstallPWA;
