import React from 'react';
import useInstallPWA from '../../hooks/installApp';
import logo from '../../assets/images/logo.png';
import { isIOSDevice, isSafariBrowser } from '../../utils/helper';
import { Export, PlusSquare } from '@phosphor-icons/react';

const AddHomeScreen = () => {
  const { installApp, isInstalled } = useInstallPWA()

  const isIOS = isIOSDevice()
  const isSafari = isSafariBrowser()

  const IosDescriptionText = () => {
    return (
      <p className='mx-5 text-gray-800 dark:text-onBackground tracking-wider'>
        <span className='inline-flex items-center gap-2'>Step 1: click the share button <Export /></span>
        <span className='inline-flex items-center gap-2 mt-2'>step 2: click "add to home screen" <PlusSquare /></span>
      </p>
    )
  }

  const DescriptionText = () => {
    return (
      <p className='mx-5 text-gray-800 dark:text-onBackground tracking-wider'>
        <span className='inline-flex items-center gap-2'>Step 1: click the share button <Export /></span>
        <span className='inline-flex items-center gap-2 mt-2'>step 2: click "add to home screen" <PlusSquare /></span>
      </p>
    )
  }

  return (
    <div className="flex-1 overflow-y-auto removeScrollBar h-screen pb-20 max-h-dvh flex flex-col justify-center items-start md:items-center relative">
      <div className='md:w-1/2'>
      <div className='mx-5 mb-10 relative w-24 h-24 bg-[#161719] rounded-full flex justify-center items-center'>
        <img src={logo} alt='' />
      </div>
      <h1 className='text-4xl mx-5 mb-7 font-bold text-black dark:text-[#efefefef]'>Add Wildcard to your home screen</h1>
      {isIOS ? IosDescriptionText() : DescriptionText()}
      </div>
      {/* <p className='mx-5 text-sm text-white/40 mt-6'>Have questions regarding progressive web apps?</p>
      <a href='https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps' target='_blank' className='mx-5 text-primary mt-2 underline font-semibold'>Click here to Learn more</a> */}

      {!isIOS && !isInstalled && !isSafari  && <div className='w-full md:w-[50%] mt-10 absolute bottom-12 '>
        <SignInButton handleAction={installApp} text='Install App' />
      </div>
      }
    </div>
  )
}

export default AddHomeScreen

const SignInButton = ({ handleAction = () => { }, disabled = false, text = 'Sign in with Farcaster' }) => {
  return (
      <div
          className="flex justify-center mx-5">
          <button disabled={disabled} onClick={handleAction} className="py-2 px-4 bg-[#B4F6A5] text-black flex-auto  font-title text-xl uppercase">
              {text}
          </button>
      </div>
  )
}
