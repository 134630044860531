import React from "react";
import { useNavigate } from "react-router-dom";
import { highlightWord, timeSince } from "../../../utils/helper";
import logo from "../../../assets/images/logo.png";
import powerbadge from "../../../assets/svg/powerbadge.svg"
import { useSelector } from "react-redux";
import { LinkSimple } from "@phosphor-icons/react";

const isImage = (url) => {
    return url?.startsWith('https://imagedelivery.net/') || url?.startsWith('https://static-wildcard-prod.nyc3.cdn.digitaloceanspaces.com/') || url?.startsWith('https://media.tenor.com/') || url?.startsWith('http://res.cloudinary.com/') || url?.startsWith('https://res.cloudinary.com/');
};

const EmbeddedCast = ({ data }) => {
    const navigate = useNavigate()
    const { userSelectedLanguage } = useSelector((state) => state.app);
    const { farcaster_user, cast_id, cast_details } = data;

    const hasImage = cast_details?.data?.castAddBody?.embeds?.some(embed => isImage(embed.url));
    const castEmbeds = cast_details?.data?.castAddBody?.embeds?.filter(embed => isImage(embed.url)) || [];
    let castText = cast_details?.data?.castAddBody?.translations?.translations ? cast_details?.data?.castAddBody?.translations?.translations[userSelectedLanguage] : cast_details?.data?.castAddBody?.translations?.original_text;
    castText = castText || cast_details?.data?.castAddBody?.text;
    const handleCastDetailed = (e) => {
        e.stopPropagation();

        // keep the below data if API change in future for cast data persist
        // const castData = {
        //     cast: {
        //         body: cast_details?.data?.castAddBody?.text,
        //         embeds: cast_details?.data?.castAddBody?.embeds,
        //         id: cast_id?.hash,
        //         parentCastId: cast_id?.fid,
        //         text: cast_details?.data?.castAddBody?.text,
        //         timestamp: cast_details?.data?.timestamp
        //     },
        //     farcaster_user: farcaster_user
        // }

        navigate(`/cast/${cast_id?.hash}/${cast_id?.fid}`)
        // dispatch(setSelectedCastDetails(castData))
    }


    return (
        <div
            role="button"
            onClick={(e) => handleCastDetailed(e)}
            className={`bg-gray-200 dark:bg-background px-5 py-4 cursor-default w-full border-outlineII border rounded-lg overflow-hidden`}>
            {data?.cast_details ? <>
                <div className="flex items-center justify-between h-[45px] mb-4">
                    <div className={`flex justify-between w-full  items-center`}>
                        <div className="flex items-center gap-2">
                            <div className="border border-secondary dark:border-primary rounded-full">
                                <img src={farcaster_user?.pfp_url || farcaster_user?.cdn_url || logo} alt="profile" className="w-10 h-10 rounded-full" />
                            </div>
                            <div className="prose-BodyLargeBold capitalize mr-0 text-txtOnLightBg dark:text-onBackground">{farcaster_user?.display_name}</div>
                            {
                                farcaster_user?.power_badge &&
                                <span>
                                    <img src={powerbadge} alt=""
                                        className="w-4 h-4" />
                                </span>
                            }
                        </div>
                        <div>
                            <span className="text-subTxtOnLightBg dark:text-onBackgroundIII prose-BodyMedium lowercase ms-1">{timeSince(cast_details?.data?.timestamp)}</span>
                        </div>
                    </div>
                </div>
                <div className="overflow-hidden">
                    <p className={"prose-BodyLarge overflow-hidden text-txtOnLightBg dark:text-onBackground truncate w-full max-h-[72px]"}>
                        {highlightWord(castText, true, navigate)}
                    </p>
                    <div className='w-full'>
                        {
                            cast_details?.data?.castAddBody?.urls?.map((url, index) => {
                                const data = cast_details?.data?.castAddBody?.url_metadatas[url] || null;
                                if (data == null) return;
                                if (data?.content_type === 'text/html')
                                    return (
                                        <div key={url} className='border dark:border-outlineII bg-white dark:bg-background p-5 rounded-md relative' onClick={(e) => e.stopPropagation()}>
                                            <a href={url} target="_blank" rel="noopener noreferrer" className='absolute inset-0 size-full'></a>
                                            <div className='flex gap-3 items-start'>
                                                {data?.image && <img src={data?.image} alt={data?.title} className='size-20 dark:bg-white/10 bg-background/10 rounded-sm object-contain' />}
                                                {!data?.image && <LinkSimple size={24} className='fill-black dark:fill-white' />}
                                                <div className='flex flex-col gap-3'>
                                                    {data?.title && <h3 className='prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground'>{data?.title}</h3>}
                                                    <p className='prose-BodySmall text-txtOnLightBg dark:text-white'>{data?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                            })
                        }
                    </div>
                    <div className='w-full flex gap-2'>
                        {
                            cast_details?.data?.castAddBody?.urls?.map((url, index) => {
                                const metaData = cast_details?.data?.castAddBody?.url_metadatas ? cast_details?.data?.castAddBody?.url_metadatas[url] : null;
                                if (isImage(url)) return (
                                    <div key={index} className={`relative max-h-[300px] md:max-h-[400px] mb-2`}>
                                        <img src={url} alt="embed" className='h-full object-cover rounded-lg cursor-pointer' />
                                    </div>
                                )
                                if (metaData == null) return;
                                if (metaData?.media_type === 'img' || isImage(url))
                                    return (
                                        <div key={index} className={`relative max-h-[200px] md:max-h-[300px] mb-2`}>
                                            <img src={url} alt="embed" className='h-full object-cover rounded-lg cursor-pointer' />
                                        </div>
                                    )

                            })
                        }
                    </div>
                </div></> :
                <div className="h-4">
                    <img src={logo} alt="" className="size-full min-w-4" />
                    <p>{`/cast/${cast_id?.hash}/${cast_id?.fid}`}</p></div>}
        </div>
    )
}

export default EmbeddedCast;
