import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import TopHeader from '../../components/Sidebars/TopHeader';
import Spinner from '../../components/UI/Spinner';
import { getCurrentUserRevenue, getMyPointBalances, getQuests, getWalletTipsTransactions } from '../../services/api';
import { QUESTS_TAB_CHOICES, getSelectedCurrencyLocalStorage } from '../../utils/constants';

import { Info, X } from '@phosphor-icons/react';
import Tabs from '../../components/Tabs';
import CustomModal from '../../components/UI/CustomModal';
import ToolTip from '../../components/UI/Tooltip';

import { useNavigate, useParams } from 'react-router-dom';
import CurrencyModal from '../../components/Modals/CurrencyModal';
import UserPoints from '../../components/UserPoints';
import { highlightWord } from '../../utils/helper';
import QuestsTab from './Components/QuestsTab';
import RevenueTab from './Components/RevenueTab';
import TipsTab from './Components/TipsTab';

const initialTabs = [
    {
        name: QUESTS_TAB_CHOICES.TIPS,
        isActive: true,
        id: 'tips'
    },
    {
        name: QUESTS_TAB_CHOICES.REVENUE,
        isActive: false,
        id: 'revenue'
    }
];

const mobileTabs = [
    {
        name: QUESTS_TAB_CHOICES.QUESTS,
        isActive: true,
        id: 'quests'
    },
    {
        name: QUESTS_TAB_CHOICES.TIPS,
        isActive: false,
        id: 'tips'
    },
    {
        name: QUESTS_TAB_CHOICES.REVENUE,
        isActive: false,
        id: 'revenue'
    }
];


const toolTipText = (type, currency) => {
    switch (type) {
        case 'received': return `Total tips received in the last 24 hrs (after 25% deduction for ticket holders). Resets daily at 12 AM UTC.`
        // switch (currency) {
        //     case 'WILD': return 'Tips WILD'
        //     case 'TREE': return 'Tips TREE'
        //     case 'DEGEN': return 'Tips DEGEN'
        // }
        case 'revenue': return `Your share of ticket's earning. Updates real-time. Resets daily 12 AM UTC.`
        // switch (currency) {
        //     case 'WILD': return 'Revenue WILD'
        //     case 'TREE': return 'Revenue TREE'
        //     case 'DEGEN': return 'Revenue DEGEN'
        // }
        case 'allowance': switch (currency) {
            case 'WILD': return 'Daily tipping allowance based on activity. Resets daily 12 AM UTC. Tip wisely.'
            case 'TREE': return 'Daily tipping allowance based on activity. Resets daily 12 AM UTC. Tip wisely.'
            case 'HAM': return 'Your daily tip allowance. Proportional to HAM locked in. Read more https://ham.fun/airdrops'
            case 'DUDEGEN': return 'Daily tipping allowance based on activity. Resets daily 12 AM UTC. Tip wisely.'
            case 'DEGEN': return 'Your daily tip allowance. Proportional to $DEGEN locked in. Read more https://www.degen.tips/airdrop2/current'
            default: return ''

        }
        case 'tokenInfo': switch (currency) {
            case 'WILD': return `WILD points are native to Wildcard. Earn them via tips, quests or ticket's revenue share.`
            case 'TREE': return `To be eligible for $TREE allowance, you must hold $TREE in your WILD wallet.\nAllocation tiers:\n100k $TREE = 1k daily\n1M $TREE = 2.5k daily\n5M staked $TREE on rebase = 10k daily\nBuy tree (https://app.uniswap.org/explore/tokens/base/0x6888c2409d48222e2cb738eb5a805a522a96ce80)`
            case 'DUDEGEN': return `$DUDEGEN token belongs to /dudegen.You need 10K $DUDEGEN in wallet to start earning allowance.`
            case 'HAM': return `HAM is 1-1 repped by $tn100x & token belongs to /lp. To qualify, either hold \n(1) 100K $tn100x on base or hamchain, or \n(2) 1 Ham LP NFT, or\n(3) 1 Based LP NFT\nto earn allowance. Read more: https://ham.fun/airdrops`
            case 'DEGEN': return `$DEGEN token belongs to /degen. You need to lock in $degen to earn allowance. Read more: https://www.degen.tips/airdrop2/current`
            default: return ''

        }
        default: return ''
    }
}


const PointsPage = ({ showHeader }) => {

    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || 'WILD';
    const queryClient = useQueryClient()

    const allowanceRef = useRef(null);
    const receivedRef = useRef(null);
    const revenueRef = useRef(null);

    const allowanceTipRef = useRef(null);
    const receivedTipRef = useRef(null);
    const revenueTipRef = useRef(null);
    const timeoutRefs = useRef({ allowance: null, received: null, revenue: null });

    const navigate = useNavigate();
    const { tab } = useParams();


    const [selectedTab, setSelectedTab] = useState('');
    const [tabs, setTabs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [toolTipOpen, setToolTipOpen] = useState({ allowance: false, received: false, revenue: false });
    const [showCurrencyModal, setShowCurrencyModal] = useState(false);

    const [showRules, setShowRules] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        if (window.innerWidth > 768) {
            const name = tab || 'tips';
            const newTabs = initialTabs.map((tab) => ({
                ...tab,
                isActive: tab.id === name
            }));
            setTabs(newTabs);
            setSelectedTab(name);
            setIsLoading(false)
        } else {
            const name = tab || 'quests';
            const newTabs = mobileTabs.map((tab) => ({
                ...tab,
                isActive: tab.id === name
            }));
            setTabs(newTabs);
            setSelectedTab(name);
            setIsLoading(false)
        }
        const handleResize = () => {
            if (window.innerWidth > 768) {

                setSelectedTab('tips')
                setTabs(initialTabs)
                setIsLoading(false)
            } else {

                setSelectedTab('quests')
                setTabs(mobileTabs)
                setIsLoading(false)
            }
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, [])

    const { data: myPointsBalances, isLoading: pointsBalancesLoading } = useQuery({
        queryKey: ['myPointsBalances'],
        queryFn: () => getMyPointBalances(true),
    });

    const { data: currentTokenData, isLoading: currentTokenDataLoading } = useQuery({
        queryKey: ['myPointsBalances', selectedCurrency],
        queryFn: () => getMyPointBalances(true, selectedCurrency),
    });


    useEffect(() => {
        const prefetchQueriesSequentially = async () => {
            await queryClient.prefetchQuery({
                queryKey: ['walletTips', selectedCurrency],
                queryFn: () => getWalletTipsTransactions(1, selectedCurrency)
            });
            await queryClient.prefetchQuery({
                queryKey: ['currUserRevenue'],
                queryFn: () => getCurrentUserRevenue()
            });
            await queryClient.prefetchQuery({
                queryKey: ['quests', selectedCurrency],
                queryFn: () => getQuests(selectedCurrency)
            });
        };
        prefetchQueriesSequentially();
    }, [selectedCurrency])


    useEffect(() => {
        if (currentTokenDataLoading)
            return;
        let tokenArr = {};
        currentTokenData?.data?.forEach(element => {
            const curr = element?.currency_points?.currency_config?.currency;
            tokenArr[`${curr}`] = false;
        });
        setToolTipOpen(prev => ({ ...prev, ...tokenArr }))
    }, [currentTokenDataLoading, currentTokenData])


    const handleTabClick = (name) => {
        const newTabs = tabs.map((tab) => ({
            ...tab,
            isActive: tab.id === name
        }));
        setTabs(newTabs);
        setSelectedTab(name);
        navigate(`/points/${name}`);
    };

    const handleTabChange = (index) => {
        const newTab = tabs[index];
        handleTabClick(newTab.id);
    };

    const positionToolTip = (type) => {
        let containerRef, tipRef;
        switch (type) {
            case 'allowance':
                containerRef = allowanceRef.current;
                tipRef = allowanceTipRef.current;
                tipRef.style.top = `120%`;
                tipRef.style.left = `${containerRef.offsetLeft - containerRef.parentElement.scrollLeft}px`;
                break;
            case 'received':
                containerRef = receivedRef.current;
                tipRef = receivedTipRef.current;
                tipRef.style.top = `120%`;
                tipRef.style.left = `${containerRef.offsetLeft - containerRef.parentElement.scrollLeft}px`;
                break;
            case 'revenue':
                containerRef = revenueRef.current;
                tipRef = revenueTipRef.current;
                tipRef.style.top = `120%`;
                tipRef.style.left = `${containerRef.offsetLeft - containerRef.parentElement.scrollLeft}px`;
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        if (toolTipOpen.allowance) {
            positionToolTip('allowance');
        }
        if (toolTipOpen.received) {
            positionToolTip('received');
        }
        if (toolTipOpen.revenue) {
            positionToolTip('revenue');
        }
    }, [toolTipOpen]);

    const openToolTip = (type) => {
        closeToolTips();

        setToolTipOpen(prevState => ({ ...prevState, [type]: true }));

        if (timeoutRefs.current[type]) {
            clearTimeout(timeoutRefs.current[type]);
        }

        timeoutRefs.current[type] = setTimeout(() => {
            setToolTipOpen(prevState => ({ ...prevState, [type]: false }));
        }, 6000);
    };

    const closeToolTips = () => {
        setToolTipOpen({ allowance: false, received: false, revenue: false });
        Object.values(timeoutRefs.current).forEach(clearTimeout);
    };


    useEffect(() => {
        document.addEventListener('mousedown', closeToolTips);
        return () => {
            document.removeEventListener('mousedown', closeToolTips);
        };
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty('--color-primary', localStorage.getItem('theme') == 'dark'
            ? getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.app_primary_color
            : selectedCurrency == 'WILD' ? '#20A402' : getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.app_primary_color
        )
        document.documentElement.style.setProperty('--color-onPrimary', localStorage.getItem('theme') != 'dark'
            ? "#FFFFFF"
            : selectedCurrency == 'WILD' ? '#000' : "#FFFFFF"
        )
    }, [selectedCurrency, localStorage.getItem('theme')])

    let currencyData = myPointsBalances?.data?.filter((token) => token?.currency_points?.currency_config?.currency === selectedCurrency)
    const { display_allowance_remaining, display_tips_received, display_todays_allowance, currency_points } = currencyData?.length != 0 && currencyData != undefined ? currencyData[0] : {
        display_allowance_remaining: '-',
        display_balance: '-',
        display_tips_received: '-',
        display_todays_allowance: '-',
        currency_points: { currency_config: { currency: selectedCurrency } }
    }
    currencyData = currencyData?.length != 0 && currencyData != undefined ? currencyData[0] : {};

    return (
        <div>
            <div className={`sticky top-0 z-30 bg-white dark:bg-background transition-transform duration-1000 ${showHeader ? 'translate-y-0' : 'translate-y-[-80px] lg:translate-y-0'} `}>
                <TopHeader PageTitle={'Points'} tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} showTabsInHeader={false} tabPosition={'top'} />
            </div>
            <UserPoints />
            {selectedCurrency != 'WILD' &&
                <div onClick={() => setShowRules(true)} className='cursor-pointer flex justify-end items-center gap-1 w-full translate-y-[-30px]'>
                    <div className='border dark:border-outline hover:border-black/20 dark:hover:border-white/20 text-subTxtOnLightBg dark:text-onBackgroundII px-2 rounded-sm prose-BodyMediumBold flex items-center w-fit mt-1'>Rules <Info className='fill-gray-400' /></div>
                </div>}


            {selectedCurrency == 'WILD' ? <div className='mx-2 bg-[#e8e8e8] dark:bg-surface border border-outline py-4 px-4 rounded-md mt-4'>
                <p className='prose-BodyLargeBold flex items-center gap-1 mb-2 text-txtOnLightBg dark:text-onBackground'>WILD info <Info size={16}/> </p>
                <p className='prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground'>Please note that <span className='text-primary'>WILD</span> Tipping is no longer available or valid on the <span className='text-primary'>Wildcard app</span>.</p>
                <p className='prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground mt-1'>We're working on the next steps and will have an announcement for you soon.</p>
            </div> :
                <>
                    {currencyData?.currency_points?.currency_config?.tippable && <>
                        <div className={`w-full mt-4 flex gap-2 relative px-5`}>
                            <div ref={allowanceTipRef} className='z-[101] max-w-1 absolute '>
                                {toolTipOpen.allowance && <ToolTip pointer='top' text={toolTipText('allowance', selectedCurrency)} />}
                            </div>
                            <div ref={receivedTipRef} className='z-[101] max-w-1 absolute'>
                                {toolTipOpen.received && <ToolTip pointer='top' text={toolTipText('received', selectedCurrency)} />}
                            </div>
                            <div ref={revenueTipRef} className='z-[101] max-w-1 absolute'>
                                {toolTipOpen.revenue && <ToolTip pointer='top' text={toolTipText('revenue', selectedCurrency)} />}
                            </div>

                            <div className='flex gap-4 overflow-x-auto removeScrollBar  w-full' onScroll={() => { closeToolTips() }}>
                                {display_tips_received && <div ref={receivedRef} className='border dark:border-outline bg-gray-200 dark:bg-surface flex flex-col items-start ps-2 pe-6 min-w-[180px] lg:min-w-[180px] max-w-[200px] w-full p-2 relative'>
                                    <p className='prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundIII flex items-center gap-1'>Tips Received
                                        <span>
                                            <Info size={12} weight='bold' className='fill-gray-500 dark:fill-[#ffffff80]' role='button' onClick={() => openToolTip('received')} />
                                        </span>
                                    </p>
                                    {pointsBalancesLoading ? <div className='h-5 w-14 bg-gray-500/20 animate-pulse mt-2 rounded-sm' /> :
                                        <p className='prose-HeaderLarge text-txtOnLightBg dark:text-onBackground'>{(display_tips_received)} {selectedCurrency}</p>
                                    }
                                </div>}
                                {currencyData?.display_ticket_tips_divident_received && <div ref={revenueRef} className='border dark:border-outline bg-gray-200 dark:bg-surface  flex flex-col items-start ps-2 pe-6 min-w-[180px] lg:min-w-[180px] max-w-[200px] w-full lg p-2 relative'>
                                    <p className='prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundIII flex items-center gap-1'>Tips Revenue
                                        <span>
                                            <Info size={12} weight='bold' className='fill-gray-500 dark:fill-[#ffffff80]' role='button' onClick={() => openToolTip('revenue')} />
                                        </span>
                                    </p>
                                    {pointsBalancesLoading ? <div className='h-5 w-14 bg-gray-500/20 animate-pulse mt-2 rounded-sm' /> :
                                        <p className='prose-HeaderLarge text-txtOnLightBg dark:text-onBackground'>{(currencyData?.display_ticket_tips_divident_received)} {selectedCurrency}</p>
                                    }
                                </div>}
                                <div ref={allowanceRef} className='border dark:border-outline bg-gray-200 dark:bg-surface flex flex-col items-start ps-2 pe-6 min-w-[180px] lg:min-w-[180px] max-w-[200px] w-full p-2 relative'>
                                    <p className='prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundIII flex items-center gap-1'>Tips Allowance
                                        <span>
                                            <Info size={12} weight='bold' className='fill-gray-500 dark:fill-[#ffffff80]' role='button' onClick={() => openToolTip('allowance')} />
                                        </span>
                                    </p>
                                    <p className='prose-HeaderLarge text-txtOnLightBg dark:text-onBackground'>
                                        {pointsBalancesLoading ? <div className='h-5 w-14 bg-gray-500/20 animate-pulse mt-2 rounded-sm' /> :
                                            <><span>{display_allowance_remaining || '0'}</span><span className='prose-LabelSmall'>/{display_todays_allowance || '0'}</span></>
                                        }
                                    </p>
                                </div>

                            </div>
                        </div>
                    </>}

                    <Tabs tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} fullWidthTab={true} type='top' />
                    
                    <div className='mt-2 hidden md:block'>
                        {isLoading ? <div className='relative min-h-20'><Spinner /></div> :
                            <SwipeableViews style={{ height: 'auto' }} index={tabs.findIndex(tab => tab.id === selectedTab)} onChangeIndex={handleTabChange}>
                                <div>
                                    {selectedTab === 'tips' && <TipsTab />}
                                </div>
                                <div>
                                    {selectedTab === 'revenue' && <RevenueTab />}
                                </div>
                            </SwipeableViews>
                        }
                    </div>

                    <div className='mt-2 block md:hidden'>
                        {isLoading ? <div className='relative min-h-20'><Spinner /></div> :
                            <SwipeableViews style={{ height: 'auto' }} index={tabs.findIndex(tab => tab.id === selectedTab)} onChangeIndex={handleTabChange}>
                                <div>
                                    {selectedTab === 'quests' && <QuestsTab />}
                                </div>
                                <div>
                                    {selectedTab === 'tips' && <TipsTab />}
                                </div>
                                <div>
                                    {selectedTab === 'revenue' && <RevenueTab />}
                                </div>
                            </SwipeableViews>
                        }
                    </div>
                </>
            }

            {showCurrencyModal &&
                <CustomModal isOpen={showCurrencyModal} closeModal={() => { setShowCurrencyModal(false) }} modalPositionTop={'50'}>
                    <CurrencyModal setShowCurrencyModal={showCurrencyModal} />
                </CustomModal>
            }

            {showRules &&
                <CustomModal isOpen={showRules} closeModal={() => setShowRules(false)} modalPositionTop={'50'}>
                    <div className='bg-white dark:bg-background min-w-[390px] border dark:border-outline rounded-sm p-5'>
                        <div className='flex justify-between items-center'>
                            <X onClick={() => setShowRules(false)} className="cursor-pointer fill-black dark:fill-[#ffffffa6]" />
                        </div>

                        <div className='flex flex-col gap-5 mt-3'>
                            <div>
                                <div className='text-txtOnLightBg dark:text-onBackground'>{highlightWord(currencyData?.currency_points?.currency_config?.description)}</div>
                                {currencyData?.currency_points?.currency_config?.allocation_tiers && <div className='mt-2'>
                                    <div className='text-txtOnLightBg dark:text-onBackground prose-BodyLargeBold'>Allocation tiers:</div>
                                    <div className='mt-1'>
                                        {currencyData?.currency_points?.currency_config?.allocation_tiers?.length && currencyData?.currency_points?.currency_config?.allocation_tiers?.map((item, idx) => (
                                            <div key={idx} className='flex gap-2 items-center mb-1'>
                                                <div className='h-2 w-2 bg-txtOnLightBg dark:bg-onBackground rounded-full'/>
                                                <div className='flex items-center gap-1'>
                                                    <div className='text-txtOnLightBg dark:text-onBackground'>{highlightWord(item?.title)}</div>
                                                    <div className='text-txtOnLightBg dark:text-onBackground'>{item?.value ? "= " + item?.value : null}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>}
                                <div className='mt-2 text-txtOnLightBg dark:text-onBackground'>{highlightWord(currencyData?.currency_points?.currency_config?.currency_external_link)}</div>
                            </div>

                            {selectedCurrency != 'WILD'  &&
                                selectedCurrency != 'HAM' && currencyData?.currency_points?.currency_config?.buy_url &&
                                <a href={currencyData?.currency_points?.currency_config?.buy_url} target='_blank' className='break-all prose-BodyLargeBold text-onBackground dark:text-onPrimary  bg-primary max-w-fit px-4 py-1 rounded-sm'>Buy ${selectedCurrency}</a>
                            }
                        </div>

                    </div>
                </CustomModal>
            }
        </div>
    )
}

export default PointsPage
