import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedCastDetails } from '../features/globalStateSlice';
import { displaySnackbar, TippingMoxie } from '../features/thunkMiddleware';
import { sendTip } from '../services/api';
import { formatPrice } from '../utils/helper';
import useMoxieBalance from './useMoxieBalance';



const useCastTipping = (selectedTip, selectedToken, setModalIsOpen, refetch = () => { }, refetchUserdetail = () => { }, toTipWalletAddress) => {
    let tippingCountFailed = 0

    const dispatch = useDispatch()
    const { sendMoxie } = useMoxieBalance()

    const [isTipLoading, setIsTipLoading] = useState(false);
    const [isTipSuccess, setIsTipSuccess] = useState(false);
    const [isTipFailed, setIsTipFailed] = useState(false);
    const [amount, setAmount] = useState(0);

    const closeModal = useCallback(() => {
        setModalIsOpen(false);
    }, [setModalIsOpen]);

    const handleTipFailure = (amount) => {
        if (tippingCountFailed < 3) {
            tippingCountFailed++;
            handleTip(amount);
        } else if (tippingCountFailed === 3) {
            setIsTipFailed(true)
            dispatch(displaySnackbar('Failed to tip'));
        }
        setIsTipSuccess(false);
        closeModal();
    }

    const handleTip = useCallback((amount) => {
        setIsTipLoading(true);
        setAmount(amount);
        const tipAmount = parseFloat(amount);
        const fid = selectedTip?.farcaster_user?.fid;
        const hash = selectedTip?.cast?.id;
        const channelUrl = selectedTip?.channel?.url || null;
        const currency = selectedToken;
        if (!hash || !fid) {
            return;
        }
        const req = {
            hash: hash,
            fid: fid,
            amount: tipAmount,
            channelUrl: channelUrl,
            currency: currency,
        };

        if (amount > 0) {
            if (currency === 'MOXIE') {
                dispatch(TippingMoxie(true))
                sendMoxie(amount, selectedTip?.farcaster_user?.display_name, toTipWalletAddress).then((res) => {
                    dispatch(TippingMoxie(false))
                })
            }
            sendTip(req).then((res) => {
                setTimeout(() => {
                    setIsTipLoading(false);
                }, 1000);

                if (res?.data?.tip === 'success') {
                    setTimeout(() => {
                        setIsTipSuccess(true);
                    }, 1000);
                    setTimeout(() => {
                        setIsTipSuccess(false);
                        closeModal();
                        refetch()
                        refetchUserdetail()

                        if (currency === "WILD") {
                            dispatch(setSelectedCastDetails({
                                cast: {
                                    ...selectedTip,
                                    tips_24h: {
                                        value: selectedTip.tips_24h.value + tipAmount,
                                        display_value: formatPrice(selectedTip.tips_24h.value + tipAmount),
                                        currency: 'WILD'
                                    }
                                }, fromEmbed: false
                            }))
                        }
                    }, 2000);
                } else {
                    refetch()
                    refetchUserdetail()
                    handleTipFailure(amount);
                }
            });

        }
    }, [selectedTip, closeModal, handleTipFailure]);

    return {
        isTipLoading,
        isTipSuccess,
        handleTip,
        isTipFailed,
        amount,
        selectedToken
    };
};

export default useCastTipping;
