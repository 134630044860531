import { Clock, NotePencil, Trash, X } from '@phosphor-icons/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsCastModalOpen } from '../../features/globalStateSlice.js';
import { displaySnackbar } from '../../features/thunkMiddleware.js';
import { deleteDraft, editScheduleCast, submitDraft } from '../../services/api.js';
import { CAST_MODAL_TAB_CHOICES } from '../../utils/constants.js';
import { mapToPercentageInterval } from '../../utils/helper.js';
import DraftTab from '../Cards/CastModal/DraftsTab.jsx';
import ScheduledTab from '../Cards/CastModal/ScheduleTab.jsx';
import CastCards from '../Cards/Casts/CastCards.jsx';
import Tabs from '../Tabs.jsx';
import CastInput from './CastModal/Components/CastInput.jsx';
import ChannelSelector from './CastModal/Components/ChannelSelector.jsx';
import MediaSelector from './CastModal/Components/MediaSelector.jsx';
import ScheduleOptions from './CastModal/Components/ScheduleOptions.jsx';
import UrlPreviewer from './CastModal/Components/UrlPreviewer.jsx';
import useSubmitCast from './useSubmitCast.jsx';
import { formatForCastAction } from '../../hooks/signer.jsx';

import useAccount from '../../hooks/account.jsx';

const initialTabs = [
    {
        name: CAST_MODAL_TAB_CHOICES.CAST,
        isActive: true,
    },
    {
        name: CAST_MODAL_TAB_CHOICES.DRAFTS,
        isActive: false,
    },
    {
        name: CAST_MODAL_TAB_CHOICES.SCHEDULED,
        isActive: false,
    }
];

const isImage = (url) => {
    return url?.startsWith('https://supercast.com/') ||
        url?.startsWith('https://imagedelivery.net/') ||
        url?.startsWith('https://static-wildcard-prod.nyc3.cdn.digitaloceanspaces.com/') ||
        url?.startsWith('https://media.tenor.com/') ||
        url?.startsWith('http://res.cloudinary.com/') ||
        url?.startsWith('https://res.cloudinary.com/') ||
        url?.startsWith('https://i.imgur.com/');
};

const CastModal = ({ closeModal, castDetails, channelDetail, fromReplyCastModal, isQuoteCast = false }) => {
    const dispatch = useDispatch()
    const [selectedTab, setSelectedTab] = useState(CAST_MODAL_TAB_CHOICES.CAST);
    const [tabs, setTabs] = useState(initialTabs);
    const { handleSignerValidate } = useAccount();

    const inputRef = useRef(null);
    const modalRef = useRef(null);
    const [inputValue, setInputValue] = useState('')
    const [disableCast, setDisableCast] = useState(true)

    const [selectedChannel, setSelectedChannel] = useState('');
    const { isSubmittingCast, handleSubmitCast, uploadMedia } = useSubmitCast();
    const [modalSize, setModalSize] = useState({ width: 0 });
    const [mediaPreview, setMediaPreview] = useState([]);
    const [curCastDetails, setCurCastDetails] = useState(castDetails);
    const [isCurCastQuote, setIsCurCastQuote] = useState(isQuoteCast);

    const [showWebSave, setShowWebSave] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const [currSelectedDraft, setCurrSelectedDraft] = useState('');
    const [isDragging, setIsDragging] = useState(false);

    const [selectedScheduleType, setSelectedScheduleType] = useState(`Don't Repeat`);
    const [scheduleOptionsOpen, setScheduleOptionsOpen] = useState(false);
    const [timeValue, setTimeValue] = useState(null);
    const [dateValue, setDateValue] = useState(null);
    const [repeatSchedule, setRepeatSchedule] = useState(1);
    const [isEditingScheduled, setIsEditingScheduled] = useState(false);
    const [selectedScheduleId, setSelectedScheduleId] = useState(null);

    const allowedMedia = isCurCastQuote ? 1 : 2;

    useEffect(() => {
        dispatch(setIsCastModalOpen(true));
        return () => {
            dispatch(setIsCastModalOpen(false));
        }
    }, [])

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setModalSize({
                    width: entry.contentRect.width,
                });
            }
        });

        if (modalRef.current) {
            resizeObserver.observe(modalRef.current);
        }

        if (inputRef.current) {
            inputRef.current.focus()
        }

        return () => {
            if (modalRef.current) {
                resizeObserver.unobserve(modalRef.current);
            }
        };

    }, []);


    useEffect(() => {
        if ((inputValue?.length && inputValue?.length <= 1024) || mediaPreview.length > 0) {
            setDisableCast(false);
        } else {
            setDisableCast(true);
        }
    }, [inputValue, mediaPreview]);

    const handleTabClick = (name) => {
        const newTabs = tabs.map((tab) => ({
            ...tab,
            isActive: tab.name === name
        }));
        setTabs(newTabs);
        setSelectedTab(name);
    };

    const handleCast = async () => {

        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const parentCastHash = curCastDetails?.cast?.id;
        const parentCastFid = curCastDetails?.farcaster_user?.fid;
        if (!scheduleOptionsOpen) {
            handleSubmitCast(inputValue, mediaPreview, selectedChannel?.id || undefined, parentCastHash, parentCastFid, currSelectedDraft, undefined, undefined, isCurCastQuote);
            closeModal();
        }
        else {
            const timestamp = createTimestampFromInputs(dateValue, timeValue);
            let selectedDateTime = new Date(timestamp);
            let scheduleInfo = undefined;
            const now = new Date();
            if (selectedDateTime < now) {
                selectedDateTime = undefined;
                dispatch(displaySnackbar('Please select a future date and time'));
                return;
            }
            else {
                selectedDateTime = selectedDateTime?.getTime();
                scheduleInfo = {
                    timestamp: selectedDateTime,
                    repeat: selectedScheduleType === 'Don\'t Repeat' ? undefined : selectedScheduleType,
                    repeat_count: repeatSchedule
                }
            }

            const response = await handleSubmitCast(inputValue, mediaPreview, selectedChannel?.id || undefined, parentCastHash, parentCastFid, currSelectedDraft, undefined, undefined, isCurCastQuote, scheduleInfo);
            const isSuccess = response?.data?.success
            if (!fromReplyCastModal) {
                setInputValue('');
                setMediaPreview([]);
                setIsCurCastQuote(false);
                setCurCastDetails(null);
                setScheduleOptionsOpen(false);
                setSelectedScheduleType(`Don't Repeat`);
                setRepeatSchedule(1);
                setSelectedChannel({ id: 'custom_global', name: "Global" });
                setCurrSelectedDraft('');
                if (isSuccess) {
                    handleTabClick(CAST_MODAL_TAB_CHOICES?.SCHEDULED);

                }
            }
            else
                closeModal();
        }
    };

    const wordProgress = mapToPercentageInterval((inputValue.length / 1024) * 100);

    const handleOpenScheduleOptions = () => {
        const date = new Date();
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let year = date.getFullYear();
        const dateString = `${year}-${month}-${day}`;
        const timeString = date.toTimeString().split(' ')[0].slice(0, 5);
        setDateValue(dateString);
        setTimeValue(timeString);
        setScheduleOptionsOpen(prev => !prev);
    }

    const handleInputsFromTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let year = date.getFullYear();
        const dateString = `${year}-${month}-${day}`;
        const timeString = date.toTimeString().split(' ')[0].slice(0, 5);

        setDateValue(dateString);
        setTimeValue(timeString);
    };

    const handleTimeChange = (e) => {
        setTimeValue(e.target.value);
    }

    const handleDateChange = (e) => {
        setDateValue(e.target.value);
    }

    const createTimestampFromInputs = (date, time) => {
        if (date && time) {
            const dateTimeString = `${date}T${time}:00`;
            return new Date(dateTimeString).getTime();
        }
        return null;
    };

    const handleRepeatInputChange = (e) => {
        const valueInt = parseInt(e.target.value);
        if (valueInt > 0)
            setRepeatSchedule(valueInt);
        else
            setRepeatSchedule(1);
    };

    const handleDeleteMedia = (data) => {
        const filteredMedia = mediaPreview.filter((media) => media?.url !== data.url)
        setMediaPreview(filteredMedia)
    }

    const handleSaveDraft = async () => {
        closeModal();
        try {
            const updatedUrls = await uploadMedia(mediaPreview)
            const response = await submitDraft(inputValue, selectedChannel || undefined, updatedUrls);
            if (response?.status == 200) {
                if (currSelectedDraft != '')
                    deleteDraft(currSelectedDraft)
                dispatch(displaySnackbar('Draft saved successfully'))
            }
            else
                dispatch(displaySnackbar('Failed to save draft'))
        }
        catch {
            dispatch(displaySnackbar('Failed to save draft'))
        }

    }

    const handleDraftClick = (draft) => {
        setInputValue(draft?.text)
        setMediaPreview(draft?.embeds)
        if (draft?.selected_channel)
            setSelectedChannel(draft?.selected_channel)
        else
            setSelectedChannel({ id: 'custom_global', name: "Global" })
        handleTabClick(CAST_MODAL_TAB_CHOICES?.CAST)
    };

    const handleEditScheduledCast = (scheduledCast) => {
        const currCast = scheduledCast?.cast;
        setInputValue(currCast?.cast?.body || '');

        const urls = currCast?.cast?.urls;
        const metaDatas = currCast?.cast?.metadata;

        if (currCast?.cast?.embeds?.length > 0) {
            const quotedCast = currCast?.cast?.embeds[0];
            setCurCastDetails(quotedCast);
            setIsCurCastQuote(true);
        }
        setSelectedChannel(currCast?.channel || { id: 'custom_global', name: "Global" });

        const timestamp = scheduledCast?.schedule_details?.scheduled_timestamp;
        handleInputsFromTimestamp(timestamp);
        setIsEditingScheduled(true);
        setScheduleOptionsOpen(true);
        setSelectedScheduleId(scheduledCast?.schedule_details?.id);

        const mediaUrls = urls.filter(url => {
            const metaData = metaDatas ? metaDatas[url] : null;
            return metaData?.is_frame ? false : (metaData?.media_type === 'video' || isImage(url) || metaData?.media_type === 'img');
        });

        const mediaPreviewArray = mediaUrls.map(url => {
            const metaData = metaDatas ? metaDatas[url] : null;
            return {
                url: url,
                isVideo: metaData?.media_type === 'video',
                isFrame: metaData?.is_frame
            };
        });

        setMediaPreview(mediaPreviewArray);
        handleTabClick(CAST_MODAL_TAB_CHOICES?.CAST);
    };

    const handleSubmitEdited = async () => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        setDisableCast(true);
        const timestamp = createTimestampFromInputs(dateValue, timeValue);
        const now = new Date();
        if (timestamp < now) {
            dispatch(displaySnackbar('Please select a future date and time'));
            return;
        }
        const castFid = curCastDetails?.farcaster_user?.fid;
        const castHash = curCastDetails?.cast?.id;

        let updatedMediaUrls = await uploadMedia(mediaPreview);
        if (isCurCastQuote) {
            if (updatedMediaUrls)
                updatedMediaUrls = [{ cast_id: { fid: castFid, hash: castHash } }, ...updatedMediaUrls];
            else
                updatedMediaUrls = [{ cast_id: { fid: castFid, hash: castHash } }];
        }

        const body = {
            cast_request: formatForCastAction({
                text: inputValue,
                embeds: updatedMediaUrls,
                channelUrl: selectedChannel?.id === 'custom_global' ? '' : selectedChannel?.id,
            }),
            timestamp: timestamp,
        }
        try {
            setScheduleOptionsOpen(false);
            setIsEditingScheduled(false);
            const response = await editScheduleCast(selectedScheduleId, body);
            if (response?.data?.success) {
                dispatch(displaySnackbar('Scheduled cast edited successfully'));
                setSelectedScheduleId(null);
                setInputValue('');
                setMediaPreview([]);
            }
            else {
                dispatch(displaySnackbar('Failed to edit scheduled cast'));
            }
        }
        catch (err) {
            dispatch(displaySnackbar('Failed to edit scheduled cast'));
        } finally {
            handleExitScheduleEdit();
            setDisableCast(false);
        }
    }

    const handleExitScheduleEdit = () => {
        setScheduleOptionsOpen(false);
        setIsEditingScheduled(false);
        setSelectedScheduleId(null);
        setInputValue('');
        setMediaPreview([]);
        setSelectedChannel({ id: 'custom_global', name: "Global" });
        setIsCurCastQuote(false);
        setCurCastDetails(null);
    }

    const handleCloseModal = (e) => {
        e.stopPropagation();
        if (fromReplyCastModal || isCurCastQuote) {
            closeModal();
            return
        }
        if ((inputValue?.length || mediaPreview?.length) > 0) {
            setShowSave(true)
        }
        else {
            closeModal()
        }
    }

    useEffect(() => {
        if ((inputValue?.length || mediaPreview?.length) > 0) {
            setShowWebSave(true)
        }
        else setShowWebSave(false)
    }, [inputValue, mediaPreview])

    const handlePaste = (e) => {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        let i = mediaPreview?.length;
        for (let item of items) {
            if (i >= allowedMedia) {
                dispatch(displaySnackbar(`Maximum media limit reached`))
                return
            }
            i++;
            if (item.type.indexOf('image') === 0) {
                const blob = item.getAsFile();
                if (!blob) return;
                const objectUrl = blob
                setMediaPreview((prev) => [...prev, { url: objectUrl }]);
            }
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            if (mediaPreview?.length >= allowedMedia) {
                dispatch(displaySnackbar(`Maximum media limit reached`))
                return
            }
            const objectUrl = files[0]
            setMediaPreview([...mediaPreview, { url: objectUrl }])
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };
    const handleKeyDown = (e) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
            e.preventDefault();
            handleCast();
        }
    };

    const handleRemoveQuotedCast = () => {
        setCurCastDetails(null);
        setIsCurCastQuote(false);
    }

    return (
        <>
            <div ref={modalRef} onClick={(e) => { e.stopPropagation(); setShowSave(false) }} className='w-full md:w-[495px] h-screen md:h-fit max-h-screen pt-0 md:pt-6 relative bg-white dark:bg-background flex flex-col border-0 md:border-[#FFFFFF26] md:border-[1px] overflow-hidden'>
                <div role='button' className='absolute top-2 right-2 hidden justify-end items-center md:flex'><X size={24}
                    onClick={(e) => { e.stopPropagation(); closeModal() }} className='fill-black dark:fill-white' />
                    {
                        showWebSave && (selectedTab === CAST_MODAL_TAB_CHOICES?.CAST) && !isEditingScheduled && !isCurCastQuote && !fromReplyCastModal && <div
                            role='button'
                            onClick={handleSaveDraft}
                            className='h-7 flex items-center justify-center w-[94px] border dark:border-outlineII rounded-full absolute -left-2 -translate-x-full hover:bg-outlineII'>
                            <NotePencil size={16} className='fill-black dark:fill-white' />
                            <span className='ml-2 text-txtOnLightBg dark:text-white text-xs'>Save Draft</span>
                        </div>
                    }
                </div>
                {
                    isEditingScheduled && <div className='h-10 md:flex items-center px-5 gap-2 prose-TitleMedium hidden '>
                        EDITING SCHEDULED <NotePencil size={16} />
                    </div>
                }
                <div>
                    {!(fromReplyCastModal) && !isEditingScheduled && <div className='w-fit md:block hidden'>
                        <Tabs tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} onToggle={ontoggle} fullWidthTab={true} fromCastModal={true} />
                    </div>}
                </div>
                <div className='grow flex flex-col overflow-hidden'>
                    {
                        selectedTab === CAST_MODAL_TAB_CHOICES?.CAST &&
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onPaste={handlePaste}
                            onKeyDown={handleKeyDown}
                            className='flex flex-col overflow-y-auto custom-scrollbar'>

                            <div className='px-5 py-3 flex items-center justify-between md:hidden gap-1 sticky z-10 bg-background top-0'>
                                {
                                    isEditingScheduled && <div className='h-10 flex items-center gap-1 prose-TitleSmall'>
                                        EDITING <NotePencil size={16} className='mb-[1px]' />
                                    </div>
                                }
                                {!isEditingScheduled && <button
                                    onClick={(e) => { handleCloseModal(e) }}
                                    className='prose-TitleSmall text-txtOnLightBg dark:text-onBackground'>CANCEL</button>}
                                {(!curCastDetails || !fromReplyCastModal || isCurCastQuote) && <>


                                </>
                                }
                                <div className='flex items-center gap-2'>
                                    {!isEditingScheduled && <><button
                                        onClick={() => handleTabClick(CAST_MODAL_TAB_CHOICES.DRAFTS)}
                                        className='prose-LabelLarge h-6 z-[] cursor-pointer flex items-center justify-center  text-primary md:hidden'>
                                        Drafts
                                    </button>
                                        <button
                                            onClick={() => handleTabClick(CAST_MODAL_TAB_CHOICES.SCHEDULED)}
                                            className='prose-LabelLarge h-6 z-[] cursor-pointer flex items-center justify-center  text-primary md:hidden'>
                                            Scheduled
                                        </button></>}
                                    {!isEditingScheduled && !fromReplyCastModal && <button onClick={handleOpenScheduleOptions} className='block md:hidden'>
                                        {!scheduleOptionsOpen ? <Clock size={24} className='cursor-pointer  fill-primary' /> : <X size={24} className='cursor-pointer fill-black dark:fill-white' />}
                                    </button>}
                                    {!isEditingScheduled && !scheduleOptionsOpen && <button onClick={() => handleCast()} disabled={disableCast} className={`${disableCast ? " opacity-60 bg-primary" : "bg-primary"} text-white dark:text-onPrimary prose-LabelLarge h-[26px] w-10 cursor-pointer flex items-center justify-center`}>
                                        {isSubmittingCast
                                            ? "..."
                                            : "Cast"
                                        }
                                    </button>}
                                    {!isEditingScheduled && scheduleOptionsOpen && <button onClick={() => handleCast()} disabled={disableCast} className={`${disableCast ? " opacity-60 bg-primary" : "bg-primary"} text-white dark:text-onPrimary prose-LabelLarge h-[26px] w-fit px-2 cursor-pointer flex items-center justify-center`}>
                                        Schedule
                                    </button>}
                                </div>

                                {isEditingScheduled && <div className='flex items-center gap-1'>
                                    <button onClick={() => handleExitScheduleEdit()} className={`bg-error text-onPrimary prose-LabelLarge h-[26px] w-fit px-2 cursor-pointer flex items-center justify-center`}>
                                        Cancel
                                    </button>
                                    <button onClick={() => handleSubmitEdited()} disabled={disableCast} className={`${disableCast ? "opacity-60" : "opacity-100"}  bg-primary text-onPrimary prose-LabelLarge h-[26px] w-10 cursor-pointer flex items-center justify-center`}>
                                        Save
                                    </button>
                                </div>
                                }
                            </div>

                            <div className='relative break-all'>
                                {curCastDetails && !isCurCastQuote && <CastCards castData={curCastDetails} i={0} refetchCasts={() => { }} fromReplyCastModal={true} />}
                                <div className='w-1 h-full absolute top-0 mt-5 left-[38px] border-r border-black/20 dark:border-white/20'></div>
                            </div>
                            <div
                                className={`mx-5 mt-5  border border-dashed border-transparent ${isDragging && 'border-[#B4F6A5] opacity-50'} flex flex-col`}>
                                <div className={`flex ${(inputValue.length < 200 && mediaPreview.length < 1) ? 'min-h-40' : ''}`}>
                                    <CastInput inputRef={inputRef} inputValue={inputValue} setInputValue={setInputValue} fromReplyCastModal={fromReplyCastModal} castDetails={curCastDetails} />
                                </div>
                                <UrlPreviewer mediaPreview={mediaPreview} setMediaPreview={setMediaPreview} inputValue={inputValue} allowedMedia={allowedMedia} />
                                <div className='flex flex-row gap-2 flex-wrap'>
                                    {!!mediaPreview &&
                                        mediaPreview.length > 0 &&
                                        mediaPreview?.map((media, index) => {
                                            if (media?.isFrame) return;
                                            const src = !!media?.url && media?.url?.size ? URL.createObjectURL(media?.url) : media?.url;
                                            const isVideo = media?.isVideo
                                            return (
                                                <div className='relative' key={index}>
                                                    {isVideo ?
                                                        <video src={!!src && src} alt='media' className='w-24 h-24 rounded-md object-cover aspect-square' />
                                                        :
                                                        <img src={!!src && src} alt='media' className='w-20 h-20 rounded-md object-cover aspect-square' />
                                                    }                                                    <span className='absolute -top-2 -right-2 bg-slate-800 rounded-full cursor-pointer'><X size={15} onClick={() => handleDeleteMedia(media)} /></span>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </div>
                                <div className='relative'>
                                    {
                                        isCurCastQuote && <div className='mt-2 relative break-all bg-gray-200 dark:bg-surface rounded-md'>
                                            <button className='p-1 rounded-full dark:bg-white bg-surface absolute top-4 right-4' onClick={handleRemoveQuotedCast}>
                                                <X size={20} className='fill-white dark:fill-surface' />
                                            </button>
                                            <div className='pointer-events-none'>
                                                <CastCards castData={curCastDetails} i={0} refetchCasts={() => { }} fromReplyCastModal={true} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        selectedTab === CAST_MODAL_TAB_CHOICES.DRAFTS &&
                        <DraftTab handleDraftClick={handleDraftClick} currSelectedDraft={currSelectedDraft} setCurrSelectedDraft={setCurrSelectedDraft} handleTabClick={handleTabClick} />
                    }
                    {
                        selectedTab === CAST_MODAL_TAB_CHOICES.SCHEDULED &&
                        <ScheduledTab handleTabClick={handleTabClick} handleEditScheduledCast={handleEditScheduledCast} />
                    }
                </div>
                {selectedTab === CAST_MODAL_TAB_CHOICES.CAST && <div className='w-full relative'>
                    <div className='flex justify-between items-center h-[52px] md:h-[60px] border-t border-outlineII mx-5'>
                        <MediaSelector inputRef={inputRef} inputValue={inputValue} setInputValue={setInputValue} setMediaPreview={setMediaPreview} mediaPreview={mediaPreview} allowedMedia={allowedMedia} />
                        <div className='flex items-center gap-4'>
                            <div>
                                {inputValue?.length > 0 &&
                                    <div>
                                        {inputValue?.length <= 1024 ?
                                            <div className="radial-progress bg-gray-200 dark:bg-surface text-primary" style={{ "--value": wordProgress, "--size": "21px", "--thickness": "2px" }} role="progressbar"></div>
                                            :
                                            <span className='prose-BodyMediumBold text-error'>
                                                {1024 - inputValue?.length}
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                            {(!curCastDetails || !fromReplyCastModal || isCurCastQuote) && <>
                                <div className=''>
                                    <ChannelSelector selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel} dropDown={false} isQuoteCast={isCurCastQuote} castDetails={castDetails} channelDetail={channelDetail} />
                                </div>
                            </>
                            }
                            {!isEditingScheduled && !fromReplyCastModal && <button onClick={handleOpenScheduleOptions} className='hidden md:block'>
                                {!scheduleOptionsOpen ? <Clock size={24} className='cursor-pointer  fill-primary' /> : <X size={24} className='cursor-pointer fill-black dark:fill-white' />}
                            </button>}
                            {
                                isEditingScheduled && <div className='flex items-center gap-2'>
                                    <button onClick={() => handleExitScheduleEdit()} className={`text-onPrimary bg-error/90 hover:bg-error prose-BodyLarge font-title  h-8 px-[10px] cursor-pointer hidden md:flex items-center justify-center`}>
                                        Cancel
                                    </button>
                                    <button onClick={() => handleSubmitEdited()} disabled={disableCast} className={`${disableCast ? "opacity-60 bg-primary" : "bg-primary custom-hover"} text-onPrimary prose-BodyLarge font-title  h-8 px-[10px] cursor-pointer hidden md:flex items-center justify-center`}>
                                        Save
                                    </button>
                                </div>
                            }
                            {scheduleOptionsOpen && !isEditingScheduled && <button onClick={() => handleCast()} disabled={disableCast} className={`${disableCast ? " opacity-60 bg-primary" : "bg-primary custom-hover"} text-onPrimary  font-[bungee] text-[14px] h-9 px-[10px] z-[] cursor-pointer hidden md:flex items-center justify-center`}>
                                Schedule
                            </button>}
                            {!scheduleOptionsOpen && !isEditingScheduled && < button onClick={() => handleCast()} disabled={disableCast} className={`${disableCast ? "opacity-60 bg-primary" : "bg-primary custom-hover"} text-onPrimary font-[bungee] text-[14px] h-9 px-4 rounded-sm z-[] cursor-pointer hidden md:flex items-center justify-center`}>
                                {isSubmittingCast
                                    ? "Casting..."
                                    : "Cast"
                                }
                            </button>}
                        </div>
                    </div>
                    {scheduleOptionsOpen && <ScheduleOptions timeValue={timeValue} handleTimeChange={handleTimeChange} dateValue={dateValue} handleDateChange={handleDateChange} selectedScheduleType={selectedScheduleType} setSelectedScheduleType={setSelectedScheduleType} repeatSchedule={repeatSchedule} handleRepeatInputChange={handleRepeatInputChange} isEditingScheduled={isEditingScheduled} />}
                </div>}
                {
                    showSave && <div className='h-[192px] pt-9 pb-6 bg-background z-20 absolute bottom-0 left-0 w-full flex flex-col border-t border-outlineII md:hidden'>
                        <div className='prose-HeaderSmall mx-5'>
                            <div
                                role='button'
                                onClick={() => closeModal()}
                                className='text-[#900B09] mb-6 flex items-center'>
                                <Trash size={24} />
                                <p>Delete Cast</p>
                            </div>
                            <div className='text-subTxtOnLightBg dark:text-onBackgroundII flex items-center'
                                role='button'
                                onClick={() => handleSaveDraft()}>
                                <NotePencil size={24} />
                                <p>Save Draft</p>
                            </div>
                        </div>
                        <div
                            role='button'
                            onClick={() => setShowSave(false)}
                            className='h-9 mt-6 mx-5 border border-onBackground text-onBackgroundII font-title leading-6 tracking-[0.01em] flex justify-center items-center'>
                            Cancel
                        </div>
                    </div>
                }
            </div >
        </>
    )
}

export default CastModal;