import { ChatCircleDots, Coins, Heart, Pen, Repeat, Ticket } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import degen from '../../../assets/images/degen.png';
import tree from '../../../assets/images/tree.png';
import EmbeddedCast from '../../../components/Cards/Casts/EmbeddedCast';
import FrameCard from '../../../components/Cards/Casts/components/card/FrameCard';
import CastModal from '../../../components/Modals/CastModal';
import TipModal from '../../../components/Modals/TipModal';
import CustomModal from '../../../components/UI/CustomModal';
import { displaySnackbar } from '../../../features/thunkMiddleware';
import useCastAction from '../../../hooks/signer';
import { getCurrentUserProfile } from '../../../services/api';
import { timeSince } from '../../../utils/helper';

const getCurrencyLogo = (token) => {
    switch (token) {
        case 'DEGEN': return degen;
        case 'TREE': return tree;
    }
}

const isNestedCast = (obj) => {
    if ('cast_id' in obj && obj?.cast_id != null && obj?.cast_details != null) {
        return true;
    }
    return false;
};


const isImage = (url) => {
    return url?.startsWith('https://supercast.com/') || url?.startsWith('https://imagedelivery.net/') || url?.startsWith('https://static-wildcard-prod.nyc3.cdn.digitaloceanspaces.com/') || url?.startsWith('https://media.tenor.com/') || url?.startsWith('http://res.cloudinary.com/') || url?.startsWith('https://res.cloudinary.com/') || url?.startsWith('https://i.imgur.com/')
};

const TrendingCard = ({ castData = {}, isRepliesCast, i, refetchCasts, fromReplyCastModal, selectedCurrency = 'WILD', fromNotificationReply, setIsCastModalOpen = () => { } }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { removeCast, likeCast, unLikeCast, recastCast, unRecastCast } = useCastAction()
    const { currentUser } = useSelector(state => state.app)

    const [textHidden, setTextHidden] = useState(true);
    const [showTipModal, setShowTipModal] = useState(false);
    const [showBuyModal, setShowBuyModal] = useState(false);
    const [showReplyCastModal, setShowReplyCastModal] = useState(false);
    const [isReactionLoading, setIsReactionLoading] = useState(false)

    const [showImgModal, setShowImgModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')

    const [currLikes, setCurrLikes] = useState(castData?.likes);
    const [currRecasts, setCurrRecasts] = useState(castData?.recasts);
    const [hasLiked, setHasLiked] = useState(castData?.user_reaction?.has_liked);
    const [hasRecasted, setHasRecasted] = useState(castData?.user_reaction?.has_recasted);
    const [filteredEmbeds, setFilteredEmbeds] = useState([]);
    const [framesData, setFramesData] = useState([])
    const [showCastOptions, setShowCastOptions] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const [showRecastOptions, setshowRecastoptions] = useState(false);
    const [showQuoteCastModal, setShowQuoteCastModal] = useState(false);

    const { data: profileData, isLoading: isProfileLoading, refetch: profileDataRefetch } = useQuery({
        queryKey: ['getCurrentUserProfile'],
        queryFn: getCurrentUserProfile,
    });
    const { cast, farcaster_user, replies, channel, tips_24h, tips_24h_all, frames } = castData || {};
    const tipsData = tips_24h_all?.filter(token => token?.currency === selectedCurrency)[0];

    const isCastDetailed = pathname === `/cast/${cast?.id}/${farcaster_user?.fid}`;


    const hasNestedCast = cast?.embeds?.some(embed => isNestedCast(embed))
    const hasImage = cast?.embeds?.some(embed => isImage(embed.url));
    const castEmbedsImgs = cast?.embeds?.filter(embed => isImage(embed.url)) || [];
    const nestedCastEmbeds = cast?.embeds?.filter(embed => isNestedCast(embed)) || [];

    useEffect(() => {
        const filterAndSetEmbeds = async () => {
            const filtered = await filterEmbeds(cast);
            setFilteredEmbeds(filtered);
        };
        filterAndSetEmbeds();
    }, [cast?.embeds, framesData]);

    const filterEmbeds = async (cast) => {
        var embeds = cast?.embeds
        if (!Array.isArray(embeds)) {
            return [];
        }

        const frameURLs = [];
        if (framesData?.length) {
            for (const frameObj of framesData) {
                frameURLs.push(frameObj.frames_url)
            }
        }
        const filtered = [];

        for (const embed of embeds) {
            if (embed?.url) {
                if (!embed?.url in frameURLs) {
                    filtered.push({ url: embed.url });
                }
            }
        }

        return filtered;
    };

    const updateRecastValue = () => {
        if (hasRecasted)
            setCurrRecasts(prev => prev - 1)
        else
            setCurrRecasts(prev => prev + 1)
        setHasRecasted(prev => !prev)
        setshowRecastoptions(prev => !prev);
    }

    const handleShowRecastOptions = () => {
        setshowRecastoptions(prev => !prev);
    }

    const handleRecastOptions = (e, type) => {
        e.stopPropagation()
        if (type === 'quote') {
            handleOpenQuoteCastModal()
        }
        if (type === 'recast') {
            handleReCast(cast?.id, farcaster_user?.fid)
            updateRecastValue();

        }
    }


    const handleTextHidden = (e) => {
        e.stopPropagation();
        setTextHidden((prev) => !prev);
    }

    const handleCastDetailed = () => {
        if (fromNotificationReply) return
        navigate(`/cast/${cast?.id}/${farcaster_user?.fid}`)
    }

    const handleUserProfileNavigate = (e) => {
        e.stopPropagation();
        navigate(`/profile/${farcaster_user?.username}`)
    }

    const handleOpenTipModal = (e) => {
        e.stopPropagation();
        if (profileData?.data?.farcaster_user?.fid === farcaster_user?.fid) return
        setShowTipModal(true)
        setIsCastModalOpen(true)
    }
    const handleCloseTipModal = () => {
        setShowTipModal(false)
    }

    const handleBuyModal = (e) => {
        e.stopPropagation();
        setShowBuyModal(true)
    }
    const handleCloseBuyModal = () => {
        setShowBuyModal(false)
    }

    const handleOpenReplyCastModal = (e) => {
        e.stopPropagation();
        setShowReplyCastModal(true)
        setIsCastModalOpen(true)
    }
    const handleCloseReplyCastModal = () => {
        setShowReplyCastModal(false)
    }
    const handleOpenQuoteCastModal = () => {
        setShowQuoteCastModal(true)
        setIsCastModalOpen(true)
    }
    const handleCloseQuoteCastModal = () => {
        setShowQuoteCastModal(false)
    }

    const handleLikeReaction = (e) => {
        e.stopPropagation();
        setIsReactionLoading(true)
        if (hasLiked) {
            unLikeCast(cast?.id, farcaster_user?.fid)
        } else {
            likeCast(cast?.id, farcaster_user?.fid)
        }
    }

    const handleReCast = (hash, fid) => {
        if (hasRecasted) {
            unRecastCast(hash, fid)
        } else {
            recastCast(hash, fid)
        }
    }

    const handleOpenImageModal = (e, url) => {
        e.stopPropagation();
        setSelectedImage(url);
        setShowImgModal(prev => !prev);
    }
    const handleCloseImageModal = () => {
        setShowImgModal(false);
    }

    const handleCopyCastUrl = () => {
        const isLocalHost = window?.location?.host?.includes('localhost:');
        const url = `${window?.location?.host}/cast/${cast?.id}/${farcaster_user?.fid}`
        const copyValue = isLocalHost ? url : 'https://' + url;
        navigator.clipboard.writeText(copyValue)
            .then(() => {
                dispatch(displaySnackbar('Copied!'))
            })
            .catch((error) => console.error('Failed to copy:', error));
    }

    const handleDeleteCast = async () => {
        setTimeout(() => {
            setIsDeleted(true);
        }, 1000)
        try {
            const response = await removeCast(cast?.id);
            const result = response?.data.success
            if (result) {
                dispatch(displaySnackbar('Cast deleted successfully'))
            }
            else
                dispatch(displaySnackbar('Failed to delete'))
        }
        catch (err) {
            console.log(err);
            dispatch(displaySnackbar('Failed to delete'))
        }

    }

    useEffect(() => {
        document.addEventListener('click', () => {
            setShowCastOptions(false);
        })

        return document.removeEventListener('click', () => {
            setShowCastOptions(false);
        })
    }, [])

    useEffect(() => {
        setFramesData(frames)
    }
        , [frames])

    return (
        <>
            <div key={i} onClick={handleCastDetailed} className={`${isDeleted ? 'hidden' : ''} h-auto border gap-4 pt-4 border-gray-300 dark:border-outlineII rounded px-5 w-full pb-2 cursor-pointer ${!fromReplyCastModal ? 'lg:hover:bg-white/5 border-b' : ''}`}>
                <div className='w-full cursor-pointer'>

                    <div className='flex items-start gap-4 w-full'>
                        <div className='w-full  items-start flex gap-2 justify-start'>
                            {/* <img src={farcaster_user?.pfp_url || farcaster_user?.cdn_url || logo} alt="cast" className={`size-8 object-cover aspect-square rounded-full bg-background ${isRepliesCast ? "border-2 border-outline" : ""}`} /> */}
                            <div className='flex flex-row gap-2 justify-between w-full items-center'>
                                <span onClick={(e) => handleUserProfileNavigate(e)} className='prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground'>@{farcaster_user?.username}</span>
                                <div>

                                    <span className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII'>{`• ${timeSince(cast?.timestamp)}`}</span>
                                </div>
                                {/* <span className='prose-BodyMedium'>@{farcaster_user?.username}</span> */}
                            </div>
                        </div>
                    </div>
                    <div className='mt-4 flex justify-center flex-col gap-2 w-full'>
                        <div className='w-full'>
                            {
                                cast?.urls?.map((url, index) => {
                                    const data = cast?.url_metadatas[url] || null;
                                    if (data == null) return;
                                    if (data?.is_frame) return;
                                    if (data?.content_type === 'text/html')
                                        return (
                                            <div key={url} className='border dark:border-outlineII bg-white dark:bg-background p-2 rounded-md relative' onClick={(e) => e.stopPropagation()}>
                                                <a href={url} target="_blank" rel="noopener noreferrer" className='absolute inset-0 size-full'></a>
                                                <div className='flex gap-3 items-start'>
                                                    {data?.image && <img src={data?.image} alt={data?.title} className='size-full dark:bg-white/10 bg-background/10 rounded-sm object-contain' />}
                                                </div>
                                            </div>
                                        );
                                })
                            }
                        </div>
                        <div className='w-full flex items-center gap-2'>
                            {
                                cast?.urls?.map((url, index) => {
                                    const metaData = cast?.url_metadatas ? cast?.url_metadatas[url] : null;
                                    if (isImage(url)) return (
                                        <div key={index} className='relative w-full h-[200px] md:h-[300px]'>
                                            <img src={url} alt="embed" className='w-full h-full object-contain rounded-lg cursor-pointer' onClick={(e) => handleOpenImageModal(e, url)} />
                                        </div>
                                    )
                                    if (metaData == null) return;
                                    if (metaData?.media_type === 'img' || isImage(url))
                                        return (
                                            <div key={index} className='relative w-full h-[200px] md:h-[300px]'>
                                                <img src={url} alt="embed" className='w-full h-full object-contain rounded-lg cursor-pointer' onClick={(e) => handleOpenImageModal(e, url)} />
                                            </div>
                                        )

                                })
                            }
                        </div>
                        <div className='w-full flex items-center gap-2'>
                            {
                                cast?.urls?.map((url, index) => {
                                    if (url.startsWith('https://stream.warpcast.com/v1/video/')) {
                                        return <div className='relative w-full h-[200px] md:h-[300px]'>
                                            <ReactPlayer key={index} url={url} controls={true} width='100%' height='100%' />
                                        </div>
                                    }

                                })
                            }
                        </div>
                        {!fromReplyCastModal && framesData && framesData?.length > 0 &&
                            <div onClick={e => e.stopPropagation()} className={`flex flex-col ${castEmbedsImgs?.length > 1 ? 'w-1/2' : 'w-full'} border dark:border-outlineII rounded-lg flex-row overflow-hidden border-default justify-around`}>
                                {framesData?.map((embed, index) => (<React.Fragment key={index}>  <FrameCard embed={embed} cast={cast} castUser={farcaster_user} setFramesData={setFramesData} /> </React.Fragment>)
                                )}
                            </div>
                        }
                    </div>

                    {!fromReplyCastModal && <div className='mt-4'>
                        {hasNestedCast &&
                            <div className="flex flex-col mb-4 gap-2 overflow-hidden border-default w-full rounded-lg">
                                {
                                    nestedCastEmbeds?.map((embeds, idx) => (
                                        <EmbeddedCast data={embeds} key={idx} />
                                    ))
                                }
                            </div>
                        }
                    </div>
                    }

                    {!isRepliesCast && isCastDetailed && !fromReplyCastModal && (
                        <div className="flex gap-2 mt-10 mb-4 md:justify-center items-center md:-translate-x-10">
                            <button onClick={(e) => { handleBuyModal(e) }} className="bg-primary text-onPrimary prose-TitleLarge flex items-center justify-center gap-1 py-2 mt-3 w-[150px]"><Ticket size={20} color="black" weight="fill" /> BUY</button>
                            <button onClick={(e) => { handleOpenTipModal(e) }} className="bg-primary text-onPrimary prose-TitleLarge flex items-center justify-center gap-1 py-2 mt-3 w-[150px]"><Coins size={20} color="black" weight="fill" /> TIP</button>
                        </div>
                    )}

                    <>
                        {/* <div className='mt-4'>
                            {channel?.name && <span
                                role='button'
                                onClick={(e) => { e.stopPropagation(); navigate(`/channel/${channel?.id}`) }}
                                className='prose-LabelSmall text-onBackgroundII text-center ms-2 px-3 py-1 rounded-full bg-white/5 hover:bg-outline'>{channel?.name}</span>}
                        </div> */}

                        <div className='flex flex-col mt-4 mb-2 w-full'>
                            <span className='mb-2'>
                                {channel?.name && <span
                                    role='button'
                                    onClick={(e) => { e.stopPropagation(); navigate(`/channel/${channel?.id}`) }}
                                    className='prose-LabelSmall text-subTxtOnLightBg dark:text-onBackgroundII ms-2 px-3 py-1 text-center rounded-full bg-background/5 dark:bg-surface hover:bg-background/10 dark:hover:bg-outline'>{channel?.name}</span>}
                            </span>
                            <div className='flex items-center gap-2 md:gap-4'>
                                <div onClick={handleOpenReplyCastModal} className='flex items-center group'>
                                    <span className='  rounded-full md:p-[7px] pr-1 transition duration-[450ms]'>
                                        <ChatCircleDots size={16} className='group-hover:fill-btnColorOnLightBg fill-black dark:fill-[#ffffffa6]' />
                                    </span>
                                    <span className='prose-LabelSmall text-txtOnLightBg dark:text-onBackgroundII group-hover:text-btnColorOnLightBg '>{replies}</span>
                                </div>
                                <div onClick={(e) => {
                                    e.stopPropagation();
                                    handleShowRecastOptions();
                                }}
                                    className='flex items-center group cursor-pointer relative'
                                >
                                    <span className='  rounded-full md:p-[7px] pr-1 transition duration-[450ms]'>
                                        <Repeat size={16}
                                            className={`group-hover:fill-btnColorOnLightBg dark:group-hover:fill-primary  ${hasRecasted ? "fill-primary" : "fill-black dark:fill-[#FFFFFFA6]"}`} />
                                    </span>
                                    <div className={`${showRecastOptions ? 'scale-1' : 'scale-0'} absolute bottom-5 left-4 transition duration-300 bg-white/10 backdrop-blur-sm p-2 rounded-sm`}>
                                        <div role='button' onClick={(e) => { handleRecastOptions(e, 'recast') }} className='flex items-center gap-2 hover:bg-white/20 rounded-sm p-1'><Repeat size={16} />Recast</div>
                                        <div role='button' onClick={(e) => { handleRecastOptions(e, 'quote') }} className='flex items-center gap-2 hover:bg-white/20 rounded-sm p-1'><Pen size={16} />Quote</div>
                                    </div>
                                    <span className='prose-LabelSmall text-txtOnLightBg dark:text-onBackgroundII group-hover:text-btnColorOnLightBg'>{parseInt(currRecasts)}</span>
                                </div>
                                <div onClick={(e) => {
                                    handleLikeReaction(e)
                                    if (hasLiked)
                                        setCurrLikes(prev => prev - 1)
                                    else
                                        setCurrLikes(prev => prev + 1)
                                    setHasLiked(prev => !prev)
                                }}
                                    className='flex items-center group cursor-pointer'
                                >
                                    <span className='  rounded-full md:p-[7px] pr-1 transition duration-[450ms]'>
                                        <Heart
                                            size={16}
                                            // fill={!hasLiked ? "#FFFFFFA6" : "#B4F6A5"}
                                            // weight={!hasLiked ? "bold" : "fill"}
                                            weight={!hasLiked ? "regular" : "fill"}
                                            className={`group-hover:fill-btnColorOnLightBg dark:group-hover:fill-primary ${hasLiked ? "fill-btnColorOnLightBg dark:fill-primary" : "fill-black dark:fill-[#FFFFFFA6]"}`}
                                        />
                                        {/* <Heart hasLiked={hasLiked}/> */}
                                    </span>
                                    <span className='prose-LabelSmall text-txtOnLightBg dark:text-onBackgroundII group-hover:text-btnColorOnLightBg'>{parseInt(currLikes)}</span>
                                </div>
                                <div className='flex items-center group' onClick={handleOpenTipModal}>
                                    <span className='  rounded-full md:p-[7px] pr-1 transition duration-[450ms]'>
                                        {selectedCurrency === 'WILD' ? <Coins size={16} className='group-hover:fill-btnColorOnLightBg fill-black dark:fill-[#ffffffa6]' /> :
                                            <img src={getCurrencyLogo(selectedCurrency)} alt="logo" className='max-w-5 h-5 aspect-square rounded-full' />}
                                    </span>
                                    <span className='prose-LabelSmall text-txtOnLightBg dark:text-onBackgroundII group-hover:text-btnColorOnLightBg'>{tipsData?.display_value === "0" ? 0 : tipsData?.display_value}</span>
                                </div>
                            </div>
                        </div>
                    </>
                </div>

                <CustomModal isOpen={showImgModal} closeModal={handleCloseImageModal} modalPositionTop={'50'} translatePosition={'translate(-50%, -50%)'}>
                    <div>
                        <img src={selectedImage} alt="embed" className="w-full h-full object-contain" onClick={(e) => { e.stopPropagation(); handleCloseImageModal() }} />
                    </div>
                </CustomModal>

                {/* {showBuyModal && <CustomModal isOpen={showBuyModal} closeModal={handleCloseBuyModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                <BuyModal selectedBuy={castData} closeModal={handleCloseBuyModal} refetch={refetchCasts} />
                </CustomModal>} */}
            </div >
            {
                showReplyCastModal && <CustomModal isOpen={showReplyCastModal} closeModal={handleCloseReplyCastModal} showSlideAnimation={false} modalPositionTop={'20'} translatePosition={'translate(-50%, -50%)'}>
                    <CastModal closeModal={handleCloseReplyCastModal} castDetails={castData} refetch={refetchCasts} fromReplyCastModal={true} />
                </CustomModal>
            }
            {
                showQuoteCastModal && <CustomModal isOpen={showQuoteCastModal} closeModal={handleCloseQuoteCastModal} showSlideAnimation={false} modalPositionTop={'20'} translatePosition={'translate(-50%, -50%)'}>

                    <CastModal closeModal={handleCloseQuoteCastModal} castDetails={castData} refetch={refetchCasts} isQuoteCast={true} updateRecastValue={updateRecastValue} />
                </CustomModal>
            }
            {
                showTipModal && <CustomModal isOpen={showTipModal} closeModal={handleCloseTipModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                    <TipModal selectedTip={castData} closeModal={handleCloseTipModal} setShowTipModal={setShowTipModal} refetch={refetchCasts} refetchUserdetail={profileDataRefetch} />
                </CustomModal>
            }

        </>
    )
}

export default TrendingCard