import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createFarcasterAccount, getAccountCreationPayload, getInviteCode, pollInviteCodeStatus } from '../../services/api';
import { useDispatch } from 'react-redux';
import { displaySnackbar } from '../../features/thunkMiddleware';
import { usePrivy } from '@privy-io/react-auth';
import { useQuery } from '@tanstack/react-query';
import EditProfileModal from '../../components/Modals/EditProfile';
import CustomModal from '../../components/UI/CustomModal';
import useAccount from '../../hooks/account';
import Spinner from '../../components/UI/Spinner';

const InvitePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [inviteCode, setInviteCode] = useState('');
    const [error, setError] = useState('');
    const [isPolling, setIsPolling] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [paymentConfirmed, setPaymentConfirmed] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const dispatch = useDispatch();
    const { user } = useAccount()

    const usernameRegex = /^[a-z0-9][a-z0-9-]{0,15}$/;
    const isDefaultUsername = !!(user?.farcaster_details?.username && !usernameRegex.test(user.farcaster_details.username));

    useEffect(() => {
        if (user?.farcaster_details?.username && user?.farcaster_details?.display_name && user?.farcaster_details?.profile_bio && user?.farcaster_details?.pfp_url && !isDefaultUsername) {
            navigate('/')
        }
    }, [user])

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const codeFromUrl = urlParams.get('code');
        if (codeFromUrl) {
            setInviteCode(codeFromUrl);
        }
    }, [location.search]);

    const handleInputChange = (e) => {
        setInviteCode(e.target.value);
        setError('');
    };

    const handleSubmit = async () => {
        setIsloading(true)
        if (!inviteCode.trim()) {
            setError('Please enter a valid invite code.');
            return;
        }
        setError('');
        setIsPolling(true);
        try {
            const initialRes = await pollInviteCodeStatus(inviteCode);
            if (initialRes?.data?.available) {
                setPaymentConfirmed(true)
                setIsPolling(false);
                dispatch(displaySnackbar('Invite code is valid!'));
            } else {
                dispatch(displaySnackbar('Checking invite code availability...'));
                pollInviteStatus(inviteCode);
            }
        } catch (error) {
            console.error('Error submitting invite code:', error);
            setError('Error submitting invite code. Please try again.');
            setIsPolling(false);
        }
        finally {
            setIsloading(false)
        }
    };

    const handleGenerateCode = async () => {
        setIsGenerating(true);
        try {
            const res = await getInviteCode();
            const data = res?.data;
            const newCode = data.inviteCodes[0];
            const paymentLink = data?.coinbaseResp?.data?.hosted_url;
            if (!newCode) {
                throw new Error('Failed to generate new code');
            }
            window.open(paymentLink, '_blank');
            dispatch(displaySnackbar('New code generated'));
            setInviteCode(newCode);
            setIsPolling(true);
            pollInviteStatus(newCode);
        } catch (error) {
            console.error('Error generating code:', error);
            setError('Failed to generate new code. Please try again.');
        } finally {
            setIsGenerating(false);
        }
    };

    const pollInviteStatus = async (code) => {
        const pollInterval = setInterval(async () => {
            try {
                const res = await pollInviteCodeStatus(code);
                if (res?.data?.reason == "code already used") {
                    setIsloading(false);
                    clearInterval(pollInterval);
                    setIsPolling(false);
                    dispatch(displaySnackbar('Code already used'));
                    setError('Invite code already used.');
                } else if (res?.data?.available) {
                    setIsloading(false);
                    clearInterval(pollInterval);
                    setIsPolling(false);
                    dispatch(displaySnackbar('Invite code is valid!'));
                    setPaymentConfirmed(true); // Payment is successful
                }
            } catch (error) {
                console.error('Error polling invite code status:', error);
                clearInterval(pollInterval);
                setIsPolling(false);
                setError('Error checking invite code status. Please try again.');
            }
        }, 5000);
    };

    useEffect(() => {
        const AccoutStatusPayload = async () => {
            const res = await getAccountCreationPayload();
            const errorMessage = res?.response?.data?.error
            if (errorMessage === "User already has a Farcaster account") {
                navigate('/')
            }
        }
        AccoutStatusPayload()
    }, [])

    const handleCheckPaymentStatus = async () => {
        if (!inviteCode.trim()) {
            setError('Please enter a valid invite code.');
            return;
        }
        try {
            dispatch(displaySnackbar('Checking payment status...'));
            const res = await pollInviteCodeStatus(inviteCode);
            if (res?.data?.reason === 'payment pending') {
                dispatch(displaySnackbar('Payment pending'));
            }
        } catch (error) {
            setError('Error checking payment status. Please try again.');
        }
    };

    if (paymentConfirmed) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-background text-onBackground">
                <div className="max-w-md w-full p-6 bg-surface rounded-lg shadow-lg">
                    <h1 className="prose-DisplaySmall mb-6 text-center">Join Wildcard</h1>
                    <p className='prose-BodyMedium mb-6 text-center'>Create your Farcaster account and update profile details:</p>
                    <div className="mb-6">
                        <SignTypedDataButton inviteCode={inviteCode} />
                    </div>
                </div>
            </div>
        )

    }
    return (
        <div className="min-h-screen flex items-center justify-center bg-white dark:bg-background text-onBackground ">
            <div className="max-w-md w-full p-6 bg-surface rounded-lg shadow-lg">
                <h1 className="prose-DisplaySmall mb-6 text-center">Join Wildcard</h1>
                {isPolling ?
                    <div className='flex flex-col justify-center items-center'>
                        <div className="flex flex-row justify-center items-center">
                            <span className='relative w-10 h-10'>
                                <Spinner />
                            </span>
                            <p className='prose-BodyMedium'>Payment pending...</p>

                        </div>
                        <button
                            onClick={handleCheckPaymentStatus}
                            className="text-blue-500 text-center ml-2 prose-BodySmall underline underline-offset-2 focus:outline-none focus:ring-2 focus:ring-primary"
                        >
                            Check Status
                        </button>
                    </div> :
                    <div>
                        <div className="mb-6">
                            <p className="prose-BodyLarge mb-2">Enter your invite code:</p>
                            <input
                                type="text"
                                value={inviteCode}
                                onChange={handleInputChange}
                                className="w-full p-3 border border-outline rounded focus:outline-none focus:ring-2 focus:ring-primary mb-2"
                                placeholder="Enter code"
                                disabled={isPolling}
                            />
                            {error && <p className="text-error text-sm mb-2">{error}</p>}
                            <button
                                onClick={handleSubmit}
                                disabled={isPolling || !inviteCode.trim()}
                                className="w-full p-3 bg-[#B4F6A5] text-background rounded hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary mb-4 disabled:bg-opacity-50 transition-all duration-300"
                            >
                                Submit Code
                            </button>
                        </div>
                        <div className="text-center">
                            <p className="prose-BodyMedium mb-4">Don't have a code?
                                <button
                                    onClick={handleGenerateCode}
                                    disabled={isGenerating}
                                    className="text-blue-500 ml-2 underline underline-offset-2 focus:outline-none focus:ring-2 focus:ring-primary"
                                >
                                    {isGenerating ? 'Generating...' : 'Generate new'}
                                </button>
                            </p>
                        </div>
                    </div>}
            </div>
        </div>
    );
};

export const SignTypedDataButton = ({ inviteCode = '' }) => {
    const dispatch = useDispatch();
    const { refetchUser, user } = useAccount();
    const [showEditProfileModal, setShowEditProfileModal] = useState(false)
    const { signTypedData, authenticated } = usePrivy();
    const { data: accountCreationPayload, isLoading } = useQuery(
        {
            queryKey: ['signTypedData'],
            queryFn: getAccountCreationPayload,
            enabled: authenticated,
        }
    )
    useEffect(() => {
        const errorMessage = accountCreationPayload?.response?.data?.error
        if (errorMessage === "User already has a Farcaster account") {
            dispatch(displaySnackbar('User already has a Farcaster account'))
            return
        }
    }, [accountCreationPayload])


    const handleRegisterParams = async (inviteCode) => {
        refetchUser();
        const registerParams = accountCreationPayload?.data?.data?.registerParams;
        const registerUiConfig = {
            title: '(1/2) Register your account on Farcaster',
            description: 'Sign payload to authorize creation of your account on Farcaster',
            buttonText: 'Sign',
        };
        const registerParamsSig = await signTypedData(registerParams, registerUiConfig);
        const signerParams = accountCreationPayload.data?.data?.signerParams;
        const signerUiConfig = {
            title: '(2/2) Link with Wildcard',
            description: 'Get access to posting content on Farcaster via Wildcard',
            buttonText: 'Sign',
        };
        const signerParamsSig = await signTypedData(signerParams, signerUiConfig);

        const res = await createFarcasterAccount({
            registerParams,
            registerParamsSig,
            signerParams,
            signerParamsSig,
            inviteCode: inviteCode
        });

        const isSuccess = !!res?.data?.txHash;
        if (isSuccess) {
            const pollInterval = setInterval(async () => {
                try {
                    await refetchUser(); // Use refetchUser from the hook
                    const isCompleted = user?.creation_details?.tx_status === 'complete';
                    if (isCompleted) {
                        clearInterval(pollInterval);
                        setShowEditProfileModal(true);
                    }
                } catch (error) {
                    console.error('Error polling account status:', error);
                    clearInterval(pollInterval);
                }
            }, 2000);
        } else if (res?.response?.data?.error) {
            dispatch(displaySnackbar(res?.response?.data?.error));
        }
    };


    const handleCloseEditModal = () => {
        setShowEditProfileModal(false);
    };

    return (
        <div className="text-center">
            <button onClick={() => handleRegisterParams(inviteCode)} disabled={isLoading} className='w-full p-3 bg-[#B4F6A5] text-background rounded hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary mb-4 disabled:bg-opacity-50 transition-all duration-300'>
                Continue to the app
            </button>
            {showEditProfileModal &&
                <CustomModal isOpen={showEditProfileModal} closeModal={handleCloseEditModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                    <EditProfileModal closeModal={handleCloseEditModal} key={'1'} refetch={() => { }} />
                </CustomModal>
            }
        </div>

    )
}
export default InvitePage;