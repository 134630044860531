import { usePrivy } from '@privy-io/react-auth';
import axios from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const UNAUTHENTICATED_PAGES = ['/signin'];

const useAuthMiddleware = () => {
    const { ready, authenticated, getAccessToken } = usePrivy();
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname, search } = location;

    useEffect(() => {
        const checkAuth = async () => {
            const token = await getAccessToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            if (UNAUTHENTICATED_PAGES.includes(pathname) && authenticated) {
                navigate('/');
                return;
            }

            if (!authenticated && pathname !== '/refresh') {
                if (pathname === '/' || !token) {
                    navigate(`/signin${pathname === '/' ? '' : `?redirect=${pathname}${search}`}`);
                    return;
                }

                navigate(`/refresh?redirect=${pathname}${search}`);
            }
        };

        if (ready) {
            checkAuth();
        }
    }, [ready, authenticated, pathname, search, getAccessToken, navigate]);
};

export default useAuthMiddleware;
