import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import SwipeableViews from 'react-swipeable-views';
import { Empty } from '@phosphor-icons/react';
import TopHeader from '../../components/Sidebars/TopHeader';
import { getNotifications } from '../../services/api';
import FollowCard from './components/cards/FollowCard';
import LikeCard from './components/cards/LikeCard';
import MentionCard from './components/cards/MentionCard';
import QuoteCard from './components/cards/QuoteCard';
import RecastCard from './components/cards/RecastCard';
import NotificationSkeletonLoading from './components/NotificationSkeletonLoading';
import ReplyCard from './components/cards/ReplyCard';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationsNumber } from '../../features/globalStateSlice';
import { getSelectedCurrencyLocalStorage } from '../../utils/constants';

const initialTabs = [
    { name: 'All', isActive: true, id: 'all' },
    // { name: 'Mentions', isActive: false, id: 'mentions' },
];

const unreadTimer = 15000; //15 sec

const Notifications = () => {
    const dispatch = useDispatch();
    dispatch(setNotificationsNumber(0));
    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency
    const { isCastModalOpen } = useSelector(state => state?.app);
    const [tabs, setTabs] = useState(initialTabs);
    const [selectedTab, setSelectedTab] = useState('All');
    const [page, setPage] = useState(1);
    const [notificationsData, setNotificationsData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [moreLoading, setMoreLoading] = useState(false);
    const observer = useRef();

    const { data: notifications, isLoading } = useQuery({
        queryKey: ['notifications', page],
        queryFn: () => getNotifications(page, 25, selectedCurrency),
        enabled: (selectedTab === 'All') && !isCastModalOpen,
    });

    useEffect(() => {
        if (page === 1) {
            const filteredNotifications = selectedTab === 'All'
                ? notifications?.data
                : notifications?.data?.filter(notification => notification.notification_type === 'mention');

            setNotificationsData(filteredNotifications || []);
        }
    }, [notifications, selectedTab, page]);

    const handleTabClick = useCallback((name) => {
        setTabs(tabs.map(tab => ({ ...tab, isActive: tab.name === name })));
        setSelectedTab(name);
    }, [tabs]);

    const handleTabChange = useCallback((index) => {
        handleTabClick(tabs[index].name);
    }, [handleTabClick, tabs]);

    const fetchMoreData = async () => {
        setMoreLoading(true);
        try {
            if (page !== 1) {
                const response = await getNotifications(page, 25, selectedCurrency);
                if (response.success && response.data.length > 0) {
                    const data = response.data;
                    setNotificationsData(prevData => [...prevData, ...data]);
                    if (data.length === 0) setHasMore(false);
                } else {
                    setHasMore(false);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setHasMore(false);
        } finally {
            setMoreLoading(false);
        }
    };

    const lastElementRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                fetchMoreData();
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);

    const renderNotifications = () => (
        notificationsData.map((notification, index) => {
            const NotificationCard = getNotificationCard(notification.notification_type);
            if (notificationsData.length === index + 1) {
                return <div ref={lastElementRef} key={index}><NotificationCard data={notification} unreadTimer={unreadTimer} /></div>;
            }
            return <div key={index}><NotificationCard data={notification} unreadTimer={unreadTimer} /></div>;
        })
    );

    const getNotificationCard = (type) => {
        switch (type) {
            case 'like': return LikeCard;
            case 'recast': return RecastCard;
            case 'quote': return QuoteCard;
            case 'follow': return FollowCard;
            case 'mention': return MentionCard;
            case 'reply': return ReplyCard;
            default: return LikeCard;
        }
    };
    return (
        <div className='overflow-hidden'>
            <div className="sticky top-0 z-30 bg-white dark:bg-background transition-transform duration-1000 translate-y-0">
                <TopHeader
                    PageTitle="Notifications"
                    tabs={tabs}
                    selectedTab={selectedTab}
                    onTabClick={handleTabClick}
                    showTabsInHeader
                    tabPosition="fullTop"
                />
            </div>
            <SwipeableViews
                style={{ height: 'auto' }}
                index={tabs.findIndex(tab => tab.name === selectedTab)}
                onChangeIndex={handleTabChange}
            >
                <div className="mt-12">
                    {isLoading && page === 1 ? (
                        Array.from({ length: 5 }).map((_, index) => <React.Fragment key={index}><NotificationSkeletonLoading /></React.Fragment>)
                    ) : (
                        !notificationsData.length ? (
                            <div className="h-12 border-b dark:border-outline flex items-center gap-3 p-4 mx-5">
                                <Empty size={20} className='fill-subTxtOnLightBg dark:fill-[#FFFFFF66]' />
                                <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                            </div>
                        ) : (
                            <div className="w-full">
                                {renderNotifications()}
                                {moreLoading && (
                                    <div className="absolute bottom-0 md:bottom-0 w-full flex items-center justify-center">
                                        <span className="loading loading-dots bg-gray-400 dark:bg-white  loading-md"></span>
                                    </div>
                                )}
                            </div>
                        )
                    )}
                </div>
            </SwipeableViews>
        </div>
    );
};

export default Notifications;