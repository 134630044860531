import React from 'react'
import { useNavigate } from 'react-router-dom';
import { pluralizeWord } from '../../../utils/helper';
import logo from '../../../assets/images/logo.png';
import powerBadge from '../../../assets/svg/powerbadge.svg';

const TktHolderCard = ({ data, i }) => {

    const { farcaster_user, num_tickets } = data;
    const navigate = useNavigate();

    const handleProfileNavigate = (farcaster_user) => {
        navigate(`/profile/${farcaster_user?.username}`);
    }
    return (
      <div className="flex flex-col justify-between bg-gray-300 dark:bg-surface p-2 overflow-hidden mr-2 min-w-[140px] rounded-md">
        <button 
          onClick={() => handleProfileNavigate(farcaster_user)} 
          className="flex-row items-center gap-2 w-full mb-2"
        >
          <img 
            src={farcaster_user?.cdn_url || farcaster_user?.pfp_url} 
            alt="Profile" 
            className="w-full aspect-square rounded-md object-contain" 
          />
        </button>
        <div className="flex flex-row items-center justify-between w-full">
          <p className="text-black dark:text-onBackgroundII text-[14px] flex-1 truncate">
            @{farcaster_user?.username}
          </p>
          <p className="text-black dark:text-onBackground prose-TitleLarge py-1 px-1 rounded-sm">
            {Number(num_tickets) / 1000}
          </p>
        </div>
      </div>
    );
}

export default TktHolderCard