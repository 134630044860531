import { LinkSimple } from '@phosphor-icons/react';
import React, { useCallback, useEffect, useState } from 'react';
import { getUrlData } from '../../../../services/api.js';
import { debouncefn } from '../../../../utils/helper.js';
import FrameCard from '../../../Cards/Casts/components/card/FrameCard.jsx';


const UrlPreviewer = ({ mediaPreview, setMediaPreview, inputValue, allowedMedia }) => {

    const [urlPreview, setUrlPreview] = useState([])
    const [urlPreviewLoading, setUrlPreviewLoading] = useState(false);
    const [prevUrls, setPrevUrls] = useState([])

    function extractUrls(text) {
        const urlPattern = /https?:\/\/[^\s]+/g;
        return text.match(urlPattern) || [];
    }

    const getDataFromUrls = async (urlsPresent) => {
        const uniqueUrls = [...new Set(urlsPresent)];
        setUrlPreviewLoading(true);

        const arr = await Promise.all(
            uniqueUrls.map(async (url) => {
                try {
                    const response = await getUrlData(url);

                    if (response?.success === false || response?.data?.success === false) {
                        return null;
                    }

                    const urlData = response?.data?.data;

                    if (!urlData?.description && !urlData?.image) {
                        return null;
                    }
                    return { url: url, ...urlData };
                } catch (err) {
                    console.log(err);
                    return null;
                }
            })
        );

        const filteredArr = arr.filter(data => data !== null);
        setUrlPreview(filteredArr?.filter((url) => !url?.is_frame));
        let framesUrls = filteredArr.map((url) => {
            if (url?.is_frame)
                return { url: url?.url, isFrame: true, urlData: url }
            else return null;
        })
        framesUrls = framesUrls.filter((url) => url !== null);
        setMediaPreview(prev => {
            const filteredMedia = prev.filter((media) => !media?.isFrame);
            if (filteredMedia.length >= allowedMedia) return filteredMedia;
            return [...filteredMedia, ...framesUrls.slice(0, allowedMedia - filteredMedia.length)]
        })
        setUrlPreviewLoading(false);
    };

    const debouncedGetDataFromUrls = useCallback(debouncefn(getDataFromUrls, 1000), []);

    useEffect(() => {
        const urlsPresent = extractUrls(inputValue);
        const areUrlsUpdated = urlsPresent.some(url => !prevUrls.includes(url)) || prevUrls.length !== urlsPresent.length;
        if (areUrlsUpdated) {
            setPrevUrls(urlsPresent);
            debouncedGetDataFromUrls(urlsPresent);
        }
    }, [inputValue, debouncedGetDataFromUrls, mediaPreview?.filter((media) => !media?.isFrame)?.length]);

    return (
        <div className={`flex flex-col `}>
            {urlPreviewLoading && (
                <div className='border w-full overflow-hidden dark:border-outlineII bg-white dark:bg-background p-2 rounded-md mb-2'>
                    <div className='flex gap-3 items-start w-full overflow-hidden'>
                        <div className='size-10 bg-gray-200 dark:bg-gray-700 rounded-sm animate-pulse'></div>
                        <div className='flex flex-col gap-1 overflow-hidden w-full'>
                            <div className='h-4 bg-gray-200 dark:bg-gray-700 rounded-sm w-3/4 animate-pulse'></div>
                            <div className='h-3 bg-gray-200 dark:bg-gray-700 rounded-sm w-1/2 animate-pulse'></div>
                        </div>
                    </div>
                </div>
            )}
            {!urlPreviewLoading && urlPreview?.length > 0 && urlPreview?.map((url, index) => {
                return (
                    <div key={index} className='border w-full overflow-hidden dark:border-outlineII bg-white dark:bg-background p-2 rounded-md mb-2' onClick={(e) => e.stopPropagation()}>
                        <div className='flex gap-3 items-start w-full overflow-hidden'>
                            {url?.image && <img src={url?.image} alt={url?.title} className='size-10 dark:bg-white/10 bg-background/10 rounded-sm object-contain' />}
                            {!url?.image && <LinkSimple size={24} className='fill-black dark:fill-white' />}
                            <div className='flex flex-col gap-1 overflow-hidden'>
                                {url?.title && <h3 className='prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground truncate'>{url?.title || url?.url}</h3>}
                                <p className='prose-BodySmall text-txtOnLightBg dark:text-white truncate'>{url?.description}</p>
                            </div>
                        </div>
                    </div>
                )
            })}
            {
                mediaPreview?.map((url, index) => {
                    if (url?.isFrame)
                        return (
                            <FrameCard embed={url?.urlData?.frame} fromCastModal />
                        )
                })
            }
        </div>
    )
}

export default UrlPreviewer;