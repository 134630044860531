import { Empty } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TransactionCards from '../../../components/Cards/Tickets/TransactionCards';
import TransactionSkeletonLoading from '../../../components/Cards/Tickets/TransactionSkeletonLoading';
import { getWalletTradesTransactions } from '../../../services/api';

const TradesTab = () => {

    const [transactionsTradesData, setTransactionsTradesData] = useState([]);
    const [isLoadingTransactionsTrades, setIsLoadingTransactionsTrades] = useState(false);
    const [hasMoreTransactionsTrades, setHasMoreTransactionsTrades] = useState(true);
    const [transactionsTradesPage, setTransactionsTradesPage] = useState(2);

    const observer = useRef();
    const transactionsRef = useRef(null);

    const fetchQueryOptions = { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 10 };

    const { data: transactionsTrades = [], isLoading: transactionsTradesLoading, refetch: transactionTradesRefetch } = useQuery({
        queryKey: ['transactionTrades'],
        queryFn: () => getWalletTradesTransactions(1),
        ...fetchQueryOptions,
    });

    useEffect(() => {
        if (transactionsTrades?.length) {
            setTransactionsTradesData(transactionsTrades);
        }
    }, [transactionsTradesLoading, transactionsTrades]);

    const fetchMoreData = useCallback(async () => {
        setIsLoadingTransactionsTrades(true);
        try {
            const newTradesData = await getWalletTradesTransactions(transactionsTradesPage);
            if (newTradesData.length === 0) {
                setHasMoreTransactionsTrades(false);
            }
            if (newTradesData.length) {

                const data = newTradesData;
                setTransactionsTradesData((prev) => [...prev, ...data]);
                setTransactionsTradesPage((prev) => prev + 1);
                setIsLoadingTransactionsTrades(false);
                if (!data.length) setHasMoreTransactionsTrades(false);
            } else {
                setHasMoreTransactionsTrades(false);
            }
        } catch (error) {
            console.log('error', error)
            setIsLoadingTransactionsTrades(false);
        } finally {
            setIsLoadingTransactionsTrades(false);
        }
    }, [transactionsTradesPage]);

    const lastElementRef = useCallback(node => {
        if (isLoadingTransactionsTrades) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMoreTransactionsTrades) {
                fetchMoreData();
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoadingTransactionsTrades, hasMoreTransactionsTrades]);

    return (
        <div>
            {transactionsTradesLoading ? <div className='flex flex-col'>
                <TransactionSkeletonLoading />
                <TransactionSkeletonLoading />
                <TransactionSkeletonLoading />
                <TransactionSkeletonLoading />
            </div>
                : !transactionsTradesData?.length ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-mx-5">
                    <Empty size={20} color="#FFFFFF66" className='fill-black dark:fill-[#ffffff66]' />
                    <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                </div>
                    : <div ref={transactionsRef}> {transactionsTradesData?.map((data, idx) => (
                        <div key={idx} ref={transactionsTradesData?.length - 8 === idx + 1 ? lastElementRef : null}>
                            <TransactionCards data={data} key={idx} />
                        </div>
                    ))}
                        {isLoadingTransactionsTrades && (
                            <div className='absolute bottom-0 md:bottom-0 w-full flex items-center justify-center'>
                                <span className='loading loading-dots bg-gray-400 dark:bg-white  loading-md'></span>
                            </div>
                        )}
                    </div>
            }
        </div>
    );
}

export default TradesTab;