import axios from 'axios';
import { getAccessToken } from '@privy-io/react-auth';

const baseURL = process.env.REACT_APP_WILDCARD_API_URL || 'https://sys.wildcard.lol';
const gowildBaseURL = process.env.REACT_APP_GOWILD_API_URL || 'https://gowild-t54me.ondigitalocean.app';
// const baseURL = process.env.REACT_APP_WILDCARD_API_URL || 'https://888a-91-73-202-16.ngrok-free.app';
// const baseURL = "http://localhost:8000";
// const gowildBaseURL = "http://localhost:8080";

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const gowildAxiosInstance = axios.create({
    baseURL: gowildBaseURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const tokenInterceptor = async (config) => {
    const token = await getAccessToken();

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}

axiosInstance.interceptors.request.use(tokenInterceptor, Promise.reject);

gowildAxiosInstance.interceptors.request.use(tokenInterceptor, Promise.reject);

export { axiosInstance, gowildAxiosInstance };

export default axiosInstance;
