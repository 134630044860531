import { CheckSquare, Empty, GlobeHemisphereEast, Square, X } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteDraft, getDrafts } from '../../../services/api';
import { CAST_MODAL_TAB_CHOICES } from '../../../utils/constants';
import { getTimeInLocalFormat, highlightWord, cleanMentions } from '../../../utils/helper.js';
import Spinner from '../../UI/Spinner.jsx';

const DraftTab = ({ currSelectedDraft, handleDraftClick, setCurrSelectedDraft, handleTabClick }) => {
    const navigate = useNavigate();

    const [drafts, setDrafts] = useState([]);
    const [selectedDrafts, setSelectedDrafts] = useState([]);
    const [editMode, setEditMode] = useState(false);

    const { data: draftsData = [], isLoading: draftsLoading } = useQuery({
        queryKey: ['draftsData'],
        queryFn: getDrafts,
    });

    useEffect(() => {
        if (draftsData?.data) {
            setDrafts(draftsData?.data)
        }
    }, [draftsData])

    const handleDeleteSelected = () => {
        selectedDrafts.map(draftId => {
            if (draftId == currSelectedDraft)
                setCurrSelectedDraft('')
            try {
                deleteDraft(draftId)
            }
            catch (err) {
                console.log(err)
            }
        });
        const updatedDrafts = drafts.filter(d => !selectedDrafts.includes(d.id));
        setDrafts(updatedDrafts);
        setSelectedDrafts([]);
    };

    const handleSelectAll = () => {
        if (selectedDrafts.length === drafts.length) {
            setSelectedDrafts([]);
        } else {
            setSelectedDrafts(drafts.map(d => d.id));
        }
    };

    const handleCheckboxChange = (draftId) => {
        if (selectedDrafts.includes(draftId)) {
            setSelectedDrafts(selectedDrafts.filter(id => id !== draftId));
        } else {
            setSelectedDrafts([...selectedDrafts, draftId]);
        }
    };

    return (
        <>
            <div className='flex flex-col'>
                <div className='h-[60px] prose-TitleSmall grid grid-cols-3 items-center uppercase mx-5 md:hidden'>
                    <p className='dark:text-white text-black'>{!editMode && <span role='button' onClick={() => handleTabClick(CAST_MODAL_TAB_CHOICES.CAST)}>BACK</span>}</p>
                    <p className=' text-primary text-center'><span>Drafts</span></p>
                    <p className='flex justify-end dark:text-white text-black'>{drafts?.length > 0 && <span role='button' onClick={() => setEditMode(prev => !prev)}>{!editMode ? 'Edit' : <X weight='bold' size={20} className='fill-black dark:fill-white' />}</span>}</p>
                </div>
                <div className='mt-5 md:flex justify-between px-5 hidden pb-5'>
                    <div className='max-h-[18px]'>

                        {editMode && drafts?.length > 0 && (
                            <>
                                <button className="prose-LabelLarge  text-primary" onClick={() => handleSelectAll()}>
                                    {selectedDrafts.length === drafts.length ? 'Unselect All' : 'Select All'}
                                </button>
                                {
                                    selectedDrafts.length > 0 &&
                                    <button className="prose-LabelLarge text-error ml-4" onClick={() => handleDeleteSelected()}>
                                        Delete
                                    </button>
                                }
                            </>
                        )}
                    </div>
                    {drafts?.length > 0 && <button role='button' onClick={() => { setEditMode(prev => !prev); setSelectedDrafts([]) }} className='prose-TitleSmall text-txtOnLightBg dark:text-onBackground'>
                        {!editMode ? "EDIT DRAFTS" : 'CANCEL'}
                    </button>}
                </div>
                <div className='overflow-y-auto custom-scrollbar w-full md:h-[283px] h-[100vh] md:pb-0 pb-[120px]'>
                    {draftsLoading ? <Spinner /> : <ul className="w-full flex flex-col">
                        {
                            drafts?.length <= 0 ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-full">
                                <Empty size={20} className="fill-subTxtOnLightBg dark:fill-[#FFFFFF66]" />
                                <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                            </div> :
                                <>{drafts?.map(draft => (
                                    <li key={draft.id}
                                        onClick={() => { !editMode && handleDraftClick(draft?.cast_details); setCurrSelectedDraft(draft?.id) }}
                                        className="flex items-start pb-3 px-5  pt-[18px] border-b border-outlineII gap-1 w-full relative bg-gray-200 dark:bg-background hover:bg-gray-300 dark:hover:bg-surface">
                                        {editMode && (
                                            <div role='button' onClick={() => handleCheckboxChange(draft.id)}>
                                                {
                                                    selectedDrafts?.includes(draft.id) ? <CheckSquare weight='fill' size={16} className='min-w-4  fill-primary' /> : <Square size={16} color='#FFFFFF' className='min-w-4' />
                                                }
                                            </div>

                                        )}
                                        <div
                                            className="cursor-pointer prose-BodyMedium w-full overflow-x-hidden"
                                        >
                                            <div className='flex w-full items-start gap-4'>
                                                <div className='w-10 h-10 rounded-full'>
                                                    {
                                                        !draft?.cast_details?.selected_channel || draft?.cast_details?.selected_channel?.id === 'custom_global' ? <GlobeHemisphereEast size={40} className=' fill-primary' /> : <img className='max-w-10 min-w-10 h-10 rounded-full' src={draft?.cast_details?.selected_channel?.image_url} alt="" />
                                                    }
                                                </div>
                                                <div className='grow'>
                                                    <p className='prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground mb-3'>
                                                        {draft?.cast_details?.selected_channel?.name || 'Global'}
                                                    </p>
                                                    <div className='absolute top-5 right-5 px-2 bg-gray-400 dark:bg-surface rounded-full'>
                                                        <p className='prose-LabelSmall flex items-center gap-1'>{getTimeInLocalFormat(draft?.event_datetime, 'time')}
                                                            <p className='size-1 min-w-1 bg-white rounded-full'></p> {getTimeInLocalFormat(draft?.event_datetime, 'date')}</p>

                                                    </div>
                                                    <p className='prose-BodyLarge text-txtOnLightBg dark:text-onBackground truncate break-words text-wrap w-full'>{highlightWord(cleanMentions(draft?.cast_details?.text), true, navigate)}</p>
                                                    <div className='flex items-end justify-between'>
                                                        <div className='flex gap-2 mt-2'>
                                                            {
                                                                draft?.cast_details?.embeds?.length > 0 && <>
                                                                    {
                                                                        draft?.cast_details?.embeds?.map(embed => (
                                                                            <img className='size-20 rounded-sm' src={embed?.url} alt="img" />
                                                                        ))
                                                                    } </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}</>}
                    </ul>}
                </div>
            </div>
            <div className='h-[52px] w-full border-t  dark:border-outlineII flex items-center justify-between prose-LabelMedium bg-white dark:bg-[#090909]'>
                {editMode && drafts?.length > 0 && (
                    <>
                        <button className="px-4 py-2  text-primary md:hidden" onClick={() => handleSelectAll()}>
                            {selectedDrafts.length === drafts.length ? 'Unselect All' : 'Select All'}
                        </button>
                        {
                            selectedDrafts.length > 0 &&
                            <button className="px-4 py-2 md:hidden dark:text-white text-black" onClick={() => handleDeleteSelected()}>
                                Delete
                            </button>
                        }
                    </>
                )}
            </div>
        </>
    )
}

export default DraftTab;