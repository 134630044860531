import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import powerBadge from '../../../assets/svg/powerbadge.svg';
import { pluralizeWord } from '../../../utils/helper';
import SellModal from '../../Modals/SellModal';
import CustomModal from '../../UI/CustomModal';
import ProfileHoverCard from '../../ProfileHoverCard';

const TicketCard = ({data, i, refetch}) => {

    const navigate = useNavigate();
    // const { sellNFT } = useBuyNFTTransaction();
    const { farcaster_user, key, num_tickets, pnl } = data;
    const [showSellTicketModal, setShowSellTicketModal] = useState(false)

    const handleNavigate = (username) => {
        navigate(`/profile/${username}`)
    }

    const handleShowSellTicketModal = () => {
        setShowSellTicketModal(true)
    }

    const handleCloseSellTicketModal = () => {
        setShowSellTicketModal(false)
    }

  return (
    <div
        role="button"
        // onClick={() => toggleModal()}
        key={i} className="min-h-[45px] flex justify-between mb-6">
        <div className="flex flex-row items-center gap-2">
            <div 
            onClick={()=>{handleNavigate(farcaster_user?.username)}}
            className="flex items-start justify-center">
                <ProfileHoverCard username={farcaster_user?.username}>
                    <img src={farcaster_user?.cdn_url || farcaster_user?.pfp_url || logo} alt=""
                        className="min-w-9 h-9 rounded-full" />
                </ProfileHoverCard>
            </div>
            <div className="min-h-[45px] flex flex-col justify-center w-full">
                <div 
                onClick={()=>{handleNavigate(farcaster_user?.username)}}
                className="w-full">
                    <ProfileHoverCard username={farcaster_user?.username} data={farcaster_user}>
                        <span className="prose-BodyLarge text-txtOnLightBg dark:text-onBackground break-words text-wrap w-full">{farcaster_user?.display_name}</span>
                    </ProfileHoverCard>
                    <span className="mx-1">{farcaster_user?.power_badge && <img src={powerBadge} alt="" className="inline mb-[2px]"/>}</span>
                </div>
                {
                    <div className="flex items-center gap-[6px]">
                        <p className="text-subTxtOnLightBg dark:text-onBackgroundIII prose-BodyMedium">{Number(num_tickets) / 1000} {pluralizeWord('Ticket', Number(num_tickets) / 1000)}  Owned</p>
                    </div>
                }
            </div>
        </div>
        <div className="flex flex-col items-end">
            <p className="font-['Inter'] font-normal text-[17px] leading-[25.5px] tracking-[0.01em] text-txtOnLightBg dark:text-onBackground">
                {key?.buy_price?.amount?.display_value}
                {key?.buy_price?.amount?.currency}
            </p>
            <div className="flex items-center gap-2">
                <p className={`prose-LabelSmall ${pnl?.usd_value < 0?'text-[#ff0000]':' text-btnColorOnLightBg dark:text-primary'}`}> {pnl?.usd_value_display}</p>
                <span
                    role="button"
                    onClick={handleShowSellTicketModal}
                    className=" text-primary prose-TitleLarge"
                > 
                    SELL
                </span>
            </div>

        </div>
            
        {showSellTicketModal && 
            <CustomModal isOpen={showSellTicketModal} closeModal={handleCloseSellTicketModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                <SellModal data={data} closeModal={handleCloseSellTicketModal} key={i} refetch={refetch}/>
            </CustomModal>
        }
    </div>
  )
}

export default TicketCard