import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import Logo from '../../assets/images/wildcard_logo.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { usePrivy } from '@privy-io/react-auth';
import Spinner from '../../components/UI/Spinner';
import axios from 'axios';
import { createFarcasterAccount, getAccountCreationPayload } from '../../services/api';
import useAccount from '../../hooks/account';


const SignInPage = () => {
    const { search } = useLocation()
    const navigate = useNavigate();
    const { ready, authenticated, login, getAccessToken } = usePrivy();
    const [isLoading, setIsLoading] = useState(true);
    const disableLogin = !ready || (ready && authenticated);
    const redirectPath = new URLSearchParams(search).get('redirect') || '/';
    const { user, refetchUser } = useAccount()

    useEffect(() => {
        delete axios.defaults.headers.common['Authorization'];
        fetchData();
    }, [ready, authenticated, getAccessToken]);

    const fetchData = async () => {
        try {
            if (ready && authenticated) {
                const accessToken = await getAccessToken();
                if (accessToken) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                    if (!user?.farcaster_details) {
                        navigate(`/invite`);
                        return;
                    }
                    navigate(`${redirectPath}`);
                } else {
                    setIsLoading(false);
                }
            } else if (ready && !authenticated) {
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching access token:", error);
        }
    };

    return (
        <div className='h-dvh w-full relative flex justify-center items-center px-4'>
            <div className='w-full md:max-w-[400px] flex flex-col items-center'>
                <div>
                    <img src={Logo} alt='logo' className='size-[150px] lg:size-64' />
                </div>
                <div className='flex items-center gap-2 font-title w-full h-[48px] mb-[10px] mt-4'>
                    <p className='bg-white flex-1 text-surface prose-DisplayLarge flex items-center justify-center'>
                        Wildcard
                    </p>
                    <div className='w-[10px] h-full bg-white' />
                    <div className='w-[10px] h-full bg-white' />
                    <div className='w-[10px] h-full bg-white' />
                </div>
                <div className='flex items-center justify-center bg-white text-center text-surface  uppercase prose-DisplayMedium w-full'>
                    SocialFi Gone WILD
                </div>

                <div className='w-full px-4 flex justify-center items-center mt-10 absolute bottom-12'>
                    {isLoading
                        ? <Spinner /> :
                        <button onClick={login} disabled={isLoading} className='bg-[#B4F6A5] text-black text-xl flex justify-center items-center  prose-DisplayLarge  py-2 w-full lg:w-[400px]'>
                            Sign in with Farcaster
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default SignInPage