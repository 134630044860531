import { ArrowLeft } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import ProfileDetails from '../../components/Cards/Profile/ProfileDetails';
import TopHeader from '../../components/Sidebars/TopHeader';
import Tabs from '../../components/Tabs';
import { getProfileDetailNew, getProfileHolders } from '../../services/api';
import { getSelectedCurrencyLocalStorage, USER_PROFILE_TAB_CHOICES } from '../../utils/constants';
import CastsTab from './Components/CastsTab';
import HoldersTab from './Components/HoldersTab';
import OnchainTab from './Components/OnchainTab';
import PortfolioTab from './Components/PortfolioTab';
import TipsTab from './Components/TipsTab';

const initialTabs = [
    {
        name: USER_PROFILE_TAB_CHOICES.CASTS,
        isActive: true,
        id: 'casts'
    },
    {
        name: USER_PROFILE_TAB_CHOICES.CASTS_REPLIES,
        isActive: false,
        id: 'casts_replies'
    },
    {
        name: USER_PROFILE_TAB_CHOICES.ONCHAIN,
        isActive: false,
        id: 'onchain'
    },
    {
        name: USER_PROFILE_TAB_CHOICES.PORTFOLIO,
        isActive: false,
        id: 'portfolio'
    },
    {
        name: USER_PROFILE_TAB_CHOICES.TIPS,
        isActive: false,
        id: 'tips'
    },
    {
        name: USER_PROFILE_TAB_CHOICES.HOLDINGS,
        isActive: false,
        id: 'Holders'
    },

];

const ProfilePage = ({ showHeader }) => {
    const navigate = useNavigate();

    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency;

    const [selectedTab, setSelectedTab] = useState('casts');
    const [tabs, setTabs] = useState(initialTabs);

    const { username, tab } = useParams()

    const [backNavCount, setBackNavCount] = useState(-1);

    const [isScrolling, setIsScrolling] = useState(false);
    const [netWorth, setNetWorth] = useState(0);

    useEffect(() => {
        const handleTouchStart = (e) => {
            if (e.target.closest('.scrollable-div-1') || e.target.closest('.scrollable-div-2')) {
                setIsScrolling(true);
            } else {
                setIsScrolling(false);
            }
        };

        document.addEventListener('touchstart', handleTouchStart);

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
        };
    }, []);


    const { data: profileData } = useQuery({
        queryKey: ['userProfileNew', username, selectedCurrency],
        queryFn: () => getProfileDetailNew(username, selectedCurrency),
        enabled: !!username && !!selectedCurrency,
    });

    const { refetch: profileHoldersRefetch } = useQuery({
        queryKey: ['currentUserHolders', username],
        queryFn: () => getProfileHolders(username),
    });

    const handleTabClick = (name) => {
        const newTabs = tabs.map((tab) => ({
            ...tab,
            isActive: tab.id === name
        }));
        setTabs(newTabs);
        setSelectedTab(name);
        navigate(`/profile/${username}/${name}`)
        setBackNavCount(prev => prev - 1);
    };

    const handleTabChange = (index) => {
        const newTab = tabs[index];
        handleTabClick(newTab.id);
    };

    useEffect(() => {

        const tabName = tab || 'casts';
        const newTabs = tabs.map((tab) => ({
            ...tab,
            isActive: tab.id === tabName
        }));
        setTabs(newTabs);
        setSelectedTab(tabName);
    }, [tab])

    useEffect(() => {
        document.documentElement.style.setProperty('--color-primary', localStorage.getItem('theme') == 'dark'
            ? getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.app_primary_color
            : getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency == 'WILD' ? '#20A402' : getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.app_primary_color
        )
        document.documentElement.style.setProperty('--color-onPrimary', localStorage.getItem('theme') != 'dark'
            ? "#FFFFFF"
            : getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency == 'WILD' ? '#000' : "#FFFFFF"
        )
    }, [])

    return (
        <div>
            <div className={`sticky top-0 z-30 bg-white dark:bg-background transition-transform duration-1000 hidden md:block ${showHeader ? 'translate-y-0' : 'translate-y-[-90px] lg:translate-y-0'}`}>
                <TopHeader PageTitle={'Profile'} showTabsInHeader={false} showLanguageToggle showCastButton />
            </div>
            <div className='mx-5 md:mt-10 mt-[60px] relative'>
                <ArrowLeft size={24} onClick={() => navigate(backNavCount)} role='button' className='absolute -top-[40px] hidden md:block fill-black dark:fill-white' />
                <ArrowLeft size={24} onClick={() => navigate('/feed')} role='button' className='absolute -top-[40px] md:hidden fill-black dark:fill-white' />
                <ProfileDetails fid={profileData?.data?.profile?.fid} profileHoldersRefetch={profileHoldersRefetch} showHeader={showHeader} netWorth={netWorth} handleTabClick={handleTabClick} />
            </div>
            <Tabs tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} fullWidthTab={true} type='top' />

            <div className='mt-1 pb-20 md:pb-0'>
                <SwipeableViews disabled={isScrolling} style={{ height: 'auto' }} index={tabs.findIndex(tab => tab.id === selectedTab)} onChangeIndex={handleTabChange}>
                    <div className=''>
                        {selectedTab === 'casts' && <CastsTab /> }
                    </div>
                    <div className=''>
                        {selectedTab === 'casts_replies' && <CastsTab withReplies/>}
                    </div>
                    <div className='px-4 mt-2'>
                        {selectedTab === 'onchain' && <OnchainTab fid={profileData?.data?.profile?.fid} />}
                    </div>
                    <div className='overflow-hidden'>
                        {selectedTab === 'portfolio' && <PortfolioTab setNetWorth={setNetWorth} /> }
                    </div>
                    <div>
                        {selectedTab === 'tips' && <TipsTab /> }
                    </div>
                    <div className='overflow-hidden'>
                        {selectedTab === USER_PROFILE_TAB_CHOICES.HOLDINGS && <HoldersTab profileData={profileData} />}
                    </div>
                </SwipeableViews>
            </div>
        </div>
    )
}

export default ProfilePage
