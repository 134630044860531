import React, { useEffect, useRef, useState } from 'react';
import ToolTip from '../UI/Tooltip';
import { Info, X } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { getMyPointBalances } from '../../services/api';
import { useDispatch } from 'react-redux';
import { getSelectedCurrencyLocalStorage, setSelectedCurrencyLocalStorage } from '../../utils/constants';
import { setSelectedCurrency } from '../../features/globalStateSlice';
import { useSelector } from 'react-redux';
import logo from "../../assets/images/logo.png"
import useMoxieBalance from '../../hooks/useMoxieBalance';
import { formatCash } from '../../utils/helper';

const CurrencyModal = ({setShowCurrencyModal}) => {
    const [toolTipOpen, setToolTipOpen] = useState({ allowance: false, received: false, revenue: false });
    const dispatch = useDispatch();
    const { getUserPrivyMoxieBalance } = useMoxieBalance()

    const { data: myPointsBalances, isLoading: pointsBalancesLoading} = useQuery({
        queryKey: ['myPointsBalances'],
        queryFn: () => getMyPointBalances(true),
    });

    const {data: moxieBalance, isLoading: isLoadingMoxieBalance} = useQuery({
        queryKey: ['moxieBalance'],
        queryFn: () => getUserPrivyMoxieBalance(),
    })

    const timeoutRefs = useRef({ allowance: null, received: null, revenue: null });

    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || 'WILD';


    const handleTokenSelect = (token) => {
        dispatch(setSelectedCurrency(`${token?.currency_points?.currency_config?.currency}`))
        setSelectedCurrencyLocalStorage(token)
    }

    useEffect(() => {
        if (pointsBalancesLoading)
            return;
        let tokenArr = [];
        myPointsBalances?.data?.forEach(element => {
            const curr = element?.currency_points?.currency_config?.currency;
            tokenArr[`${curr}`] = false;
        });
        setToolTipOpen(prev => ({ ...prev, ...tokenArr }))
    }, [setSelectedCurrency])

    const openToolTip = (type) => {
        closeToolTips();

        setToolTipOpen(prevState => ({ ...prevState, [type]: true }));

        if (timeoutRefs.current[type]) {
            clearTimeout(timeoutRefs.current[type]);
        }

        timeoutRefs.current[type] = setTimeout(() => {
            setToolTipOpen(prevState => ({ ...prevState, [type]: false }));
        }, 6000);
    };

    const closeToolTips = () => {
        setToolTipOpen({ allowance: false, received: false, revenue: false });
        Object.values(timeoutRefs.current).forEach(clearTimeout);
    };
    
    return (
        <div className='bg-white dark:bg-background min-w-[390px] border dark:border-outline rounded-sm p-5'>
        <div className='flex justify-between items-center'>
            <p className='prose-DisplaySmall text-txtOnLightBg dark:text-onBackground'>Your Token</p>
            <X onClick={() => setShowCurrencyModal(false)} fill="#ffffffa6" className="cursor-pointer" />
        </div>
        <div className='mt-4'>
            {myPointsBalances?.data?.map((token, i) => {
                const display_balance = token?.currency_points?.currency_config?.currency == "MOXIE" ? formatCash(parseFloat(moxieBalance)) : token?.display_balance;
                const loadingMoxieBalance = token?.currency_points?.currency_config?.currency == "MOXIE" ? isLoadingMoxieBalance : false;
                return (
                    <div key={i} onClick={() => handleTokenSelect(token)} className={`flex justify-between items-center px-2 py-2 rounded-sm mb-2 ${selectedCurrency === token?.currency_points?.currency_config?.currency ? 'bg-gray-300 dark:bg-surface dark:border-outline' : 'border-transparent'} border cursor-pointer hover:bg-gray-200 dark:hover:bg-white/5`}>
                        <div className='flex items-center gap-1'>
                            <img src={token?.currency_points?.currency_config?.image || logo} alt="" className='size-8 rounded-full' />
                            <p className='prose-BodyLarge text-txtOnLightBg dark:text-onBackground'>{token?.currency_points?.currency_config?.currency}</p>
                            {/* <div className='ml-1'>
                                <span className='relative'>
                                    <Info size={12} weight='bold' role='button' className='fill-gray-500 dark:fill-[#ffffff80]' onClick={() => openToolTip(token?.currency_points?.currency_config?.currency)} />
                                    {toolTipOpen[token?.currency_points?.currency_config?.currency] && <div className='absolute left-6 -top-[70px]'>
                                        <ToolTip pointer={'left'} text={toolTipText('tokenInfo', token?.currency_points?.currency_config?.currency)} />
                                    </div>}
                                </span>
                            </div> */}
                        </div>
                        <div className='flex flex-col justify-center items-end'>
                            <p className='prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground'>{loadingMoxieBalance ? <div className='bg-white/10 h-6 w-12 rounded-md animate-pulse'/> : display_balance || "-"}</p>
                            <p className='prose-LabelSmall text-subTxtOnLightBg dark:text-onBackgroundII'>points</p>
                        </div>
                    </div>
                )
            })}
        </div>
        <button onClick={() => setShowCurrencyModal(false)} className='font-[bungee] bg-primary text-onPrimary w-full py-2 rounded-sm mt-3'>
            Switch to {selectedCurrency}
        </button>
    </div>
    );
};

export default CurrencyModal;


const toolTipText = (type, currency) => {
    switch (type) {
        case 'received': return `Total tips received in the last 24 hrs (after 25% deduction for ticket holders). Resets daily at 12 AM UTC.`
        // switch (currency) {
        //     case 'WILD': return 'Tips WILD'
        //     case 'TREE': return 'Tips TREE'
        //     case 'DEGEN': return 'Tips DEGEN'
        // }
        case 'revenue': return `Your share of ticket's earning. Updates real-time. Resets daily 12 AM UTC.`
        // switch (currency) {
        //     case 'WILD': return 'Revenue WILD'
        //     case 'TREE': return 'Revenue TREE'
        //     case 'DEGEN': return 'Revenue DEGEN'
        // }
        case 'allowance': switch (currency) {
            case 'WILD': return 'Daily tipping allowance based on activity. Resets daily 12 AM UTC. Tip wisely.'
            case 'TREE': return 'Daily tipping allowance based on activity. Resets daily 12 AM UTC. Tip wisely.'
            case 'HAM': return 'Your daily tip allowance. Proportional to HAM locked in. Read more https://ham.fun/airdrops'
            case 'DUDEGEN': return 'Daily tipping allowance based on activity. Resets daily 12 AM UTC. Tip wisely.'
            case 'DEGEN': return 'Your daily tip allowance. Proportional to $DEGEN locked in. Read more https://www.degen.tips/airdrop2/current'
            default: return ''

        }
        case 'tokenInfo': switch (currency) {
            case 'WILD': return `WILD points are native to Wildcard. Earn them via tips, quests or ticket's revenue share.`
            case 'TREE': return `To be eligible for $TREE allowance, you must hold $TREE in your WILD wallet.\nAllocation tiers:\n100k $TREE = 1k daily\n1M $TREE = 2.5k daily\n5M staked $TREE on rebase = 10k daily\nBuy tree (https://app.uniswap.org/explore/tokens/base/0x6888c2409d48222e2cb738eb5a805a522a96ce80)`
            case 'DUDEGEN': return `$DUDEGEN token belongs to /dudegen.You need 10K $DUDEGEN in wallet to start earning allowance.`
            case 'HAM': return `HAM is 1-1 repped by $tn100x & token belongs to /lp. To qualify, either hold \n(1) 100K $tn100x on base or hamchain, or \n(2) 1 Ham LP NFT, or\n(3) 1 Based LP NFT\nto earn allowance. Read more: https://ham.fun/airdrops`
            case 'DEGEN': return `$DEGEN token belongs to /degen. You need to lock in $degen to earn allowance. Read more: https://www.degen.tips/airdrop2/current`
            default: return ''

        }
        default: return ''
    }
}