import { Minus, Plus, X } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.png';
import useBuyNFTTransaction from '../../hooks/buySellNFT';
import { getWalletDetail } from '../../services/api';
import { buyTicketRange } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { displaySnackbar } from '../../features/thunkMiddleware';
import { roundOff } from '../../utils/helper';


const findClosestIndex = (value) => {
    let closest = 0;
    let minDiff = Math.abs(value - buyTicketRange[0]);
    for (let i = 1; i < buyTicketRange.length; i++) {
        const diff = Math.abs(value - buyTicketRange[i]);
        if (diff < minDiff) {
            closest = i;
            minDiff = diff;
        }
    }
    return closest;
};


const BuyModal = ({ isOpen, closeModal, selectedBuy, refetch }) => {

    const { buyNFT, getNFTPricesBulk } = useBuyNFTTransaction();
    const dispatch = useDispatch()

    const [rangeValue, setRangeValue] = useState(0);
    const [costExceedBal, setCostExceedBal] = useState(false);
    const [remainingBal, setRemainingBal] = useState(0)

    const [buyTicketQuantity, setBuyTicketQuantity] = useState(1);

    const [value, setValue] = useState(0.0001);

    const [BuyRange, setBuyRange] = useState([])

    const [showDepositModal, setShowDepositModal] = useState(false)

    const [ethBuyPrice, setEthBuyPrice] = useState(0);

    const { data: walletDetails, isLoading: isLoadingWalletDetails } = useQuery({
        queryKey: ['walletDetails'],
        queryFn: getWalletDetail,
    });
   

    useEffect(() => {
        if (!isLoadingWalletDetails) {
            setRemainingBal(walletDetails?.eth_holdings?.value)
            // setRangeValue(1)
        }
        // if (!isOpen) {
        //     setBuyTicketQuantity(1)
        // }
        getBulkPrice()
    }, [isLoadingWalletDetails, isOpen])

    useEffect(() => {
        if (!isLoadingWalletDetails && walletDetails?.eth_holdings?.value <= ethBuyPrice) {
            setRemainingBal((walletDetails?.eth_holdings?.value + (ethBuyPrice)))
            setCostExceedBal(true)
            return
        }
        if (!isLoadingWalletDetails && walletDetails?.eth_holdings?.value > ethBuyPrice) {
            setRemainingBal((walletDetails?.eth_holdings?.value - (ethBuyPrice)))
            setCostExceedBal(false)
            return
        }
    }, [buyTicketQuantity, ethBuyPrice, walletDetails])

    useEffect(() => {

        async function getEthToUsdRate() {
            const response = await fetch('https://api.uniblock.dev/uni/v1/token/price?chainId=8453&contractAddress=0x4200000000000000000000000000000000000006');
            const data = await response.json();
            localStorage.setItem('ethToUsdRate', data?.usdPrice);
            return data.usdPrice;
        }
        getEthToUsdRate()

        const getEthPrice = setInterval(() => {
            async function getEthToUsdRateInterval() {
                const response = await fetch('https://api.uniblock.dev/uni/v1/token/price?chainId=8453&contractAddress=0x4200000000000000000000000000000000000006');
                const data = await response.json();
                localStorage.setItem('ethToUsdRate', data?.usdPrice);
                return data?.usdPrice;
            }
            getEthToUsdRateInterval();
        }, 100000);
    
        return () => clearInterval(getEthPrice);
    }, [])
    
    
    const convertEthToUsd = (ethAmount) => {
        const rate = localStorage.getItem('ethToUsdRate');
        const usdAmount = ethAmount * rate;
        return usdAmount?.toFixed(2);
    }

    const getBulkPrice = async () => {
        const quantities = buyTicketRange.map(i => i * 1000)
        const bulkPrice = await getNFTPricesBulk(selectedBuy?.farcaster_user?.fid, quantities)
        const buyPrices = bulkPrice?.map(i => Number(i.buyPriceWithFee))
        setBuyRange(buyPrices)

        setTimeout(() => {
            if(convertEthToUsd(buyPrices[buyTicketRange.indexOf(1)]) > 100){ // if eth USD greater than 100 then set 0.1 tkt quantity
                setEthBuyPrice(buyPrices[buyTicketRange.indexOf(0.1)])
                setRangeValue(buyTicketRange[buyTicketRange.indexOf(0.1)]);
                setBuyTicketQuantity(buyTicketRange[buyTicketRange.indexOf(0.1)]);
            } else {
                setEthBuyPrice(buyPrices[buyTicketRange.indexOf(1)])
                setRangeValue(buyTicketRange[buyTicketRange.indexOf(1)]);
                setBuyTicketQuantity(buyTicketRange[buyTicketRange.indexOf(1)]);
            }
        }, 500);
    }

    function getCurrentIndex(buyTicketQuantity) {
        return buyTicketRange.indexOf(buyTicketQuantity);
    }

    const handleChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const index = Math.round(e.target.value);
        setRangeValue(buyTicketRange[index]);
        setBuyTicketQuantity(buyTicketRange[index]);
        setEthBuyPrice(BuyRange[index])
        setValue(buyTicketRange[index]);
    };

    const handleIncrement = () => {
        const currentIndex = getCurrentIndex(buyTicketQuantity);
        if (currentIndex < buyTicketRange.length - 1) {
            setBuyTicketQuantity(buyTicketRange[currentIndex + 1]);
            setRangeValue(buyTicketRange[currentIndex + 1]);
            setEthBuyPrice(BuyRange[currentIndex + 1])
        }
    }

    const handleDecrement = () => {
        const currentIndex = getCurrentIndex(buyTicketQuantity);
        if (currentIndex > 0) {
            setBuyTicketQuantity(buyTicketRange[currentIndex - 1]);
            setRangeValue(buyTicketRange[currentIndex - 1]);
            setEthBuyPrice(BuyRange[currentIndex - 1])
        }
    }

    const handleEthInputValue = async (e) => {
        const value = e.target.value;
        // debouncefn( async () =>{
            const bulkPrice = await getNFTPricesBulk(selectedBuy?.farcaster_user?.fid, [value * 1000]);
            if (value >= 0.0001 && value <= 50 && bulkPrice) {
                setValue(value);
                setBuyTicketQuantity(value);
                setRangeValue(buyTicketRange[findClosestIndex(value)]);
                setEthBuyPrice(bulkPrice[0]?.buyPriceWithFee)
            } 
            if(value <= 0.0001 ){
                setValue(0.0001)
                setBuyTicketQuantity(value);
                setRangeValue(buyTicketRange[findClosestIndex(0.0001)]);
            }
            if(value >= 50){
                setValue(50)
                setBuyTicketQuantity(value);
                setRangeValue(buyTicketRange[findClosestIndex(50)]);
            }
        // }, 1000)
    }


    const handleBuyNFT = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        // setBuyTicketLoading(true)

        closeModal(e)

        // const ethValueWithTax = ethValue;
        const fid = selectedBuy.farcaster_user?.fid;
        const username = selectedBuy.farcaster_user?.username;

        const res = await buyNFT(username, ethBuyPrice, fid, buyTicketQuantity * 1000);
        if (res) {
            // setBuyTicketSuccess(true)
            refetch()
            dispatch(displaySnackbar(`Bought ${buyTicketQuantity} tickets of ${username}`))
            // setBuyTicketLoading(false)
        } else {
            // setBuyTicketFail(true)
            // setBuyTicketLoading(false)
            dispatch(displaySnackbar(`Failed to buy tickets of ${username}`))
            setTimeout(() => { closeModal(e) }, 2000)
        }
    };
    
    const handleShowDepositModal = () => {
        setShowDepositModal(true)
    }

    const handleCloseDepositModal = () => {
        setShowDepositModal(false)
    }

    // if (buyTicketLoading) return (
    //     <div className="h-screen flex justify-center items-center relative bg-transparent bg-background rounded-lg sm:max-w-sm sm:mx-auto overflow-y-scroll p-4">
    //         <div className='flex flex-col gap-4 justify-center items-center prose-HeaderLarge text-white'>
    //             <span>
    //                 <img src={EmptyCheck} alt="EmptyCheck" />
    //             </span>
    //             <span>
    //                 Buying Ticket...
    //             </span>
    //         </div>
    //     </div>
    // )

    // if (buyTicketSuccess) return (
    //     <div className="h-screen flex justify-center items-center relative bg-transparent bg-background rounded-lg sm:max-w-sm sm:mx-auto overflow-y-scroll p-4">
    //         <div className='flex flex-col gap-4 justify-center items-center prose-HeaderLarge text-white'>
    //             <span>
    //                 <CheckCircle size={64} color='#B4F6A5' />
    //             </span>
    //             <span>
    //                 Ticket Bought!
    //             </span>
    //         </div>
    //     </div>
    // )

    // if (buyTicketFail) return (
    //     <div className="h-screen flex justify-center items-center relative bg-transparent bg-background rounded-lg sm:max-w-sm sm:mx-auto overflow-y-scroll p-4">
    //         <div className='flex flex-col gap-4 justify-center items-center prose-HeaderLarge text-white'>
    //             <span>
    //                 <XCircle size={64} color='red' />
    //             </span>
    //             <span>
    //                 Transaction Failed!
    //             </span>
    //         </div>
    //     </div>
    // )

    return (
        <div
            onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
            className={`relative flex flex-col bg-white dark:bg-[#090909] rounded-lg  overflow-y-scroll pt-7 px-5 pb-6 w-full max-w-[480px]`}>
            <div className='absolute top-4 right-4' onClick={(e) => { closeModal(e); e.preventDefault(); e.stopPropagation() }}
            ><X size={24} className='cursor-pointer fill-black dark:fill-white' /></div>
            <div className='flex flex-col items-start justify-between'>
                <div className='flex items-center gap-4'>
                    <button
                        onClick={() => { }}
                        className="size-12 flex justify-center items-center rounded-full self-end overflow-hidden">
                        <img
                            src={selectedBuy?.farcaster_user?.pfp_url || logo}
                            alt="avatar"
                            className="size-full rounded-full object-cover"
                        />
                    </button>
                    <div>
                    {
                        selectedBuy?.num_tickets_owned_by_me > 0 && <p className=' prose-BodyLarge text-subTxtOnLightBg dark:text-onBackgroundII '>Tickets Owned by you: { selectedBuy?.num_tickets_owned_by_me/1000}</p>
                    }
                </div>
                </div>
                <div className='prose-DisplaySmall text-txtOnLightBg dark:text-onBackground break-words my-4'>
                    BUY TICKET OF {selectedBuy?.farcaster_user?.display_name}
                </div>
            </div>
            <div className='border-t dark:border-outline'></div>
            <div className='flex flex-col justify-center items-center mt-4 h-[131px]'>
                <div className='flex items-center gap-6'>
                    <button onClick={(e) => { handleDecrement(e) }} className='border dark:order-outline dark:bg-outline size-8 flex justify-center items-center'><Minus size={20} className='fill-black dark:fill-white'/></button>
                    <input 
                        onChange={(e) => {handleEthInputValue(e)}} 
                        type='number' 
                        value={buyTicketQuantity}
                        placeholder={value}
                        min="0.0001" 
                        max="50" 
                        step="0.001" 
                        style={{ "MozAppearance": "textfield" }}
                        className="font-['Bungee'] max-w-[200px] text-[40px] text-center leading-[83.2px] border-none outline-none focus:border-none bg-transparent text-txtOnLightBg dark:text-onBackground [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" 
                    />
                    <button onClick={(e) => { handleIncrement(e) }} className='border dark:border-outline dark:bg-outline size-8 flex justify-center items-center'><Plus size={20} className='fill-black dark:fill-white'/></button>
                </div>
            </div>
            <div className="flex mt-4 items-center slidecontainer relative">
                <input
                    type="range"
                    // defaultValue={buyTicketRange.indexOf(0.1)}
                    min="0"
                    max={buyTicketRange?.length - 1}
                    value={buyTicketRange?.indexOf(rangeValue)}
                    onChange={(e) => handleChange(e)}
                    onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                    className="w-full  accent-primary"
                />
                <div className='absolute left-0 prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundII top-3'>{buyTicketRange[0]}</div>
                <div className='absolute right-0 prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundII top-3'>{buyTicketRange[buyTicketRange?.length - 1]}</div>
            </div>

            <div className='mt-8 flex flex-col gap-[10px] border-t dark:border-outline pt-4'>
                <div className='flex justify-between items-center'>
                    <h3 className='prose-BodyLarge text-subTxtOnLightBg dark:text-onBackgroundII'>Wallet Balance</h3>
                    <p className='prose-BodyLargeBold text-subTxtOnLightBg dark:text-onBackgroundII'>{isLoadingWalletDetails ? <p className='animate-pulse w-14 h-4 bg-outline rounded-sm'></p> : <span>{walletDetails?.eth_holdings?.display_value} {walletDetails?.eth_holdings?.currency}</span>}</p>
                </div>
                <div className='flex justify-between items-center'>
                    <h3 className='prose-BodyLarge text-subTxtOnLightBg dark:text-onBackgroundII'>Cost for {buyTicketQuantity}x Tickets</h3>
                    <p className='prose-BodyLargeBold  text-primary flex items-center gap-1'>-{ethBuyPrice && roundOff(ethBuyPrice)} ETH</p>
                </div>
                <div className='flex justify-between items-center'>
                    <h3 className='prose-BodyLarge text-subTxtOnLightBg dark:text-onBackgroundII'>Remaining Balance</h3>
                    <p className={`prose-BodyLargeBold ${costExceedBal ? "text-error" : "text-subTxtOnLightBg dark:text-onBackgroundII"}`}>{costExceedBal ? "Insufficient Balance" : remainingBal?.toFixed(4) + " ETH"}</p>
                </div>
            </div>
            <div className='mt-4 w-full flex justify-center items-center'>
                {costExceedBal
                    ? <button onClick={handleShowDepositModal} className={`prose-HeaderLarge  bg-primary drop-shadow-sm text-center text-white dark:text-onPrimary py-[5px] w-full`}>Fund your wallet</button>
                    : <button onClick={(e) => handleBuyNFT(e)} disabled={costExceedBal} className={`prose-HeaderLarge ${costExceedBal ? ' opacity-60 bg-primary' : ' bg-primary custom-hover'} drop-shadow-sm text-white dark:text-onPrimary py-[5px] w-full`}>BUY</button>
                }
            </div>
            {/* <CustomModal isOpen={showDepositModal} closeModal={handleCloseDepositModal} downModal={true}>
                <DepositModal walletDetail={walletDetails} closeModal={handleCloseDepositModal} downModal={true} refetch={refetch} />
            </CustomModal> */}
        </div>
    )
}

export default BuyModal
