/** Parses a [CAIP-10](https://github.com/ChainAgnostic/CAIPs/blob/main/CAIPs/caip-10.md) compliant URL with optional token ID */
export function getTokenFromUrl(url) {
    // Split the URL by ':' to get the parts
    const parts = url.split(":");

    if (parts.length < 3 || parts.length > 4) {
        throw new Error("Invalid token URL");
    }

    const [namespace, chainId, address, tokenId] = parts;

    if (!namespace || !chainId || !address) {
        throw new Error("Invalid token URL");
    }

    return {
        namespace,
        chainId: parseInt(chainId, 10),
        contractAddress: address,
        tokenId: tokenId || undefined,
    };
}