import React from "react";
import { Link } from "react-router-dom";
import ChannelHoverCard from "../components/ChannelHoverCard";
import ProfileHoverCard from "../components/ProfileHoverCard";
// import Frame from "../components/Frame";

export const decodeAndParseToken = (token = '') => {
    const value = token.split('.')[1]
    try {
        const decodedToken = window.atob(value);
        const parsedToken = JSON.parse(decodedToken);
        return parsedToken;
    } catch (error) {
        if (error instanceof DOMException && error.name === 'InvalidCharacterError') {
            console.error("Error decoding token: The token contains invalid characters.");
        } else {
            console.error("Error decoding or parsing token:", error);
        }
        return null;
    }
}

export const shortenHexString = (hexString, visibleLength = 10) => {
    if (!hexString) {
        return '';
    }
    if (hexString.length <= visibleLength) {
        return hexString;
    }

    const visiblePartLength = Math.floor((visibleLength - 2) / 2);
    const start = hexString.slice(0, visiblePartLength);
    const end = hexString.slice(-visiblePartLength);

    return `${start}..${end}`;
}

export const isMobileDevice = () => {
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

export const isIOSDevice = () => {
    const userAgent = navigator.userAgent;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

export const isSafariBrowser = () => {
    const userAgent = navigator.userAgent;
    return /^((?!chrome|android).)*safari/i.test(userAgent);
}

export const isDesktopDevice = () => {
    return window.innerWidth >= 768;
}

export const extractTimeValue = (displayValue) => {
    const match = displayValue.match(/\d+[dhms]/);
    return match ? match[0] : '';
};

export const debouncefn = (fn, delay = 1000) => {
    let timeoutId;
    return function (...args) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}

export const pluralizeWord = (word, count) => {
    if (count <= 1) {
        return word;
    }
    return `${word}s`;
}

export const formatPrice = (value) => {
    if (value < 1000) {
        return value?.toString();
    } else if (value < 1000000) {
        return (value / 1000)?.toFixed(1) + 'K';
    } else if (value < 1000000000) {
        return (value / 1000000)?.toFixed(1) + 'M';
    } else {
        return (value / 1000000000)?.toFixed(1) + 'B';
    }
}


export const timeSince = (timestamp) => {
    const now = Math.floor(Date.now() / 1000);
    const seconds = now - timestamp;

    if (seconds < 60) {
        return "just now";
    }

    const intervals = {
        year: { value: 31536000 },
        month: { value: 2592000 },
        week: { value: 604800, abbreviation: "w" },
        day: { value: 86400, abbreviation: "d" },
        hour: { value: 3600, abbreviation: "h" },
        minute: { value: 60, abbreviation: "m" }
    };

    for (const [interval, { value, abbreviation = interval }] of Object.entries(intervals)) {
        const count = Math.floor(seconds / value);
        if (count >= 1) {
            return `${count}${abbreviation}`;
        }
    }
};

export const converDateTimeStr = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) return `${interval} y`;
    if (interval === 1) return "1 y";

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return `${interval} mon`;
    if (interval === 1) return "1 mon";

    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `${interval} d`;
    if (interval === 1) return "1 d";

    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `${interval} h`;
    if (interval === 1) return "1 h";

    interval = Math.floor(seconds / 60);
    if (interval > 1) return `${interval} m`;
    if (interval === 1) return "1 m";

    return seconds > 1 ? `${seconds} s` : "just now";
}

export const roundOff = (number) => {
    if (number === 0) return 0;

    const decimalPlaces = Math.floor(Math.log10(Math.abs(number))) * -1;
    const factor = Math.pow(10, decimalPlaces);
    const rounded = Math.round(number * factor) / factor;

    return parseFloat(rounded.toString());
}

export const highlightWord = (text = "", lineBreak = true, navigate) => {
    const elements = [];

    // Updated regex to include dots in usernames and mentions
    const regex = /(https?:\/\/[^\s]+|\B\/[\w.-]+|\B@[\w.-]+|\B\$\S+|\n+)/g;

    const createElement = (part, index) => {
        if (part.match(/^https?:\/\/[^\s]+$/)) {
            return (
                <a key={index} href={part} onClick={e => e.stopPropagation()} className="cursor-pointer break-all text-blue-500 hover:underline-offset-2 hover:underline text-wrap flex-wrap" target="_blank" rel="noopener noreferrer">
                    {part}
                </a>
            );
        } else if (part.match(/^\B\/[\w.-]+$/)) {
            // Remove punctuation marks from the end of the channel name
            const cleanedPart = part.replace(/[.,!?;:]$/, '');
            const channelId = cleanedPart.slice(1);
            return (
                <Link to={`/channel/${channelId}`} key={index}>
                    <ChannelHoverCard channelid={channelId}>
                        <span role="button" onClick={(e) => {
                            e.stopPropagation();
                        }} key={index} className="cursor-pointer text-primary">
                            {part}
                        </span>
                    </ChannelHoverCard>
                </Link>
            );
        } else if (part.match(/^\B@[\w.-]+$/)) {
            return (
                <Link to={`/profile/${part.slice(1)}`} key={index}>
                    <ProfileHoverCard username={part.slice(1)}>
                        <span role="button" onClick={(e) => {
                            e.stopPropagation();
                            }} key={index} className="cursor-pointer text-primary">
                            {part}
                        </span>
                    </ProfileHoverCard>
                </Link>
            );
        } else if (part.match(/^\$\S+$/)) {
            return (
                <span key={index} className="text-warning">
                    {part}
                </span>
            );
        } else if (part.includes('\n') && lineBreak) {
            const lines = part.split('\n');
            lines.forEach((line, i) => {
                elements.push(
                    <React.Fragment key={`${index}-${i}`}>
                        {line}
                        {i !== lines.length - 1 && <br />}
                    </React.Fragment>
                );
            });
        } else {
            return part;
        }
    };

    text.split(regex).forEach((part, index) => {
        if (part) {
            elements.push(createElement(part, index));
        }
    });

    return elements;
};


export const getQuestTitle = (quest_type, num_steps) => {
    switch (quest_type) {
        case "invite_quest": return `Invite ${num_steps} ${pluralizeWord("Friend", num_steps)}`
        case "tip_quest": return `Tip on ${num_steps} ${pluralizeWord("Cast", num_steps)}`
        case "purchase_quest": return `Buy ${num_steps} ${pluralizeWord("Ticket", num_steps)}`
        case "cast_quest": return `Maintain ${num_steps} days cast streak`
        case "tree_cast_quest": return `Maintain ${num_steps} days cast streak in /treeplanting`
        default: return "";
    }
}
export const getQuestDescription = (quest_type, num_steps) => {
    switch (quest_type) {
        case "invite_quest": return `Invite ${num_steps} friends and win rewards`
        case "tip_quest": return `Tip on ${num_steps} ${pluralizeWord("Cast", num_steps)} and win rewards`
        case "purchase_quest": return `Buy ${num_steps} ${pluralizeWord("Ticket", num_steps)} and win rewards`
        case "cast_quest": return `Maintain ${num_steps} days cast streak and win rewards`
        case "tree_cast_quest": return `Maintain ${num_steps} days cast streak in /treeplanting and win rewards`
        default: return "";

    }
}

export const updateTimer = (expiryDate, setTimeLeft, setExpiringSoon = () => { }) => {
    const now = new Date();
    const timeDifference = expiryDate - now;

    if (timeDifference <= 0) {
        setTimeLeft('Expired');
        return;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    if (days > 0) {
        setTimeLeft(`${days} days ${hours} h`);
    } else {
        setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
    }

    if (timeDifference < 5 * 24 * 60 * 60 * 1000) { // less than 5 days
        setExpiringSoon(true);
    } else {
        setExpiringSoon(false);
    }
};

export const mapToPercentageInterval = (length) => {
    if (length === 0) return 0;
    if (length <= 25) return 25;
    if (length <= 50) return 50;
    if (length <= 75) return 75;
    if (length <= 100) return 100;
    return 0;
};

export const getSystemTheme = () => {
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return isDarkMode ? 'dark' : 'light';
}

export const getTimeInLocalFormat = (timestamp, type) => {
    const date = new Date(timestamp);
    switch (type) {
        case 'time':
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        case 'date':
            return date.toLocaleDateString();
    }
};

export const formatNumber = (num) => {
    const roundedValue = (value) => {
        return value % 1 === 0 ? value.toString() : value.toFixed(2);
    };

    if (num >= 1000000000) {
        return roundedValue(num / 1000000000) + 'B';
    } else if (num >= 1000000) {
        return roundedValue(num / 1000000) + 'M';
    } else if (num >= 1000) {
        return roundedValue(num / 1000) + 'K';
    } else {
        return roundedValue(num);
    }
}


export const cleanMentions = (text) => {
    const mentionPattern = /@\[(.*?)\]\(\d+\)/g;
    let cleanedText = text.replace(mentionPattern, '@$1');
    const channelPattern = /\/\[(.*?)\]/g;
    cleanedText = cleanedText.replace(channelPattern, '/$1');
    return cleanedText;
}

export const formatCash = (amount) => {
    return Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 1
    }).format(amount);
}
