import { Empty } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CastCards from '../../../components/Cards/Casts/CastCards';
import CastSkeletonLoading from '../../../components/Cards/Casts/CastSkeletonLoading';
import { getProfileCasts } from '../../../services/api';
import { useParams } from 'react-router-dom';
import { getSelectedCurrencyLocalStorage } from '../../../utils/constants';

const CastsTab = ({withReplies = false}) => {
    const castObserver = useRef();
    const [castsData, setCastsData] = useState([]);
    const [hasMoreCasts, setHasMoreCasts] = useState(true);
    const [moreCastsLoading, setMoreCastsLoading] = useState(false);
    const [castCursor, setCastCursor] = useState(null)

    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency;

    const { username } = useParams()

    const { data: profileCasts, isLoading: profileCastsLoading, refetch: refetchCasts } = useQuery({
        queryKey: ['currentUserCasts', username, withReplies, selectedCurrency],
        queryFn: () => getProfileCasts(username, undefined, withReplies, selectedCurrency),
        enabled: !!selectedCurrency
    });

    useEffect(() => {
        setCastsData(profileCasts?.data?.casts)
        setCastCursor(profileCasts?.data?.next_cursor)
        setHasMoreCasts(profileCasts?.data?.has_more)
    }, [profileCasts])

    const fetchMoreCastData = async () => {
        if (castCursor === null) return;
        setMoreCastsLoading(true);
        try {
            let response = await getProfileCasts(username, castCursor, withReplies, selectedCurrency);
            if (response.success) {
                const data = response?.data?.casts;
                setCastCursor(response?.data?.next_cursor)
                setCastsData((prevData) => [...prevData, ...data]);
                if (!data.length) setHasMoreCasts(false);
            } else {
                setHasMoreCasts(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setHasMoreCasts(false);
        } finally {
            setMoreCastsLoading(false);
        }
    };

    const lastCastElementRef = useCallback((node) => {
        if (profileCastsLoading) return;
        if (castObserver.current) castObserver.current.disconnect();
        castObserver.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMoreCasts) {
                fetchMoreCastData();
            }
        });
        if (node) castObserver.current.observe(node);
    }, [profileCastsLoading, hasMoreCasts, username, castCursor]);


    return (
        <div>
            {profileCastsLoading ? <>
                <CastSkeletonLoading />
                <CastSkeletonLoading />
            </>
                : !castsData?.length ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4">
                    <Empty size={20} className='fill-black dark:fill-[#ffffffa6]' />
                    <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                </div>
                    : castsData?.map((data, idx) => {
                        if (castsData.length === idx + 1) {

                            return (<div ref={lastCastElementRef} key={idx}>
                                <CastCards castData={data} i={idx} refetchCasts={refetchCasts} fromNotificationReply={false} />
                            </div>)
                        }
                        return (<div ref={lastCastElementRef} key={idx}>
                            <CastCards castData={data} i={idx} refetchCasts={refetchCasts} fromNotificationReply={false} />
                        </div>)
                    })
            }
            {moreCastsLoading && (
                <div className='absolute -bottom-1 md:bottom-0 w-full flex items-center justify-center'>
                    <span className='loading loading-dots bg-gray-400 dark:bg-white  loading-md'></span>
                </div>
            )}
        </div>
    );
}
export default CastsTab;