import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import WalletDetail from '../../components/Cards/Wallet/WalletDetail';
import TopHeader from '../../components/Sidebars/TopHeader';
import Tabs from '../../components/Tabs';
import { getWalletDetail, getWalletOwnedTickets, getWalletTradesTransactions } from '../../services/api';
import { WALLET_TAB_CHOICES } from '../../utils/constants';

import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

import HoldingsTab from './Components/HoldingsTab';
import TradesTab from './Components/TradesTab';

const initialTabs = [
    {
        name: WALLET_TAB_CHOICES.HOLDINGS,
        isActive: true,
        id: 'holdings'
    },
    {
        name: WALLET_TAB_CHOICES.TOKENS,
        isActive: false,
        id: 'tokens'
    },
    {
        name: WALLET_TAB_CHOICES.TRADES,
        isActive: false,
        id: 'trades'
    },
];



const WalletPage = ({ showHeader }) => {

    const queryClient = useQueryClient();
    const { tab } = useParams();
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState('holdings');
    const [tabs, setTabs] = useState(initialTabs);

    const fetchQueryOptions = { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 10 };

    const { refetch: ownedTicketRefetch } = useQuery({
        queryKey: ['ownedTicket'],
        queryFn: getWalletOwnedTickets,
        enabled: selectedTab === 'holdings'
    });

    const { refetch: transactionTradesRefetch } = useQuery({
        queryKey: ['transactionTrades'],
        queryFn: () => getWalletTradesTransactions(1),
        ...fetchQueryOptions,
        enabled: selectedTab === 'trades'
    });

    const { data: walletDetail = {}, isLoading: walletDetailLoading, refetch: walletRefetch } = useQuery({
        queryKey: ['walletDetail'],
        queryFn: getWalletDetail
    });

    useEffect(() => {
        switch (tab) {
            case 'holdings':
                ownedTicketRefetch();
                break;
            case 'trades':
                transactionTradesRefetch(1);
                break;
            default:
                ownedTicketRefetch();
                break;
        }
    }, [selectedTab])

    useEffect(() => {
        const prefetchQueriesSequentially = async () => {
            await queryClient.prefetchQuery({
                queryKey: ['ownedTicket'],
                queryFn: () => getWalletOwnedTickets()
            });
            await queryClient.prefetchQuery({
                queryKey: ['transactionTrades'],
                queryFn: () => getWalletTradesTransactions(1)
            });
        };
        prefetchQueriesSequentially();
    }, [])

    const handleTabClick = (name) => {
        const newTabs = tabs.map((tab) => ({
            ...tab,
            isActive: tab.id === name
        }));
        setTabs(newTabs);
        setSelectedTab(name);
        navigate(`/wallet/${name}`);
    };

    const handleTabChange = (index) => {
        const newTab = tabs[index];
        handleTabClick(newTab.id);
    };

    useEffect(() => {
        const name = tab || 'holdings';
        const newTabs = tabs.map((tab) => ({
            ...tab,
            isActive: tab.id === name
        }));
        setTabs(newTabs);
        setSelectedTab(name);
    }, [tab])

    
    return (
        <div>
            <div className={`sticky top-0 z-30 bg-white dark:bg-background transition-transform duration-1000 ${showHeader ? 'translate-y-0' : 'translate-y-[-80px] lg:translate-y-0'} `}>
                <TopHeader PageTitle={'Wallet'} tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} showTabsInHeader={false} />
            </div>

            <div className='mb-6'>
                <WalletDetail walletDetail={walletDetail} walletRefetch={walletRefetch} />
            </div>

            <Tabs tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} fullWidthTab={true} type='top' />

            <div className='mt-5'>
                <SwipeableViews style={{ height: 'auto' }} index={tabs.findIndex(tab => tab.id === selectedTab)} onChangeIndex={handleTabChange}>
                    <div className='px-5'>
                        {selectedTab === 'holdings' && <HoldingsTab />}
                    </div>
                    <div className='px-5'>
                        {selectedTab === 'tokens' && <div>
                            {walletDetailLoading ? <div className='h-2 w-10 bg-surface animate-pulse'></div>
                                : walletDetail?.token_balances?.map((token, idx) => {
                                    return token.currency == 'WILD' ? <></> : <div key={idx} className='flex justify-between items-center mb-4'>
                                        <div className='flex items-center gap-1'>
                                                <img src={token?.image || logo} alt='token' className='size-5 rounded-full' />
                                            <p className='prose-BodyLarge text-txtOnLightBg dark:text-onBackground'>{token?.currency}</p>
                                        </div>
                                        <div>
                                            <p className='prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground'>{token?.display_value}</p>
                                        </div>
                                    </div>
                                })}
                        </div>}
                    </div>
                    <div className=''>
                        {selectedTab === 'trades' && <TradesTab /> }
                    </div>
                </SwipeableViews>
            </div>
        </div>
    )
}

export default WalletPage