import ethLogo from '../../../assets/images/ethLogo.png';
import baseLogo from '../../../assets/images/baseLogo.png';
import { formatNumber } from '../../../utils/helper';

const getChainLogo = (chainId) => {
    switch (chainId) {
      case 1:
        return ethLogo;
      case 8453:
        return baseLogo;
      default:
        return ethLogo;
    }
  }  

const TokenCard = ({ item, i }) => (
    <div 
    key={i}
    className="flex flex-row justify-between items-center w-full mb-4">
      <div className="flex flex-row items-center gap-2">
        <div className="w-9 h-9 relative">
          <img
            src={item?.token_logo}
            alt={item?.token_name}
            className="w-9 h-9 rounded-full text-[5px] text-black dark:text-white flex items-center justify-center border dark:border-0 border-black/10"
          />
          <img
            src={getChainLogo(item?.chain_id)}
            alt="Chain Logo"
            className="w-[16px] h-[16px] rounded-full absolute left-0 bottom-0"
          />
        </div>
        <div>
          <p className="text-black dark:text-onBackground prose-BodyMedium break-all max-w-[180px]" >
            {item?.token_name}
          </p>
          <p className="text-subTxtOnLightBg dark:text-onBackgroundII text-[14px]" style={{ fontSize: 14 }}>
            {formatNumber(item?.balance)} {item?.token_symbol}
          </p>
        </div>
      </div>
      <div>
        <p className="text-black dark:text-onBackground prose-LabelLarge" >
          $ {formatNumber(item?.usd_value)}
        </p>
      </div>
    </div>
  );
  
  export default TokenCard;
  