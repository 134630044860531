import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../../utils/helper';

import logo from '../../../assets/images/logo.png';
import powerBadge from '../../../assets/svg/powerbadge.svg';
import trophyBronze from '../../../assets/svg/ranking/trophy_bronze.svg';
import trophyGold from '../../../assets/svg/ranking/trophy_gold.svg';
import trophySilver from '../../../assets/svg/ranking/trophy_silver.svg';
import ProfileHoverCard from '../../ProfileHoverCard';

const HoldersCard = ({ data, i, selectedOption, trophies = false }) => {

    const navigate = useNavigate();
    // const [showProfileModal, setShowProfileModal] = useState(false)

    const { farcaster_user, tips_24h, key_details, num_tickets, top_holders_users, market_cap, market_cap_change_24h, buy_price_change_24h } = data;

    const handleNavigation = (username) => {
        navigate(`/profile/${username}`)
    }

    // const handleOpenProfileModal = () => {
    //     setShowProfileModal(true)
    // }
    // const handleCloseProfileModal = () => {
    //     setShowProfileModal(false)
    // }

    return (
        <div
            role="button"
            onClick={() => handleNavigation(farcaster_user?.username)}
            className=" h-[77px] lg:grid flex justify-between items-center grid-cols-3 py-4 px-5 lg:px-5 dark:lg:hover:bg-surface lg:hover:bg-surface/20"
        >

            <div className="flex flex-row items-center gap-2 w-full">
                <div className="flex items-start justify-center">
                    <ProfileHoverCard username={farcaster_user?.username}>
                        <img
                            onClick={(e) => {
                            e.stopPropagation();
                            handleNavigation(farcaster_user?.username)
                        }}
                        src={farcaster_user?.cdn_url || farcaster_user?.pfp_url || logo} alt=""
                        className="w-9 h-9 min-w-9 rounded-full" />
                    </ProfileHoverCard>
                </div>
                <div className="h-[45px] flex flex-col justify-center lg:w-[420px]">
                    <div className="flex items-center gap-2 lg:w-full lg:max-w-[270px] break-before-auto">
                        <ProfileHoverCard username={farcaster_user?.username} data={farcaster_user}>
                            <h2 className="prose-BodyLarge  text-txtOnLightBg dark:text-onBackground">@{farcaster_user?.username}</h2>
                        </ProfileHoverCard>
                        {
                            farcaster_user?.power_badge && <img src={powerBadge} alt="" />
                        }
                        {trophies ? i == 0 ? <img src={trophyGold} alt="" /> : i == 1 ? <img src={trophySilver} alt="" /> : i == 2 ? <img src={trophyBronze} alt="" /> : null : null
                        }
                    </div>
                    {
                        num_tickets > 0 &&
                        <div className="flex items-center justify-start gap-[6px] lg:hidden">
                            <div className="flex flex-row">
                                {
                                    top_holders_users.slice(0, 3)?.map((user, i) => {
                                        return <img key={i} src={user?.pfp_url || logo} alt="" className={`w-4 h-4 rounded-full ${i != 0 ? '-ml-1' : ''} `} />
                                    })
                                }
                            </div>
                            <p className="text-subTxtOnLightBg dark:text-onBackgroundIII prose-BodyMedium">{num_tickets} Holders</p>
                        </div>
                    }
                </div>
            </div>

            <div className="hidden items-center justify-center gap-[6px] lg:flex">
                {
                    num_tickets > 0 &&
                    <> <div className="flex flex-row">
                        {
                            top_holders_users.slice(0, 3)?.map((user, i) => {
                                return <img key={i} src={user?.pfp_url || logo} alt="" className={`w-4 h-4 rounded-full ${i != 0 ? '-ml-1' : ''} `} />
                            })
                        }
                        </div>
                        <p className="text-subTxtOnLightBg dark:text-onBackgroundIII prose-BodyMedium">{num_tickets} Holders</p>
                    </>
                }

            </div>
            <div className="flex flex-col items-end">
                <p className="font-['Inter'] font-normal text-[17px] text-txtOnLightBg dark:text-onBackground leading-[25.5px] tracking-[0.01em]">
                    {selectedOption === 'Price' && `${key_details?.buy_price?.amount?.display_value_obj?.usd_value_display}`}
                    {selectedOption === 'Market Cap' && `$${formatPrice(market_cap?.display_value)}`}
                </p>
                {
                    selectedOption === 'Market Cap' && (
                        (market_cap_change_24h?.value >= 0) ?
                            <p className="prose-LabelSmall  text-primary">+{formatPrice(market_cap_change_24h?.display_value)}</p> :
                            <p className="prose-LabelSmall text-[red]"> {formatPrice(market_cap_change_24h?.display_value)}</p>
                    )
                }
                {
                    selectedOption === 'Price' && (
                        (buy_price_change_24h?.value >= 0) ?
                            <p className="prose-LabelSmall  text-primary">+{formatPrice(buy_price_change_24h?.display_value)}</p> :
                            <p className="prose-LabelSmall text-[red]"> {formatPrice(buy_price_change_24h?.display_value)}</p>
                    )
                }
            </div>
            {/* {showProfileModal && <CustomModal isOpen={showProfileModal} closeModal={handleCloseProfileModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                <ProfileModal usernameFromClick={farcaster_user?.username} fromProfileModal={true} />
            </CustomModal>
            } */}
        </div>
    )
}

export default HoldersCard