import { usePrivy } from '@privy-io/react-auth';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuthMiddleware from '../hooks/useAuthMiddleware';
import useAccount from '../hooks/account';

const PrivateRoute = () => {
    const { ready, authenticated } = usePrivy();
    const location = useLocation();
    const { user,isFetchingUser } = useAccount();
    useAuthMiddleware();


    if (!ready && isFetchingUser) {
        return null
    }
    if (!user?.farcaster_details && !isFetchingUser) {
        return <Navigate to="/invite" />;
    }
    if (ready && authenticated) {

        return <Outlet />;
    }

    return <Navigate to={`/signin?redirect=${location.pathname}${location.search}`} />;
};

export default PrivateRoute;
