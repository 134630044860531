import React, {useState, useEffect} from 'react'
import CastCards from '../../../../components/Cards/Casts/CastCards'
import { markNotificationAsRead } from '../../../../services/api';

const QuoteCard = ({ data, unreadTimer }) => {
    const {is_read, id} = data;
    const [ isRead, setIsRead ] = useState(is_read);

    useEffect(() => {
        if (!isRead) {
            markNotificationAsRead([id]);
            const timeoutId = setTimeout(() => {
                setIsRead(true);
            }, unreadTimer);
    
            return () => clearTimeout(timeoutId);
        }
    }, [isRead, id]);

    const handleClick = () => {
        if(isRead) return;
        setIsRead(true)
    }

    if(data?.new_cast_hash_details === null) return null;

    return (
        <div 
        role='button'
        onClick={()=>handleClick()}
        className={`${isRead? 'bg-white dark:bg-background':'dark:bg-primary/10'} transition duration-500`}>
            <CastCards castData={data?.new_cast_hash_details} fromNotifications />
        </div>
    )
}

export default QuoteCard
