const TipTransactionSkeletonLoading = ({i}) => {
    return (
        <div key={i} className='flex justify-between items-center mt-4 px-5'>
            <div className='flex items-center gap-2'>
                <div className="size-9 rounded-full animate-pulse bg-surface" />
                <div>
                    <p className='h-[21px] w-36 animate-pulse bg-surface'></p>
                    <p className='h-[10px] w-5 animate-pulse bg-surface mt-[5px]'></p>
                </div>
            </div>
            <div className='h-6 w-20 animate-pulse bg-surface'>
            </div>
        </div>
    )
}

export default TipTransactionSkeletonLoading;