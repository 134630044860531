import { Gif, ImageSquare, Smiley } from '@phosphor-icons/react';
import EmojiPicker from 'emoji-picker-react';
import GifPicker, { Theme } from 'gif-picker-react';
import React, { useRef, useState } from 'react';
import { REACT_TENOR_API_KEY } from '../../../../utils/constants.js';
import Portal from '../../../UI/ReactPortal.jsx';

const MediaSelector = ({ inputRef, inputValue, setInputValue, setMediaPreview, mediaPreview, allowedMedia }) => {

    const [showPicker, setShowPicker] = useState(false);
    const [showMemepics, setShowMemepics] = useState(false);
    const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });
    const [emojiPosition, setEmojiPosition] = useState({ top: 0, left: 0 });
    const stickerRef = useRef(null);
    const emojiRef = useRef(null);

    const onEmojiClick = (emojiObject) => {
        const text = inputValue + (emojiObject?.emoji || '');
        setInputValue(text);
        setShowPicker(false);
        inputRef.current.focus();
    };
    const handleSelectGiphy = (e) => {
        setMediaPreview([...mediaPreview, { url: e?.preview?.url }])
        setShowMemepics(false)
        inputRef.current.focus();
    }

    const handleStickerClick = (type) => {
        if (stickerRef.current) {
            const rect = stickerRef.current.getBoundingClientRect();
            const emojirect = emojiRef.current.getBoundingClientRect();
            const isMobile = window.matchMedia("(max-width: 768px)").matches;
            const emojiModalHeight = 360;
            const stickerModalHeight = 300;
            const bottomSpaceEmoji = window.innerHeight - emojirect.bottom;
            const bottomSpaceSticker = window.innerHeight - rect.bottom;

            if (isMobile) {
                if (type === 'emoji') {
                    setEmojiPosition({ top: emojirect.top - emojiModalHeight, left: '50%' });
                } else if (type === 'sticker') {
                    setPickerPosition({ top: rect.top - stickerModalHeight, left: '50%' });
                }
            } else {
                if (type === 'emoji') {
                    if (bottomSpaceEmoji < emojiModalHeight) {
                        setEmojiPosition({ top: emojirect.top - emojiModalHeight, left: emojirect.left });
                    } else {
                        setEmojiPosition({ top: emojirect.bottom, left: emojirect.left });
                    }
                } else if (type === 'sticker') {
                    if (bottomSpaceSticker < stickerModalHeight) {
                        setPickerPosition({ top: rect.top - stickerModalHeight, left: rect.left });
                    } else {
                        setPickerPosition({ top: rect.bottom, left: rect.left });
                    }
                }
            }
        }
        if (type === 'emoji') {
            setShowPicker(true);
        } else if (type === 'sticker') {
            setShowMemepics(true);
        }
    };

    const handleMedia = (e) => {
        if (mediaPreview?.length >= allowedMedia) return;
        const file = e.target.files[0];
        if (!file) return;

        const isVideo = file.type.startsWith('video/');

        if (isVideo) {
            const videoElement = document.createElement('video');
            videoElement.preload = 'metadata';
            videoElement.onloadedmetadata = () => {
                const canvas = document.createElement('canvas');
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                canvas.getContext('2d').drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                const thumbnailUrl = canvas.toDataURL();

                setMediaPreview([...mediaPreview, {
                    url: file,
                    isVideo: true,
                    thumbnail: thumbnailUrl,
                    duration: videoElement.duration
                }]);
            }
            videoElement.src = URL.createObjectURL(file);
        } else {
            setMediaPreview([...mediaPreview, {
                url: file,
                isVideo: false
            }]);
        }
    }

    return (
        <div className='flex items-center gap-4'>
            <Smiley ref={emojiRef} onClick={() => { handleStickerClick('emoji') }} size={24} className='cursor-pointer fill-primary' />
            <button disabled={mediaPreview?.length >= allowedMedia} ref={stickerRef} onClick={() => handleStickerClick('sticker')}>
                <Gif size={24} className={`${mediaPreview?.length >= allowedMedia ? "fill-[#ffffffa6]" : "fill-primary"} cursor-pointer`} />
            </button>
            <button>
                <input accept="image/gif, image/jpeg, image/png, image/webp, video/mp4, video/quicktime, video/x-m4v"
                    disabled={mediaPreview?.length >= allowedMedia} onChange={handleMedia} type="file" id="file" className='hidden' />
                <label htmlFor="file">
                    <ImageSquare size={24} className={`${mediaPreview?.length >= allowedMedia ? "fill-[#ffffffa6]" : "fill-primary"} cursor-pointer`} />
                </label>
            </button>
            {
                showMemepics && (
                    <Portal>
                        <div className='fixed top-0 left-0 w-screen h-screen z-[1000]'>
                            <div className='fixed inset-0 bg-background opacity-50' onClick={() => setShowMemepics(false)}></div>
                            <div className='absolute -translate-x-1/2 md:translate-x-0' style={{ top: pickerPosition.top, left: pickerPosition.left }}>
                                <GifPicker
                                    height={300}
                                    tenorApiKey={REACT_TENOR_API_KEY}
                                    theme={Theme.DARK}
                                    onGifClick={handleSelectGiphy}
                                />
                            </div>
                        </div>
                    </Portal>
                )
            }
            {
                showPicker && (
                    <Portal>
                        <div className='fixed top-0 left-0 w-screen h-screen z-[1000]'>
                            <div className='fixed inset-0 bg-background opacity-50' onClick={() => setShowPicker(false)}></div>
                            <div className='absolute -translate-x-1/2 md:translate-x-0' style={{ top: emojiPosition.top, left: emojiPosition.left }}>
                                <EmojiPicker
                                    className='react-emoji-picker'
                                    skinTonePickerLocation="top"
                                    height={360}
                                    width={'100%'}
                                    theme={`${localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'}`}
                                    onEmojiClick={onEmojiClick}
                                    native={true}
                                    pickerStyle={{ width: '20%', height: 'auto' }}
                                    pickerColor="#B4F6A5"
                                    suggestedEmojisMode='recent'
                                />
                            </div>
                        </div>
                    </Portal>
                )
            }
        </div>
    )
}

export default MediaSelector;