import { ArrowLeft, Empty, Translate } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import logo from '../../assets/images/logo.png';
import CastCards from '../../components/Cards/Casts/CastCards';
import CastSkeletonLoading from '../../components/Cards/Casts/CastSkeletonLoading';
import TipTransactionCard from '../../components/Cards/Quest/TipTransactionCard';
import CastModal from '../../components/Modals/CastModal';
import Tabs from '../../components/Tabs';
import CustomModal from '../../components/UI/CustomModal';
import { setUserSelectedLanguage } from '../../features/globalStateSlice';
import { getCastDetailed, getCastTips, getCurrentUserProfile } from '../../services/api';
import { CAST_DETAIL_TAB_CHOICES, getSelectedCurrencyLocalStorage, LANGUAGES } from '../../utils/constants';
// import { useSelector } from 'react-redux';

const initialTabs = [
  {
    name: CAST_DETAIL_TAB_CHOICES.REPLIES,
    isActive: true,
  },
  {
    name: CAST_DETAIL_TAB_CHOICES.TIPS,
    isActive: false,
  }
];

const CastDetailedPage = () => {
  const navigate = useNavigate();
  // const castdata = useSelector(state => state?.app?.selectedCastDetails)

  const { fid, hash } = useParams();
  const dispatch = useDispatch();
  const { userSelectedLanguage } = useSelector((state) => state.app);
  const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency
  const [selectLanguageOpen, setSelectLanguageOpen] = useState(false);

  const [selectedTab, setSelectedTab] = useState(CAST_DETAIL_TAB_CHOICES.REPLIES);
  const [tabs, setTabs] = useState(initialTabs);

  const [showCastReplyModal, setShowCastReplyModal] = useState(false)

  const observer = useRef();
  const [tipsData, setTipsData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [moreLoading, setMoreLoading] = useState(false);
  const [page, setPage] = useState(2);

  const { data: specificCastData, isLoading: isCastDataLoading, refetch: castRefetch } = useQuery({
    queryKey: ['castDetail', { fid, hash }],
    queryFn: () => getCastDetailed({ fid, hash, currency: selectedCurrency }),
    enabled: !!fid && !!hash && !!selectedCurrency
  });

  const { data: castTips, isLoading: castTipsLoading } = useQuery({
    queryKey: ['castTips', { hash }, page],
    queryFn: () => getCastTips(hash, 1),
    enabled: !!hash
  });

  const { data: profileData, isLoading: profileDataLoading, refetch: profileRefetch } = useQuery({
    queryKey: ['getCurrentUserProfile'],
    queryFn: () => getCurrentUserProfile(),
  });

  useEffect(() => {
    setTipsData(castTips?.tips)
  }, [castTips])

  const fetchMoreData = async () => {
    setMoreLoading(true);
    try {
      let response = await getCastTips(hash, page);
      if (response.status === 200) {
        const data = response?.data;
        setTipsData((prevData) => [...prevData, ...data]);
        setPage(prev => prev + 1)
        if (!data.length) setHasMore(false);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setHasMore(false);
    } finally {
      setMoreLoading(false);
    }
  };

  const lastElementRef = useCallback((node) => {
    if (moreLoading || castTipsLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        fetchMoreData();
      }
    });
    if (node) observer.current.observe(node);
  }, [moreLoading, hasMore, page]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [hash, fid])

  const handleTabClick = (name) => {
    const newTabs = tabs.map((tab) => ({
      ...tab,
      isActive: tab.name === name
    }));
    setTabs(newTabs);
    setSelectedTab(name);
  };

  const handleTabChange = (index) => {
    const newTab = tabs[index];
    handleTabClick(newTab.name);
  };

  const handleShowCastReplyModal = () => {
    setShowCastReplyModal(true)
  }

  const handleCloseReplyCastModal = () => {
    setShowCastReplyModal(false)
  }

  const navigateUserProfile = () => {
    navigate(`/profile/${profileData?.data?.farcaster_user?.username}`)
  }

  const handleLanguageSelect = (language) => {
    dispatch(setUserSelectedLanguage(language));
  }  

  return (
    <>
      <div className='sticky top-0 left-0 bg-white dark:bg-background w-full z-50 pb-2 pt-4 px-4 flex items-center justify-between border-b dark:border-b-outlineIII'>
        <ArrowLeft size={24} onClick={() => navigate(-1)} className='cursor-pointer fill-black dark:fill-white' />
        <div className='h-8 relative w-fit px-4 border-outline rounded-sm uppercase bg-gray-400 dark:bg-surface hover:bg-gray-500  hidden md:flex justify-center items-center gap-2 lg:w-fit font-bold text-xs leading-[18px] tracking-widest text-nowrap text-gray-300 dark:text-onBackgroundII hover:text-gray-200 dark:hover:text-white' role='button' onClick={() => { setSelectLanguageOpen(prev => !prev) }}>
          <Translate size={16} /> {userSelectedLanguage.slice(0, 2)}
          {selectLanguageOpen && (
            <div className='absolute top-10 left-0 w-auto flex flex-col gap-1 bg-gray-300 dark:bg-surface'>
              {
                LANGUAGES.map((language, index) => (
                  <div key={index} onClick={() => handleLanguageSelect(language)} className='hover:bg-gray-400  text-subTxtOnLightBg dark:text-onBackgroundII prose-LabelSmall cursor-pointer px-2 flex items-center'>
                    {language}
                  </div>
                ))
              }
            </div>
          )}
        </div>
      </div>

      <div>
        {isCastDataLoading ? <>
          <CastSkeletonLoading />
          <div className='flex items-center gap-4 mx-5 my-4'>
            <div className='size-10 rounded-full bg-surface/50 dark:bg-surface animate-pulse' />
            <div className='h-6 w-24 bg-surface/50 dark:bg-surface animate-pulse' />
          </div>
          <div className='sticky top-[46px] md:top-[54px] bg-white dark:bg-background z-50 size-full mt-2'>
            <Tabs tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} fullWidthTab={true} type='top' />
          </div>
          <CastSkeletonLoading />
          <CastSkeletonLoading />
        </>
          : !specificCastData?.data?.cast?.length ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 mx-5 mt-4">
            <Empty size={20} className='fill-black dark:fill-[#ffffffa6]' />
            <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
          </div>
            : <div className='flex flex-col'>

              {specificCastData && specificCastData?.data?.parent_casts?.length > 0 && <div className='relative'>
                {specificCastData?.data?.parent_casts?.map((cast, idx) => (
                  <CastCards castData={cast} i={idx} fromNotificationReply={false} fromCastDetails={true} />
                ))}
                <div className='absolute h-full w-[1px] bg-white/10 left-10 top-5'></div>
              </div>}
              {specificCastData?.data?.cast.map((cast, idx) => (
                <CastCards castData={cast} i={idx} refetchCasts={castRefetch} fromNotificationReply={false} />
              ))}
              <div onClick={handleShowCastReplyModal} className='px-5 py-2 flex justify-between items-center cursor-pointer border-b dark:border-outline'>
                <div className='flex items-center gap-2'>
                  <img src={profileData?.data?.farcaster_user?.cdn_url || profileData?.data?.farcaster_user?.pfp_url || logo} onClick={navigateUserProfile} alt='avatar' className='size-10 rounded-full' />
                  <div className='prose-HeaderSmall text-txtOnLightBg dark:text-onBackgroundII'>Cast your reply</div>
                </div>
              </div>

              <div className='sticky top-[46px] md:top-[54px] bg-white dark:bg-background z-50 size-full mt-2'>
                <Tabs tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} fullWidthTab={true} type='top' />
              </div>

              <div className=''>
                <SwipeableViews style={{ height: 'auto' }} index={tabs.findIndex(tab => tab.name === selectedTab)} onChangeIndex={handleTabChange}>
                  <div>
                    {
                      selectedTab === CAST_DETAIL_TAB_CHOICES.REPLIES && <div className='flex flex-col gap-1'>
                        {specificCastData?.data?.child_casts?.length == 0 ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 mx-5">
                          <Empty size={20} className='fill-black dark:fill-[#ffffff66]' />
                          <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                        </div> : specificCastData?.data?.child_casts?.map((cast, index) =>
                          <React.Fragment key={index}>
                            <CastCards castData={cast} i={index} refetchCasts={castRefetch} isRepliesCast={true} fromNotificationReply={false} />
                          </React.Fragment>
                        )}
                      </div>
                    }
                  </div>
                  <div>
                    {
                      selectedTab === CAST_DETAIL_TAB_CHOICES.TIPS && <div className='overflow-y-auto removeScrollBar'>{
                        tipsData?.length == 0 ? (<div className="h-12  dark:order-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 mx-5">
                          <Empty size={20} className='fill-black dark:fill-[#ffffff66]' />
                          <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                        </div>) :
                          <><div className='px-5'>
                            {tipsData?.map((tip, i) => {
                              if (tipsData?.length === i + 1)
                                return <div key={i} ref={lastElementRef}>
                                  <TipTransactionCard data={tip} i={i} />
                                </div>

                              return <React.Fragment key={i}>
                                <TipTransactionCard data={tip} i={i} /></React.Fragment>
                            })}
                          </div>
                            {tipsData?.length <= 4 && <div className='min-h-80'></div>}
                          </>}
                        {moreLoading && (
                          <div className='absolute -bottom-12 md:bottom-0 w-full flex items-center justify-center'>
                            <span className='loading loading-dots bg-gray-400 dark:bg-white  loading-md'></span>
                          </div>
                        )}
                      </div>
                    }
                  </div>
                </SwipeableViews>
              </div>

              {/* <div className='mt-4 flex justify-end size-full'>
                  <div className='flex flex-col border-l border-outlineII relative h-full w-[90%]'>
                    {specificCastData?.data?.child_casts && specificCastData?.data?.child_casts.map((cast, idx) => (
                      <CastCards castData={cast} i={idx} isRepliesCast={true}/>
                    ))}
                  </div>
                </div> */}
            </div>
        }
      </div>
      {showCastReplyModal &&
        <CustomModal isOpen={showCastReplyModal} closeModal={handleCloseReplyCastModal} showSlideAnimation={false} modalPositionTop={'20'} translatePosition={'translate(-50%, 0%)'}>
          <CastModal closeModal={handleCloseReplyCastModal} castDetails={specificCastData?.data.cast[0]} refetch={castRefetch} fromReplyCastModal={true} />
        </CustomModal>
      }
    </>
  )
}

export default CastDetailedPage