import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileHoverCard from '../ProfileHoverCard';

import { CHAIN_LOGO, fallbackCircledLetterLogo, getDexScreenerLogo, getDexScreenerUrl, handleBlockExplorerNavigate } from '../../utils/chain';

const handleTimeChange = (timestamp) => {
    if (timestamp !== null) {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const differenceInSeconds = currentTimestamp - timestamp;

        let timeDifferenceString = '';
        if (differenceInSeconds < 60) {
            timeDifferenceString = <div className='prose-TitleMedium'>{differenceInSeconds}<span className='text-[12px] font-semibold ms-[2px]'>s</span></div>;
        } else if (differenceInSeconds < 3600) {
            const minutes = Math.floor(differenceInSeconds / 60);
            timeDifferenceString = <div className='prose-TitleMedium'>{minutes}<span className='text-[12px] font-semibold ms-[2px]'>m</span></div>;
        } else if (differenceInSeconds < 86400) {
            const hours = Math.floor(differenceInSeconds / 3600);
            timeDifferenceString = <div className='prose-TitleMedium'>{hours}<span className='text-[12px] font-semibold ms-[2px]'>h</span></div>;
        } else {
            const days = Math.floor(differenceInSeconds / 86400);
            timeDifferenceString = <div className='prose-TitleMedium'>{days}<span className='text-[12px] font-semibold ms-[2px]'>d</span></div>;
        }
        return timeDifferenceString;
    }
}


const chainRedirect = (chainID, TokenAddress) => {
    const url = getDexScreenerUrl(chainID, TokenAddress);
    window.open(url, '_blank', 'noopener,noreferrer');
}

const OnchainTable = ({ item }) => {

    const navigate = useNavigate()

    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleProfileNavigate = (e, username) => {
        e.stopPropagation()
        navigate(`/profile/${username}/onchain`)
    }

  return (
    <div
        className={`relative flex items-center w-full hover:bg-gray-200 dark:hover:bg-surface px-1 `}
        // className={`relative flex items-center w-full hover:bg-surface px-1 ${item.type === 'buy' ? 'bg-gradient-to-r from-white dark:from-black from-[25%] via-green-500/50 via-[60%] to-white dark:to-black to-[90%]' : 'bg-gradient-to-r from-white dark:from-black from-[30%] via-rose-600/50 via-[60%] to-white dark:to-black to-[90%]'}`}
    >
        <div onClick={(e) => handleBlockExplorerNavigate(e, item.TxnID, item.ChainID)} rel='noreferrer' target='_blank' className='grid grid-cols-12 w-full cursor-pointer'>
            <div className="col-span-1 lg:col-span-1 py-2 ps-2 transition-all duration-700 flex items-center text-subTxtOnLightBg dark:text-onBackground text-[8px] font-semibold">{handleTimeChange(item?.BlockTimestamp)}</div>
            
            <div className="col-span-3 lg:col-span-3 py-2 flex items-center gap-1 ps-3 lg:ps-0 group">
                <ProfileHoverCard username={item.Username}>
                    <img onClick={(e) => handleProfileNavigate(e, item.Username)} src={item?.Pfp} alt='token' className='size-5 lg:size-7 rounded-full border group-hover:border-2 group-hover:border-primary cursor-pointer'/>
                </ProfileHoverCard>
                <p onClick={(e) => handleProfileNavigate(e, item.Username)} rel='noreferrer' target='_blank' className='text-[10px] lg:prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackground/80 group-hover:text-black dark:group-hover:text-onBackground group-hover:scale-[1.01] cursor-pointer truncate'>
                    <ProfileHoverCard username={item.Username}>
                        @{item.Username}
                    </ProfileHoverCard>
                </p>
            </div>

            <div className='col-span-1 flex justify-center items-center'>
                <img src={CHAIN_LOGO[item.ChainID]} alt='eth' className='size-4 rounded-full' />
            </div>

            <div className="col-span-1 py-2 flex justify-start md:justify-start items-center gap-1">
                <span className={`${item.Type === 'buy' ? 'text-green-700' : 'text-red-700'} md:mr-1`}>
                    {item.Type === 'buy' 
                        ? <div className='h-6 w-6 rounded-full bg-green-600/15 text-[11px] lg:prose-TitleMedium flex items-center justify-center'>BUY</div> 
                        : <div className='h-6 w-6 rounded-full bg-red-600/15 text-[11px] lg:prose-TitleMedium flex items-center justify-center'>SELL</div>}
                </span>
            </div>

            <div className="col-span-2 py-2 flex justify-start md:justify-start items-center gap-1 ">
                <p className='text-[11px] lg:prose-BodyMediumBold font-extrabold text-subTxtOnLightBg dark:text-onBackground'>{item?.Type === 'buy' ? item?.TokenInDisplayValue : item?.TokenOutDisplayValue}</p>
            </div>

            <div onClick={() => chainRedirect(item?.ChainID, item.Type === 'buy' ? item?.TokenInAddress : item?.TokenOutAddress)} className="col-span-2 py-2 flex justify-start md:justify-start items-center gap-1 ">
                <div className={`flex justify-between items-center  rounded-full ps-1 pe-[2px] md:ps-2 py-[2px] gap-2`}>
                    <img src={item.Type === 'buy' 
                        ? item?.TokenInLogo || getDexScreenerLogo(item?.ChainID, item?.TokenInAddress)
                        : item?.TokenOutLogo || getDexScreenerLogo(item?.ChainID, item?.TokenOutAddress)}
                        onError={(e) => {
                            e.target.src = fallbackCircledLetterLogo(item.Type === 'buy' ? item?.TokenInSymbol : item?.TokenOutSymbol);
                        }}
                        alt='token' className='size-4 lg:size-5 rounded-full flex justify-end' />
                    <span className='text-[8px] font-semibold text-black/90 dark:text-onBackground'>{item.Type === 'buy' ? item?.TokenInSymbol : item?.TokenOutSymbol}</span>
                </div>
            </div>

            <div className={`col-span-2 text-end lg:text-end py-2 text-[9px]  lg:prose-TitleMedium ${item?.UsdValue >= 1000 ? 'text-yellow-500' : 'text-subTxtOnLightBg dark:text-onBackground'}`}>${item?.UsdDisplayValue}</div>
        </div>
    </div>
  )
}

export default OnchainTable