import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Chart } from '../../Chart';

const SharesChart = ({usernameFromClick}) => {

    const pathname = useLocation()
    const username = pathname.pathname.split('/')[2]

    const [selectusername, setSelectUsername] = useState(username)

    useEffect(() => {
        setSelectUsername(usernameFromClick || username)
    }, [pathname])

    
    return (
        <div className='mx-5 mt-4 lg:mt-0 lg:p-5 flex'>
            {/* <div className='w-[30%]'>
                <div>
                    <p className='prose-TitleMedium text-onBackgroundII uppercase'>Your Share</p>
                    <div>
                        <span className='prose-DisplayMedium text-primary'>{shareIntegerPart}</span>
                        <span className='prose-HeaderMedium text-primary'>.{shareDecimalPart || 0}</span>
                    </div>
                </div>
                <div className='mt-8'>
                    <p className='prose-TitleMedium text-onBackgroundII'>Tickets owned</p>
                    <p className='prose-DisplayMedium text-onBackground'>{profileData?.data?.num_tickets_owned_by_me / 1000}</p>
                </div>
            </div> */}

            {/* <div className='h-[250px] mx-8 w-1 border-r border-outline'/> */}
           
            <div className='w-full'>
                <div className='prose-TitleMedium text-subTxtOnLightBg dark:text-onBackgroundII'>Market Cap</div>
                <div className='w-full min-h-[40px]'>
                    <Chart username={usernameFromClick || selectusername} chartHeight={130}/> 
                </div>
            </div>
        </div>
    )
}

export default SharesChart