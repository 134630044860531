import { Empty } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import TipTransactionCard from '../../../components/Cards/Quest/TipTransactionCard';
import TipTransactionSkeletonLoading from '../../../components/Cards/Quest/TipTransactionSkeletonLoading';
import { getProfileTips } from '../../../services/api';
import { getSelectedCurrencyLocalStorage } from '../../../utils/constants';

const TipsTab = () => {
    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency;

    const { username } = useParams()

    const observer = useRef();
    const [tipsData, setTipsData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [moreLoading, setMoreLoading] = useState(false);
    const [page, setPage] = useState(2);

    const { data: profileTips, isLoading: profileTipsLoading, refetch: refetchTips } = useQuery({
        queryKey: ['currentUserTips', username, 1, selectedCurrency],
        queryFn: () => getProfileTips(username, 1, selectedCurrency),
    });

    useEffect(() => {
        setTipsData(profileTips?.data?.tips)
        setPage(2)
        setHasMore(true)
    }, [profileTips, selectedCurrency])

    const fetchMoreData = async () => {
        setMoreLoading(true);
        try {
            let response = await getProfileTips(username, page, selectedCurrency);
            if (response.status === 200) {
                const data = response?.data?.tips;
                setTipsData((prevData) => [...prevData, ...data]);
                setPage(prev => prev + 1)
                if (!data.length) setHasMore(false);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setHasMore(false);
        } finally {
            setMoreLoading(false);
        }
    };

    const lastElementRef = useCallback((node) => {
        if (profileTipsLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                fetchMoreData();
            }
        });
        if (node) observer.current.observe(node);
    }, [profileTipsLoading, hasMore, username, page, selectedCurrency]);

    return (
        <div className=''>
            {profileTipsLoading ? <>
                <TipTransactionSkeletonLoading />
                <TipTransactionSkeletonLoading />
                <TipTransactionSkeletonLoading />
            </>
                : !tipsData?.length ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-mx-5">
                    <Empty size={20} color="#FFFFFF66" />
                    <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                </div>
                    : tipsData?.map((data, idx) => {
                        if (tipsData?.length === idx + 1)
                            return (
                                <div ref={lastElementRef}>
                                    <TipTransactionCard data={data} i={idx} />
                                </div>
                            )

                        return (
                            <TipTransactionCard data={data} i={idx} />
                        )
                    })
            }
            {moreLoading && (
                <div className='absolute -bottom-1 md:bottom-0 w-full flex items-center justify-center'>
                    <span className='loading loading-dots bg-gray-400 dark:bg-white  loading-md'></span>
                </div>
            )}
        </div>
    )
}

export default TipsTab;