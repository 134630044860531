const ProfileDetailSkeleton = () => {
    return (
        <div>
            <div className='flex flex-col w-full lg:flex-row flex-wrap  justify-between items-center'>
                <div className="min-h-[120px] w-full flex items-start gap-[18px] overflow-hidden order-1">
                    <div className="min-w-[120px] max-w-[120px] aspect-square animate-pulse bg-surface/50 dark:bg-surface">
                    </div>
                    <div className='flex-1 h-full flex flex-col justify-between items-start gap-5 w-full'>
                        <h2 className="animate-pulse bg-surface/50 dark:bg-surface h-6 w-40">
                        </h2>
                        <div className='w-full'>
                            <p className='w-24 animate-pulse bg-surface/50 dark:bg-surface h-[17px] mb-2 '>
                            </p>
                            <div className='flex items-center min-h-[21px] gap-x-4 w-full flex-wrap'>
                                <p className='text-nowrap h-[14px] animate-pulse bg-surface/50 dark:bg-surface w-16' />
                                <p className='text-nowrap h-[14px] animate-pulse bg-surface/50 dark:bg-surface w-16' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-2 h-[14px] animate-pulse bg-surface/50 dark:bg-surface  items-start w-full order-2 lg:order-3' />
            <div className='mt-2 h-[14px] animate-pulse bg-surface/50 dark:bg-surface  items-start w-full order-2 lg:order-3' />
            <div className='flex justify-center gap-3 mt-4'>
                <button className='animate-pulse bg-surface/50 dark:bg-surface w-[140px] h-[42px]'></button>
                <button className='animate-pulse bg-surface/50 dark:bg-surface w-[140px] h-[42px]'></button>
            </div>

        </div>
    )
}

export default ProfileDetailSkeleton;


