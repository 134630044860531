import { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { debouncefn } from "../utils/helper";
import Spinner from "./UI/Spinner";
import { MagnifyingGlass, Empty } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { getSearchRecent, getUserAndChannelData } from "../services/api";
import powerBadge from '../assets/svg/powerbadge.svg'
import logo from '../assets/images/logo.png'
import { isDesktopDevice } from "../utils/helper";

const SearchBar = ({ searchTerm, setSearchTerm }) => {
    const isDesktop = isDesktopDevice();
    const [loading, setLoading] = useState(false);
    const [showAllUsers, setShowAllUsers] = useState(false);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    const { data: recentResult = [], isLoading: recentLoading } = useQuery({
        queryKey: ['recentResult'],
        queryFn: () => getSearchRecent(),
        enabled: !searchTerm && !loading && !isDesktop,
    });

    const { data: searchResults = {}, isLoading: searchLoading } = useQuery({
        queryKey: ['searchResult', searchTerm],
        queryFn: () => getUserAndChannelData(searchTerm, true, true),
        enabled: !!searchTerm,
    });

    useEffect(() => {
        setLoading(searchLoading);
    }, [searchLoading]);

    useEffect(() => {
        if (!searchTerm)
            setShowAllUsers(false)
    }, [searchTerm]);

    const delayedSearch = debouncefn((term) => {
        setSearchTerm(term);
    });

    const handleInput = (e) => {
        const term = e.target.value;
        delayedSearch(term);
    };

    const handleNavigate = (username) => {
        navigate(`/profile/${username}`);
        setSearchTerm('');
        setShowAllUsers(false);
    }

    const handleChannelNavigate = (channelId) => {
        navigate(`/channel/${channelId}`);
        setSearchTerm('');
        setShowAllUsers(false);
    }

    return (
        <div className={`bg-white dark:bg-[#121212] w-full py-5 relative`}>
            <div className=" gap-4 flex justify-between items-center mx-5">
                <div className="flex w-full bg-gray-300/40 dark:bg-background outline outline-1 outline-outlineIII items-center px-4 py-[8px] gap-[10px] rounded-full">
                    <MagnifyingGlass size={28} className="fill-black dark:fill-[#ffffffa6]"/>
                    <input maxLength={20} className="w-full bg-transparent prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground focus:outline-none placeholder:text-subTxtOnLightBg dark:placeholder:text-onBackgroundII" type="text" name="searchTerm" id="searchTerm" placeholder="Search users, channels" onInput={handleInput} ref={inputRef} />
                </div>
                <label htmlFor="rightSidebar" className="drawer-button md:hidden">
                    <div onClick={() => { setSearchTerm(''); inputRef.current.value = ''; setShowAllUsers(false) }} className="prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundII cursor-pointer block md:hidden">Cancel</div>
                </label>
            </div>
            <div className="overflow-y-auto removeScrollBar top-20 absolute z-10 w-full bg-white dark:bg-background left-0 px-5 md:max-h-[540px]">
                <div className="pb-40 md:pb-0">
                    {
                        loading && <div className="min-h-20"><Spinner /></div>
                    }
                    {
                        searchTerm && <>
                            <div className="flex flex-col gap-0.5 items-baseline mt-4 w-full">
                                {
                                    searchResults?.users?.length <= 0 && searchResults?.channels?.length <= 0 &&
                                    <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-full">
                                        <Empty size={20} className="fill-subTxtOnLightBg dark:fill-[#ffffff66]"/>
                                        <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                                    </div>
                                }
                                {
                                    searchResults?.users?.length > 0 && <div className="w-full">
                                        <div className="flex items-baseline mt-4">
                                            <p className="uppercase text-subTxtOnLightBg dark:text-onBackgroundII prose-TitleMedium ">{searchResults?.users?.length} Users  \\</p>
                                            <div className="w-full border-b dark:border-outlineII flex-1 ms-2" />
                                        </div>
                                        {searchResults?.users?.slice(0, showAllUsers ? searchResults?.users?.length : 5).map((user, index) => (
                                            <div key={index} className="cursor-pointer flex items-center gap-[16px] py-[14px] rounded-md md:px-2 r w-full " onClick={() => { handleNavigate(user?.farcaster_user?.username) }}>
                                                <div className="flex items-center gap-[6px]">
                                                    <img src={user?.farcaster_user?.cdn_url || user?.farcaster_user?.pfp_url || logo} alt="profile" className="size-9 rounded-full border-[1.5px] border-primary" />
                                                    <div className="text-txtOnLightBg dark:text-onBackground">
                                                    <p className="prose-HeaderMedium">{user?.farcaster_user?.display_name}</p>
                                                    <p className="text-txtOnLightBg dark:text-onBackgroundII prose-HeaderSmall flex items-center flex-wrap gap-2">{user?.farcaster_user?.username}
                                                    {user?.farcaster_user?.power_badge && <img src={powerBadge} alt="power" className="size-4" />}
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {searchResults?.users?.length > 5 && (
                                            <button className="text-subTxtOnLightBg dark:text-onBackgroundII" onClick={() => setShowAllUsers(!showAllUsers)}>
                                                {showAllUsers ? '...Show Less' : 'Show More...'}
                                            </button>
                                        )}
                                    </div>
                                }
                                {
                                    searchResults?.channels?.length > 0 && <div className="w-full">
                                        <div className="flex items-baseline mt-4">
                                            <p className="uppercase text-subTxtOnLightBg dark:text-onBackgroundII prose-TitleMedium ">{searchResults?.channels?.length} Channels  \\</p>
                                            <div className="w-full border-b dark:border-outlineII flex-1 ms-2" />
                                        </div>
                                        <div className="flex items-start gap-4 overflow-x-scroll custom-scrollbar">
                                            {searchResults?.channels?.map((channel, index) => (
                                                <div key={index} className="cursor-pointer flex items-center gap-[16px] py-[14px]" onClick={() => { handleChannelNavigate(channel?.id) }}>
                                                    <div className="flex flex-col items-center gap-[6px]">
                                                        <img src={channel?.image_url || channel?.farcaster_user?.pfp_url || logo} alt="profile" className="aspect-square min-w-12 max-w-12 rounded-full border-[1.5px] border-primary" />
                                                        <p className="text-txtOnLightBg dark:text-onBackground prose-BottomSmall text-nowrap">{channel?.name.slice(0, 10)}{channel?.name?.length > 10 ? "..." : ''}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                    {
                        !searchTerm && <div className="md:hidden">
                            <div className="flex items-baseline py-4 sticky top-0 z-10 bg-white dark:bg-background">
                                <p className="uppercase text-subTxtOnLightBg dark:text-onBackgroundII prose-TitleMedium ">Recently viewed users</p>
                            </div>
                            {recentResult && recentResult?.length > 0 && <div className="flex flex-col">
                                {
                                    recentResult?.map((data, index) => (
                                        <div key={index} className="cursor-pointer flex items-center gap-[16px] py-[14px]" onClick={() => { navigate(`/profile/${data?.target?.username}`) }}>
                                            <div className="flex items-center gap-[6px]">
                                                <img src={data?.target?.cdn_url || data?.target?.pfp_url} alt="profile" className="size-9 rounded-full border-[1.5px] border-primary" />
                                                <p className="text-txtOnLightBg dark:text-onBackground prose-HeaderSmall">{data?.target?.username}</p>
                                                {data?.target?.power_badge && <img src={powerBadge} alt="power" className="size-4" />}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SearchBar;