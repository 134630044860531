import { ChatCircleDots, Coins, Heart, LinkSimple, Repeat, X } from '@phosphor-icons/react';
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import powerbadge from "../../../assets/svg/powerbadge.svg";
import useCastTipping from "../../../hooks/useCastTipping";
import { removeTinderCasts } from "../../../services/api";
import { highlightWord, timeSince } from "../../../utils/helper";
import CustomModal from '../../UI/CustomModal';
import EmbeddedCast from '../Casts/EmbeddedCast';
import useSubmitCast from '../../Modals/useSubmitCast';
import useCastAction from '../../../hooks/signer';
import degen from "../../../assets/images/degen.png";
import dudegen from '../../../assets/images/dudegen.png';
import tree from "../../../assets/images/tree.png";
import ham from "../../../assets/images/ham.png";
import CastModal from '../../Modals/CastModal';
import ReactPlayer from 'react-player';

const getCurrencyLogo = (token) => {
    switch (token) {
        case 'DEGEN': return degen;
        case 'TREE': return tree;
        case 'DUDEGEN': return dudegen;
        case 'HAM': return ham;
    }
}

const SwipeCard = ({ data, tipValue, setTokenList, setBackgroundText, setBackgroundPosition, setTipLoadingQueue, selectedCurrency, isInternal }) => {

    const { likeCast, unLikeCast, recastCast, unRecastCast } = useCastAction()
    const videoParentRef = useRef(null);

    const { cast, channel, farcaster_user, tips_24h_all, replies } = data;
    const tipsData = tips_24h_all?.filter(token => token?.currency === selectedCurrency)[0];

    const [metadata, setMetadata] = useState(null);
    const cardRef = useRef(null);

    const [isModalOpen, setIsModalOpen] = useState(false)

    const [currLikes, setCurrLikes] = useState(data?.likes);
    const [currRecasts, setCurrRecasts] = useState(data?.recasts);
    const [hasLiked, setHasLiked] = useState(data?.user_reaction?.has_liked);
    const [hasRecasted, setHasRecasted] = useState(data?.user_reaction?.has_recasted);
    const [showReplyCastModal, setShowReplyCastModal] = useState(false);

    const [startX, setStartX] = useState(0);
    const navigate = useNavigate();

    const { isTipLoading, handleTip, isTipFailed, amount: tipAmount, selectedToken: tipSelectedToken } = useCastTipping(data, selectedCurrency, () => { }, () => { }, () => { }, () => { });
    const { isSubmittingCast, handleSubmitCast, isCastFailed, tipAmount: castAmount, selectedToken: castSelectedToken } = useSubmitCast();


    const handleLikeReaction = (e) => {
        e.stopPropagation();
        if (hasLiked) {
            setCurrLikes(prev => prev - 1)
            unLikeCast(cast?.id, farcaster_user?.fid)
        } else {
            setCurrLikes(prev => prev + 1)
            likeCast(cast?.id, farcaster_user?.fid)
        }
        setHasLiked(prev => !prev)
    }

    const handleReCast = (e, hash, fid) => {
        e.preventDefault();
        e.stopPropagation();
        if (hasRecasted) {
            setCurrRecasts(prev => prev - 1)
            recastCast(hash, fid)
        } else {
            setCurrRecasts(prev => prev + 1)
            unRecastCast(hash, fid);
        }
        setHasRecasted(prev => !prev)
    }

    const handleOpenReplyCastModal = (e) => {
        e.stopPropagation();
        setShowReplyCastModal(true)
    }
    const handleCloseReplyCastModal = () => {
        setShowReplyCastModal(false)
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const handleStart = (e) => {
        if (!(tipValue > 0)) return;
        const startX = e.touches ? e.touches[0].clientX : e.clientX;
        setStartX(startX);
    };

    const handleMove = (e) => {
        if (!(tipValue > 0)) return;
        const moveX = (e.touches ? e.touches[0].clientX : e.clientX) - startX;

        if (moveX > 0) {
            setBackgroundText(`Tip`);
            setBackgroundPosition('right');
        } else {
            setBackgroundText('Skip');
            setBackgroundPosition('left');
        }

        cardRef.current.style.transform = `translateX(${moveX}px) rotate(${moveX * 0.05}deg)`;
        cardRef.current.style.opacity = `${1 - Math.abs(moveX / window.innerWidth)}`;
    };

    const handleEnd = (e) => {
        if (!(tipValue > 0)) return;
        const moveX = (e.changedTouches ? e.changedTouches[0].clientX : e.clientX) - startX;

        if (moveX == 0) {
            setIsModalOpen(true)
        }
        if (moveX > 150) {
            onRightSwipe();
        } else if (moveX < -150) {
            onLeftSwipe();
        } else {
            resetCardPosition();
        }
    };

    const onRightSwipe = () => {
        setBackgroundText('')

        if (tipValue <= 0) {
            resetCardPosition();
            return;
        }

        if (isInternal)
            handleTip(tipValue);
        else {
            if (selectedCurrency === 'HAM') {
                handleSubmitCast(`🍖x${tipValue}`, undefined, undefined, data?.cast?.id, data?.farcaster_user?.fid, undefined, tipValue, selectedCurrency)
            }
            else {
                handleSubmitCast(`${tipValue} $${selectedCurrency}`, undefined, undefined, data?.cast?.id, data?.farcaster_user?.fid, undefined, tipValue, selectedCurrency)
            }
        }


        removeTinderCasts(data?.cast?.id, data?.farcaster_user?.fid)

        cardRef.current.style.transform = `translateX(${window.innerWidth}px)`;
        cardRef.current.style.transition = `all 1s`;
        removeChildDiv();
    };

    useEffect(() => {
        if (isTipFailed) {
            setTokenList(prev => {
                const newData = {}
                newData[`${tipSelectedToken}`] = prev[`${tipSelectedToken}`]
                newData[`${tipSelectedToken}`].allowance_remaining += tipAmount
                return (
                    {
                        ...prev, ...newData
                    }
                )
            })
        }
        if (isCastFailed) {
            setTokenList(prev => {
                const newData = {}
                newData[`${castSelectedToken}`] = prev[`${castSelectedToken}`]
                newData[`${castSelectedToken}`].allowance_remaining += castAmount
                return (
                    {
                        ...prev, ...newData
                    }
                )
            })
        }
        if (isTipLoading || isSubmittingCast) {
            setTipLoadingQueue(prev => [...prev, 'loading'])
            setTokenList(prev => {
                const newData = {}
                newData[`${selectedCurrency}`] = prev[`${selectedCurrency}`]
                newData[`${selectedCurrency}`].allowance_remaining -= tipValue
                return (
                    {
                        ...prev, ...newData
                    }
                )
            })
        }

        else
            setTipLoadingQueue(prev => prev.slice(0, prev.length - 1))
    }, [isTipLoading, isTipFailed, isSubmittingCast, isCastFailed])

    const onLeftSwipe = () => {
        setBackgroundText('')
        removeTinderCasts(data?.cast?.id, data?.farcaster_user?.fid)
        cardRef.current.style.transform = `translateX(-${window.innerWidth}px)`;
        cardRef.current.style.transition = `all 1s`;
        removeChildDiv();
    };

    const removeChildDiv = () => {
        if (videoParentRef.current) {
            const childDiv = videoParentRef.current.querySelector('.swipecard-video-div');
            if (childDiv) {
                setTimeout(() => {
                    videoParentRef.current.removeChild(childDiv);
                }, 1000);
            }
        }
    };

    const resetCardPosition = () => {
        cardRef.current.style.transform = 'translateX(0px) rotate(0deg)';
        cardRef.current.style.opacity = '1';
        setBackgroundText('');
    };

    const previewLinkInPost = (body) => {
        const regex = /https?:\/\/[^\s]+/g;
        const urls = body?.match(regex);
        if (urls) {
            return urls[0];
        }
    };

    const url = previewLinkInPost(cast?.body);

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                if (!url) return;
                const response = await axios.get(url);
                const html = await response.text();
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');

                const titleElement = doc.querySelector('title');
                const descriptionElement = doc.querySelector('meta[name="description"]');
                const imageElement = doc.querySelector('meta[property="og:image"]');

                const metadata = {
                    title: titleElement ? titleElement.textContent : '',
                    description: descriptionElement ? descriptionElement.getAttribute('content') : '',
                    image: imageElement ? imageElement.getAttribute('content') : '',
                };
                setMetadata(metadata);
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        };

        fetchMetadata();
    }, [url]);

    const isImage = (url) => {
        return url?.startsWith('https://supercast.com/') || url?.startsWith('https://imagedelivery.net/') || url?.startsWith('https://static-wildcard-prod.nyc3.cdn.digitaloceanspaces.com/') || url?.startsWith('https://media.tenor.com/') || url?.startsWith('http://res.cloudinary.com/') || url?.startsWith('https://res.cloudinary.com/') || url?.startsWith('https://i.imgur.com/')
    };

    const isNestedCast = (obj) => {
        if ('cast_id' in obj && obj?.cast_id != null && obj?.cast_details != null) {
            return true;
        }
        return false;
    };

    const hasImage = cast?.urls?.length > 0;
    const hasNestedCast = cast?.embeds?.some(embed => isNestedCast(embed))
    const hasMedia = cast?.urls?.some(url => {
        const data = cast?.url_metadatas[url] || null;
        if (url.startsWith('https://stream.warpcast.com/v1/video/')) return true;
        if (isImage(url)) return true;
        if (data == null) return false;
        if (data?.content_type === 'text/html') return true;
        if (data?.media_type === 'img') return true;
    });

    const castEmbeds = cast?.embeds?.filter(embed => isImage(embed.url)) || [];
    const nestedCastEmbeds = cast?.embeds?.filter(embed => isNestedCast(embed)) || [];
    return (
        <>
            <div
                ref={cardRef}
                className="bg-white dark:bg-[#151515] cursor-default h-full max-h-[420px] flex flex-col justify-between absolute transition-transform duration-0 w-full border border-outline p-4 pt-6 overflow-hidden text-black dark:text-onBackground"
                onTouchStart={(e) => { e.stopPropagation(); handleStart(e); setIsModalOpen(false) }}
                onTouchMove={(e) => { e.stopPropagation(); handleMove(e); }}
                onTouchEnd={(e) => { e.preventDefault(); e.stopPropagation(); handleEnd(e) }}
                onClick={() => setIsModalOpen(true)}
            >
                <div className={`flex h-8 items-center justify-between mb-4`}>
                    <div className="flex items-center gap-2">
                        <div className="border border-primary rounded-full">
                            <img src={farcaster_user?.pfp_url || farcaster_user?.cdn_url || logo} alt="profile" className="w-8 h-8 rounded-full" />
                        </div>
                        <div className="prose-HeaderMedium mr-0">
                            {farcaster_user?.username}
                        </div>
                        {farcaster_user?.power_badge && (
                            <span>
                                <img src={powerbadge} alt="" className="w-4 h-4" />
                            </span>
                        )}
                    </div>
                    <div>

                        <span className="text-black dark:text-onBackgroundIII prose-LabelSmall lowercase ms-1">{timeSince(cast?.timestamp)}</span>
                    </div>
                </div>
                <div className={`grow overflow-hidden ${(hasMedia || hasNestedCast) ? '' : 'pb-3'} flex flex-col`}>
                    <p className={`prose-BodyLarge truncate ${(hasMedia || hasNestedCast) ? 'max-h-12 min-h-12' : 'text-wrap'}`}>
                        {highlightWord(cast?.body, true, navigate)}
                    </p>
                    <div ref={videoParentRef} className={`${(hasMedia || hasNestedCast) ? 'grow' : ''} overflow-hidden flex flex-col max-h-fit`}>
                        <div className='w-full flex grow overflow-hidden'>
                            {
                                cast?.urls?.map((url, index) => {
                                    const data = cast?.url_metadatas[url] || null;
                                    if (data == null) return;
                                    if (data?.content_type === 'text/html') {
                                        return (
                                            <div key={url} className='border dark:border-outlineII bg-white dark:bg-background p-2 rounded-md relative' onClick={(e) => e.stopPropagation()}>
                                                <a href={url} target="_blank" rel="noopener noreferrer" className='absolute inset-0 size-full'></a>
                                                {data?.image && <img src={data?.image} alt={data?.title} className='size-full dark:bg-white/10 bg-background/10 rounded-sm object-contain' />}
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                        <div className='w-full flex items-center gap-2 grow overflow-hidden'>
                            {
                                cast?.urls?.map((url, index) => {
                                    const metaData = cast?.url_metadatas ? cast?.url_metadatas[url] : null;
                                    if (isImage(url)) {
                                        return (
                                            <div key={index} className='relative w-full h-full overflow-hidden'>
                                                <img src={url} alt="embed" className='w-full h-full object-contain rounded-lg cursor-pointer' />
                                            </div>
                                        )
                                    }
                                    if (metaData == null) return;
                                    if (metaData?.media_type === 'img' || isImage(url)) {

                                        return (
                                            <div key={index} className='relative w-full h-full overflow-hidden'>
                                                <img src={url} alt="embed" className='w-full h-full object-contain rounded-lg cursor-pointer' />
                                            </div>
                                        )
                                    }

                                })
                            }
                        </div>
                        <div className='w-full flex items-center gap-2 grow overflow-hidden swipecard-video-div'>
                            {
                                cast?.urls?.map((url, index) => {
                                    if (url.startsWith('https://stream.warpcast.com/v1/video/')) {

                                        return <div
                                            onClick={(e) => e.stopPropagation()}
                                            onTouchStart={(e) => e.stopPropagation()}
                                            onTouchMove={(e) => e.stopPropagation()}
                                            onTouchEnd={(e) => e.stopPropagation()}
                                            className='relative w-full h-full overflow-hidden'>
                                            <ReactPlayer key={index} url={url} controls={true} width='100%' height='100%' />
                                        </div>
                                    }

                                })
                            }
                        </div>
                        <div className={`w-full flex flex-col items-start gap-2 grow overflow-hidden ${hasNestedCast ? 'h-full' : ''}`}>
                            {
                                nestedCastEmbeds?.map((embeds) => {
                                    const { farcaster_user, cast_id, cast_details } = embeds;
                                    return (
                                        <div className={`flex flex-col w-full gap-2 items-start border border-outline rounded-md p-3 h-full overflow-hidden max-h-[104px]`}>
                                            <div className='flex items-center gap-3 w-full'>
                                                <div className="border border-secondary dark:border-primary size-8 min-w-8 rounded-full">
                                                    <img src={farcaster_user?.pfp_url || farcaster_user?.cdn_url || logo} alt="profile" className="size-8 min-w-8 rounded-full overflow-hidden" />
                                                </div>
                                                <div className="w-full prose-BodyLargeBold truncate text-nowrap capitalize mr-0 text-txtOnLightBg dark:text-onBackground flex items-center justify-between gap-1"><div className='flex items-center gap-1'>
                                                    {farcaster_user?.display_name}{
                                                        farcaster_user?.power_badge &&
                                                        <span>
                                                            <img src={powerbadge} alt=""
                                                                className="w-4 h-4" />
                                                        </span>
                                                    }
                                                </div>
                                                    <div>
                                                        <span className="text-subTxtOnLightBg dark:text-onBackgroundIII prose-BodyMedium lowercase ms-1">{timeSince(cast_details?.data?.timestamp)}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='overflow-hidden h-full w-full'>


                                                <p className='prose-BodySmall text-black dark:text-onBackgroundII'>{cast_details?.data?.castAddBody?.text}</p>
                                            </div>
                                        </div>

                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
                <div className="bg-inherit max-h-20">
                    <div className='flex items-center gap-4 text-onBackgroundII mt-2'>
                        <div
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleOpenReplyCastModal(e) }}
                            onTouchStart={(e) => { e.stopPropagation(); }}
                            onTouchMove={(e) => { e.stopPropagation(); }}
                            onTouchEnd={(e) => { e.preventDefault(); e.stopPropagation(); handleOpenReplyCastModal(e) }}
                            className='flex items-center group text-black dark:text-onBackgroundII'>
                            <span className='  rounded-full p-[7px] transition duration-[450ms]'>
                                <ChatCircleDots size={20} className='group-hover:fill-primary' />
                            </span>
                            <span className='prose-LabelSmall '>{replies}</span>
                        </div>
                        <div
                            onTouchStart={(e) => { e.stopPropagation(); }}
                            onTouchMove={(e) => { e.stopPropagation(); }}
                            onTouchEnd={(e) => { e.preventDefault(); e.stopPropagation(); handleReCast(e, cast?.id, farcaster_user?.fid) }}
                            onClick={(e) => { handleReCast(e, cast?.id, farcaster_user?.fid) }}
                            className='flex items-center group cursor-pointer dark:text-onBackgroundII'
                        >
                            <span className='  rounded-full p-[7px] transition duration-[450ms]'>
                                <Repeat size={20} className={`group-hover:fill-primary ${!hasRecasted ? 'text-black dark:text-onBackgroundII' : 'text-primary'}`} />
                            </span>
                            <span className='prose-LabelSmall'>{currRecasts}</span>
                        </div>
                        <div
                            onTouchStart={(e) => { e.stopPropagation(); }}
                            onTouchMove={(e) => { e.stopPropagation(); }}
                            onTouchEnd={(e) => { e.preventDefault(); e.stopPropagation(); handleLikeReaction(e) }}
                            onClick={(e) => { handleLikeReaction(e) }}
                            className='flex items-center group cursor-pointer dark:text-onBackgroundII'
                        >
                            <span className='  rounded-full p-[7px] transition duration-[450ms]'>
                                <Heart
                                    size={20}
                                    weight={!hasLiked ? "bold" : "fill"}
                                    className={`group-hover:fill-primary ${!hasLiked ? 'text-black dark:text-onBackgroundII' : 'text-primary'}`}
                                />
                            </span>
                            <span className='prose-LabelSmall'>{currLikes}</span>
                        </div>
                        <div className='flex items-center group text-black dark:text-onBackgroundII'>
                            <span className='  rounded-full p-[7px] transition duration-[450ms]'>
                                {selectedCurrency === 'WILD' ? <Coins size={20} className='group-hover:fill-primary' /> :
                                    <img src={getCurrencyLogo(selectedCurrency)} alt="logo" className='max-w-5 h-5 aspect-square rounded-full' />}
                            </span>
                            <span className='prose-LabelSmall'>{tipsData?.display_value === "0" ? 0 : tipsData?.display_value}</span>
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal isOpen={showReplyCastModal} closeModal={handleCloseReplyCastModal} showSlideAnimation={false} modalPositionTop={'20'} translatePosition={'translate(-50%, -50%)'}>
                <CastModal closeModal={handleCloseReplyCastModal} castDetails={data} fromReplyCastModal={true} />
            </CustomModal>
            {isModalOpen && <CustomModal isOpen={isModalOpen} closeModal={closeModal} modalPositionTop={25} blurValue={10}>
                <X size={24} className='absolute -top-4 right-5' role='button' onClick={closeModal} />
                <div
                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                    onTouchStart={(e) => { e.stopPropagation(); }}
                    onTouchMove={(e) => { e.stopPropagation(); }}
                    onTouchEnd={(e) => { e.preventDefault(); e.stopPropagation(); }}
                    className='overflow-y-scroll max-h-[80vh]'>
                    <div className={`flex gap-2 pt-4 border-outline px-5 w-full pb-2 cursor-pointer`}>
                        <div className='min-h-12 min-w-12 h-fit cursor-pointer'>
                            <img src={farcaster_user?.pfp_url || farcaster_user?.cdn_url || logo} alt="cast" className={`size-12 object-cover rounded-full bg-background`} />
                        </div>
                        <div className='w-full cursor-pointer'>
                            <div className='w-full flex items-center justify-between'>
                                <div className='flex items-center flex-wrap gap-1 prose-BodyLargeBold text-onBackground'>
                                    {farcaster_user?.display_name}
                                    {
                                        farcaster_user?.power_badge &&
                                        <span>
                                            <img src={powerbadge} alt="powerbadge"
                                                className="w-4 h-4" />
                                        </span>
                                    }
                                    <span className='prose-BodyMedium'>@{farcaster_user?.username}</span>
                                    <span className='size-1 bg-white rounded-full' />
                                    <span className='prose-BodyMedium text-onBackgroundII'>{timeSince(cast?.timestamp)}</span>
                                </div>

                            </div>
                            <div>
                                <div className={"prose-BodyLarge text-onBackground flex-wrap text-wrap md:w-full mt-3"}>
                                    {highlightWord(cast?.body, true, () => { })}
                                </div>
                            </div>
                            <div className='mt-2 w-full'>
                                <div className='w-full'>
                                    {
                                        cast?.urls?.map((url, index) => {
                                            const data = cast?.url_metadatas[url] || null;
                                            if (data == null) return;
                                            if (data?.is_frame) return;
                                            if (data?.content_type === 'text/html')
                                                return (
                                                    <div key={url} className='border dark:border-outlineII bg-white dark:bg-background p-5 rounded-md relative' onClick={(e) => e.stopPropagation()}>
                                                        <a href={url} target="_blank" rel="noopener noreferrer" className='absolute inset-0 size-full'></a>
                                                        <div className='flex gap-3 items-start'>
                                                            {data?.image && <img src={data?.image} alt={data?.title} className='size-20 dark:bg-white/10 bg-background/10 rounded-sm object-contain' />}
                                                            {!data?.image && <LinkSimple size={24} className='fill-black dark:fill-white' />}
                                                            <div className='flex flex-col gap-3'>
                                                                {data?.title && <h3 className='prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground'>{data?.title}</h3>}
                                                                <p className='prose-BodySmall text-txtOnLightBg dark:text-white'>{data?.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                        })
                                    }
                                </div>
                                <div className='w-full flex items-center gap-2'>
                                    {
                                        cast?.urls?.map((url, index) => {
                                            const metaData = cast?.url_metadatas ? cast?.url_metadatas[url] : null;
                                            if (isImage(url)) return (
                                                <div key={index} className='relative w-full h-[200px] md:h-[300px]'>
                                                    <img src={url} alt="embed" className='w-full h-full object-contain rounded-lg cursor-pointer' />
                                                </div>
                                            )
                                            if (metaData == null) return;
                                            if (metaData?.media_type === 'img' || isImage(url))
                                                return (
                                                    <div key={index} className='relative w-full h-[200px] md:h-[300px]'>
                                                        <img src={url} alt="embed" className='w-full h-full object-contain rounded-lg cursor-pointer' />
                                                    </div>
                                                )

                                        })
                                    }
                                </div>
                                <div className='w-full flex items-center gap-2'>
                                    {
                                        cast?.urls?.map((url, index) => {
                                            if (url.startsWith('https://stream.warpcast.com/v1/video/')) {
                                                return <div className='relative w-full h-[200px] md:h-[300px]'>
                                                    <ReactPlayer key={index} url={url} controls={true} width='100%' height='100%' />
                                                </div>
                                            }

                                        })
                                    }
                                </div>
                            </div>
                            <div className='mt-4'>
                                {hasNestedCast &&
                                    <div className="flex flex-col mb-4 gap-2 overflow-hidden border-default w-full rounded-lg">
                                        {
                                            nestedCastEmbeds?.map((embeds, idx) => (
                                                <EmbeddedCast data={embeds} key={idx} />
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                            <div className="bg-inherit max-h-20">

                                <div className="mt-4">
                                    {Object.keys(channel).length === 0 ? (
                                        <></>
                                    ) : (
                                        <p className="px-2 py-[7px] rounded-full text-[10px] leading-[10px] tracking-[0.01em] bg-outline text-onBackgroundII h-6 flex items-center justify-center max-w-fit min-w-10">
                                            {channel?.name}
                                        </p>
                                    )}
                                </div>
                                <div className='flex items-center gap-4 text-onBackgroundII mt-2'>
                                    <div
                                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleOpenReplyCastModal(e) }}
                                        onTouchStart={(e) => { e.stopPropagation(); }}
                                        onTouchMove={(e) => { e.stopPropagation(); }}
                                        onTouchEnd={(e) => { e.preventDefault(); e.stopPropagation(); handleOpenReplyCastModal(e) }}
                                        className='flex items-center group'>
                                        <span className='  rounded-full p-[7px] transition duration-[450ms]'>
                                            <ChatCircleDots fill='#FFFFFFA6' size={20} className='group-hover:fill-primary' />
                                        </span>
                                        <span className='prose-LabelSmall text-onBackgroundII group-hover:text-primary '>{replies}</span>
                                    </div>
                                    <div
                                        onTouchStart={(e) => { e.stopPropagation(); }}
                                        onTouchMove={(e) => { e.stopPropagation(); }}
                                        onTouchEnd={(e) => { e.preventDefault(); e.stopPropagation(); handleReCast(e, cast?.id, farcaster_user?.fid) }}
                                        onClick={(e) => { handleReCast(e, cast?.id, farcaster_user?.fid) }}
                                        className='flex items-center group cursor-pointer'
                                    >
                                        <span className='  rounded-full p-[7px] transition duration-[450ms]'>
                                            <Repeat size={20}
                                                fill={hasRecasted ? "#B4F6A5" : "#FFFFFFA6"} className='group-hover:fill-primary' />
                                        </span>
                                        <span className='prose-LabelSmall text-onBackgroundII group-hover:text-primary'>{currRecasts}</span>
                                    </div>
                                    <div
                                        onTouchStart={(e) => { e.stopPropagation(); }}
                                        onTouchMove={(e) => { e.stopPropagation(); }}
                                        onTouchEnd={(e) => { e.preventDefault(); e.stopPropagation(); handleLikeReaction(e) }}
                                        onClick={(e) => { handleLikeReaction(e) }}
                                        className='flex items-center group cursor-pointer'
                                    >
                                        <span className='  rounded-full p-[7px] transition duration-[450ms]'>
                                            <Heart
                                                size={20}
                                                fill={!hasLiked ? "#FFFFFFA6" : "#B4F6A5"}
                                                weight={!hasLiked ? "bold" : "fill"}
                                                className={`group-hover:fill-primary`}
                                            />
                                        </span>
                                        <span className='prose-LabelSmall text-onBackgroundII group-hover:text-primary'>{currLikes}</span>
                                    </div>
                                    <div className='flex items-center group'>
                                        <span className='  rounded-full p-[7px] transition duration-[450ms]'>
                                            {selectedCurrency === 'WILD' ? <Coins fill='#FFFFFFa6' size={20} className='group-hover:fill-primary' /> :
                                                <img src={getCurrencyLogo(selectedCurrency)} alt="logo" className='max-w-5 h-5 aspect-square rounded-full' />}
                                        </span>
                                        <span className='prose-LabelSmall text-onBackgroundII group-hover:text-primary'>{tipsData?.display_value === "0" ? 0 : tipsData?.display_value}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>}
        </>
    );
};

export default SwipeCard;
