import { CaretDown, CaretUp } from '@phosphor-icons/react';
import React, { useState } from 'react';

const ScheduleOptions = ({ timeValue, handleTimeChange, dateValue, handleDateChange, selectedScheduleType, setSelectedScheduleType, repeatSchedule, handleRepeatInputChange, isEditingScheduled }) => {
    const [selectScheduleTypeOpen, setSelectScheduleTypeOpen] = useState(false);

    return (
        <div className='flex flex-col mx-5 relative'>
        <div className='flex items-center gap-1 mb-2 text-subTxtOnLightBg dark:text-onBackgroundII'>
            <div className='flex w-full px-2 items-center justify-between prose-LabelLarge h-[34px] border dark:border-outline rounded-sm'>
                <input value={timeValue} onChange={(e) => handleTimeChange(e)} type='time' className='mt-[2px] h-full bg-transparent rounded-md text-txtOnLightBg dark:text-white focus:outline-none w-full' />
            </div>
            <div className='flex w-full px-2 items-center justify-between prose-LabelLarge h-[34px] border dark:border-outline rounded-sm'>
                <input value={dateValue} onChange={(e) => handleDateChange(e)} type='date' className='mt-[2px] h-full bg-transparent rounded-md text-txtOnLightBg dark:text-white focus:outline-none w-full' />
            </div>
            {!isEditingScheduled && <div
                className='w-full h-[34px] px-2 bg-transparent border dark:border-outline rounded-sm hidden sm:flex justify-between items-center prose-LabelLarge relative'>
                <div className='flex items-center justify-between w-full gap-4'>
                    <p role='button' onClick={() => setSelectScheduleTypeOpen(prev => !prev)} className='flex items-center w-full justify-between gap-1 capitalize'>{selectedScheduleType} {selectScheduleTypeOpen ? <CaretUp size={10} role='button'
                    /> : <CaretDown size={10} role='button' className='fill-black dark:fill-white'
                    />}</p>
                    {selectedScheduleType !== `Don't Repeat` && <div className='w-fit flex items-center text-nowrap gap-1'>
                        Count :
                        <input type="number" value={repeatSchedule} min={1} onChange={(e) => { e.stopPropagation(); handleRepeatInputChange(e) }} className='w-10 pl-1 focus:outline-none border border-outline' placeholder='1' />
                    </div>}
                </div>

                {selectScheduleTypeOpen && <div className='absolute bottom-12 w-full bg-gray-400 dark:bg-surface flex flex-col gap-2 p-2 left-0' onClick={() => setSelectScheduleTypeOpen(prev => !prev)}>
                    <p role='button' onClick={() => setSelectedScheduleType(`Don't Repeat`)} className='w-full p-2'>Don't Repeat</p>
                    <p role='button' onClick={() => setSelectedScheduleType('daily')} className='w-full p-2'>Daily</p>
                    <p role='button' onClick={() => setSelectedScheduleType('weekly')} className='w-full p-2'>Weekly</p>
                    <p role='button' onClick={() => setSelectedScheduleType('monthly')} className='w-full p-2'>Monthly</p>
                </div>}
            </div>}
        </div>
        {!isEditingScheduled && <div
            className='w-full h-[34px] px-2 bg-transparent border dark:border-outline rounded-sm flex sm:hidden justify-between items-center prose-LabelLarge relative mb-2'>
            <div className='flex items-center gap-4'>
                <p role='button' onClick={() => setSelectScheduleTypeOpen(prev => !prev)} className='flex items-center gap-1 capitalize'>{selectedScheduleType} {selectScheduleTypeOpen ? <CaretUp size={10} role='button'
                /> : <CaretDown size={10} role='button' className='fill-black dark:fill-white'
                />}</p>
                {selectedScheduleType !== `Don't Repeat` && <div className='w-fit flex items-center text-nowrap gap-1'>
                    Count :
                    <input type="number" value={repeatSchedule} min={1} onChange={(e) => { e.stopPropagation(); handleRepeatInputChange(e) }} className='w-10 pl-1 focus:outline-none border dark:border-outline' placeholder='1' />
                </div>}
            </div>

            {selectScheduleTypeOpen && <div className='absolute bottom-12 w-full bg-gray-400 dark:bg-surface flex flex-col gap-2 p-2 left-0' onClick={() => setSelectScheduleTypeOpen(prev => !prev)}>
                <p role='button' onClick={() => setSelectedScheduleType(`Don't Repeat`)} className='w-full p-2'>Don't Repeat</p>
                <p role='button' onClick={() => setSelectedScheduleType('daily')} className='w-full p-2'>Daily</p>
                <p role='button' onClick={() => setSelectedScheduleType('weekly')} className='w-full p-2'>Weekly</p>
                <p role='button' onClick={() => setSelectedScheduleType('monthly')} className='w-full p-2'>Monthly</p>
            </div>}
        </div>}

    </div>
    )
}

export default ScheduleOptions;