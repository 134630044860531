import { Repeat } from '@phosphor-icons/react'
import React, { useState, useEffect } from 'react'
import { isDesktopDevice } from '../../../../utils/helper'
import { Route, useNavigate } from 'react-router-dom';
import { markNotificationAsRead } from '../../../../services/api';
import { Link } from 'react-router-dom';
import ProfileHoverCard from '../../../../components/ProfileHoverCard';

const RecastCard = ({ data, unreadTimer }) => {
    const navigate = useNavigate();
    const isDesktop = isDesktopDevice()
    const { actors, created_at, id, is_read, target_cast_hash_details, target_cast_hash, text } = data;

    const [isRead, setIsRead] = useState(is_read);

    useEffect(() => {
        if (!isRead) {
            markNotificationAsRead([id]);
            const timeoutId = setTimeout(() => {
                setIsRead(true);
            }, unreadTimer);

            return () => clearTimeout(timeoutId);
        }
    }, [isRead, id]);


    if (data == undefined) return null;

    return (
        <Link to={`/cast/${target_cast_hash}/${target_cast_hash_details?.farcaster_user?.fid}`}>
            <div className={`${isRead ? 'bg-white dark:bg-background' : 'dark:bg-primary/10'} transition duration-500 flex justify-start  border-b dark:border-outline items-start gap-4 hover:bg-gray-100 dark:hover:bg-surface/40 px-5 py-4`}>
                <div className='h-12 min-w-12 bg-maintextColorOnLightBg/10 dark:bg-surface rounded-full flex justify-center items-center'>
                    <Repeat size={24} weight='fill' className=' fill-primary' />
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex items-center gap-2'>
                        {actors?.slice(0, 5)?.map((actor, index) => (
                            <ProfileHoverCard username={actor?.username} data={actor}>
                            <img
                                key={index}
                                src={actor.pfp_url}
                                className="w-8 h-8 object-cover rounded-full cursor-pointer"
                                alt="profile img"
                            />
                            </ProfileHoverCard>
                        ))}
                    </div>
                    <div className='md:min-w-[375px]'>
                        <div className='text-txtOnLightBg dark:text-onBackground prose-BodyLarge flex items-center gap-[6px]'>
                            <ProfileHoverCard username={actors[0]?.username} data={actors[0]}>
                                {actors[0]?.display_name || actors[0]?.username}
                            </ProfileHoverCard>
                            <p className='bg-gray-300 dark:bg-surface rounded-full px-2 py-[2px] prose-LabelSmall text-subTxtOnLightBg dark:text-onBackgroundII'>
                                {
                                    getRecastText(actors)
                        }</p>
                        </div>
                        <p className={`mt-2 prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundIII truncate ${isDesktop ? 'max-w-[50ch]' : 'max-w-[30ch]'}`}>
                            {target_cast_hash_details?.cast?.body}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default RecastCard


const getRecastText = (actors) => {
    if (actors.length == 1) {
        return 'Recasted your Cast'
    }
    return `and ${actors.length - 1} others Recasted your Cast`
}