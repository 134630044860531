const NotificationSkeletonLoading = () => {
    return (
        <div
            className="flex h-[93px] py-4 justify-start items-center gap-4 align-start bg-white dark:bg-background px-5">
            <div className="flex items-center">
                <button className={`animate-pulse bg-surface/50 dark:bg-surface flex items-center justify-center overflow-hidden rounded-full h-12 w-12`}>
                </button>
            </div>
            <div className="w-full break-all flex flex-col gap-1">
                <div className="flex items-center gap-[6px] h-[14px] w-48 animate-pulse bg-surface/50 dark:bg-surface" />
                <div className="flex items-center gap-[6px] h-[14px] w-48 animate-pulse bg-surface/50 dark:bg-surface md:hidden" />
                <div className="flex items-center gap-[6px] h-[14px] w-20 animate-pulse bg-surface/50 dark:bg-surface" />
            </div>
        </div>
    )
}

export default NotificationSkeletonLoading;