import React from 'react'
import { extractTimeValue } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png'
import ProfileHoverCard from '../../ProfileHoverCard';

const TipTransactionCard = ({ data, i, fromReply = false }) => {

    const { tip_given, from_user, to_user, time, tip_amount } = data;
    const timeValue = extractTimeValue(time.display_value);
    const navigate = useNavigate();

    function handleNavigate(username) {
        navigate(`/profile/${username}`);
    }

    return (
        <div key={i} className='flex justify-between items-center mt-4 md:mt-6 px-5'>
            {
                fromReply ? <>
                    <div className='flex items-center gap-2'>

                        {
                            <button onClick={() => handleNavigate(from_user?.username)}>
                                <ProfileHoverCard username={from_user?.username}>
                                    <img
                                        src={from_user?.cdn_url || from_user?.pfp_url || logo}
                                        className={`object-cover size-9 rounded-full border-[2px] border-primary`}
                                        alt="profile img"
                                    />
                                </ProfileHoverCard>
                            </button>
                        }
                        <div>
                            <p className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII'>{'Tip received from'}
                                <ProfileHoverCard username={from_user?.username} data={from_user}>
                                    <span className='text-txtOnLightBg dark:text-onBackground'> {from_user?.display_name.length > 20 ? from_user?.display_name.slice(0, 15) + "..." : from_user?.display_name}</span>
                                </ProfileHoverCard>
                            </p>
                            <p className='text-[10px] text-onBackgroundIII'>{timeValue}</p>
                        </div>
                    </div>
                    <div className='text-end'>
                        <p className={`prose-BodyLargeBold  text-primary`}>{tip_amount?.display_value || ''} {tip_amount?.currency || ''}</p>
                    </div>
                </> :
                    <><div className='flex items-center gap-2'>
                        {tip_given &&
                            <button onClick={() => handleNavigate(to_user?.username)}>
                                <ProfileHoverCard username={to_user?.username}>
                                    <img
                                        src={to_user?.cdn_url || to_user?.pfp_url || logo}
                                        className={`object-cover size-9 rounded-full border-[2px] border-warning`}
                                        alt="profile img"
                                    />
                                </ProfileHoverCard>
                            </button>
                        }
                        {!tip_given &&
                            <button onClick={() => handleNavigate(from_user?.username)}>
                                <ProfileHoverCard username={from_user?.username}>
                                    <img
                                        src={from_user?.cdn_url || from_user?.pfp_url || logo}
                                        className="object-cover size-9 rounded-full  border-[2px] border-primary"
                                        alt="profile img"
                                    />
                                </ProfileHoverCard>
                            </button>
                        }
                        <div>
                            <p className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII'>
                                {tip_given ? from_user?.display_name.length > 20 ? from_user?.display_name.slice(0, 15) + "..." : from_user?.display_name + ' Tipped' : 'Tip received from'}
                                <span className='text-txtOnLightBg dark:text-onBackground'> {tip_given ? to_user?.display_name.length > 20 ? to_user?.display_name.slice(0, 15) + "..." : to_user?.display_name : from_user?.display_name.length > 20 ? from_user?.display_name.slice(0, 15) + "..." : from_user?.display_name}</span>
                            </p>
                            <p className='text-[10px] text-subTxtOnLightBg dark:text-onBackgroundIII'>{timeValue}</p>
                        </div>
                    </div>
                        <div className='text-end'>
                            <p className={`prose-BodyLargeBold ${tip_given ? 'text-warning' : ' text-primary'}`}>{tip_amount?.display_value || ''} {tip_amount?.currency || ''}</p>
                            {/* <p className='text-[10px] text-onBackgroundIII'>$20</p> */}
                        </div>
                    </>
            }
        </div>
    )
}

export default TipTransactionCard