import { Empty } from "@phosphor-icons/react"

const BannedState = () =>{
    return (
        <div className="flex flex-col justify-center items-center max-h-dvh pt-10 px-5">
            <Empty size={48} color="red"/>
            <p className="prose-HeaderLarge"> Your account has been banned, if you think this is wrong, <span className="text-primary">dm defikaran.eth</span></p>
       </div>
    )                 
}

export default BannedState;