const TktSkeletonLoading = () =>{
    return (
        <div className="flex items-center h-[80px] bg-clip-padding bg-opacity-80 px-5">
            <div className="flex py-6 justify-between gap-4 w-full items-start">
                <div className="flex flex-row justify-start gap-5 items-center">
                    <div className={`-mx-2 overflow-hidden rounded-full border-2 border-black h-10 w-10 animate-pulse bg-surface`}>
                    </div>
                    <div>
                        <h2 className="h-[22.5px] w-24 animate-pulse bg-surface"></h2>
                        <div className="gap-2 mt-1 h-4 w-20 animate-pulse bg-surface">
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-1 items-center h-4 w-16 animate-pulse bg-surface">
                </div>
            </div>
        </div>
    )
}

export default TktSkeletonLoading;