import { Coins, Empty } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import QuestCard from '../../../components/Cards/Quest/QuestCard';
import { getQuests } from '../../../services/api';
import { SUPPORTED_QUEST_TYPES } from '../../../utils/constants';

import EmptyQuestSVG from '../../../assets/svg/empty_quests.svg';
import QuestSkeletonLoading from '../../../components/Cards/Quest/QuestSkeletonLoading';

import { useNavigate } from 'react-router-dom';
import coin1 from '../../../assets/images/tippingArena/coin1.png';
import coin2 from '../../../assets/images/tippingArena/coin2.png';
import coin3 from '../../../assets/images/tippingArena/coin3.png';
import { getSelectedCurrencyLocalStorage } from '../../../utils/constants';



const QuestsTab = ({showArenaNav = true}) => {

    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || 'WILD';
    const navigate = useNavigate();

    const [questAvailable, setQuestAvailable] = useState(false);
    const [filteredQuest, setFilteredQuest] = useState([]);

    const { data: questsData = {}, isLoading: questsLoading } = useQuery({
        queryKey: ['quests', selectedCurrency],
        queryFn: () => getQuests(selectedCurrency),
    });

    useEffect(() => {
        const data = questsData?.data?.filter((quest) => (SUPPORTED_QUEST_TYPES.includes(quest?.quest_spec?.quest_type)))
        setFilteredQuest(data);
    }, [questsData])

    return (
        <div>
            {showArenaNav && <div className='h-[81px] mt-6 mx-5 relative px-5 py-[14px] text-black bg-primary'
                role='button'
                onClick={() => navigate('/tippingarena')}>
                <p className='prose-TitleLarge mb-[7px]'>Tipping Arena</p>
                <p className='prose-LabelMedium flex items-center gap-1'><Coins size={16} />Let's go wild</p>
                <img src={coin2} alt="coinImg" className='absolute right-0 bottom-0' />
                <img src={coin3} alt="coinImg" className='absolute bottom-0 right-[60px]' />
                <img src={coin1} alt="coinImg" className='absolute right-5 bottom-4' />
            </div>}
            <div className={`relative overflow-y-auto removeScrollBar h-full pb-8`}>{
                questsLoading ? (
                    <>
                        <QuestSkeletonLoading />
                        <QuestSkeletonLoading />
                        <QuestSkeletonLoading />

                    </>
                ) :
                    <div className='flex flex-col gap-1 mx-5 md:mx-0 mt-2'>

                        {filteredQuest?.length < 0 ? (
                            <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-mx-5">
                                <Empty size={20} color="#FFFFFF66" />
                                <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                            </div>
                        ) :
                            <>
                                {
                                    filteredQuest?.map((quest, i) => {
                                        if (quest?.quest_user_record?.status === "in_progress") {
                                            if (!questAvailable)
                                                setQuestAvailable(true);
                                            return <QuestCard key={i} quest={quest} />
                                        }
                                    })
                                }
                                {
                                    filteredQuest?.map((quest, i) => {
                                        if (quest?.quest_user_record?.status === "not_started") {
                                            if (!questAvailable)
                                                setQuestAvailable(true);
                                            return <QuestCard key={i} quest={quest} />
                                        }
                                    })
                                }
                                {
                                    filteredQuest?.map((quest, i) => {
                                        if (quest?.quest_user_record?.status === "completed") {
                                            if (!questAvailable)
                                                setQuestAvailable(true);
                                            return <QuestCard key={i} quest={quest} />
                                        }
                                    })
                                }
                                {
                                    filteredQuest?.length <= 0 && <div className="size-full flex flex-col justify-center items-center gap-[12px]">
                                        <img src={EmptyQuestSVG} alt="no quests" />
                                        <p className="prose-DisplaySmall dark:text-onBackgroundII uppercase text-center">More Quests are coming soon</p>
                                    </div>
                                }

                            </>
                        }

                    </div>
            }</div>
        </div>
    );
}

export default QuestsTab;