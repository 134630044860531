import { Empty } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import HoldersSkeletonLoading from '../../../components/Cards/Ranking/HoldersSkeletonLoading';
import TicketCard from '../../../components/Cards/Wallet/TicketCard';
import { getWalletOwnedTickets } from '../../../services/api';


const HoldingsTab = () => {

    const { data: ownedTicketData = [], isLoading: ownedTicketLoading, refetch: ownedTicketRefetch } = useQuery({
        queryKey: ['ownedTicket'],
        queryFn: getWalletOwnedTickets,
    });

    return (
        <div>
            {ownedTicketLoading ? <div className='flex flex-col gap-6'>
                <HoldersSkeletonLoading />
                <HoldersSkeletonLoading />
                <HoldersSkeletonLoading />
                <HoldersSkeletonLoading />
            </div>
                : !ownedTicketData?.data?.owned_tickets?.length ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-mx-5">
                    <Empty size={20} color="#FFFFFF66" className='fill-black dark:fill-[#ffffff66]' />
                    <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                </div>
                    : ownedTicketData?.data?.owned_tickets?.map((data, idx) => (
                        <TicketCard data={data} key={idx} refetch={ownedTicketRefetch} />
                    ))}
        </div>
    )
}

export default HoldingsTab;