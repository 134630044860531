import { Empty } from '@phosphor-icons/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { getCurrentUserRevenue } from '../../../services/api';

import logo from '../../../assets/images/logo.png';
import { getSelectedCurrencyLocalStorage } from '../../../utils/constants';
import ProfileHoverCard from '../../../components/ProfileHoverCard';


const RevenueTab = () => {
    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || 'WILD';
    const queryClient = useQueryClient()

    const [filteredRevenue, setFilteredRevenue] = useState([]);

    const { data: userRevenue, isLoading: isLoadingRevenue, refetch: refectRevenue } = useQuery({
        queryKey: ['currUserRevenue'],
        queryFn: () => getCurrentUserRevenue(),
    })

    useEffect(() => {
        if (isLoadingRevenue)
            return;

        const filteredRevenue = userRevenue?.data?.filter(revenue => revenue?.divident?.currency === selectedCurrency)
        setFilteredRevenue(filteredRevenue);

    }, [userRevenue, selectedCurrency])

    return (
        <div className='mx-5'>
        {isLoadingRevenue ? <div className='relative min-h-20'>
            <div className='grid grid-cols-3 w-full'>
                <div className='flex items-center gap-2'>
                    <div className='size-8 rounded-full animate-pulse bg-surface/50 dark:bg-surface'></div>
                    <p className='prose-BodyLarge text-onBackground h-5 w-40 animate-pulse bg-surface/50 dark:bg-surface'></p>
                </div>
                <div className='prose-BodyMedium  flex items-center justify-center'>
                    <p className='animate-pulse bg-surface/50 dark:bg-surface h-4 w-20'></p>
                </div>
                <div className='prose-BodyLargeBold flex items-center justify-end'>
                    <p className='animate-pulse bg-surface/50 dark:bg-surface h-5 w-20'></p>
                </div>
            </div><div className='grid grid-cols-3 w-full mt-4'>
                <div className='flex items-center gap-2'>
                    <div className='size-8 rounded-full animate-pulse bg-surface/50 dark:bg-surface'></div>
                    <p className='prose-BodyLarge text-onBackground h-5 w-40 animate-pulse bg-surface/50 dark:bg-surface'></p>
                </div>
                <div className='prose-BodyMedium  flex items-center justify-center'>
                    <p className='animate-pulse bg-surface/50 dark:bg-surface h-4 w-20'></p>
                </div>
                <div className='prose-BodyLargeBold flex items-center justify-end'>
                    <p className='animate-pulse bg-surface/50 dark:bg-surface h-5 w-20'></p>
                </div>
            </div>
            <div className='grid grid-cols-3 w-full mt-4'>
                <div className='flex items-center gap-2'>
                    <div className='size-8 rounded-full animate-pulse bg-surface/50 dark:bg-surface'></div>
                    <p className='prose-BodyLarge text-onBackground h-5 w-40 animate-pulse bg-surface/50 dark:bg-surface'></p>
                </div>
                <div className='prose-BodyMedium  flex items-center justify-center'>
                    <p className='animate-pulse bg-surface/50 dark:bg-surface h-4 w-20'></p>
                </div>
                <div className='prose-BodyLargeBold flex items-center justify-end'>
                    <p className='animate-pulse bg-surface/50 dark:bg-surface h-5 w-20'></p>
                </div>
            </div>

        </div>
            : filteredRevenue?.length === 0 ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4">
                <Empty size={20} color="#FFFFFF66" />
                <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
            </div>
                : filteredRevenue?.map((revenue, idx) => (
                    <div key={idx} className='grid grid-cols-3 w-full mt-4'>
                        <div className='flex items-center gap-2'>
                            <ProfileHoverCard username={revenue?.subject?.username} >
                                <img src={revenue?.subject?.cdn_url || revenue?.subject?.pfp_url || logo} alt="user" className='size-8 rounded-full' />
                            </ProfileHoverCard>
                            <ProfileHoverCard username={revenue?.subject?.username}>
                                <p className='prose-BodyLarge text-txtOnLightBg dark:text-onBackground'>{revenue?.subject?.display_name}</p>
                            </ProfileHoverCard>
                        </div>
                        <div className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundIII flex items-center justify-center'>
                            {parseFloat(revenue?.balance) / 1000} Tickets
                        </div>
                        <div className='prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground flex items-center justify-end'>
                            {revenue?.divident?.display_value} {revenue?.divident?.currency}
                        </div>
                    </div>
                ))
        }
    </div>
    )
}

export default RevenueTab;