export default {
  suggestions: {
    list: {
      position: "fixed",
      zIndex: 1000,
      backgroundColor: "white",
      fontSize: 18,
      backgroundColor: "#000",
    },
    item: {
      "&focused": {
        backgroundColor: "#000",
      },
    },
  },
};
