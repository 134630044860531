import { CaretDown, CaretUp, CurrencyCircleDollar, Info } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { getCurrentUserProfile, getWalletDetail } from '../../../services/api';
import { Chart } from '../../Chart';
import DepositModal from '../../Modals/DepositModal';
import WithdrawModal from '../../Modals/WithdrawModal';
import CustomModal from '../../UI/CustomModal';
import Spinner from '../../UI/Spinner';
import ToolTip from '../../UI/Tooltip';

import moxiePng from '../../../assets/images/moxie.png';
import Portal from '../../UI/ReactPortal';


const WalletDetail = () => {

    const toolTipTimeoutRef = useRef(null);
    const buttonRef = useRef(null)

    const [toolTipOpen, setToolTipOpen] = useState(false);
    const [showDepositModal, setShowDepositModal] = useState(false)
    const [showWithdrawModal, setShowWithdrawModal] = useState(false)
    const [showCurrencyModal, setShowCurrencyModal] = useState(false)
    const [postion, setPosition] = useState({ top: 0, left: 0 })
    const [isWithdrawingMoxie, setIsWithdrawingMoxie] = useState(false)



    const { data: walletDetail = {}, isLoading: walletDetailLoading, refetch: walletRefetch } = useQuery({
        queryKey: ['walletDetail'],
        queryFn: getWalletDetail
    });

    const { data: currUserData, isLoading: currUserLoading } = useQuery({
        queryKey: ['getCurrentUserProfile'],
        queryFn: getCurrentUserProfile,
    });

    const openToolTip = () => {
        if (toolTipTimeoutRef.current) {
            clearTimeout(toolTipTimeoutRef.current);
        }
        setToolTipOpen(true);
        toolTipTimeoutRef.current = setTimeout(() => {
            setToolTipOpen(false);
        }, 6000);
    };
    const handleDocumentClick = () => {
        setToolTipOpen(false);
    };
    const closeToolTip = () => {
        setToolTipOpen(false);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleDocumentClick);
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);

    const handelOpenDepositModal = () => {
        setShowDepositModal(true)
    }
    const handleCloseDepositModal = () => {
        setShowDepositModal(false)
    }

    const handelOpenWithdrawModal = (currency) => {
        switch(currency) {
            case 'ETH':
                setShowWithdrawModal(true)
                setIsWithdrawingMoxie(false)
                setShowCurrencyModal(false)
                break;
            case 'MOXIE':
                setShowWithdrawModal(true)
                setIsWithdrawingMoxie(true)
                setShowCurrencyModal(false)
                break;
            default:
                setShowWithdrawModal(true)
        }
    }
    const handleCloseWithdrawModal = () => {
        setShowWithdrawModal(false)
    }

    const getPositionOfElement = () => {
        if (buttonRef.current) {
            const ChannelDropDownbtn = buttonRef.current.getBoundingClientRect();
            const isMobile = window.matchMedia("(max-width: 500px)").matches;
            const isTablet = window.matchMedia("(max-width: 767px)").matches;
            if (isMobile || isTablet) {
                setPosition({ top: ChannelDropDownbtn.bottom, left: ChannelDropDownbtn.left - 50 });
            } else {
                setPosition({ top: ChannelDropDownbtn.bottom, left: ChannelDropDownbtn.left });
            }
        }
    }

    return (
        <div className='flex flex-col justify-center items-center'>
            <div className='md:flex justify-between w-full'>
            <div className='flex flex-col justify-center items-center md:items-start md:w-full md:ml-5'>
                <div className='prose-TitleMedium text-subTxtOnLightBg dark:text-onBackgroundII relative'>ETH Holdings
                    <Info onClick={openToolTip} onMouseOver={openToolTip} onMouseLeave={closeToolTip} ref={toolTipTimeoutRef} className='cursor-pointer inline-block ml-2'/>
                    <div className='absolute top-6 left-0 '>
                        {toolTipOpen && (
                            <ToolTip pointer='top' text={"Your wallet balance. Revenue from 2.5% on buy/sell of your tickets gets added to this in realtime"} />
                        )}
                    </div>
                </div>
                <div className='prose-DisplayMedium  text-primary'>{walletDetail?.eth_holdings?.display_value || <p className='h-6 w-40 animate-pulse bg-surface mt-1'></p>} {walletDetail?.eth_holdings?.currency || ''}</div>
                <div className='prose-TitleMedium text-subTxtOnLightBg dark:text-onBackgroundII'>{walletDetail?.eth_holdings?.usd_value_display || <p className='h-[13px] w-16 animate-pulse bg-surface mt-1'></p>}</div>
            </div>
        
            <div className='flex md:flex-col md:items-end md:mr-5 items-center gap-2 md:mt-0 mt-4 w-full md:w-[80%] justify-center px-6 lg:px-0'>
                <button onClick={handelOpenDepositModal} className='px-4 w-full lg:px-0 lg:w-[170px] py-1 font-[bungee] rounded-sm  bg-primary  text-white dark:text-onPrimary custom-hover'>Deposit</button>
                <div className='flex items-center w-full lg:px-0 lg:w-[170px] rounded-sm border dark:border-outline text-subTxtOnLightBg dark:text-onBackgroundII'>
                    <button onClick={() => handelOpenWithdrawModal('ETH')} className='w-full h-full font-[bungee] text-sm  text-subTxtOnLightBg dark:text-onBackgroundII dark:md:hover:text-white hover:bg-primary'>Withdraw</button>
                    <div className='h-full w-[1px] bg-black/20 dark:bg-white/10'/>
                    <div ref={buttonRef}
                        className={`h-9 bg-white dark:bg-[#121214] flex items-center prose-LabelLarge gap-[2px] px-1 py-2 justify-between cursor-pointer ps-2`}
                        onClick={() => { setShowCurrencyModal(prev => !prev); getPositionOfElement() }}
                    >
                        <CurrencyCircleDollar size={24}/>
                        {!showCurrencyModal ? <CaretDown size={16} className='fill-black dark:fill-white' /> : <CaretUp size={16} className='fill-black dark:fill-white' />}
                    </div>
                </div>
            </div>
            
            </div>
            <div className='flex md:flex-col lg:flex-row items-center gap-10 p-4 w-full md:w-[80%] justify-center mt-4 border dark:border-outline rounded-md'>
                <div>
                    <p className='flex prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundII items-center gap-2'>Portfolio 
                        {/* <span className='bg-[#44E955]/20 text-[#A5F6AD] prose-LabelSmall px-1 py-[2px]'>+32.12%</span> */}
                        </p>
                    <p className='prose-DisplaySmall text-txtOnLightBg dark:text-onBackground'>{walletDetail?.portfolio_value?.display_value}<span className='prose-HeaderLarge text-onBackgroundII'>{walletDetail?.portfolio_value?.currency}</span></p>
                    <p className='prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundII'>{walletDetail?.portfolio_value?.usd_value_display}</p>
                </div>
                <div className='min-w-[100px] w-full'>                  
                    <Chart username={currUserData?.data?.farcaster_user?.username} chartHeight={64} containerHeight={78} showAxis={false}/>                   
                </div>
            </div>

            {showDepositModal && <CustomModal isOpen={showDepositModal} closeModal={handleCloseDepositModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                    <DepositModal walletDetail={walletDetail} closeModal={handleCloseDepositModal} refetch={walletRefetch}/>
                </CustomModal>}
            {showWithdrawModal && <CustomModal isOpen={showWithdrawModal} closeModal={handleCloseWithdrawModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                    <WithdrawModal walletDetail={walletDetail} closeModal={handleCloseWithdrawModal} refetch={walletRefetch} isWithdrawingMoxie={isWithdrawingMoxie}/>
                </CustomModal>}

            {showCurrencyModal && (
                <Portal>
                    <div className='fixed top-0 left-0 w-screen h-screen z-[1000]'>
                        <div className='fixed inset-0 bg-white dark:bg-background opacity-80' onClick={() => setShowCurrencyModal(false)}></div>
                        <div style={{ top: postion.top, left: postion.left }} className={`overflow-y-auto removeScrollBar px-2 py-2 max-h-[240px] absolute w-full max-w-fit bg-white dark:bg-[#121214] border dark:border-outline rounded-md shadow-lg z-[101] flex flex-col gap-1 pb-1 hover:bg-surface group`}>
                            <div onClick={() => handelOpenWithdrawModal('MOXIE')} className='flex items-center gap-1 cursor-pointer'>
                                <img src={moxiePng} alt='moxie' className='w-8 h-8'/>
                                <p className='prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground group-hover:text-onBackgroundII group-dark:hover:text-onBackground'>MOXIE</p>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </div>
    )
}

export default WalletDetail