import { CHAIN_IDS } from "./constants";

import ethLogo from '../assets/images/ethereum-logo.svg'
import baseLogo from '../assets/images/base-logo.svg'
import arbitrumLogo from '../assets/images/arbitrum-logo.svg'
import optimismLogo from '../assets/images/optimism-logo.svg'
import blastLogo from '../assets/images/blast-logo.svg'

export const handleBlockExplorerNavigate = (e, txn_id, chainId) => {
    e?.stopPropagation()
    let explorerUrl;
    switch (chainId) {
        case CHAIN_IDS.ETH:
            explorerUrl = `https://etherscan.io/tx/${txn_id}`;
            break;
        case CHAIN_IDS.OPTIMISM:
            explorerUrl = `https://optimistic.etherscan.io/tx/${txn_id}`;
            break;
        case CHAIN_IDS.ARBITRUM:
            explorerUrl = `https://arbiscan.io/tx/${txn_id}`;
            break;
        case CHAIN_IDS.BASE:
            explorerUrl = `https://basescan.org/tx/${txn_id}`;
            break;
        case CHAIN_IDS.BLAST:
            explorerUrl = `https://blastscan.io/tx/${txn_id}`;
            break;
        default:
            explorerUrl = `https://etherscan.io/tx/${txn_id}`;
    }
    window.open(explorerUrl, '_blank', 'noopener,noreferrer');
}

export const CHAIN_LOGO = {
    [CHAIN_IDS.ETH]: ethLogo,
    [CHAIN_IDS.BASE]: baseLogo,
    [CHAIN_IDS.ARBITRUM]: arbitrumLogo,
    [CHAIN_IDS.OPTIMISM]: optimismLogo,
    [CHAIN_IDS.BLAST]: blastLogo
}

export const DEXSCREENER_CHAIN_ID = {
    [CHAIN_IDS.ETH]: 'ethereum',
    [CHAIN_IDS.BASE]: 'base',
    [CHAIN_IDS.ARBITRUM]: 'arbitrum',
    [CHAIN_IDS.OPTIMISM]: 'optimism',
    [CHAIN_IDS.BLAST]: 'blast'
}

export const getDexScreenerLogo = (chainId, tokenAddress) => {
    return `https://dd.dexscreener.com/ds-data/tokens/${DEXSCREENER_CHAIN_ID[chainId]}/${tokenAddress}.png?size=lg`   
}

export const getDexScreenerUrl = (chainId, tokenAddress) => {
    return `https://dexscreener.com/${DEXSCREENER_CHAIN_ID[chainId]}/${tokenAddress}`
}

export const fallbackCircledLetterLogo = (symbol) => {

    const firstLetter = (symbol?.charAt(0) || 'A').toLowerCase();
    return `https://ui-avatars.com/api/?name=${firstLetter}&background=random`
}