import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';

import { isMobileDevice } from '../../utils/helper';
import { setSignerModal } from '../../features/globalStateSlice';
import useAccount from '../account';
import { X } from '@phosphor-icons/react';

const SignerSignin = ({ closeModal = () => { } }) => {
    const { hasSignedKeyToken } = useAccount();
    const dispatch = useDispatch();

    useEffect(() => {
        const scriptId = "siwn-script";
        let script = document.getElementById(scriptId);

        if (!script) {
            script = document.createElement("script");
            script.id = scriptId;
            script.src = "https://neynarxyz.github.io/siwn/raw/1.2.0/index.js";
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        }

        return () => {
            if (script) {
                document.body.removeChild(script);
            }

            const button = document.getElementById("siwn-button");
            if (button && button.parentElement) {
                button.parentElement.removeChild(button);
            }
        };
    }, []);

    useEffect(() => {
        if (hasSignedKeyToken) {
            dispatch(setSignerModal(false));
        }
    }, [hasSignedKeyToken]);

    return (
        <div className='relative flex justify-start flex-col gap-8  items-center w-full bg-background h-80  rounded-xl border border-outlineII overflow-hidden'>
            {!hasSignedKeyToken &&
                <AuthButton closeModal={closeModal} />
            }
        </div>
    );
};

const AuthButton = ({ closeModal }) => {
    const { hasSignedKeyToken, warpcastSignedKeyData, signerPayload, setSignerPollingEnabled } = useAccount();
    const [showQR, setShowQR] = useState(false);
    const deeplinkUrl = signerPayload?.deeplink_url;

    useEffect(() => {
        setSignerPollingEnabled(true);
        return () => {
            setSignerPollingEnabled(false);
        };
    }, [setSignerPollingEnabled]);

    const handleClick = async () => {
        if (isMobileDevice()) {
            window.location.href = deeplinkUrl;
        } else {
            setShowQR(true);
        }

    };

    if (warpcastSignedKeyData == null) {
        return <div className='w-full h-full relative flex flex-col  items-center'>
            <h1 className='prose-TitleLarge text-center absolute top-24 max-w-80'>
                We need you to provide write access to Farcaster
                Please wait...
            </h1>
            <div className="absolute bottom-12 loading loading-spinner loading-md bg-primary" />
        </div>
    }

    return (
        <div className='flex flex-col items-center  w-full h-full relative'>
            <button
                onClick={closeModal}
                className='absolute top-4 left-4 text-white'
            >
                <X size={24} className='cursor-pointer' />
            </button>
            {!hasSignedKeyToken && !showQR && (
                <>
                    <h1 className='prose-TitleLarge absolute top-10 text-center mb-6 max-w-80'>
                        We need you to provide write access to Farcaster
                        {warpcastSignedKeyData?.isSponsored && <div className='text-sm text-onBackground'>fee is on us :D</div>}
                    </h1>
                </>
            )}
            {!showQR ? (
                <button
                    disabled={hasSignedKeyToken}
                    onClick={handleClick}
                    className='bg-primary absolute bottom-24 text-background py-2 px-5 prose-TitleLarge rounded-sm hover:bg-opacity-90'
                >
                    Sign in With Farcaster
                </button>
            ) : (
                <div className="flex absolute top-12 flex-col items-center">
                    <QRCode value={deeplinkUrl} size={180} />
                    <p className="mt-2 text-sm">Scan with your mobile device</p>
                </div>
            )}
            {showQR && <span className="absolute bottom-4 loading loading-spinner loading-md bg-primary" />}
        </div>
    );
};


export default SignerSignin;
