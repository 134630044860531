import { CaretDown, CaretUp, SignOut } from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getMyPointBalances, getProfileDetailNew, getProfileHolders } from '../../services/api'
import SearchBar from '../SearchBar'
import UserPoints from '../UserPoints'

import { useDisconnect, useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers5/react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useBuyNFTTransaction from '../../hooks/buySellNFT'
import QuestsTab from '../../pages/Points/Components/QuestsTab'
import SharesChart from '../Cards/Profile/SharesChart'
import BuyModal from '../Modals/BuyModal'
import SellModal from '../Modals/SellModal'
import CustomModal from '../UI/CustomModal'
import ProfileHoverCard from '../ProfileHoverCard'
import { getSelectedCurrencyLocalStorage } from '../../utils/constants'
import useAccount from '../../hooks/account'

const RigthSideBar = () => {
    const navigate = useNavigate()

    const { getUserMarketCap } = useBuyNFTTransaction();

    const { pathname } = useLocation()
    const { disconnect } = useDisconnect()
    const { open, } = useWeb3Modal()
    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency
    const { user } = useAccount()
    const isUserExists = user?.farcaster_details || false

    const [showUserPoints, setShowUserPoints] = useState(false)

    const [showQuests, setShowQuests] = useState(true)
    const [showAllowance, setShowAllowance] = useState(true)
    const [showUserChart, setShowUserChart] = useState(false)
    const { address, isConnected } = useWeb3ModalAccount()

    const [searchTerm, setSearchTerm] = useState('');

    const username = pathname.split('/')[2]

    const [showBuyModal, setShowBuyModal] = useState(false);
    const [selectedBuy, setSelectedBuy] = useState(null);
    const [selectedSell, setSelectedSell] = useState(null);
    const [showSellTicketModal, setShowSellTicketModal] = useState(false);

    const [userMarketcap, setUserMartetcap] = useState({
        value: 0,
        isLoading: false
    })


    useEffect(() => {
        pathname.includes('/points') ? setShowUserPoints(false) : setShowUserPoints(true)
        pathname.includes('/profile') ? setShowUserChart(true) : setShowUserChart(false)

        return () => setShowUserChart(false)
    }, [pathname])


    const { data: myPointsBalances } = useQuery({
        queryKey: ['myPointsBalances'],
        queryFn: () => getMyPointBalances(true),
        enabled: pathname !== '/invite'
    });

    const { data: profileData, isLoading: profileDataLoading, refetch: profileRefetch } = useQuery({
        queryKey: ['userProfileNew', username],
        queryFn: () => getProfileDetailNew(username, selectedCurrency),
        enabled: !!username && pathname.includes('profile')
    });

    // const { data: profileHolders = [], isLoading: profileHoldersLoading } = useQuery({
    //     queryKey: ['currentUserTopHolders', username],
    //     queryFn: () => getProfileHolders(username, false, 3),
    //     enabled: !!username && pathname.includes('/profile')
    // });

    const { data: profileHolder, isLoading: profileHoldersLoading, refetch: profileHoldersRefetch } = useQuery({
        queryKey: ['currentUserHolders', username],
        queryFn: () => getProfileHolders(username),
        enabled: !!username && pathname.includes('/profile')
    });

    const handleBuyModal = (e) => {
        e.stopPropagation();
        setSelectedBuy({
            farcaster_user: {
                username: profileData?.data?.profile?.username,
                fid: profileData?.data?.profile?.fid,
                display_name: profileData?.data?.profile?.display_name,
                pfp_url: profileData?.data?.profile?.pfp_url,
            },
            num_tickets_owned_by_me: profileData?.data?.view_user_context?.num_tickets_owned?.value
        });
        setShowBuyModal(true);
    };
    const handleCloseBuyModal = () => {
        setShowBuyModal(false);
    };

    const handleOpenSellTicketModal = (e) => {
        e.stopPropagation();
        setSelectedSell({
            farcaster_user: {
                username: profileData?.data?.profile?.username,
                fid: profileData?.data?.profile?.fid,
                display_name: profileData?.data?.profile?.display_name,
                pfp_url: profileData?.data?.profile?.pfp_url,
            },
            num_tickets: profileData?.data?.view_user_context?.num_tickets_owned?.value
        });
        setShowSellTicketModal(true);
    };

    const handleCloseSellTicketModal = () => {
        setShowSellTicketModal(false);
    };

    useEffect(() => {
        setUserMartetcap({ value: 0, isLoading: true })
        if (!profileData?.data?.profile?.fid) return
        getUserMarketCap(profileData?.data?.profile?.fid).then((res) => {
            setUserMartetcap({
                value: res,
                isLoading: false
            })
        })
    }, [profileData?.data?.profile?.fid])

    const refetchAfterBuy = () => {
        profileHoldersRefetch();
        profileRefetch();
    }


    const tokenData = myPointsBalances?.data?.filter((token) => token?.currency_points?.currency_config?.currency === selectedCurrency)
    const { display_allowance_remaining, display_todays_allowance, currency_points } = tokenData?.length != 0 && tokenData != undefined ? tokenData[0] : {
        display_allowance_remaining: null,
        display_todays_allowance: null,
        currency_points: null
    }

    if (!isUserExists) {
        return null
    }
    return (
        <div className='h-screen lg:ml-2 bg-white dark:bg-[#121212] flex flex-col'>
            <div>
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </div>
            {!searchTerm && (
                <>
                    <div className='mx-2'>
                        {pathname.includes('/profile') &&
                            <>
                                <div>
                                    <SharesChart />
                                </div>
                                {!profileDataLoading &&
                                    <div className='flex flex-row md:flex-row justify-center gap-3 items-center w-full'>
                                        <button disabled={profileData?.data?.view_user_context?.num_tickets_owned?.value == 0} onClick={handleOpenSellTicketModal} className={`bg-gray-500 dark:bg-background border dark:border-primary font-[bungee] w-full text-gray-200 dark:text-onBackgroundII h-[42px] ${profileData?.data?.view_user_context?.num_tickets_owned?.value == 0 ? '' : 'custom-hover hover:text-white'}`}>Sell</button>
                                        <button onClick={handleBuyModal} className='bg-primary text-white dark:text-onPrimary font-[bungee] w-full h-[42px] custom-hover'>Buy Ticket</button>
                                    </div>}
                                {profileDataLoading ?
                                    <UserPointsSkeleton />
                                    :
                                    <div className='flex flex-col justify-between mt-5 h-[200px]'>
                                        <div className='bg-gray-300 dark:bg-[#202022]/20 w-full border border-outline h-[60px] min-w-[130px] flex flex-row items-center justify-between px-4 rounded-sm'>
                                            <p className='prose-LabelMedium text-subTxtOnLightBg dark:text-onBackgroundIII w-min'>Market Cap</p>
                                            <p className='prose-HeaderLarge text-txtOnLightBg dark:text-onBackground'>{userMarketcap?.isLoading ? <div className='bg-surface animate-pulse h-6 w-12' /> : parseFloat(userMarketcap?.value).toFixed(2)}</p>
                                        </div>
                                        <div className='bg-gray-300 dark:bg-[#202022]/20 w-full border border-outline h-[60px]  min-w-[130px  flex flexrowl items-center justify-between  px-4 rounded-sm'>
                                            <p className='prose-LabelMedium text-subTxtOnLightBg dark:text-onBackgroundIII w-min'>Tips Received</p>
                                            <p className='prose-HeaderLarge text-txtOnLightBg dark:text-onBackground'>{profileData?.data && profileData?.data?.tip_recieved?.value?.display_value || 0}</p>
                                        </div>
                                        <div className='bg-gray-300 dark:bg-[#202022]/20 w-full border border-outline h-[60px]  min-w-[130px]  flex flex-row items-center justify-between  px-4 rounded-sm'>
                                            <p className='prose-LabelMedium text-subTxtOnLightBg dark:text-onBackgroundIII w-min'>Top Holders</p>
                                            <div className='text-txtOnLightBg dark:text-onBackground'>
                                                <div className='h-9 flex items-center gap-1'>
                                                    {
                                                        profileHoldersLoading ? <>
                                                            <div className='w-9 max-w-9 h-9 aspect-square rounded-full animate-pulse bg-surface/50 dark:bg-surface' />
                                                            <div className='w-9 max-w-9 h-9 aspect-square rounded-full animate-pulse bg-surface/50 dark:bg-surface' />
                                                            <div className='w-9 max-w-9 h-9 aspect-square rounded-full animate-pulse bg-surface/50 dark:bg-surface' />
                                                        </> :
                                                            profileHolder?.length < 1 ? <p>0</p>
                                                                : profileHolder && profileHolder?.length > 0 && profileHolder?.slice(0, 3)?.map((holder, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className='w-9 max-w-9 h-9 aspect-square rounded-full'>
                                                                            <ProfileHoverCard username={holder?.trader?.username}>
                                                                                <img src={holder?.trader?.pfp_url || holder?.trader?.cdn_url} alt="holder" className='w-9 max-w-9 h-9 rounded-full' />
                                                                            </ProfileHoverCard>
                                                                        </div>
                                                                    )
                                                                })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }

                    </div>
                    <div>
                        {!showUserChart && <>
                            {showUserPoints && <div className='mt-6 mx-2 border-b dark:border-outlineIII pb-3'>
                                <UserPoints />
                                {tokenData && tokenData[0]?.currency_points?.currency_config?.tippable && tokenData[0]?.currency_points?.currency_config?.currency != "WILD" ? <>
                                    <div className='flex justify-between items-center prose-TitleLarge text-txtOnLightBg dark:text-onBackground mt-6 mx-3 cursor-pointer'>My Allowance {showAllowance ? <CaretUp onClick={() => setShowAllowance((prev) => !prev)} className='cursor-pointer' /> : <CaretDown onClick={() => setShowAllowance((prev) => !prev)} className='cursor-pointer' />}</div>
                                    {showAllowance && <div className='mt-2 mx-5'>
                                        <div className='prose-HeaderLarge text-btnColorOnLightBg dark:text-primary'> {display_todays_allowance || '-'} {currency_points?.currency_config?.currency || ''}</div>
                                        <div className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundIII'>{display_allowance_remaining || '-'} remaining</div>
                                    </div>
                                    }
                                </>
                                    : null
                                }
                            </div>
                            }
                        </>
                        }
                    </div>

                    <div className='pb-2 mt-4 mx-2 flex flex-col grow overflow-hidden'>
                        <div className='flex justify-between items-center prose-TitleLarge text-txtOnLightBg dark:text-onBackground mx-3'>My Quests {showQuests ? <CaretUp onClick={() => setShowQuests((prev) => !prev)} className='cursor-pointer' /> : <CaretDown onClick={() => setShowQuests((prev) => !prev)} className='cursor-pointer' />}</div>
                        {showQuests &&
                            <div className={`overflow-y-auto grow removeScrollBar`}>
                                <QuestsTab showArenaNav={false} />
                            </div>
                        }
                    </div>
                    {isConnected ? (
                        <div className='my-4 mb-8 mx-4'>
                            <div className='prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundII flex justify-between items-center'>
                                <p>Connected account: <span className='text-txtOnLightBg dark:text-onBackground inline-flex'>{`${address?.slice(0, 4)}...${address?.slice(address?.length - 4, address?.length - 0)}`}</span></p>
                                <SignOut onClick={disconnect} className='cursor-pointer' />
                            </div>
                        </div>
                    ) :
                        <button onClick={() => open()} className='my-4 prose-BodyLarge text-subTxtOnLightBg  dark:hover:text-onBackground dark:text-onBackground  underline-offset-4 hover:text-subTxtOnLightBg hover:underline py-2'>Connect wallet</button>
                    }
                </>
            )}
            {showBuyModal && <CustomModal isOpen={showBuyModal} closeModal={handleCloseBuyModal} setShowTipModal={setShowBuyModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                <BuyModal selectedBuy={selectedBuy} closeModal={handleCloseBuyModal} refetch={refetchAfterBuy} />
            </CustomModal>}

            {showSellTicketModal &&
                <CustomModal isOpen={showSellTicketModal} closeModal={handleCloseSellTicketModal} modalPositionTop={'50'} translatePosition={"translate(-50%, -50%)"}>
                    <SellModal data={selectedSell} closeModal={handleCloseSellTicketModal} key={'1'} refetch={() => { }} />
                </CustomModal>
            }
        </div>
    )
}

export default RigthSideBar


const UserPointsSkeleton = () => {
    return <div className='flex justify-between mt-5 flex-col h-[200px]'>
        <div className='bg-surface h-[60px] w-full animate-pulse rounded-sm' />
        <div className='bg-surface h-[60px] w-full animate-pulse rounded-sm' />
        <div className='bg-surface h-[60px] w-full animate-pulse rounded-sm' />
    </div>
}