const HoldersSkeletonLoading = ({ i }) => {
    return (
        <div
            key={i} className="h-[45px] flex justify-between items-center px-5">
            <div className="flex flex-row items-center gap-2 w-full">
                <div className="flex items-start justify-center">
                    <div className="w-9 h-9 rounded-full animate-pulse bg-surface/50 dark:bg-surface" />
                </div>
                <div className="h-[45px] flex flex-col lg:flex-row justify-end lg:w-[400px]">
                    <div className="flex items-center gap-2 w-[60%]">
                        <h2 className="prose-BodyLarge animate-pulse bg-surface/50 dark:bg-surface w-16 h-4"></h2>
                    </div>
                    
                    <div className="flex items-center justify-center gap-[6px] mt-1 lg:mt-0 lg:w-full">
                        <div className="flex flex-row">
                            <div className={`w-4 h-4 rounded-full animate-pulse bg-surface/50 dark:bg-surface`} /> 
                            <div className={`w-4 h-4 rounded-full -ms-1 animate-pulse bg-surface/50 dark:bg-surface`} /> 
                        </div>
                        <p className="text-onBackgroundIII prose-BodyMedium animate-pulse w-16 h-4 bg-surface/50 dark:bg-surface"></p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-end gap-1">
                <p className="h-4 w-16 animate-pulse bg-surface/50 dark:bg-surface"></p>
                <p className="h-3 w-8 animate-pulse bg-surface/50 dark:bg-surface"></p>
            </div>
        </div>
    )
}
export default HoldersSkeletonLoading