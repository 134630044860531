import { ArrowClockwise, CaretDown, HandSwipeLeft, HandSwipeRight, Minus, Plus, X } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useNavigate } from 'react-router-dom';
import bgRays from '../../assets/images/tippingArena/bgRays.png';
import zero from '../../assets/images/tippingArena/zero.png';
import SwipeCard from '../../components/Cards/TippingArena/SwipeCard';
import TopHeader from '../../components/Sidebars/TopHeader';
import CustomModal from '../../components/UI/CustomModal';
import Spinner from '../../components/UI/Spinner';
import { getMyPointBalances, getTinderCasts } from '../../services/api';

import logo from '../../assets/images/logo.png';
import { useSelector } from 'react-redux';

const minTippingAmount = 1;

const TippingArena = () => {
    const navigate = useNavigate();

    const { selectedChannel } = useSelector((state) => state.app);

    const [tokenList, setTokenList] = useState({})
    const [modalSelectedToken, setModalSelectedToken] = useState('DEGEN')
    const [selectedToken, setSelectedToken] = useState('DEGEN')

    const [showCurrencyModal, setShowCurrencyModal] = useState(false);

    const inputRef = useRef(null);
    const [tipQuantity, setTipQuantity] = useState(100);

    const [confetti, setConfetti] = useState(false);
    const [confettiShown, setConfettiShown] = useState(false);

    const [items, setItems] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);
    const [backgroundText, setBackgroundText] = useState('');
    const [backgroundPosition, setBackgroundPosition] = useState('');
    const [tipLoadingQueue, setTipLoadingQueue] = useState([])

    useEffect(() => {
        const hasSeenTutorial = localStorage.getItem('hasSeenTutorial');
        if (!hasSeenTutorial) {
            setShowTutorial(true);
        }
    }, []);

    const getCards = async (channelId) => {
        setIsFetching(true);
        try {
            const response = await getTinderCasts(channelId);
            setItems(response);
        }
        catch (err) {
            console.log(err);
        }
        setIsFetching(false);
    };

    useEffect(() => {
        if(selectedChannel.id === 'custom_global')
            getCards();
        else
            getCards(selectedChannel.id);
    }, [selectedChannel]);

    const { data: myPointsBalances, isLoading: pointsBalancesLoading, refetch: refetchPointsBalance } = useQuery({
        queryKey: ['myPointsBalances'],
        queryFn: () => getMyPointBalances(true),
    });


    useEffect(() => {
        if (pointsBalancesLoading)
            return;

        let tokenList = {}
        myPointsBalances?.data?.forEach((token) => {
            if(token?.currency_points?.currency_config?.currency === 'WILD') return
            const tokenData = {
                currency: token?.currency_points?.currency_config?.currency,
                internal: token?.currency_points?.currency_config?.internal,
                daily_allowance: token?.currency_points?.todays_allowance,
                allowance_remaining: token?.currency_points?.allowance_remaining
            }
            tokenList[token?.currency_points?.currency_config?.currency] = tokenData
        })

        setTokenList(tokenList)
    }, [pointsBalancesLoading, myPointsBalances])

    useEffect(() => {
        if (pointsBalancesLoading)
            return;
        if (tokenList[`${selectedToken}`]?.allowance_remaining < tipQuantity)
            setTipQuantity(tokenList[`${selectedToken}`]?.allowance_remaining)
    }, [tokenList, pointsBalancesLoading, selectedToken]);

    useEffect(() => {
        if (!tokenList[`${selectedToken}`])
            return;
        if (tipQuantity <= tokenList[`${selectedToken}`]?.allowance_remaining)
            setTipQuantity(tipQuantity);
        else
            setTipQuantity(tokenList[`${selectedToken}`]?.allowance_remaining);

        setConfetti(false);

        if (tokenList[`${selectedToken}`]?.allowance_remaining === 0) {
            if (confettiShown)
                return;
            setConfettiShown(true)
            setTimeout(() => setConfetti(true), 1000);
        }
    }, [tokenList, selectedToken]);

    useEffect(() => {
        setConfettiShown(false);
    }, [selectedToken])

    const handleShowCurrencyModal = () => {
        setShowCurrencyModal(true)
    }
    const handleCloseCurrencyModal = () => {
        setShowCurrencyModal(false)
        setConfettiShown(false)
        setSelectedToken(modalSelectedToken)
    }

    const handleTokenSelect = (token) => {
        setModalSelectedToken(token);
    }


    const decrement = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (tipQuantity - 10 <= minTippingAmount)
            setTipQuantity(minTippingAmount);
        else
            setTipQuantity((prev) => parseInt(prev) - 10);
    };

    const increment = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (tipQuantity + 10 >= tokenList[`${selectedToken}`]?.allowance_remaining)
            setTipQuantity(tokenList[`${selectedToken}`]?.allowance_remaining);
        else
            setTipQuantity((prev) => parseInt(prev) + 10);
    };

    const handleTipInputValue = (e) => {
        if (e.target.value == '') {
            setTipQuantity(0)
            return
        };
        const value = parseInt(e.target.value);
        if (value <= tokenList[`${selectedToken}`]?.allowance_remaining && value >= 0) {
            setTipQuantity(value || 0);
        }
    };

    const handleOverlayClick = () => {
        setShowTutorial(false);
        localStorage.setItem('hasSeenTutorial', 'true');
    };

    return (
        <div className="flex-1 max-h-screen h-screen overflow-hidden relative flex flex-col bg-no-repeat bg-cover bg-[center] md:max-w-[430px]" style={{ backgroundImage: `url(${bgRays})` }}>
            {/* <img className='fixed top-0 left-0 w-full h-full -z-10' src={bgRays} alt="" /> */}

            {showTutorial && !pointsBalancesLoading && !isFetching && tokenList[`${selectedToken}`]?.allowance_remaining != 0 && (
                <div
                    className='fixed top-0 left-0 w-full h-full z-40 bg-background/70 flex items-center justify-between px-5'
                    onClick={handleOverlayClick}
                >
                    <div>
                        <HandSwipeLeft size={40} />
                        Swipe Left to Skip
                    </div>
                    <div className='flex flex-col items-end'>
                        <HandSwipeRight size={40} />
                        Swipe Right to Tip
                    </div>
                </div>
            )}

            <TopHeader PageTitle={'Tipping Arena'} />
            <div className='min-h-[78px] prose-BodyMedium text-center mx-5 pt-3'>
                <p className='mb-[6px] text-black dark:text-onBackgroundIII'>Remaining Allowance</p>
                <h2 className=' prose-DisplayMedium dark:text-primary text-maintextColorOnLightBg flex items-center justify-center'>{tokenList[`${selectedToken}`]?.allowance_remaining || 0} ${tokenList[`${selectedToken}`]?.currency} <CaretDown onClick={handleShowCurrencyModal} weight="bold" height="20" className="cursor-pointer" /></h2>
            </div>
            {
                pointsBalancesLoading ? <div className='min-h-80'><Spinner /></div> :
                    <>
                        <div className='flex items-center justify-center grow mx-5'>
                            <div className='relative mx-5 h-full max-h-[420px] min-w-full'>
                                {backgroundText && (
                                    <div
                                        className={`absolute px-3 top-0 ${backgroundPosition === 'left' ? 'left-0 bg-error text-white -rotate-2' : 'right-0 bg-primary text-black rotate-[4.85deg]'} z-10`}
                                    >
                                        <p className={`prose-DisplayMedium flex items-center`}>{backgroundText}</p>
                                    </div>
                                )}
                                {
                                    !isFetching && tipQuantity <= 0 && <div className='absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center bg-background/80 px-10 prose-TitleSmall'>
                                        Tip amount can't be zero
                                    </div>
                                }
                                {
                                    (tokenList[`${selectedToken}`]?.allowance_remaining < minTippingAmount) && tipLoadingQueue?.length != 0 && <div className='absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center bg-background/80 px-10 prose-TitleSmall'>Evaluating...</div>
                                }
                                {
                                    tokenList[`${selectedToken}`]?.daily_allowance === 0 && !pointsBalancesLoading && <div className='absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center bg-background/90 px-10 prose-TitleSmall'>You currently don't have any daily allowance for this token</div>

                                }
                                {
                                    // selectedTokenData?.daily_allowance !== 0 && (tokenList[`${selectedToken}`]?.allowance_remaining === 0) && !pointsBalancesLoading && tipLoadingQueue?.length == 0 && <div className='absolute z-20 top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-primary text-onPrimary px-10 prose-TitleSmall rounded-lg'>
                                    //     <p>Yay! You tipped all your daily allowance for ${selectedTokenData?.currency} today</p>
                                    //     <p>Tap to share proof of zero allowance with your freinds</p>
                                    // </div>

                                }
                                {
                                    tokenList[`${selectedToken}`]?.daily_allowance !== 0 && (tokenList[`${selectedToken}`]?.allowance_remaining === 0) && !pointsBalancesLoading && tipLoadingQueue?.length == 0 && <div className='absolute z-20 top-0 left-0 w-full h-full bg-primary flex items-start justify-center max-h-[420px] rounded-lg overflow-hidden'>
                                        {/* <X size={24} className='absolute top-16 right-4' role='button' onClick={() => navigate(`/points`)} /> */}
                                        <div className='relative w-full h-full'>
                                            {confetti && <ConfettiExplosion zIndex={50} className='absolute top-0 left-1/2' />}
                                            <img src={zero} alt="" className='w-full h-full object-contain' />
                                        </div>

                                        <div className={`absolute bottom-0 mx-5 text-black`}>
                                            <h1 className='prose-DisplaySmall'>Proof of zero Allowance</h1>
                                            <p className='mb-3 prose-TitleMedium'>Yay! Congratulations on your tipping spree, come back tomorrow for more! </p>

                                            {/* <button className='w-full h-10 flex bg-background justify-center items-center font-title text-primary gap-[10px]'>
                                            <img src={farcasterIcon} alt="" />
                                            <p>Share Cast</p>
                                        </button> */}
                                        </div>
                                    </div>
                                }
                                {

                                }
                                {
                                    isFetching || pointsBalancesLoading ? <div className='h-full flex items-center justify-center'><span className='prose-HeaderLarge text-black dark:text-onBackground'>Loading...</span></div> :


                                        <div className="flex justify-center items-center h-full min-h-44 max-h-[420px]">
                                            <div className="w-full h-full relative">
                                                <div className="absolute top-0 bottom-0 flex flex-col w-full">
                                                    <p className="text-onBackgroundIII text-center">You have swiped through all the cards.</p>
                                                    <div className='h-full flex items-center justify-center flex-col'>
                                                        <button className='flex items-center justify-center flex-col dark:text-white text-black' onClick={() => { getCards(selectedChannel.id) }}>
                                                            <ArrowClockwise size={40} />
                                                            Refresh
                                                        </button>
                                                    </div>
                                                </div>
                                                {items?.length > 0 && (
                                                    items?.map((card, index) => (
                                                        <SwipeCard key={index} data={card} tipValue={tipQuantity} setTokenList={setTokenList} setBackgroundText={setBackgroundText} setBackgroundPosition={setBackgroundPosition} setTipLoadingQueue={setTipLoadingQueue} selectedCurrency={selectedToken} isInternal={tokenList[`${selectedToken}`]?.internal} />
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div>
                            <div className='prose-TitleLarge text-center pt-4 text-black dark:text-onBackground'>
                                Amount
                            </div>
                            <div className='flex flex-col justify-center items-center mt-1 h-[68px]'>
                                <div className='flex items-center gap-6'>
                                    <button onClick={(e) => { decrement(e) }} className='border border-outline bg-outline size-7 flex justify-center items-center text-black dark:text-onBackground'><Minus size={20} /></button>
                                    <input
                                        onChange={(e) => { handleTipInputValue(e) }}
                                        type='number'
                                        value={tipQuantity == 0 ? '' : tipQuantity}
                                        min={0}
                                        max={tokenList[`${selectedToken}`]?.allowance_remaining}
                                        ref={inputRef}
                                        placeholder='0'
                                        inputMode="decimal"
                                        style={{ "MozAppearance": "textfield" }}
                                        className="font-['Bungee'] max-w-[200px] text-[40px] text-center leading-[83.2px] border-none outline-none focus:border-none bg-transparent text-black dark:text-onBackground [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                    />
                                    <button onClick={(e) => { increment(e) }} className='border border-outline bg-outline size-7 flex justify-center items-center text-black dark:text-onBackground'><Plus size={20} /></button>
                                </div>
                            </div>
                            <div className='flex items-center justify-center mt-4 mb-8'>
                                <button className='flex justify-center items-center size-16 rounded-full bg-gray-200 dark:bg-outlineII text-black dark:text-onBackground'
                                    onClick={() => navigate(`/points`)}
                                >
                                    <X size={32} />
                                </button>
                            </div>
                        </div>
                    </>}
            {showCurrencyModal &&
                <CustomModal isOpen={showCurrencyModal} closeModal={() => { setShowCurrencyModal(false) }} modalPositionTop={'50'}>
                    <div className='bg-background min-w-[390px] border border-outline rounded-sm p-5'>
                        <div className='flex justify-between items-center'>
                            <p className='prose-DisplaySmall text-onBackground'>Your Token</p>
                            <X onClick={() => setShowCurrencyModal(false)} fill="#ffffffa6" className="cursor-pointer" />
                        </div>
                        <div className='mt-4'>
                            {myPointsBalances?.data?.map((token, i) => {
                                if(token?.currency_points?.currency_config?.currency === 'WILD') return
                                const todaysAllowance = token?.currency_points?.todays_allowance
                                const currency = token?.currency_points?.currency_config?.currency;
                                const tokenLogo = token?.currency_points?.currency_config?.image
                                return (
                                    <div key={i} onClick={() => { todaysAllowance > 0 && handleTokenSelect(currency); }} className={`flex justify-between items-center px-2 py-2 rounded-sm mb-2 ${todaysAllowance > 0 ? modalSelectedToken === currency ? 'bg-surface border-outline' : 'border-transparent' : 'opacity-30'} border cursor-pointer hover:bg-white/5`}>
                                        <div className='flex items-center gap-1'>
                                            <img src={tokenLogo || logo} alt="" className='size-8 rounded-full' />
                                            <p className='prose-BodyLarge text-onBackground'>{currency}</p>
                                        </div>
                                        <div className='flex flex-col justify-center items-end'>
                                            <p className='prose-BodyLargeBold text-onBackground'>{tokenList[`${currency}`]?.allowance_remaining}/{token?.display_todays_allowance}</p>

                                            <p className='prose-LabelSmall text-onBackgroundII'>remaining</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <button onClick={handleCloseCurrencyModal} className='font-[bungee] bg-primary opacity-90 hover:opacity-100 text-onPrimary w-full py-2 rounded-sm mt-3'>
                            Switch to {modalSelectedToken}
                        </button>
                    </div>
                </CustomModal>
            }
        </div>
    );
};

export default TippingArena;