import React, { useCallback, useState, useRef } from 'react'
import { useQuery } from '@tanstack/react-query';
import Logo from '../../assets/images/wildcard_logo.svg'
import { usePrivy } from '@privy-io/react-auth';
import Spinner from '../../components/UI/Spinner';
import { checkUsernameStatus, getUsernameAvailable, getUsernameRegisterPayload, registerUsername, updateProfile } from '../../services/api';
import { debouncefn } from '../../utils/helper';
import { useDispatch } from 'react-redux';
import { displaySnackbar } from '../../features/thunkMiddleware';
import useAccount from '../../hooks/account';
import { Camera, X } from '@phosphor-icons/react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const usernameRegex = /^[a-z0-9][a-z0-9-]{0,15}$/;
const defaultImg = 'https://res.cloudinary.com/dm5fwvelv/image/upload/v1728161192/avatar_deyjlv.png';

export const EditProfileModal = ({ closeModal, profileRefetch = () => { } }) => {
    const { authenticated } = usePrivy();
    const { data: usernameStatus, isLoading: usernameStatusLoading, refetch: refetchUsernameStatus } = useQuery(
        {
            queryKey: ['username_status'],
            queryFn: () => checkUsernameStatus(),
            enabled: authenticated,
        }
    )
    const username = usernameStatus?.data?.username;

    return (
        <div className='bg-surface w-full relative px-4'>
            <div className='w-full px-4 flex justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                {usernameStatusLoading || usernameStatus == null ? (
                    <Spinner />
                ) : (
                    username == null ? (
                        <SignTypedDataButton refetchUsernameStatus={refetchUsernameStatus} closeModal={closeModal} profileRefetch={profileRefetch} />
                    ) : (
                        <UpdateProfileDetails data={usernameStatus?.data} closeModal={closeModal} profileRefetch={profileRefetch} />
                    )
                )}
            </div>
        </div>
    );
}

export const SignTypedDataButton = ({ refetchUsernameStatus, closeModal }) => {
    const { signTypedData, authenticated } = usePrivy();
    const [username, setUsername] = useState('');
    const [inputUsername, setInputUsername] = useState('');
    const debouncedSetUsername = useCallback(
        debouncefn((value) => setUsername(value), 300),
        []
    );
    const { data, isLoading: usernameAvailableLoading } = useQuery(
        {
            queryKey: ['username_available', username],
            queryFn: () => getUsernameAvailable(username),
            enabled: authenticated && username?.length > 0,
        }
    )

    const handleRegisterParams = async () => {
        const data = await getUsernameRegisterPayload(username);
        const params = data.data;
        const registerUiConfig = {
            title: `Register ${username} as your Farcaster username`,
            description: 'Sign payload to authorize creation of your account on Farcaster',
            buttonText: 'Sign',
        };
        const signature = await signTypedData(params, registerUiConfig);
        await registerUsername({
            params,
            signature,
        });
        refetchUsernameStatus();
    }


    const usernameAvailable = data?.data?.available;

    const isUsernameValid = usernameRegex.test(inputUsername);

    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setInputUsername(value);
        if (isUsernameValid) {
            debouncedSetUsername(value);
        }
    };

    return (
        <div className="space-y-4 bg-surface p-5 rounded-md">
            <button
                onClick={closeModal}
                className='absolute top-2 text-white'
            >
                <X size={24} className='cursor-pointer' />
            </button>
            <h2 className='text-center my-2 prose-DisplaySmall'>Profile Update</h2>
            <div className='w-full flex flex-col items-center bg-surface p-5'>
                {isUsernameValid && !usernameAvailableLoading && !usernameAvailable && (username === inputUsername) && (
                    <div className="text-red-500 mb-2">
                        Username already taken
                    </div>
                )}
                {!isUsernameValid && inputUsername?.length > 0 && (
                    <div className="text-red-500 mb-2">
                        Invalid username. Please use only lowercase letters, numbers, and dashes.
                    </div>
                )}
                <div className="relative w-full lg:w-[400px] mb-4">
                    <input
                        type="text"
                        value={inputUsername}
                        onChange={handleUsernameChange}
                        placeholder="Enter Farcaster username"
                        className='p-2 w-full border rounded outline-0 focus:border-primary focus:ring-primary'
                    />
                    {isUsernameValid && usernameAvailableLoading && (
                        <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                            <div className="loading loading-spinner loading-md bg-primary" />
                        </div>
                    )}
                </div>
                <button
                    onClick={handleRegisterParams}
                    disabled={usernameAvailableLoading || !usernameAvailable || !username || !isUsernameValid}
                    className='bg-[#B4F6A5] text-onPrimary text-sm px-3 flex justify-center items-center prose-DisplayLarge py-2 w-full lg:w-[400px] disabled:opacity-50'
                >
                    Register your Farcaster username
                </button>
            </div>
        </div>
    )
}

const profileKeys = [
    {
        key: 'username',
        label: 'Username',
        type: 'text',
        readonly: true,
    },
    {
        key: 'profile_bio',
        label: 'Bio',
        type: 'textinput',
    },
    {
        key: 'display_name',
        label: 'Display Name',
        type: 'text',
    },
    {
        key: 'website',
        label: 'Website',
        type: 'text',
    },
]

export const UpdateProfileDetails = ({ data, closeModal, profileRefetch }) => {
    const { handleSignerValidate } = useAccount();
    const dispatch = useDispatch();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [formData, setFormData] = useState({ pfp_url: data.pfp_url, ...data });
    const fileInputRef = useRef(null);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { user } = useAccount();
    const { hasSignedKeyToken } = useAccount();
    const usernameRegex = /^[a-z0-9][a-z0-9-]{0,15}$/;
    const isDefaultUsername = !!(user?.farcaster_details?.username && !usernameRegex.test(user.farcaster_details.username));

    const uploadImageToCloudinary = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'lloxxrqn');
            formData.append('cloud_name', 'dm5fwvelv');

            const response = await fetch('https://api.cloudinary.com/v1_1/dm5fwvelv/image/upload', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload image');
            }

            const data = await response.json();
            if (!data.url) {
                throw new Error('Image URL not returned');
            }

            return data.url;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const handleAvatarChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const cloudinaryUrl = await uploadImageToCloudinary(file);
                setFormData(prev => ({ ...prev, pfp_url: cloudinaryUrl }));
                await handleProfileUpdate([{ key: 'pfp_url', label: 'Profile Picture', value: cloudinaryUrl }]);
                profileRefetch();
            } catch (error) {
                console.error('Failed to upload image:', error);
                dispatch(displaySnackbar('Failed to upload image'));
            }
        }
    };

    const handleProfileUpdate = async (updates) => {
        const valid = await handleSignerValidate();
        if (!valid) return;

        setButtonLoading(true);
        const failedUpdates = [];

        for (const { key, label, value } of updates) {
            try {
                await updateProfile({ key, value });
            } catch (err) {
                failedUpdates.push(label);
                console.log(err);
            }
        }

        if (failedUpdates.length > 0) {
            dispatch(displaySnackbar(`Failed to update: ${failedUpdates.join(', ')}`));
        } else {
            if (!isDefaultUsername && pathname === '/invite') {
                closeModal();
                navigate('/')
                return;
            }
            dispatch(displaySnackbar('Profile updated successfully'));
        }

        setButtonLoading(false);
        profileRefetch();
    };

    const handleSave = () => {
        if (!hasSignedKeyToken) {
            closeModal();
            return;
        }

        const updates = profileKeys
            .filter(({ key }) => formData[key] !== data[key])
            .map(({ key, label }) => ({ key, label, value: formData[key] }));

        if (updates.length > 0) {
            handleProfileUpdate(updates);
        }
    };

    return (
        <div className="space-y-4 bg-surface p-5 rounded-md">
            <button
                onClick={closeModal}
                className='absolute top-2 text-white'
            >
                <X size={24} className='cursor-pointer' />
            </button>
            <h2 className='text-center my-2 prose-DisplaySmall'>Profile Update</h2>
            <div className='flex items-center mt-4 gap-4'>
                <div className='relative group '>
                    <img
                        src={formData.pfp_url || defaultImg}
                        alt="Avatar"
                        className='w-16 h-16 rounded-full object-cover border border-primary'
                    />
                    <div className='absolute inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity'>
                        <Camera size={24} className='text-white' />
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        className='absolute inset-0 opacity-0 cursor-pointer'
                        onChange={handleAvatarChange}
                        ref={fileInputRef}
                    />
                </div>
                <p
                    className='prose-BodySmall text-primary cursor-pointer'
                    onClick={() => fileInputRef.current.click()}
                >
                    Upload Photo
                </p>
            </div>
            {profileKeys.map(({ key, label, type, readonly }) => (
                <div key={key} className="flex flex-col mb-4">
                    <label htmlFor={key} className="mb-1 text-sm font-medium">
                        {label}
                    </label>
                    {type === 'textinput' ? (
                        <textarea
                            id={`${key}-input`}
                            value={formData[key]}
                            disabled={readonly}
                            onChange={(e) => setFormData(prev => ({ ...prev, [key]: e.target.value }))}
                            className={`flex-grow px-3 py-2 border ${readonly && 'cursor-not-allowed'} rounded-md focus:outline-none focus:border-primary focus:ring-primary`}
                            rows="4"
                        />
                    ) : (
                        <input
                            id={`${key}-input`}
                            type={type}
                            value={formData[key]}
                            disabled={readonly}
                            onChange={(e) => setFormData(prev => ({ ...prev, [key]: e.target.value }))}
                            className={`flex-grow px-3 py-2 border ${readonly && 'cursor-not-allowed'} rounded-md focus:outline-none focus:border-primary focus:ring-primary`}
                        />
                    )}
                </div>
            ))}
            <button
                onClick={handleSave}
                disabled={buttonLoading}
                className='bg-[#B4F6A5] text-onPrimary text-xl flex justify-center items-center prose-DisplayLarge py-2 w-full lg:w-[400px] disabled:opacity-50'
            >
                {buttonLoading ? 'Saving...' : 'Save'}
            </button>
        </div>
    );
}

export default EditProfileModal
