/* global BigInt */

import { ArrowLeft } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import BaseLogo from '../../assets/svg/base_logo_fund.svg';


import { usePrivy } from '@privy-io/react-auth';
import { useDispatch } from 'react-redux';
import { displaySnackbar, TippingMoxie } from '../../features/thunkMiddleware';
import useBuyNFTTransaction from '../../hooks/buySellNFT';
import useMoxieBalance from '../../hooks/useMoxieBalance';
import { useQuery } from '@tanstack/react-query';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';

const ethereumAddress = /^0x[a-fA-F0-9]{40}$/gm;

const WithdrawModal = ({ walletDetail, closeModal, refetch, isWithdrawingMoxie }) => {
    const { withdrawEth } = useBuyNFTTransaction()
    const { getUserPrivyMoxieBalance, getMoxieBalance, withdrawPrivyMoxie } = useMoxieBalance()
    const [amount, setAmount] = useState(0);
    const { ready, authenticated, user, exportWallet } = usePrivy();
    const dispatch = useDispatch()
    const { address, isConnected } = useWeb3ModalAccount()

    const isAuthenticated = ready && authenticated;

    const hasEmbeddedWallet = !!user.linkedAccounts.find(
        (account) => account.type === 'wallet' && account.walletClient === 'privy',
    );

    const [manualWalletAddress, setManualWalletAddress] = useState()

    const {data: moxieBalance, isLoading: isLoadingMoxieBalance} = useQuery({
        queryKey: ['moxieBalance'],
        queryFn: getUserPrivyMoxieBalance
    })

    const amountToWithdraw = isWithdrawingMoxie ? parseFloat(moxieBalance) : (walletDetail?.eth_holdings?.value)?.toFixed(4) || 0;

    const stepPercent = 5;
    const minEthValue = amountToWithdraw * stepPercent / 100;
    const stepAmount = amountToWithdraw * stepPercent / 100

    useEffect(() => {
        setAmount(minEthValue)
    }, [amountToWithdraw, minEthValue])

    const handleWithdraw = async (amount) => {
        if (manualWalletAddress == "") {
            dispatch(displaySnackbar('Please enter a wallet address'))
            return
        }

        if (!ethereumAddress.test(manualWalletAddress)) {
            dispatch(displaySnackbar('Please enter a valid wallet address'))
            return
        }
        if (parseFloat(amount) <= 0) {
            dispatch(displaySnackbar('Please enter an amount greater than 0'))
            return
        }

        if(isWithdrawingMoxie){
            dispatch(TippingMoxie(true))
            closeModal()

            const response = await withdrawPrivyMoxie(parseFloat(amount), manualWalletAddress)
            if(response){
                await getUserPrivyMoxieBalance()
                await getMoxieBalance()
                dispatch(displaySnackbar('Withdrawal successful'))
                dispatch(TippingMoxie(false))
            }
            return
        }

        const response = withdrawEth(parseFloat(amount), manualWalletAddress)
        response.then((res) => {
            if (res?.confirmations === 1) {
                dispatch(displaySnackbar('Withdrawal successful'))
                refetch()
            }
            closeModal()
        })
    }

    useEffect(() => {
        // if (isConnected) {
        setAmount(amountToWithdraw / 2)
        // }
    }, [])

    const handleInput = (e) => {
        if (e.target.value < amountToWithdraw)
            setAmount(e.target.value)
        else
            setAmount(amountToWithdraw)
    }

    return (
        <div className={`bg-white dark:bg-background p-6 ${isWithdrawingMoxie ? "min-w-full md:min-w-[500px]" : "w-fit"}`}>
            <ArrowLeft onClick={closeModal} className='fill-black dark:fill-white cursor-pointer'/>
            <div className='prose-HeaderLarge text-txtOnLightBg dark:text-onBackground mt-4'>
                {isWithdrawingMoxie ? <p>Withdraw <span className='text-[#A980FC]'>MOXIE</span></p> :
                    <p>Withdraw YOUR <span className=' text-primary'>WILD</span> WITH ETH ON <span className='text-[#0029FF] inline-flex items-center gap-2'>BASE <img src={BaseLogo} alt='base' className='size-6' /></span></p> 
                }
            </div>

            <div className='bg-gray-300 dark:bg-surface p-4 rounded-md mt-6'>
                <h2 className='prose-HeaderMedium text-txtOnLightBg dark:text-onBackground'>Transfer manually</h2>
                <div className='h-1 w-full my-3 border-b-[2px] dark:border-outline' />
                <div className='prose-BodySmall md:prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII'>
                    <input onChange={(e) => { setManualWalletAddress(e.target.value) }} placeholder="Enter wallet address..." className='w-full text-txtOnLightBg dark:text-onBackground placeholder:text-subTxtOnLightBg bg-gray-400 dark:bg-background border dark:border-outline py-2 focus:ring-0 outline-none px-2' />
                </div>
                {/* {manualWalletAddress && (
                    <> */}
                <p className='prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundII mt-8'>Set Amount</p>
                <div className='flex items-center justify-center'>

                    <input
                        onChange={(e) => { handleInput(e) }}
                        type='number'
                        value={Math.round(amount * 10000000) / 10000000}
                        placeholder={minEthValue}
                        style={{ "MozAppearance": "textfield" }}
                        className="font-['Bungee'] max-w-[260px] text-[40px] text-center leading-[83.2px] outline-none text-txtOnLightBg dark:text-onBackground [appearance:textfield] bg-transparent [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    />
                </div>
                <div className="flex mt-2 items-center slidecontainer relative mb-4">
                    <input
                        type="range"
                        max={`${amountToWithdraw}`}
                        min={`${minEthValue}`}
                        step={`${stepAmount}`}
                        value={amount}
                        onChange={(e) => { setAmount(e.target.value) }}
                        onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                        className="w-full mr-4  accent-primary"
                    />
                    <div className='absolute left-0 prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundIII top-3'>{parseFloat(minEthValue)?.toFixed(4)}</div>
                    <div className='absolute right-0 prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundIII top-3'>{!isWithdrawingMoxie ? amountToWithdraw : isLoadingMoxieBalance ? <div className='bg-surface h-4 w-12 rounded-sm'/> : amountToWithdraw}</div>
                </div>
                <button disabled={amount == 0} onClick={() => { handleWithdraw(amount) }} className={`px-3 py-2 mt-4 prose-HeaderLarge text-white dark:text-onPrimary uppercase w-full ${amount == 0 ? ' opacity-60 bg-primary' : ' bg-primary'}`}>Withdraw ${amount} {isWithdrawingMoxie ? "Moxie" : "ETH"}</button>
                <p className='text-center prose-LabelLarge my-2 text-subTxtOnLightBg dark:text-onBackgroundIII'>or</p>
                <button onClick={exportWallet} disabled={!isAuthenticated || !hasEmbeddedWallet} className={`px-3 py-2 mt-2 prose-HeaderLarge text-white dark:text-onPrimary uppercase w-full  bg-primary`}>
                    Export my wallet
                </button>
                {/* </>
                )} */}
            </div>

            {/* <div className='text-center w-full my-5 prose-BodyLargeBold text-onBackgroundII'>or</div> */}

            {/* <div className='bg-surface p-4 rounded-md'>
                <h2 className='prose-HeaderMedium text-onBackground'>Withdraw to connected wallet</h2>
                <div className='h-1 w-full my-3 border-b-[2px] border-outline'/>
                {isConnected && (
                    <>
                        <div className='prose-BodySmallBold text-onBackgroundII flex justify-between items-center'>
                            <p>Connected account: <span className='text-onBackground inline-flex'>{`${address.slice(0, 4)}...${address.slice(address.length - 4, address.length - 0)}`}</span></p>
                            <SignOut onClick={disconnect}/>
                        </div>
                        <p className='prose-BodyMediumBold text-onBackgroundII mt-8'>Set Amount</p>
                        <div className="flex mt-2 items-center slidecontainer relative">
                            <input
                                type="range"
                                max={`${amountToWithdraw}`}
                                min={`${minEthValue}`}
                                step={`${stepAmount}`}
                                value={amount}
                                onChange={(e) => { setAmount(e.target.value) }}
                                onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                                className="w-full mr-4 accent-primary"
                            />
                            <div className='absolute left-0 prose-BodySmallBold text-onBackgroundIII top-3'>{parseFloat(minEthValue)?.toFixed(4)}</div>
                            <div className='absolute right-0 prose-BodySmallBold text-onBackgroundIII top-3'>{amountToWithdraw}</div>
                        </div>
                    </>
                )}
                <button onClick={() => {handleWithdraw(amount)}} className={`bg-primary px-3 py-2 ${isConnected ? "mt-8" : "mt-4"} prose-HeaderLarge text-onPrimary uppercase min-w-[250px]`}>{!isConnected ? "Connect Wallet" : `Fund ${amount} ETH`}</button>
            </div> */}
        </div>
    )
}

export default WithdrawModal
