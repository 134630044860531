import { ArrowSquareOut, Link, X } from '@phosphor-icons/react';
import React, { useState, useEffect } from 'react';
import useCastAction from '../../../../../hooks/signer';
import { useDispatch } from 'react-redux';
import { displaySnackbar } from '../../../../../features/thunkMiddleware';
import { useLocation } from 'react-router-dom';
import { useDisconnect, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { BrowserProvider } from 'ethers-v6';
import { getTokenFromUrl } from './utils';
import * as viemChains from "viem/chains";
import { createClient, reservoirChains } from "@reservoir0x/reservoir-sdk";
import {
    createPublicClient,
    createWalletClient,
    hexToBigInt,
    http,
    parseAbi,
} from "viem";
import { useSelector } from 'react-redux';


const FrameCard = ({ embed, cast, castUser, setFramesData, fromCastModal }) => {
    const { handleFrame } = useCastAction()
    const { buttons, image, input } = embed;
    const [isLoading, setIsLoading] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [videoUrl, setVideoUrl] = useState(null);
    const { open, } = useWeb3Modal();
    const { text, show } = useSelector(state => state?.app?.snackBar)
    const { address, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()
    const [showAlert, setShowAlert] = useState({ show: false, text: '' });

    const handleFrameBtnClick = async (data, btn) => {
        setIsLoading(true);
        if (btn.action_type == 'link') return;

        if ((btn.action_type === 'mint' || btn.action_type === 'tx') && !isConnected) {
            await open();
            return;
        }

        if (btn.action_type == 'mint') {

            const { namespace, chainId, contractAddress, tokenId } = getTokenFromUrl(btn.target);

            if (namespace !== 'eip155') {
                dispatch(displaySnackbar('Invalid token URL'));
                setIsLoading(false);
                return;
            }

            const viemChain = Object.values(viemChains).find(
                (chain) => chain.id === chainId
            );

            const reservoirChain = [...Object.values(reservoirChains)].find(
                (chain) => chain.id === chainId
            );

            if (!viemChain || !reservoirChain) {
                dispatch(displaySnackbar('Invalid chain ID'));
                setIsLoading(false);
                return;
            }

            const publicClient = createPublicClient({
                chain: viemChain,
                transport: http(),
            });

            try {


                const ERC1155_ERC165 = "0xd9b67a26";
                const ERC721_ERC165 = "0x80ac58cd";

                async function supportsInterface(interfaceId) {
                    return await publicClient
                        .readContract({
                            address: contractAddress,
                            abi: parseAbi([
                                "function supportsInterface(bytes4 interfaceID) external view returns (bool)",
                            ]),
                            functionName: "supportsInterface",
                            args: [interfaceId],
                        })
                        .catch((err) => {
                            console.error(err);
                            return false;
                        });
                }

                // Get token type
                const [isERC721, isERC1155] = await Promise.all([
                    supportsInterface(ERC721_ERC165),
                    supportsInterface(ERC1155_ERC165),
                ]);

                let buyTokenPartial = { token: undefined, collection: undefined };
                if (isERC721) {
                    buyTokenPartial = { collection: contractAddress };
                } else if (isERC1155) {
                    buyTokenPartial = { token: `${contractAddress}:${tokenId}` };
                } else {
                    buyTokenPartial = { collection: contractAddress };
                }

                // Create reservoir client with applicable chain
                const reservoirClient = createClient({
                    chains: [{ ...reservoirChain, active: true }],
                });

                const wallet = createWalletClient({
                    account: address,
                    transport: http(),
                    chain: viemChain,
                });


                const res = await reservoirClient.actions.mintToken({
                    items: [{ ...buyTokenPartial, quantity: 1, fillType: "mint" }],
                    options: {
                    },
                    wallet,
                    precheck: true,
                    onProgress: () => void 0,
                });


                if (res === true) {

                    dispatch(displaySnackbar('Token minted!'));
                    setIsLoading(false);
                    return;
                }

                const mintTx = res.steps?.find((step) => step?.id === "sale")?.items?.[0];
                const txResponse = {
                    chainId: `eip155:${viemChain.id}`,
                    method: "eth_sendTransaction",
                    params: {
                        ...(mintTx && mintTx.data),
                        value: mintTx && mintTx.data && mintTx.data.value ? hexToBigInt(mintTx.data.value).toString() : '0',
                    },
                };

                const ethersProvider = new BrowserProvider(walletProvider)
                const signer = await ethersProvider.getSigner()

                // Switch network to the specified chain ID
                try {
                    await signer.provider.send("wallet_switchEthereumChain", [{ chainId: `0x${parseInt(chainId).toString(16)}` }]);
                } catch (switchError) {
                    // This error code indicates that the chain has not been added to MetaMask
                    if (switchError.code === 4902) {
                        dispatch(displaySnackbar('This network is not available in your wallet. Please add it manually.'));
                    } else {
                        dispatch(displaySnackbar('Failed to switch network. Please try again.'));
                    }
                    setIsLoading(false);
                    return;
                }

                const txn = await signer.sendTransaction({
                    to: txResponse.params.to,
                    value: txResponse.params.value,
                    data: txResponse.params.data
                })
                if (txn.hash) {
                    dispatch(displaySnackbar('Token minted!'));
                    setIsLoading(false);
                    return;
                }
            } catch (error) {
                dispatch(displaySnackbar('Mint failed!'));
                setIsLoading(false);
                return;
            }


            dispatch(displaySnackbar('Mint failed!'));
            setIsLoading(false);
            return;
        }

        if (btn.action_type !== 'post' && btn.action_type !== 'tx' && btn.action_type !== 'post_redirect') {
            dispatch(displaySnackbar('This action is not yet supported, coming soon!'));
            setIsLoading(false);
            return;
        };
        var body = {
            castHash: cast?.id,
            castFid: castUser?.fid,
            version: data?.version,
            title: data?.title,
            image: data?.image,
            button: btn,
            framesUrl: data?.frames_url,
            postUrl: data?.post_url,
            address: address,
            state: data?.state,
        }

        if (inputValue) {
            body['input'] = {
                "text": inputValue
            }
        }

        try {
            var response = await handleFrame(body);

            if (btn.action_type === 'tx') {
                var callData = response?.data?.data?.transaction_calldata;
                if (callData) {
                    const ethersProvider = new BrowserProvider(walletProvider)
                    const signer = await ethersProvider.getSigner()
                    if (callData?.method == 'eth_sendTransaction') {
                        var chainId = callData.chainId
                        if (!chainId.startsWith('eip155:')) {
                            dispatch(displaySnackbar('Invalid chain id'));
                            setIsLoading(false);
                            return;
                        }
                        chainId = chainId.split(':')[1]

                        // Switch network to the specified chain ID
                        try {
                            await signer.provider.send("wallet_switchEthereumChain", [{ chainId: `0x${parseInt(chainId).toString(16)}` }]);
                        } catch (switchError) {
                            // This error code indicates that the chain has not been added to MetaMask
                            if (switchError.code === 4902) {
                                dispatch(displaySnackbar('This network is not available in your wallet. Please add it manually.'));
                            } else {
                                dispatch(displaySnackbar('Failed to switch network. Please try again.'));
                            }
                            setIsLoading(false);
                            return;
                        }

                        const value = callData.params.value ?? "0";
                        const data = callData.params.data;
                        try {
                            const txn = await signer.sendTransaction({
                                to: callData.params.to,
                                value: value,
                                data: data
                            })
                            if (txn.hash) {
                                body["transaction"] = {
                                    "hash": txn.hash,
                                }
                                body["address"] = address
                                // body["button"]["action_type"] = "post"
                                response = await handleFrame(body);
                            }
                        } catch (error) {
                            dispatch(displaySnackbar('Failed to sign transaction!'));
                            console.error('Error handling frame button click:', error);
                            setIsLoading(false);
                            return;
                        }
                    } else if (callData?.method == 'eth_signTypedData_v4') {
                        var chainId = callData.chainId
                        if (!chainId.startsWith('eip155:')) {
                            dispatch(displaySnackbar('Invalid chain id'));
                            setIsLoading(false);
                            return;
                        }
                        chainId = chainId.split(':')[1]

                        // Switch network to the specified chain ID
                        try {
                            await signer.provider.send("wallet_switchEthereumChain", [{ chainId: `0x${parseInt(chainId).toString(16)}` }]);
                        } catch (switchError) {
                            // This error code indicates that the chain has not been added to MetaMask
                            if (switchError.code === 4902) {
                                dispatch(displaySnackbar('This network is not available in your wallet. Please add it manually.'));
                            } else {
                                dispatch(displaySnackbar('Failed to switch network. Please try again.'));
                            }
                            setIsLoading(false);
                            return;
                        }
                        const { domain, types, message, primaryType } = callData.params;
                        const filteredTypes = Object.fromEntries(
                            Object.entries(types).filter(([key]) => key === primaryType)
                        );

                        const signature = await signer.signTypedData(domain, filteredTypes, message);
                        body["transaction"] = {
                            "hash": signature,
                        }
                        body["address"] = address
                        response = await handleFrame(body);
                    }
                }
            } else if (btn.action_type === 'post_redirect') {
                // Open in new tab
                const redirectUrl = response?.data?.data?.redirect;

                window.open(redirectUrl, '_blank');
                return;
            }

            const newFramesData = response?.data?.data;
            if (newFramesData.frames_url) {
                setFramesData([newFramesData]);
                setInputValue('');
                setIsLoading(false);
            }

            if (newFramesData.message) {
                if (typeof newFramesData.message === 'string') {
                    setShowAlert({ show: true, text: newFramesData.message });
                    setIsLoading(false);
                } else {
                    setShowAlert({ show: true, text: newFramesData?.message?.message });
                    setIsLoading(false);
                }
                setTimeout(() => {
                    setShowAlert({ show: false, text: '' });
                }, 2000);
            }

        } catch (error) {
            console.error('Error handling frame button click:', error);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const fetchDrakulaVideo = async () => {
            if (embed?.frames_url?.startsWith('https://drakula.app/')) {
                const thumbnailBaseUrl = embed?.image?.split('/thumbnail')[0];
                const videoSourceUrl = `${thumbnailBaseUrl}/play_720p.mp4`;
                setVideoUrl(videoSourceUrl);
            }
        };
        fetchDrakulaVideo();
    }, [embed]);

    if (fromCastModal)
        return (
            <div className={`flex relative items-center h-full flex-col gap-4 p-2 w-full bg-white dark:bg-transparent rounded-sm`}>
                <div className='flex justify-center w-full'>
                    {videoUrl ?
                        <video src={videoUrl}
                            controls
                            autoPlay
                            muted
                            className='w-[49%]' /> :
                        <img src={image} alt="frame img" className='w-full object-cover' />
                    }
                </div>
                <div className='flex items-center justify-around flex-wrap w-full gap-2 flex-row'>
                    {buttons && buttons.map((btn, idx) => {
                        return (
                            <button key={idx} className={`flex-1 h-full w-full text-nowrap bg-surface/20 dark:bg-[#3f3f3f] border-outline flex justify-center items-center rounded-sm text-black dark:text-onBackground `}>
                                {btn?.title}
                            </button>
                        );
                    })}
                </div>
            </div>
        )
    return (
        <div className={`flex relative items-center h-full flex-col gap-4 p-2 w-full bg-white dark:bg-transparent rounded-sm`}>
            <div className={`${isLoading ? 'bg-gray-700 animate-pulse' : 'bg-transparent'} transition duration-500 w-full absolute h-full pointer-events-none`} />
            <div className={`flex ${showAlert.show && 'opacity-50'} justify-center w-full`}>
                {videoUrl ?
                    <video src={videoUrl}
                        controls
                        autoPlay
                        muted
                        className='w-[49%]' /> :
                    <img src={image} alt="frame img" className='w-full object-cover' />
                }
            </div>

            {input?.text && (
                <div className='w-full mb-2'>
                    <input
                        type="text"
                        placeholder={input.text}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className='w-full p-2 border border-outline rounded-sm'
                    />
                </div>
            )}
            {showAlert.show &&
                <div className="m-5 absolute top-4  flex flex-row items-center justify-between rounded-lg border border-warning bg-warning/50 px-4 py-3 dark:border-warning dark:bg-warning/50">
                    <div className="font-normal text-md text-background dark:text-onBackground">{showAlert.text}</div>
                </div>}
            <div className='flex items-center justify-around flex-wrap w-full gap-2 flex-row'>
                {buttons && buttons.map((btn, idx) => {
                    if (btn.action_type === 'link') {
                        return (
                            <div key={idx} className='flex justify-center flex-1'>
                                <a href={`${btn?.target}`} target='_blank' rel="noopener noreferrer" className='w-full'>
                                    <button disabled={isLoading} className={`bg-surface/20 dark:bg-[#3f3f3f] border-outline ${(pathname == '/feed/cards' ? 'p-3 sm:p-2 md:p-1 lg:px-[6px] lg:py-[2px] prose-BodyLarge sm:prose-BodyMedium md:prose-BodySmall' : 'p-3')} flex items-center justify-center gap-2 rounded-sm hover:bg-surface/50 text-black dark:text-onBackground dark:hover:bg-white/20 transition duration-300 w-full h-full`}>

                                        <span className='flex flex-row gap-2 items-center text-nowrap'>
                                            {btn?.title}
                                            <Link size={16} />
                                        </span>

                                    </button>
                                </a>
                            </div>
                        );
                    } else if (btn.action_type === 'post_redirect') {
                        return (
                            <div key={idx} className='flex justify-center flex-1'>
                                <button disabled={isLoading} key={idx} onClick={() => handleFrameBtnClick(embed, btn)} className={`flex-1 h-full w-full text-nowrap bg-surface/20 dark:bg-[#3f3f3f] border-outline flex justify-center items-center ${(pathname == '/feed/cards' ? 'p-3 sm:p-2 md:p-1 lg:px-[6px] lg:py-[2px] prose-BodyLarge sm:prose-BodyMedium md:prose-BodySmall' : 'p-3')} ounded-sm hover:bg-surface/50 text-black dark:text-onBackground dark:hover:bg-white/20 transition duration-300`}>
                                    <ArrowSquareOut size={16} />
                                    <span className='pr-2' />
                                    {btn?.title}
                                </button>
                            </div>
                        );
                    }

                    return (
                        <button disabled={isLoading} key={idx} onClick={() => handleFrameBtnClick(embed, btn)} className={`flex-1 h-full w-full text-nowrap bg-surface/20 dark:bg-[#3f3f3f] border-outline flex justify-center items-center ${(pathname == '/feed/cards' ? 'p-3 sm:p-2 md:p-1 lg:px-[6px] lg:py-[2px] prose-BodyLarge sm:prose-BodyMedium md:prose-BodySmall' : 'p-3')} ounded-sm hover:bg-surface/50 text-black dark:text-onBackground dark:hover:bg-white/20 transition duration-300`}>

                            {btn?.title}
                        </button>
                    );
                })}
            </div>
        </div>
    )
}

export default FrameCard