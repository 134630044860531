import { ArrowLeft, Star, Translate, Users } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import { setUserSelectedLanguage } from '../../../features/globalStateSlice';
import { displaySnackbar } from '../../../features/thunkMiddleware';
import { followChannel, unfollowChannel } from '../../../services/api';
import { LANGUAGES } from '../../../utils/constants';
import { highlightWord } from '../../../utils/helper';

const ChannelCard = ({ data, handleCast, refetchDetails, backNavCount }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {userSelectedLanguage} = useSelector(state => state.app)

    const { channel_info, balance } = data || { channel_info: null, balance: [] };
    const balanceParts = balance[0]?.display_value?.split(".") || [0, 0];

    const [isLoading, setIsLoading] = useState(false);
    const [starFill, setStarFill] = useState(data?.following);
    const [selectLanguageOpen, setSelectLanguageOpen] = useState(false);

    useEffect(() => {
        setStarFill(data?.following)
    }, [data?.following])


    const handleFollow = async () => {
        setStarFill(true);
        setIsLoading(true);
        try {
            const res = await followChannel(channel_info?.id)
            const data = res?.data
            if (data[1]) {
                dispatch(displaySnackbar('Added to favorites'))
            }
        }
        catch (err) {
            setStarFill(false);
            dispatch(displaySnackbar('Failed to add'))
        }
        finally {
            refetchDetails();
            setIsLoading(false);
        }
    }
    const handleUnfollow = async () => {
        setStarFill(false)
        setIsLoading(true);
        try {
            const res = await unfollowChannel(channel_info?.id)
            const data = res?.data
            if (data[0] == 1) {
                dispatch(displaySnackbar('Removed from favorites'))
            }
        }
        catch (err) {
            setStarFill(true);
            dispatch(displaySnackbar('Failed to remove'))
        }
        finally {
            refetchDetails();
            setIsLoading(false);
        }
    }

    const handleLanguageSelect = (language) => {
        dispatch(setUserSelectedLanguage(language));
    }      

    return (
        <>
            <div className='flex z-50 backdrop-blur-md sticky top-0 bg-white dark:bg-background items-center md:h-[10vh] h-[50px] justify-between px-5'>
                <ArrowLeft size={24} className='cursor-pointer fill-black dark:fill-white' onClick={() => navigate(backNavCount)} />
                <div className='flex gap-4'>
                <div className='h-8 relative w-fit px-4 border-outline rounded-sm uppercase bg-surface    hidden md:flex justify-center items-center lg:w-fit font-bold text-xs leading-[18px] tracking-widest text-nowrap text-onBackgroundII hover:text-white gap-2' role='button' onClick={() => { setSelectLanguageOpen(prev => !prev) }}>
                    <Translate size={16} /> {userSelectedLanguage === "off" ? "OFF" : userSelectedLanguage.slice(0, 2)}
                    {selectLanguageOpen && (
                        <div className='absolute top-10 left-0 w-auto flex flex-col gap-1 bg-surface'>
                        {
                            LANGUAGES.map((language, index) => (
                            <div key={index} onClick={() => handleLanguageSelect(language)} className='   prose-LabelSmall cursor-pointer px-2 flex items-center'>
                                {language}
                            </div>
                            ))
                        }
                        </div>
                    )}
                </div>

                    <button onClick={handleCast} className='bg-primary custom-hover text-onPrimary prose-TitleLarge px-10 h-8 rounded-sm hidden md:block'>Cast</button>
                </div>
            </div>
            <div className='mx-5'>
                <div className='flex justify-between items-center'>
                    <div className='flex items-center flex-row gap-4'>
                        <img src={channel_info?.image_url || logo} alt="base" className='clip-polygon aspect-square bg-center bg-no-repeat bg-cover rounded-full h-10 w-10' />
                        <div className='flex flex-col'>
                            <span className='prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground'>{channel_info?.name || 'Channel does not exist'}</span>
                            <span className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII'>/{channel_info?.id}</span>
                        </div>
                    </div>
                    {/* <button className='text-background py-[1px] px-[6px] h-fit bg-onBackground prose-TitleLarge'>
                        Follow
                    </button> */}
                    {!starFill && <button disabled={isLoading} onClick={() => handleFollow()} className='p-2 rounded-full    trasition duration-[450ms]'>
                        <Star size={24} className=' fill-primary'/>
                    </button>}

                    {starFill && <button disabled={isLoading} onClick={() => handleUnfollow()} className='p-2 rounded-full    trasition duration-[450ms]'>
                        <Star size={24} weight='fill' className=' fill-primary'/>
                    </button>}
                </div>
                <p className=' text-txtOnLightBg dark:text-onBackground prose-BodyLarge my-4'>{highlightWord(channel_info?.description, true, navigate)}</p>
                {/* {balance?.length > 0 &&
                    <div className='border border-primary h-[75px] bg-surface/80 flex justify-between items-center px-4 py-3'>
                        <div>
                            <div className='flex gap-1 items-center'>
                                <h2 className='text-onBackgroundII prose-BodyMedium'>Channel’s Revenue Sharing </h2> <span><Question size={18} color='rgba(255, 255, 255, 0.65)' /></span>
                            </div>
                            <p className='prose-HeaderLarge text-onBackground'>{balanceParts[0]}<span className='prose-BodyMediumBold'>.{balanceParts[1]} {balance[0].currency}</span></p>
                        </div>
                        <div className='h-10 w-10 bg-primary rounded-full flex justify-center items-center'>
                            <Coins size={24} color='#000000' weight='bold' />
                        </div>
                    </div>
                } */}
                <div className='flex items-center mt-4'>
                    <span>
                        <Users size={20} className='fill-gray-500 dark:fill-onBackgroundIII' weight='bold' />
                    </span>
                    <p className='prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground ps-2'>
                        {channel_info?.follower_count_display?.display_value}
                    </p>
                    <p className='text-subTxtOnLightBg dark:text-onBackgroundII prose-BodyMedium px-1'>Followers </p>
                    {/* <p className='flex items-center gap-1'>
                        <span className='text-primary prose-BodyMediumBold'>
                            Channel Norms
                        </span>
                        <span>
                            <CaretRight size={14} color='#B4F6A5' weight='bold' />
                        </span>
                    </p> */}
                </div>
                {/* <div className='mt-2 gap-2 flex items-center text-onBackgroundIII prose-LabelSmall'>
                    <p>Followed by</p>
                    <p>
                        <div className="flex justify-start items-center">
                            <div className="flex items-center justify-center -mx-[6px] overflow-hidden rounded-full border-2 border-black h-6 w-6">
                                <img
                                    src={logo}
                                    className="w-full h-full object-cover"
                                    alt="profile img"
                                />
                            </div>
                            <div className="flex items-center justify-center -mx-[6px] overflow-hidden rounded-full border-2 border-black h-6 w-6">
                                <img
                                    src={logo}
                                    className="w-full h-full object-cover"
                                    alt="profile img"
                                />
                            </div>
                            <div className="flex items-center justify-center -mx-[6px] overflow-hidden rounded-full border-2 border-black h-6 w-6">
                                <img
                                    src={logo}
                                    className="w-full h-full object-cover"
                                    alt="profile img"
                                />
                            </div>
                        </div>
                    </p>
                    <p>
                        +16 more
                    </p>
                </div> */}

            </div>
        </>
    )
}

export default ChannelCard
