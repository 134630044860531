import { CaretDown, CaretUp, GlobeHemisphereEast } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../../assets/images/logo.png';
import { getFeedTopics, getUserAndChannelData } from '../../../../services/api';
import Portal from '../../../UI/ReactPortal';

const ChannelSelector = ({ selectedChannel, setSelectedChannel, dropDown = true, isQuoteCast, castDetails, channelDetail }) => {

    const buttonRef = useRef(null)
    const [postion, setPosition] = useState({ top: 0, left: 0 })
    const [searchTerm, setSearchTerm] = useState('');
    const [showChannelList, setShowChannelList] = useState(false)
    const [channelsData, setChannelsData] = useState([])

    const { data: channelList = [], isLoading: isChannelListLoading } = useQuery({
        queryKey: ['searchResult', searchTerm],
        queryFn: () => getUserAndChannelData(searchTerm, true),
        enabled: !!searchTerm,
    });

    const { data: channels, isLoading: isChannelsLoading, refetch: refetchChannel } = useQuery({
        queryKey: ['feedChannels'],
        queryFn: getFeedTopics,
    });

    useEffect(() => {
        if (searchTerm && channelList?.channels?.length > 0) {
            setChannelsData(channelList?.channels)
        } else {
            setChannelsData(channels?.data)
        }
    }, [channelList])

    const handleSelectChannel = (e, channel) => {
        setSelectedChannel(channel)
        setShowChannelList(false)
        setSearchTerm('')
    }

    useEffect(() => {
        if (isQuoteCast) {
            if (castDetails?.channel) {
                setChannelsData({
                    data: [
                        { channel: { id: castDetails?.channel?.id, name: castDetails?.channel?.name, image_url: castDetails?.channel?.image_url, url: castDetails?.channel?.url } },
                        // ...channels?.data
                    ]
                })
            }
        }
        else if (channelDetail) {
            setChannelsData({
                data: [
                    { channel: { id: channelDetail?.id, name: channelDetail?.title, image_url: channelDetail?.img, url: channelDetail?.url } },
                ]
            })
        } else {
            setChannelsData(channels)
        }
        if (isQuoteCast && castDetails?.channel) {
            setSelectedChannel({ id: castDetails?.channel?.id, name: castDetails?.channel?.name, image_url: castDetails?.channel?.image_url, url: castDetails?.channel?.url })
        }
        else if (!!channelDetail) {
            setSelectedChannel({ id: channelDetail?.id, name: channelDetail?.title, image_url: channelDetail?.img, url: channelDetail?.url })
        }
    }, [channelDetail, isQuoteCast, castDetails])


    useEffect(() => {
        if (!!channelDetail) {
            setSelectedChannel({ id: channelDetail?.id, name: channelDetail?.title, image_url: channelDetail?.img, url: channelDetail?.url })
        }
    },[channelDetail])

    const getPositionOfElement = () => {
        if (buttonRef.current) {
            const ChannelDropDownbtn = buttonRef.current.getBoundingClientRect();
            const isMobile = window.matchMedia("(max-width: 500px)").matches;
            const isTablet = window.matchMedia("(max-width: 767px)").matches;
            if (isMobile || isTablet) {
                setPosition({ top: ChannelDropDownbtn.top - 260, left: '0%' });
            } else {
                setPosition({ top: ChannelDropDownbtn.bottom, left: '40%' });
            }
        }
    }

    return (
        <div className='relative'>
            <div ref={buttonRef}
                className={`h-9 bg-white dark:bg-[#121214] border dark:border-outline rounded-full flex items-center prose-LabelLarge gap-1 px-4 py-2 justify-between cursor-pointer max-w-40`}
                onClick={() => { setShowChannelList(prev => !prev); getPositionOfElement() }}
            >
                <div className='w-4 h-4 rounded-full'>
                    {
                        selectedChannel === "" || selectedChannel?.id == "custom_global" ? <GlobeHemisphereEast size={16} className=' fill-primary' /> : <img className='max-w-4 min-w-4 h-4 rounded-full' src={selectedChannel?.image_url} alt="" />
                    }
                </div>
                <p className='text-subTxtOnLightBg dark:text-onBackgroundII truncate'>
                    {selectedChannel?.name || 'Global'}
                </p>
                {dropDown && (!showChannelList ? <CaretDown size={16} className='fill-black dark:fill-white' /> : <CaretUp size={16} className='fill-black dark:fill-white' />)}
                {!dropDown && (showChannelList ? <CaretDown size={16} className='fill-black dark:fill-white' /> : <CaretUp size={16} className='fill-black dark:fill-white' />)}
            </div>

            {showChannelList && (
                <Portal>
                    <div className='fixed top-0 left-0 w-screen h-screen z-[1000]'>
                        <div className='fixed inset-0 bg-white dark:bg-background opacity-20' onClick={() => setShowChannelList(false)}></div>
                        <div style={{ top: postion.top, left: postion.left }} className={`overflow-y-auto removeScrollBar px-2 max-h-[240px] absolute w-full md:w-[400px] bg-white dark:bg-[#121214] border dark:border-outline rounded-sm shadow-lg z-[101] flex flex-col gap-1 pb-1`}>
                            <div className='mt-2'>
                                <input onChange={(e) => setSearchTerm(e.target.value)} className='text-txtOnLightBg dark:text-white bg-gray-200 dark:bg-background/30 border prose-BodyMedium border-outline outline-none py-1 px-2 w-full rounded-sm' placeholder='Search Channels' />
                            </div>
                            {searchTerm.length != 0 &&
                                <div className='grid grid-cols-2 gap-2 mt-3'>
                                    {isChannelListLoading ? <> <div className='w-full py-1'>
                                        <div className='border border-outline h-9 w-full rounded-sm flex items-center px-1 gap-1'>
                                            <div className='h-5 w-5 rounded-full bg-surface animate-pulse' />
                                            <div className='h-5 w-full rounded-sm bg-surface animate-pulse' />
                                        </div>
                                        <div className='border border-outline h-9 w-full rounded-sm flex items-center px-1 gap-1 mt-3'>
                                            <div className='h-5 w-5 rounded-full bg-surface animate-pulse' />
                                            <div className='h-5 w-full rounded-sm bg-surface animate-pulse' />
                                        </div>
                                    </div>
                                        <div className='py-1'>
                                            <div className='border border-outline h-9 w-full rounded-sm flex items-center px-1 gap-1'>
                                                <div className='h-5 w-5 rounded-full bg-surface animate-pulse' />
                                                <div className='h-5 w-full rounded-sm bg-surface animate-pulse' />
                                            </div>
                                            <div className='border border-outline h-9 w-full rounded-sm flex items-center px-1 gap-1 mt-3'>
                                                <div className='h-5 w-5 rounded-full bg-surface animate-pulse' />
                                                <div className='h-5 w-full rounded-sm bg-surface animate-pulse' />
                                            </div>
                                        </div> </> : channelsData?.length == 0 ? <div>No Channels Found</div> : channelsData?.map((channel, i) => (
                                            <div key={channel?.id} onClick={(e) => handleSelectChannel(e, channel)} className='px-2 flex items-center gap-1 prose-LabelSmall text-txtOnLightBg dark:text-onBackground border dark:border-outline py-2 rounded-sm hover:bg-gray-200 dark:hover:bg-gray-800 hover:border-outlineII cursor-pointer'>
                                                <img src={channel?.image_url} alt="channel" className='w-4 h-4 rounded-full' />
                                                {channel?.name}
                                            </div>
                                        ))}
                                </div>
                            }
                            {!searchTerm && <>
                                <div className={`prose-LabelSmall px-4 mx-1 mt-3 py-2 text-txtOnLightBg gap-2 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800 cursor-pointer flex items-center ${selectedChannel.id === 'custom_global' || selectedChannel === "" ? 'border-maintextColorOnLightBg dark:border-[#b4f6a5] border-[1px]' : ''}`}
                                    onClick={(e) => { handleSelectChannel(e, { id: 'custom_global', name: "Global" }); setShowChannelList(false) }}>
                                    <span>
                                        <GlobeHemisphereEast size={16} className=' fill-primary' />
                                    </span>
                                    <span className='ml-1 text-nowrap'>Global</span>
                                </div>
                                {channelsData?.length > 0 && channelsData?.map((channel, i) => (
                                    <div
                                        key={i}
                                        className={`prose-LabelSmall px-4 mx-1 py-2 text-white hover:bg-gray-200 dark:hover:bg-gray-800 cursor-pointer gap-2 flex items-center ${selectedChannel?.name === (channel?.name || channel?.channel?.name) ? 'border-[#b4f6a5] border-[1px]' : ''}`}
                                        onClick={(e) => { handleSelectChannel(e, (channel?.channel || channel)); setShowChannelList(false) }}

                                    >
                                        <div className='min-w-4 aspect-square'>
                                            <img src={channel?.image_url || channel?.channel?.image_url || logo} alt="channel" className='w-full max-w-4 aspect-square rounded-full' />
                                        </div>
                                        <span className='ml-1 text-nowrap text-txtOnLightBg dark:text-onBackgroundII truncate'>{channel?.name || channel?.channel?.name}</span>
                                    </div>
                                ))}
                            </>}
                        </div>
                    </div>
                </Portal>
            )}
        </div>
    )
}

export default ChannelSelector;
