import { usePrivy } from '@privy-io/react-auth';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const RefreshPage = () => {
    const { getAccessToken, ready } = usePrivy();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!ready) return;

        const refresh = async () => {
            const token = await getAccessToken();
            const redirectTo = searchParams.get('redirect') || '/';

            if (token) {
                window.location.replace(redirectTo);
            } else {
                window.location.replace('/signin');
            }
        };

        refresh();
    }, [ready, getAccessToken, searchParams]);

    return null;
};

export default RefreshPage;
