import { Bell, Coins, Crown, Link, ListStar, Ticket, Wallet } from '@phosphor-icons/react';
import React from 'react';

const Icons = ({ iconName, isActive, size = 24, theme }) => {
  const getIconComponent = (name) => {
    const color = isActive 
      ? theme == 'dark' ? `var(--color-primary)` : `var(--color-primary)` 
      : theme == 'dark' ? `var(--onBackgroundII)`: `var(--onBackground)`;
    switch (name) {
      case "points":
        return <Coins size={size} color={color} weight={isActive ? 'fill' : 'regular'} />;
      case "onchain":
          return <Link size={size} color={color} weight={isActive ? 'fill' : 'regular'} />;
      case "notifications":
        return <Bell size={size} color={color} weight={isActive ? 'fill' : 'regular'} />;
      case "feed":
        return <ListStar size={size} color={color} weight={isActive ? 'fill' : 'regular'} />;
      case "ranking":
        return <Crown size={size} color={color} weight={isActive ? 'fill' : 'regular'} />;
      case "ticket":
        return <Ticket size={size} color={color} weight={isActive ? 'fill' : 'regular'} />;
      case "wallet":
        return <Wallet size={size} color={color} weight={isActive ? 'fill' : 'regular'} />;
      default:
        return null
    }
  };

  return getIconComponent(iconName);
};

export default Icons;