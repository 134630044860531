import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { displaySnackbar } from '../../features/thunkMiddleware';
import useCastAction from '../../hooks/signer';
import { deleteDraft } from '../../services/api';
import { usePrivy } from '@privy-io/react-auth';

const useSubmitCast = () => {
    const [isSubmittingCast, setIsSubmittingCast] = useState(false);
    const [isCastSuccess, setIsCastSuccess] = useState(false);
    const [isCastFailed, setIsCastFailed] = useState(false);
    const [tipAmount, setTipAmount] = useState(0);
    const [selectedToken, setSelectedToken] = useState('WILD');
    const dispatch = useDispatch();
    const { submitCast, scheduleCast } = useCastAction()
    // TODO(Gaurav/Kodo): Farcaster user won't necessarily be available directly on Privy user object.
    const { user } = usePrivy()


    const uploadMedia = async (mediaPreview) => {
        if (!mediaPreview || mediaPreview.length === 0) return undefined;

        try {
            const updatedMediaPreview = await Promise.all(mediaPreview.map(async (media) => {
                if (media.url instanceof File) {
                    const formData = new FormData();
                    formData.append('file', media.url);
                    formData.append('upload_preset', 'lloxxrqn');
                    formData.append('cloud_name', 'dm5fwvelv');

                    let uploadUrl = 'https://api.cloudinary.com/v1_1/dm5fwvelv/image/upload';
                    let resourceType = 'image';

                    // Check if the file is a video
                    if (media.url.type.startsWith('video/')) {
                        uploadUrl = 'https://api.cloudinary.com/v1_1/dm5fwvelv/video/upload';
                        resourceType = 'video';
                    }

                    const response = await fetch(uploadUrl, {
                        method: 'POST',
                        body: formData,
                    });

                    const cloudinaryData = await response.json();
                    if (!cloudinaryData.secure_url) {
                        throw new Error(`${resourceType.charAt(0).toUpperCase() + resourceType.slice(1)} Upload Failed`);
                    }

                    if (resourceType === 'video') {
                        // Generate .m3u8 URL
                        const m3u8Url = cloudinaryData.secure_url.replace(/\.[^/.]+$/, ".m3u8");
                        // Generate thumbnail URL
                        const thumbnailUrl = cloudinaryData.secure_url.replace(/\.[^/.]+$/, ".jpg");

                        return {
                            url: m3u8Url,
                            thumbnail: thumbnailUrl,
                            width: cloudinaryData.width,
                            height: cloudinaryData.height
                        };
                    } else {
                        return {
                            url: cloudinaryData.secure_url,
                            width: cloudinaryData.width,
                            height: cloudinaryData.height
                        };
                    }
                } else {
                    return { url: media.url };
                }
            }));

            return updatedMediaPreview;
        } catch (error) {
            console.error('Media upload failed:', error);
            dispatch(displaySnackbar('Media upload failed'));
            return [];
        }
    };

    const handleSubmitCast = async (text, embeds, channelUrl, hash, fid, draftId, tipAmount, tipCurrency, isQuoteCast = false, scheduleInfo = undefined) => {
        try {
            setIsSubmittingCast(true);
            let updatedMediaUrls = await uploadMedia(embeds);
            let castHash = hash;
            let castFid = fid;
            if (isQuoteCast) {
                if (updatedMediaUrls)
                    updatedMediaUrls = [{ cast_id: { fid: castFid, hash: castHash } }, ...updatedMediaUrls];
                else
                    updatedMediaUrls = [{ cast_id: { fid: castFid, hash: castHash } }];

                castHash = undefined;
                castFid = undefined;
            }

            const response = scheduleInfo ? await scheduleCast(text, updatedMediaUrls, channelUrl, castHash, castFid, scheduleInfo) : await submitCast(text, updatedMediaUrls, channelUrl, castHash, castFid);
            const isSuccess = response?.data?.success
            const errorMessage = response?.response?.data?.error
            if (tipAmount)
                setTipAmount(tipAmount)
            if (tipCurrency)
                setSelectedToken(tipCurrency)

            if (isSuccess) {
                setIsCastSuccess(true);
                if (scheduleInfo)
                    dispatch(displaySnackbar('Cast scheduled successfully'));
                else
                    dispatch(displaySnackbar({ message: 'Cast Created Successfully', cast: { hash: response?.data?.hash, fid: user?.farcaster?.fid } }));
                if (draftId)
                    deleteDraft(draftId)
            } else {
                setIsCastFailed(true);
                if (errorMessage) {
                    dispatch(displaySnackbar(errorMessage));
                } else {
                    dispatch(displaySnackbar('Cast Failed, Try Again'));
                }
            }
            return response
        } catch (error) {
            setIsCastFailed(true);
            dispatch(displaySnackbar(error.message));
        } finally {
            setIsSubmittingCast(false);
            setTimeout(() => {
                setIsCastSuccess(false)
            }, 2000);
        }
    };

    return { isSubmittingCast, handleSubmitCast, uploadMedia, isCastSuccess, isCastFailed, tipAmount, selectedToken };
};

export default useSubmitCast;
