import { Empty } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import OnchainTable from '../../../components/Onchain/OnchainTable';
import OnchainTradeSkeleton from '../../../components/Onchain/OnchainTradeSkeleton';
import { getUserOnChaintrades } from '../../../services/api';

const OnchainTab = ({ fid }) => {
    const [tradesData, setTradesData] = useState({
        trades: [],
        has_more: false,
        next_after_id: null,
        next_before_id: null
    });
    const [moreLoading, setMoreLoading] = useState(false);
    const [isLoadingTradesFromRouteChange, setIsLoadingTradesFromRouteChange] = useState(false);

    const observer = useRef();

    const { data: initialProfileTrades, isLoading: profileTradesLoading, refetch: refetchTrades } = useQuery({
        queryKey: ['currentUserTrades', fid],
        queryFn: () => getUserOnChaintrades(fid),
        enabled: !!fid
    });

    useEffect(() => {
        setIsLoadingTradesFromRouteChange(true);
        setTradesData({
            trades: [],
            has_more: false,
            next_after_id: null,
            next_before_id: null
        });
        if (fid) {
            refetchTrades().then(() => {
                setIsLoadingTradesFromRouteChange(false);
            });
        }
    }, [fid, refetchTrades]);

    useEffect(() => {
        if (initialProfileTrades?.data?.data) {
            setTradesData(initialProfileTrades.data.data);
        }
    }, [initialProfileTrades]);

    const fetchMoreOnchainTrades = async () => {
        if (!tradesData.has_more || moreLoading) return;

        setMoreLoading(true);

        try {
            const res = await getUserOnChaintrades(fid, null, tradesData.next_before_id);
            setTradesData(prevData => ({
                ...res.data.data,
                trades: [...prevData.trades, ...res.data.data.trades]
            }));
        } catch (error) {
            console.error("Error fetching more trades:", error);
        } finally {
            setMoreLoading(false);
        }
    };

    const lastOnchainElementRef = useCallback((node) => {
        if (profileTradesLoading || moreLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && tradesData.has_more) {
                fetchMoreOnchainTrades();
            }
        });
        if (node) observer.current.observe(node);
    }, [tradesData.has_more, profileTradesLoading, moreLoading, fid]);

    return (
        <div className='pb-4'>
            {profileTradesLoading || isLoadingTradesFromRouteChange ? (
                <>
                    {[1, 2, 3].map((item, idx) => (
                        <div key={idx} className='mb-1'>
                            <OnchainTradeSkeleton />
                        </div>
                    ))}
                </>
            ) : tradesData.trades.length === 0 ? (
                <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4">
                    <Empty size={20} className='fill-black dark:fill-[#ffffffa6]' />
                    <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                </div>
            ) : (
                tradesData.trades.map((item, idx) => (
                    <div key={idx} ref={tradesData.trades.length === idx + 1 ? lastOnchainElementRef : null}>
                        <OnchainTable item={item} />
                    </div>
                ))
            )}
            {moreLoading && (
                <div className='absolute -bottom-1 md:bottom-0 w-full flex items-center justify-center'>
                    <span className='loading loading-dots bg-gray-400 dark:bg-white loading-md'></span>
                </div>
            )}
        </div>
    );
};

export default OnchainTab;