import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy-container bg-white dark:bg-background text-txtOnLightBg dark:text-onBackground p-5 md:p-10">
      <h1 className="prose-DisplayMedium text-primary mb-4">Privacy Policy</h1>
      <p className="prose-BodyMedium mb-4">Effective Date: {new Date().toLocaleDateString()}</p>

      <p className="prose-BodyMedium mb-6">Social Composability Labs Ltd. ("we", "our", or "us") respects your privacy and is committed to protecting the personal data you share with us through our product, Wildcard ("app"). This privacy policy outlines how we collect, use, and protect your information when you use our app on web, iOS, and Android platforms.</p>

      <h2 className="prose-HeaderLarge text-txtOnLightBg dark:text-onBackground mb-3">Information We Collect</h2>
      <p className="prose-BodyMedium mb-2">We collect the following data from users of Wildcard:</p>
      <ul className="list-disc list-inside mb-6">
        <li className="prose-BodyMedium mb-1">Search History: Collected to improve product personalization.</li>
        <li className="prose-BodyMedium mb-1">Identifiers (User ID): Collected for product personalization and to enhance app functionality.</li>
        <li className="prose-BodyMedium mb-1">Usage Data (Product Interaction): Collected to improve app functionality.</li>
        <li className="prose-BodyMedium mb-1">Diagnostics (Crash Data): Collected to ensure the stability and functionality of the app.</li>
      </ul>

      <h2 className="prose-HeaderLarge text-txtOnLightBg dark:text-onBackground mb-3">Data Not Linked to You</h2>
      <p className="prose-BodyMedium mb-6">The data we collect, including Search History, User ID, Usage Data, and Crash Data, is not linked to your identity. This data is used solely to improve the user experience and maintain the performance of the app.</p>

      <h2 className="prose-HeaderLarge text-txtOnLightBg dark:text-onBackground mb-3">User Privacy Choices</h2>
      <p className="prose-BodyMedium mb-6">We are committed to transparency in how we collect and use your data. If you have any concerns or wish to make choices regarding your privacy, please contact us.</p>

      <h2 className="prose-HeaderLarge text-txtOnLightBg dark:text-onBackground mb-3">Updates to This Privacy Policy</h2>
      <p className="prose-BodyMedium">We are responsible for keeping our privacy practices up to date. If our practices change, including the addition of new data groups, we will update this policy accordingly.</p>
    </div>
  );
};

export default PrivacyPolicyPage;