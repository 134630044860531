
import { createChart, ColorType, LineType, CrosshairMode } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProfileMktCapGraph } from '../services/api';
import { roundOff } from '../utils/helper';
import Spinner from './UI/Spinner';

export const Chart = ({ username, chartHeight = 250, containerHeight = 282, showAxis=true }) => {


    const lineColor = '#B4F6A5'
    const textColor = 'black'
    const areaTopColor = '#65E249'
    const areaBottomColor = 'rgba(0, 0, 0, 0.01)'

    const chartContainerRef = useRef();

    const { data: mktcapdata, isLoading: isMktdataLoading, refetch: refetchMktGraph } = useQuery({
        queryKey: ['mktcapgraph', username],
        queryFn: () => getProfileMktCapGraph(username),
        enabled: !!username
    });


    useEffect(() => {

        const handleResize = () => {
            chart?.applyOptions({ width: chartContainerRef?.current?.clientWidth });
        };

        const profileMktData = mktcapdata?.data?.market_cap_graph;

        const mktCapData =  profileMktData?.map(item => {
            return {
                time: item.timestamp,
                value: parseFloat(roundOff(item?.market_cap?.value))
            }
        }).sort((a, b) => a.time - b.time);


        const chart = chartContainerRef?.current && createChart(chartContainerRef?.current, {
            layout: {
                background: { type: ColorType.Solid, color: "transparent" },
                textColor,
            },
            grid: { horzLines: false, vertLines: false },
            width: chartContainerRef?.current?.clientWidth,
            height: chartHeight,
            timeScale: { visible: false },
            rightPriceScale: { visible: showAxis, ticksVisible: true, borderVisible: false, scaleMargins: { top: 0.3, bottom: 0.3 }},
            crosshair: { mode: CrosshairMode.Normal },
            handleScroll: {
                mouseWheel: false,
                pressedMouseMove: false,
                horzTouchDrag: true,
                vertTouchDrag: true
            },
            handleScale: {
                axisPressedMouseMove: false,
                mouseWheel: false,
                pinch: false,
            },
        });
        chart?.timeScale()?.fitContent();

        const newSeries = !isMktdataLoading && chart?.addAreaSeries({ lineColor, topColor: areaTopColor, bottomColor: areaBottomColor, lineType: LineType.Curved, priceLineVisible: false, pointMarkersVisible: false, priceFormat: { type: 'price', precision: 5, minMove: 0.00001 } });
        !isMktdataLoading && mktCapData && newSeries?.setData(mktCapData);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);

            chart?.remove();
        };
    }, [mktcapdata, isMktdataLoading, username]);


    return (
        <div className='h-full'>
            {isMktdataLoading ? <div className={`min-h-[${containerHeight}px] h-full relative flex items-center justify-center`}> <span className="loading loading-ring loading-md"></span></div> : mktcapdata?.data?.length <= 0 ? <>
                <div className={`min-h-[${containerHeight}px] flex items-center justify-center`}>
                    <p>No chart data</p>
                </div>

            </> :
                <>
                    <div
                        ref={chartContainerRef}
                        className='w-full p-0 m-0'
                        style={{padding: '0px', margin: '0px'}}
                    />
                </>
            }
        </div>
    );
};
