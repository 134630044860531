const TransactionSkeletonLoading = ({key}) => {
    return (
        <div 
        key={key}
        className="flex h-[93px] py-4 justify-start items-center gap-4 align-start bg-white dark:bg-background px-5">
            <div className="flex items-center">
                <button className={`animate-pulse bg-surface/50 dark:bg-surface flex items-center justify-center overflow-hidden rounded-full h-[38.44px] w-[38.44px]`}>
                </button>
                <button className={`animate-pulse bg-surface/50 dark:bg-surface flex items-center justify-center -mx-2 overflow-hidden rounded-full h-[38.44px] w-[38.44px]`}>
                </button>
            </div>
            <div className="w-full break-all flex flex-col gap-1">
                <div className="flex items-center gap-[6px] h-[14px] w-48 animate-pulse bg-surface/50 dark:bg-surface" />
                <div className="flex items-center gap-[6px] h-[14px] w-48 animate-pulse bg-surface/50 dark:bg-surface md:hidden" />
                <div className="flex items-center gap-[6px] h-[14px] w-20 animate-pulse bg-surface/50 dark:bg-surface" />
            </div>
            <div className="flex-col items-end hidden md:flex">
                <div className="flex items-center gap-[6px] h-[10px] w-5 animate-pulse bg-surface/50 dark:bg-surface mb-2" />
                <div className="flex items-center gap-[6px] h-[14px] w-20 animate-pulse bg-surface/50 dark:bg-surface" />
            </div>
        </div>
    )
}

export default TransactionSkeletonLoading;