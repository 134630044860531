import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { displaySnackbar } from '../features/thunkMiddleware';
import { createFrames, deleteCast, followUserApi, likeReactionApi, makeCast, makeScheduleCast, pollAccountStatus, reacastReactionApi, unfollowUserApi, unLikeReactionApi, unRecastReactionApi } from '../services/api';
import useAccount from './account';

export const formatForCastAction = ({text, embeds, channelUrl = '', hash, fid} = {}) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (channelUrl === 'custom_global') {
        channelUrl = '';
    }

    // Extract links from the text
    const links = text.match(urlRegex) || [];

    // Structure links as objects and combine with existing embeds
    const linkEmbeds = links.map(link => ({ url: link }));
    const allEmbeds = [...linkEmbeds, ...(embeds || [])];

    const newText = text?.replace(/@\[(.*?)\]\(\d+\)/g, '@$1')?.replace(/\/\[(.*?)\]/g, '/$1');

    const body = {
        text: newText,
        embeds: allEmbeds,
        parent: hash,
        channel_id: channelUrl,
        parent_author_fid: fid
    };

    return body
}

const useCastAction = () => {
    const { handleSignerValidate, refetchUser, user } = useAccount();
    const dispatch = useDispatch()

    const submitCast = useCallback(async (text, embeds, channelUrl = '', hash, fid) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const body = formatForCastAction({text, embeds, channelUrl, hash, fid});

        try {
            const response = await makeCast(body);
            if (!response) {
                const accountStatus = await pollAccountStatus();
                const isSigner = accountStatus?.data?.data?.signer_key_active
                if (isSigner) {
                    return await makeCast(body);
                } else {
                    dispatch(displaySnackbar('Something went wrong'));
                    return false;
                }
            }
            return response;
        } catch (error) {
            console.error('Error submitting cast:', error);
            throw error;
        }
    }, [handleSignerValidate]);

    const scheduleCast = useCallback(async (text, embeds, channelUrl = '', hash, fid, { timestamp, repeat, repeat_count }) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;
        console.log(text)
        const body = {
            cast_request: formatForCastAction({text, embeds, channelUrl, hash, fid}),
            timestamp: timestamp,
            repeat: repeat,
            repeat_count: repeat_count
        };

        try {
            const response = await makeScheduleCast(body);
            if (!response) {
                const accountStatus = await pollAccountStatus();
                const isSigner = accountStatus?.data?.data?.signer_key_active
                if (isSigner) {
                    return await makeScheduleCast(body);
                } else {
                    dispatch(displaySnackbar('Something went wrong'));
                    return false;
                }
            }
            return response;
        } catch (error) {
            console.error('Error submitting cast:', error);
            throw error;
        }
    }, [handleSignerValidate]);

    const removeCast = useCallback(async (hash) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return false;

        const body = { cast_hash: hash };

        try {
            const response = await deleteCast(body);
            if (!response) {
                const accountStatus = await pollAccountStatus();
                const isSigner = accountStatus?.data?.data?.signer_key_active
                if (isSigner) {
                    return await deleteCast(body);
                } else {
                    dispatch(displaySnackbar('Something went wrong'));
                    return false;
                }
            }
            return response;
        } catch (error) {
            console.error('Error removing cast:', error);
            throw error;
        }
    }, [handleSignerValidate]);

    const likeCast = useCallback(async (target, target_author_fid) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        const response = await likeReactionApi(target, target_author_fid);
        if (!response) {
            const accountStatus = await pollAccountStatus();
            const isSigner = accountStatus?.data?.data?.signer_key_active
            if (isSigner) {
                return await likeReactionApi(target, target_author_fid);
            } else {
                dispatch(displaySnackbar('Something went wrong'));
                return false;
            }
        }
        return response;
    }, [refetchUser, user, dispatch]);

    const unLikeCast = useCallback(async (target, target_author_fid) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return false;

        const response = await unLikeReactionApi(target, target_author_fid)
        if (!response) {
            const accountStatus = await pollAccountStatus();
            const isSigner = accountStatus?.data?.data?.signer_key_active
            if (isSigner) {
                return await unLikeReactionApi(target, target_author_fid)
            } else {
                dispatch(displaySnackbar('Something went wrong'));
                return false;
            }
        }
        return response
    }, [handleSignerValidate]);

    const recastCast = useCallback(async (target, target_author_fid) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return false;

        const response = await reacastReactionApi(target, target_author_fid)
        if (!response) {
            const accountStatus = await pollAccountStatus();
            const isSigner = accountStatus?.data?.data?.signer_key_active
            if (isSigner) {
                return await reacastReactionApi(target, target_author_fid)
            } else {
                dispatch(displaySnackbar('Something went wrong'));
                return false;
            }
        }
        return response
    }, [handleSignerValidate]);

    const unRecastCast = useCallback(async (target, target_author_fid) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return false;

        const response = await unRecastReactionApi(target, target_author_fid)
        if (!response) {
            const accountStatus = await pollAccountStatus();
            const isSigner = accountStatus?.data?.data?.signer_key_active
            if (isSigner) {
                return await unRecastReactionApi(target, target_author_fid)
            } else {
                dispatch(displaySnackbar('Something went wrong'));
                return false;
            }
        }
        return response
    }, [handleSignerValidate]);

    const followUser = useCallback(async (target_ids) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return false;

        const response = await followUserApi(target_ids)
        if (!response) {
            const accountStatus = await pollAccountStatus();
            const isSigner = accountStatus?.data?.data?.signer_key_active
            if (isSigner) {
                return await followUserApi(target_ids)
            } else {
                dispatch(displaySnackbar('Something went wrong'));
                return false;
            }
        }
        return response
    }, [handleSignerValidate]);

    const unFollowUser = useCallback(async (target_ids) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return false;

        const response = await unfollowUserApi(target_ids)
        if (!response) {
            const accountStatus = await pollAccountStatus();
            const isSigner = accountStatus?.data?.data?.signer_key_active
            if (isSigner) {
                return await unfollowUserApi(target_ids)
            } else {
                dispatch(displaySnackbar('Something went wrong'));
                return false;
            }
        }
        return response
    }, [handleSignerValidate]);

    const handleFrame = useCallback(async (body) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return false;

        const response = await createFrames(body)
        if (!response) {
            const accountStatus = await pollAccountStatus();
            const isSigner = accountStatus?.data?.data?.signer_key_active
            if (isSigner) {
                return await createFrames(body)
            } else {
                dispatch(displaySnackbar('Something went wrong'));
                return false;
            }
        }
        return response

    }, [handleSignerValidate]);

    return { submitCast, removeCast, likeCast, unLikeCast, followUser, unFollowUser, handleFrame, recastCast, unRecastCast, scheduleCast };

};

export default useCastAction;
