import { CalendarBlank, Check, Clock, Empty } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { deleteScheduleCast, getScheduledCasts } from '../../../services/api';
import { CAST_MODAL_TAB_CHOICES } from '../../../utils/constants';
import { getTimeInLocalFormat } from '../../../utils/helper.js';
import Spinner from '../../UI/Spinner.jsx';
import CastCards from '../Casts/CastCards.jsx';
import useAccount from '../../../hooks/account.jsx';

const ScheduledTab = ({ handleTabClick, handleEditScheduledCast }) => {
    const { handleSignerValidate } = useAccount();
    const [scheduledCasts, setScheduledCasts] = useState([]);
    const [filteredScheduledCasts, setFilteredScheduledCasts] = useState(scheduledCasts);
    const [scheduledCastOptions, setScheduledCastOptions] = useState([]);

    const { data: scheduledCastsData = [], isLoading: scheduleCastsLoading, refetch: scheduledCastRefetch } = useQuery({
        queryKey: ['scheduledData'],
        queryFn: getScheduledCasts,
    });
    useEffect(() => {
        setScheduledCasts(scheduledCastsData?.data?.data)
    }, [scheduledCastsData])

    useEffect(() => {
        const filtered = scheduledCasts?.filter(cast => cast?.schedule_details?.publish_status !== 'PUBLISHED');
        setFilteredScheduledCasts(filtered);
    }, [scheduledCasts]);

    const handleDeleteSelectedScheduled = async (cast_id) => {
        const isValid = await handleSignerValidate();
        if (!isValid) return;

        if (cast_id) {
            deleteScheduleCast(cast_id)
            const updatedScheduledCasts = scheduledCasts.filter(d => d.schedule_details?.id !== cast_id);
            setScheduledCasts(updatedScheduledCasts);
        }
    };

    const handleScheduledCastHover = (id) => {
        if (id == null) {
            setScheduledCastOptions([]);
        }
        setScheduledCastOptions([id]);
    };

    return (
        <>
            <div className='flex flex-col grow overflow-hidden'>
                <div className='h-[60px] prose-TitleSmall grid grid-cols-3 items-center uppercase mx-5 md:hidden'>
                    <p className='dark:text-white text-black'>{<span role='button' onClick={() => handleTabClick(CAST_MODAL_TAB_CHOICES.CAST)}>BACK</span>}</p>
                    <p className='text-primary text-center'><span>Scheduled</span></p>
                    <p></p>
                </div>
                <div className='overflow-y-auto custom-scrollbar w-full md:max-h-[60vh] md:min-h-[283px] h-full md:pb-0 pb-[120px]'>
                    {scheduleCastsLoading ? <Spinner /> : <ul className="w-full flex flex-col">
                        {
                            filteredScheduledCasts?.length <= 0 ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-full">
                                <Empty size={20} className="fill-subTxtOnLightBg dark:fill-[#FFFFFF66]" />
                                <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                            </div> :
                                <>{filteredScheduledCasts?.map(cast => (

                                    <li key={cast?.schedule_details?.id}
                                        onMouseEnter={() => { handleScheduledCastHover(cast?.schedule_details?.id) }}
                                        onMouseLeave={() => { handleScheduledCastHover(null) }}
                                        className="flex items-start pb-3 pt-[18px] bg-gray-200 dark:bg-background border-b border-gray-300 dark:border-outline gap-1 w-full relative hover:bg-gray-300 dark:hover:bg-white/5">
                                        {
                                            scheduledCastOptions.includes(cast?.schedule_details?.id) && <div className='hidden top-5 right-5 md:flex items-center gap-2 prose-LabelSmall z-50 absolute'>
                                                {cast?.schedule_details?.publish_status === 'PENDING' && <>
                                                    <p role='button' className='px-1 w-fit rounded-sm md:hover:bg-maintextColorOnLightBg/10 border-black dark:border-white text-subTxtOnLightBg dark:text-onBackground' onClick={() => handleEditScheduledCast(cast)} >Edit</p>
                                                    <p role='button' className='px-1 w-fit rounded-sm md:hover:bg-maintextColorOnLightBg/10 border-black dark:border-white text-subTxtOnLightBg dark:text-onBackground' onClick={() => handleDeleteSelectedScheduled(cast?.schedule_details?.id)}>Delete</p></>
                                                }
                                            </div>

                                        }
                                        {cast?.schedule_details?.publish_status === 'PENDING' && <div className='md:hidden z-50 absolute top-5 right-5 flex items-center gap-2 prose-LabelSmall'>
                                            <p role='button' className='px-1 w-fit rounded-sm  border-black dark:border-white text-subTxtOnLightBg dark:text-onBackground' onClick={() => handleEditScheduledCast(cast)} >Edit</p>
                                            <p role='button' className='px-1 w-fit rounded-sm  border-black dark:border-white text-subTxtOnLightBg dark:text-onBackground' onClick={() => handleDeleteSelectedScheduled(cast?.schedule_details?.id)}>Delete</p>
                                        </div>}
                                        <div
                                            className="cursor-pointer prose-BodyMedium w-full overflow-x-hidden"
                                        >
                                            <div className='pointer-events-none'>
                                                <CastCards castData={cast?.cast} fromEmbeds fromSchedule />
                                            </div>
                                            <div className='grow px-5'>
                                                {cast?.schedule_details?.publish_status === 'PENDING' && <div className='prose-LabelMedium text-subTxtOnLightBg dark:text-onBackgroundII flex items center gap-1 mt-2'>
                                                    <Clock size={16} className='mr-1  fill-primary' />
                                                    Will be casted on
                                                    {" " + getTimeInLocalFormat(cast?.schedule_details?.scheduled_timestamp, 'date') + " "} at
                                                    {" " + getTimeInLocalFormat(cast?.schedule_details?.scheduled_timestamp, 'time')}
                                                </div>}
                                                {cast?.schedule_details?.publish_status === 'PUBLISHED' && <div className='prose-LabelMedium  text-primary flex items center gap-1 mt-2'>
                                                    <Check size={16} className='mr-1' />
                                                    Casted on
                                                    {" " + getTimeInLocalFormat(cast?.schedule_details?.scheduled_timestamp, 'date') + " "} at
                                                    {" " + getTimeInLocalFormat(cast?.schedule_details?.scheduled_timestamp, 'time')}
                                                </div>}
                                                {cast?.schedule_details?.publish_status === 'FAILED' && <div className='prose-LabelMedium text-error flex items center gap-1 mt-2'>
                                                    <CalendarBlank size={16} className='mr-1' />
                                                    Failed casting on
                                                    {" " + getTimeInLocalFormat(cast?.schedule_details?.scheduled_timestamp, 'date') + " "} at
                                                    {" " + getTimeInLocalFormat(cast?.schedule_details?.scheduled_timestamp, 'time')}
                                                </div>}
                                            </div>

                                        </div>

                                    </li>
                                ))}</>}
                    </ul>}
                </div>
            </div>
            <div className='h-[52px] w-full border-t border-outlineII flex items-center justify-end prose-LabelMedium bg-white dark:bg-[#090909] px-4'>
            </div></>
    )
}

export default ScheduledTab;