import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary: ", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className='flex justify-center items-center h-screen'>
                    <div>
                        <h1 className='prose-DisplaySmall'>Something went wrong :/</h1>
                        <p className='prose-BodyLarge'>Please refresh the page.</p>
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
