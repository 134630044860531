import { Empty } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import NFTCard from '../../../components/Cards/Profile/NFTCard';
import TktHolderCard from '../../../components/Cards/Profile/TktHolderCard';
import TktSkeletonLoading from '../../../components/Cards/Profile/TktSkeletonLoading';
import TokenCard from '../../../components/Cards/Profile/TokenCard';
import { getProfileNFTs, getProfileOwnedTickets, getProfileTokens } from '../../../services/api';
import { formatNumber } from '../../../utils/helper';
import { useParams } from 'react-router-dom';

const PortfolioTab = ({ setNetWorth }) => {

    const { username } = useParams()

    const { data: portfolio, isLoading: portfolioLoading, refetch: portfolioRefetch } = useQuery({
        queryKey: ['currentUserOwnedTickers', username],
        queryFn: () => getProfileOwnedTickets(username),
    });

    const { data: profileNFTs, isLoading: profileNFTsLoading } = useQuery({
        queryKey: ['currentUserNFTs', username],
        queryFn: () => getProfileNFTs(username),
    });

    const { data: profileTokens, isLoading: profileTokensLoading } = useQuery({
        queryKey: ['currentUserTokens', username],
        queryFn: () => getProfileTokens(username),
    });

    useEffect(() => {
        let netWorth = 0;
        profileTokens?.balances?.forEach((token) => {
            netWorth += token?.usd_value;
        });
        profileNFTs?.balances?.forEach((nft) => {
            netWorth += nft?.price_usd;
        });
        setNetWorth(formatNumber(netWorth));
    }, [profileTokens, profileNFTs]);

    return (
        <div>
            {
                portfolioLoading || profileNFTsLoading || profileTokensLoading ? <div className=''>
                    <TktSkeletonLoading />
                    <TktSkeletonLoading />
                    <TktSkeletonLoading />
                </div> :
                    !(profileNFTs?.balances?.length + portfolio?.length + profileTokens?.balances?.length) ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-full">
                        <Empty size={20} className="fill-subTxtOnLightBg dark:fill-[#ffffff66]" />
                        <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                    </div> : <>

                        {
                            portfolio?.length ? <div className='mx-5 mb-5'>
                                <h2 className='prose-TitleLarge my-3 text-black dark:text-onBackgroundII'>Tickets</h2>
                                <div className='flex flex-row overflow-x-scroll gap-2 scrollable-div-2'

                                >
                                    {portfolio?.map((data, i) => {
                                        return (
                                            <TktHolderCard key={i} data={data} i={i} />
                                        )
                                    })}
                                </div>
                            </div> : null
                        }
                        {
                            profileNFTs?.balances?.length ? <div className='mx-5 mb-5'>
                                <h2 className='prose-TitleLarge my-3 text-black dark:text-onBackgroundII'>NFTS</h2>
                                <div className='flex flex-row overflow-x-scroll gap-2 scrollable-div-1'

                                >
                                    {profileNFTs?.balances?.map((data, i) => {
                                        return (
                                            <NFTCard key={i} item={data} />
                                        )
                                    })}
                                </div>
                            </div> : null
                        }
                        {
                            profileTokens?.balances?.length ? <div className='mx-5 mb-5'>
                                <h2 className='prose-TitleLarge my-3 text-black dark:text-onBackgroundII'>Tokens</h2>
                                <div className=''>
                                    {profileTokens?.balances?.map((data, i) => {
                                        return (
                                            <TokenCard key={i} item={data} />
                                        )
                                    })}
                                </div>
                            </div> : null
                        }</>
            }

        </div>
    )

}

export default PortfolioTab;