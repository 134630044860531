import React from 'react';
import { highlightWord } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';

const ToolTip = ({ text, pointer }) => {
  const navigate = useNavigate();
  const pointerStyle = (type) => {
    switch (type) {
      case "bottom": return `border-b-0 -bottom-[9px] border-t-[#191919] border-r-transparent border-l-transparent left-1/2 -translate-x-1/2  border-[10px] `
      case "top": return `border-t-0 -top-[9px] border-b-[#191919] border-r-transparent border-l-transparent left-1/2 -translate-x-1/2  border-[10px] `
      case "left": return `border-l-0 -left-[9px] border-r-[#191919] border-t-transparent border-b-transparent top-1/2 -translate-y-1/2  border-[10px] `
      case "right": return `border-r-0 -right-[9px] border-l-[#191919] border-b-transparent top-1/2 -translate-y-1/2  border-[10px]`
      default : return ""
    }
  }
  if(!text)
    return
  return (
    <div onMouseDown={(e)=>e.stopPropagation()} onClick={(e)=>e.stopPropagation()} className='min-h-4 rounded-sm absolute bg-[#191919] flex items-center justify-center z-[102] px-2 py-1 min-w-40'>
      <p className='text-onBackgroundII min-w-40 text-left'>{highlightWord(text, true, navigate)}</p>
      <div className={`${pointerStyle(pointer)} absolute`}></div>
    </div>
  );
};

export default ToolTip;
