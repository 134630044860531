import { usePrivy } from '@privy-io/react-auth';
import { useWallets } from '@privy-io/react-auth';
import { ethers } from 'ethers';
import { CHAIN_ID } from '../utils/constants';
import { MOXIE_ABI } from '../utils/moxieABI';

import { gql } from "graphql-request";
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { BrowserProvider } from 'ethers-v6';
import { useEffect, useState } from 'react';
import { claimDailyMoxieReward } from '../services/api';
import { displaySnackbar } from '../features/thunkMiddleware';
import { useDispatch } from 'react-redux';



const ABI = [
    "function balanceOf(address owner) view returns (uint)",
    "function decimals() view returns (uint8)",
    "function transfer(address to, uint amount) returns (bool)"
]

const useMoxieBalance = () => {
    const { open } = useWeb3Modal()

    const { wallets } = useWallets();
    const { sendTransaction} = usePrivy()
    const wallet = wallets.find(w => w.connectorType == "embedded")
    // const nonEmbeddedWallet = wallets.find(w => w.connectorType != "embedded")
    const { walletProvider } = useWeb3ModalProvider()
    const { address, isConnected } = useWeb3ModalAccount()

    const dispatch = useDispatch()

    const [claimStatus, setClaimStatus] = useDailyResetState('dailyRewardClaimStatus', 'unclaimed');
    const [vestedClaimStatus, setVestedClaimStatus] = useDailyResetState('vestedRewardClaimStatus', 'unclaimed');

    const [isLoadingMoxieBalance, setIsLoadingMoxieBalance] = useState(false)
    const [isLoadingPrivyMoxieBalance, setIsLoadingPrivyMoxieBalance] = useState(false)
    const [isLoadingUserVestedMoxie, setIsLoadingUserVestedMoxie] = useState(false)

    const [isClaimingDailyRewards, setIsClaimingDailyRewards] = useState(false)
    const [isClaimingVestedAirdrop, setIsClaimingVestedAirdrop] = useState(false)
    const [isFundingMoxie, setIsFundingMoxie] = useState(false)

    const [moxieBalance, setMoxieBalance] = useState(0)
    const [userPrivyMoxieBalance, setUserPrivyMoxieBalance] = useState(0)


    const getMoxieBalance = async () => {
        if (isConnected && walletProvider) {
            try {
                setIsLoadingMoxieBalance(true)
                const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                const signer = await ethersProvider.getSigner()
                const contract = new ethers.Contract('0x8C9037D1Ef5c6D1f6816278C7AAF5491d24CD527', ABI, signer);
                const balance = await contract.balanceOf(address);
                const decimals = await contract.decimals();
                const formattedBalance = ethers.utils.formatUnits(balance, decimals);
                setMoxieBalance(formattedBalance)
                setIsLoadingMoxieBalance(false)

                return formattedBalance
            } catch (error) {
                console.error('Error getting Moxie balance:', error);
                return '0';
            }
        } else {
            return '0'
        }
    }

    const getUserPrivyMoxieBalance = async () => {
        if (wallet) {
            try {
                setIsLoadingPrivyMoxieBalance(true)
                const provider = wallet.getEthersProvider();
                const signer = (await provider).getSigner();
                const contract = new ethers.Contract('0x8C9037D1Ef5c6D1f6816278C7AAF5491d24CD527', ABI, signer);

                const decimals = await contract.decimals();
                
                const balance = await contract.balanceOf(wallet?.address);
                const formattedBalance = ethers.utils.formatUnits(balance, decimals);
                setUserPrivyMoxieBalance(formattedBalance)
                setIsLoadingPrivyMoxieBalance(false)
                return formattedBalance
            } catch (error) {
                console.error('Error getting Moxie balance:', error);
                return '0';
            }
        } else {
            return '0'
        }
    }

    const getUserMoxieVestingContract = async (address) => {
        setIsLoadingUserVestedMoxie(true)
        const getUserVestingContract = gql`
        query MyQuery($beneficiary: Bytes!) {
          tokenLockWallets(where: {beneficiary: $beneficiary}) {
            address: id
          }
        }
      `;

        const fetchContract = fetch('https://api.studio.thegraph.com/query/23537/moxie_vesting_mainnet/version/latest', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                query: getUserVestingContract,
                variables: { beneficiary: address }
            })
        }).then((response) => {
              if (response.status >= 400) {
                throw new Error("Error fetching data");
              } else {
                return response.json();
              }
            })
            .then((data) => {
                return data.data
            }).catch((error) => {
                console.log('error', error)
            });
      
        return fetchContract
    }

    const getUserVestedMoxie = async (contractAddress) => {   
        if (walletProvider) {
            try {
                const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                const signer = await ethersProvider.getSigner()
                const contract = new ethers.Contract(contractAddress, MOXIE_ABI, signer);
                const currentBalance = await contract.currentBalance();
                // const vestedAmount = await contract.vestedAmount();
                const releasableAmount = await contract?.releasableAmount();
                
                const formattedCurrentBalance = parseFloat(ethers.utils.formatUnits(currentBalance, 18));
                // const formattedVestedAmount = parseFloat(ethers.utils.formatUnits(vestedAmount, 18));
                const formattedReleasableAmount = parseFloat(ethers.utils.formatUnits(releasableAmount, 18));
        
                const unVestedAirdrop = formattedCurrentBalance - formattedReleasableAmount;
                const formattedBalance = ethers.utils.formatUnits(currentBalance, 18);
        
                setIsLoadingUserVestedMoxie(false)
                return {
                    // vestedAmount: formattedVestedAmount.toString(),
                    currentBalance: formattedBalance,
                    releasableAmount: formattedReleasableAmount.toString(),
                    unVestedAirdrop: unVestedAirdrop.toString(),
                    vestedAirdrop: formattedReleasableAmount.toString(),
                    vestedContract: contractAddress
                }
            } catch (error) {
                setIsLoadingUserVestedMoxie(false)
                return {
                    currentBalance: '0',
                    releasableAmount: '0',
                    unVestedAirdrop: '0',
                    vestedAirdrop: '0',
                    vestedContract: null
                }
            }
        }
    }

    const getUserFanTokensMoxie = async (address) => {
        if (wallet) {
            const getUserFanTokens = gql`
                query MyQuery($userAddresses: [ID!]) {
                    users(where: { id_in: $userAddresses }) {
                    portfolio {
                        balance
                        buyVolume
                        sellVolume
                        subjectToken {
                            name
                            symbol
                            currentPriceInMoxie
                        }
                    }
                    }
                }
            `;

            const fetchTokens = fetch('https://api.studio.thegraph.com/query/23537/moxie_protocol_stats_mainnet/version/latest', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    query: getUserFanTokens,
                    variables: { userAddresses: [address] }
                })
            }).then((response) => {
                if (response.status >= 400) {
                    throw new Error("Error fetching data");
                } else {
                    return response.json();
                }
            }).then((data) => {
                console.log('data f contract', data)
                return data.data
            }).catch((error) => {
                console.log('error', error)
            });

            return fetchTokens
           
        }
    }

    const claimVestedMoxie = async (contractAddress) => {
        if (walletProvider) {
            setIsClaimingVestedAirdrop(true)
            try {
                const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                const signer = await ethersProvider.getSigner()
                const contract = new ethers.Contract(contractAddress, MOXIE_ABI, signer);
                
                const releasableAmount = await contract.releasableAmount();
                if (releasableAmount.eq(0)) {
                    setVestedClaimStatus('claimed');
                    throw new Error("No vested Moxie available to claim at this time.");
                }

                const gasEstimate = await contract.estimateGas.release({ gasLimit: 500000 });
                
                const txReceipt = await contract.release({ gasLimit: gasEstimate.mul(120).div(100) });
                setVestedClaimStatus('claimed');
                setTimeout(() => {
                    getMoxieBalance()
                }, 2000);
                
                return txReceipt;
            } catch (error) {
                console.error('Error claiming vested Moxie:', error);
                if (error.message.includes("!auth")) {
                    console.log("You are not authorized to claim vested Moxie from this contract.");
                    dispatch(displaySnackbar('Not Authorized'))
                } else if (error.message.includes("No vested Moxie available")) {
                    console.log('No Vested Moxie available'); // Rethrow the custom error
                } else {
                    console.log("An error occurred while claiming vested Moxie. Please try again later.");
                }
            } finally {
                setIsClaimingVestedAirdrop(false)
            }
        } else {
            throw new Error("Wallet provider not available.");
        }
    }

    const sendMoxie = async (amount, username, toAddress) => {
        if (wallet) {
            try {
                const provider = wallet.getEthersProvider();
                const signer = (await provider).getSigner();
                const contract = new ethers.Contract('0x8C9037D1Ef5c6D1f6816278C7AAF5491d24CD527', ABI, signer);

                const decimals = await contract.decimals();
                const amountInWei = ethers.utils.parseUnits(amount.toString(), decimals);
                
                const txData = contract.interface.encodeFunctionData('transfer', [toAddress, amountInWei]);
                const requestData = {
                    to: contract.address,
                    data: txData,
                    chain_id: CHAIN_ID,
                    gasLimit: 1000000,
                };

                const uiConfig = {
                    header: `Send Moxie to ${username}`,
                    description: '',
                    buttonText: 'Send Now',
                };

                try {
                    const txReceipt = await sendTransaction(requestData, uiConfig);
                    return txReceipt;            
                } catch (error) {
                    console.error('Error sending Moxie:', error);
                    throw error;
                }
            } catch (error) {
                console.error('Error sending Moxie:', error);
                throw error;
            }
        }
    }

    const claimDailyMoxie = async () => {
        if (walletProvider) {
            try {
                setIsClaimingDailyRewards(true)
                await claimDailyMoxieReward().then((res) => {
                    setClaimStatus('claimed');
                    setTimeout(() => {
                        getMoxieBalance()
                    }, 2000);
                    return res;
                }).catch((err) => {
                    if (err?.response?.data?.error == "graphql: No pending rewards for claim") {
                        setClaimStatus('claimed');
                        return err;
                    } else {
                        console.log('err', err)
                    }
                })
                
            } catch (error) {
                console.error('Error claiming daily Moxie reward:', error);
            } finally {
                setIsClaimingDailyRewards(false)
            }
        } else {
            throw new Error("Wallet provider not available.");
        }
    }

    const fundMoxie = async (amount) => {
        if (walletProvider) {
            try {
                setIsFundingMoxie(true)
                const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
                const signer = await ethersProvider.getSigner()
                const contract = new ethers.Contract('0x8C9037D1Ef5c6D1f6816278C7AAF5491d24CD527', ABI, signer);
                const decimals = await contract.decimals();
                
                const amountInWei = ethers.utils.parseUnits(amount.toString(), decimals);
                
                const gasEstimate = await contract.estimateGas.transfer(wallet?.address, amountInWei);
                const gasLimit = gasEstimate.mul(120).div(100);
    
                const tx = await contract.transfer(wallet?.address, amountInWei, { gasLimit: gasLimit });
                
                const receipt = await tx.wait();
                setIsFundingMoxie(false)
                dispatch(displaySnackbar('Moxie funded successfully'))
                return receipt;
                
            } catch (error) {
                setIsFundingMoxie(false)
                dispatch(displaySnackbar('Error funding Moxie'))
                
            }
        } else {
            open()
            throw new Error("Wallet provider not available.");
        }
    }

    const withdrawPrivyMoxie = async (amount, toAddress) => {
        if (wallet) {
            try {
                const provider = wallet.getEthersProvider();
                const signer = (await provider).getSigner();
                const contract = new ethers.Contract('0x8C9037D1Ef5c6D1f6816278C7AAF5491d24CD527', ABI, signer);

                const decimals = await contract.decimals();
                const amountInWei = ethers.utils.parseUnits(amount.toString(), decimals);
                
                const txData = contract.interface.encodeFunctionData('transfer', [toAddress, amountInWei]);
                const requestData = {
                    to: contract.address,
                    data: txData,
                    chain_id: CHAIN_ID,
                    gasLimit: 1000000,
                };

                try {
                    const txReceipt = await sendTransaction(requestData);
                    return txReceipt;            
                } catch (error) {
                    console.error('Error sending Moxie:', error);
                    throw error;
                }
                
            } catch (error) {
                setIsFundingMoxie(false)
                dispatch(displaySnackbar('Error funding Moxie'))
                
            }
        } else {
            console.log('wallet not provided')
        }
    }

    return {
        getMoxieBalance,
        getUserPrivyMoxieBalance,
        getUserMoxieVestingContract,
        getUserVestedMoxie,
        sendMoxie,
        claimVestedMoxie,
        fundMoxie,
        claimDailyMoxie,
        withdrawPrivyMoxie,

        claimStatus,
        isClaimingDailyRewards,
        isClaimingVestedAirdrop,
        vestedClaimStatus,
        isFundingMoxie,

        moxieBalance,
        userPrivyMoxieBalance,

        isLoadingMoxieBalance,
        isLoadingPrivyMoxieBalance,
        isLoadingUserVestedMoxie
    };
};


const useDailyResetState = (key, initialValue) => {
    const [state, setState] = useState(() => {
        const storedValue = localStorage.getItem(key);
        if (storedValue) {
            const { value, expiry } = JSON.parse(storedValue);
            if (new Date().getTime() < expiry) {
                return value;
            }
        }
        return initialValue;
    });

    useEffect(() => {
        const setStateAndStorage = (newValue) => {
            setState(newValue);
            const now = new Date();
            const expiry = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            expiry.setHours(0, 0, 0, 0); // Set to midnight
            localStorage.setItem(key, JSON.stringify({ value: newValue, expiry: expiry.getTime() }));
        };

        setStateAndStorage(state);

        const checkExpiry = () => {
            const storedValue = localStorage.getItem(key);
            if (storedValue) {
                const { expiry } = JSON.parse(storedValue);
                if (new Date().getTime() >= expiry) {
                    setStateAndStorage(initialValue);
                }
            }
        };

        const interval = setInterval(checkExpiry, 60000); // Check every minute

        return () => clearInterval(interval);
    }, [key, state, initialValue]);

    return [state, setState];
};

export default useMoxieBalance;
