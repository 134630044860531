import React, { useEffect, useRef, useState } from 'react';
import CustomModal from '../../components/UI/CustomModal';
import SignerSignin from '../../hooks/modal/SignerSignin';
import { useSelector, useDispatch } from 'react-redux';
import { setSignerModal } from '../../features/globalStateSlice';
import { displaySnackbar } from '../../features/thunkMiddleware';
import { claimConnectId, connectWithUserConnectID, getCurrentUserProfile, getUserDetailsFromConnectId } from '../../services/api';
import { usePrivy } from '@privy-io/react-auth';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useSubmitCast from '../../components/Modals/useSubmitCast';
import html2canvas from 'html2canvas';
import basecampPng from '../../assets/images/basecamp_bg.png';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import useAccount from '../../hooks/account';

function formatWithOrdinal(date) {
  const day = date.getDate();
  let ordinal;

  if (day > 3 && day < 21) ordinal = 'th';
  else if (day % 10 === 1) ordinal = 'st';
  else if (day % 10 === 2) ordinal = 'nd';
  else if (day % 10 === 3) ordinal = 'rd';
  else ordinal = 'th';

  return `${date.toLocaleString('en-US', { month: 'short' })} ${day}${ordinal}, ${date.getFullYear()}`;
}

const ConnectPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { openSignerModal } = useSelector(state => state.app);
  const { handleSignerValidate, user } = useAccount();
  const { ready, authenticated, login, getAccessToken, logout } = usePrivy();
  const { isSubmittingCast, handleSubmitCast } = useSubmitCast();

  const desktopRef = useRef(null);
  const [setupConnect, setSetupConnect] = useState(false);
  const [meetOtherusers, setMeetOtherusers] = useState(false);
  const [screenShot, setScreenShot] = useState([]);
  const [isShared, setIsShared] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [btnBeforeSS, setBtnBeforeSS] = useState(false);

  const handleCloseSignerModal = () => {
    dispatch(setSignerModal(false));
  };

  const { data: currUserProfileData, isLoading: isProfileLoading, refetch: profileDataRefetch } = useQuery({
    queryKey: ['getCurrentUserProfile'],
    queryFn: getCurrentUserProfile,
  });

  const { data: otherUserProfilData, isLoading: profileDataLoading, refetch: profileRefetch } = useQuery({
    queryKey: ['userProfile'],
    queryFn: () => getUserDetailsFromConnectId(id),
    enabled: !!id
  });

  useEffect(() => {
    if (otherUserProfilData?.success === false && otherUserProfilData?.can_self_connect === true) {
      setSetupConnect(true);
      return;
    }
    if (otherUserProfilData?.farcaster_user?.fid === currUserProfileData?.data?.farcaster_user?.fid) {
      setMeetOtherusers(true);
      return;
    }
    if (otherUserProfilData?.farcaster_user?.fid !== currUserProfileData?.data?.farcaster_user?.fid) {
      setMeetOtherusers(false);
      const connectWithUser = async () => {
        try {
          let response = await connectWithUserConnectID(id);
          if (response?.success) {
            dispatch(displaySnackbar('Successfully connected with user'));
          }
        } catch (error) {
          console.log('error', error);
        }
      }
      connectWithUser();
      return;
    }
  }, [otherUserProfilData]);

  useEffect(() => {
    if (!authenticated) {
      login();
    }
    if (!user?.signer_key_active) {
      handleSignerValidate();
    }
  }, [user]);

  const claimConnectID = async () => {
    try {
      const response = await claimConnectId(id);
      if (response.data?.success) {
        setSetupConnect(false);
        profileRefetch();
        setMeetOtherusers(true);
        dispatch(displaySnackbar('Successfully claimed connect ID'));
      }
    } catch (error) {
      console.error('Error checking or updating signer:', error);
    }
  };

  const waitForImagesToLoad = (element) => {
    const imgElements = element.getElementsByTagName('img');
    const imgPromises = Array.from(imgElements).map((img) => {
      return new Promise((resolve) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = resolve;
          img.onerror = resolve;  // handle error by resolving the promise
        }
      });
    });
    return Promise.all(imgPromises);
  };

  const handleCast = async () => {
    setBtnBeforeSS(true);
    if (desktopRef.current) {
      try {
        await waitForImagesToLoad(desktopRef.current);
        document.fonts.ready.then(async () => {
          const canvas = await html2canvas(desktopRef.current, {
            useCORS: true,
            logging: true,
            allowTaint: false
          });
          setIsSubmitting(true);
          setBtnBeforeSS(false);
          const imgData = canvas.toDataURL('image/png');
          saveImage(imgData);
        });
      } catch (error) {
        console.error('Error capturing screenshot:', error);
        const usersMeetName = `@${currUserProfileData?.data?.farcaster_user?.username} & @${otherUserProfilData?.farcaster_user?.username}
    met at Farcaster Developer Forum/Day 2024 on ${formatWithOrdinal(new Date())}`;

        handleSubmitCast(usersMeetName, undefined, 'farcaster', undefined, undefined, undefined, undefined, undefined, false);
      }
    }
  };

  const saveImage = (imgData) => {
    try {
      const byteString = atob(imgData.split(',')[1]);
      const mimeString = imgData.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      const file = new File([blob], `Screenshot Farcaster ${new Date().toISOString().replace(/:/g, '-')}.png`, {
        type: mimeString,
        lastModified: Date.now(),
      });

      setScreenShot(imgData);

      const usersMeetName = `@${currUserProfileData?.data?.farcaster_user?.username} & @${otherUserProfilData?.farcaster_user?.username}
    met at Farcaster Developer Forum/Day 2024 on ${formatWithOrdinal(new Date())}`;

      handleSubmitCast(usersMeetName, [{ url: file }], 'farcaster', undefined, undefined, undefined, undefined, undefined, false)
        .then(() => {
          setIsShared(true);
          dispatch(displaySnackbar('Successfully shared to Farcaster!'));
        })
        .catch((error) => {
          console.error('Error sharing to Farcaster:', error);
          dispatch(displaySnackbar('Failed to share to Farcaster. Please try again.'));
        })
        .finally(() => {
          setIsSubmitting(false);
        });

    } catch (error) {
      console.error('Error processing image data:', error);
      setIsSubmitting(false);
    }
  };

  if (profileDataLoading) {
    return (
      <div className='font-[Inter]'>
        <div className='w-full flex justify-center items-start md:items-center relative'>
          <img src={basecampPng} alt='basecamp' className='absolute top-0 left-0 size-full min-h-screen bg-cover hidden md:block' />
          <div className='bg-white min-w-[400px] min-h-[300px] z-[100] translate-y-20 flex flex-col justify-center items-center'>
            <span className="loading loading-spinner loading-lg text-primary"></span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div ref={desktopRef} className='font-[Inter] size-full'>
      <div className='w-full flex justify-center items-start md:items-center relative'>
        <img src={basecampPng} alt='basecamp' className='absolute top-0 left-0 size-full min-h-screen bg-cover' style={{ zIndex: 20 }} />
        {setupConnect
          ? <div className='z-[80] min-w-[400px] min-h-[300px] bg-white border translate-y-20 flex flex-col justify-center items-center'>
            <div>
              <p className='text-subTxtOnLightBg prose-BodyMediumBold text-center'>
                <div className='flex flex-col justify-center items-center text-onPrimary prose-HeaderMedium mt-10'>
                  <img src={currUserProfileData?.data?.farcaster_user?.pfp_url || currUserProfileData?.data?.farcaster_user?.cdn_url || logo} alt='avatar' className='size-12 rounded-full' />
                  @{currUserProfileData?.data?.farcaster_user?.username}
                </div>
              </p>
            </div>
            <button onClick={() => { claimConnectID() }} className='bg-[#B4F6A5] px-6 py-2 rounded-md text-txtOnLightBg prose-HeaderMedium mt-4'>
              Setup Connect
            </button>
          </div>
          : meetOtherusers
            ? <div className='z-[80] min-w-[400px] min-h-[300px] bg-white border translate-y-20 flex flex-col justify-center items-center'>
              <img src={logo} alt='logo' className='size-24 rounded-md' />
              <p className='text-onPrimary prose-HeaderMedium mt-2'>Meet other Wilds</p>
            </div>
            : <div className='w-[90%] relative h-full lg:w-[60%] p-5  border border-black rounded-sm bg-white md:translate-y-20' style={{ zIndex: 30 }}>
              <div className='text-[32px] font-bold  flex justify-between items-center text-txtOnLightBg'>
                <h2>Farcaster Developer</h2>
                <p>001</p>
              </div>

              <div className='flex justify-between mt-2 items-center border-t border-b dark:border-black/70 text-txtOnLightBg dark:text-onPrimary h-[24px]'>
                <div className='flex items-center gap-2'>
                  <div className='text-[6px] text-txtOnLightBg'>
                    <p>Sept</p>
                  </div>
                  <div className='text-[10px] md:text-[14px] font-[Inter] text-subTxtOnLightBg'> 606 Venice Blvd., Venice, CA 90291, USA</div>
                </div>
                <div className='text-[10px] md:text-[14px] font-[Inter] text-subTxtOnLightBg'>33.9891° N, -118.4615° W</div>
              </div>
              
              <div className='mt-8'>
                <div className='flex flex-col md:flex-row justify-center items-start md:justify-between md:items-center'>

                  <div className='relative'>
                    <div className='relative'>
                      <Link to={`/profile/${currUserProfileData?.data?.farcaster_user?.username}`}><img src={currUserProfileData?.data?.farcaster_user?.pfp_url || currUserProfileData?.data?.farcaster_user?.cdn_url || logo} alt='weather' className='size-[160px] border border-black/40 rounded-md z-[60]' /></Link>
                      {profileDataLoading
                        ? <div className='size-[160px] absolute bg-surface/90 animate-pulse top-8 left-24 border border-black/40 rounded-md' />
                        : <Link to={`/profile/${otherUserProfilData?.farcaster_user?.username}`}><img src={otherUserProfilData?.farcaster_user?.pfp_url || otherUserProfilData?.farcaster_user?.cdn_url || logo} alt='weather' className='size-[160px] absolute top-8 left-24 border border-black/40 rounded-md z-[70]' /></Link>
                      }
                    </div>
                  </div>

                  <div className='flex flex-col'>
                    <div className='mt-12 flex flex-col break-all'>
                      <Link to={`/profile/${currUserProfileData?.data?.farcaster_user?.username}`} className='prose-HeaderLarge  text-txtOnLightBg '>{currUserProfileData?.data?.farcaster_user?.display_name} <span className='text-txtOnLightBg font-bold font-[inter]'>&</span></Link>
                      <Link to={`/profile/${otherUserProfilData?.farcaster_user?.username}`} className='prose-HeaderLarge  text-txtOnLightBg '>{otherUserProfilData?.farcaster_user?.display_name}</Link>
                    </div>

                    <div className='mt-2'>
                      <p className='prose-HeaderMedium text-black/70'>met at</p>
                      <p className='prose-HeaderMedium text-black/70'>Farcaster Developer</p>
                      <p className='prose-HeaderMedium text-black/70'>Forum/Day 2024</p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='text-[14px] text-subTxtOnLightBg mt-8 md:mt-14 mb-4'>
                    <p>{formatWithOrdinal(new Date())}</p>
                  </div>
                  {!isSubmitting && !isShared && !btnBeforeSS ? (
                    <button
                      onClick={handleCast}
                      className='flex flex-col text-center w-full rounded-sm h-full'
                    >
                      <div className='bg-background h-[50px] text-white w-full md:text-[28px] prose-HeaderMedium font-semibold px-4 rounded-sm text-center flex items-center justify-center'>
                        Share on Farcaster
                      </div>
                      <div className='flex justify-center py-1 bg-primary text-txtOnLightBg w-full rounded-sm'>
                        <div className='h-[20px] flex justify-center items-center gap-2'>
                          <span className='text-txtOnLightBg prose-BodyLargeBold'>via</span>
                          <img src={logo} alt='logo' className='size-5 h-full' />
                          <span className='text-txtOnLightBg prose-BodyLargeBold'>Wildcard</span>
                        </div>
                      </div>
                    </button>
                  ) : btnBeforeSS ? (
                    <div className='flex justify-center items-center h-[70px] bg-background rounded-sm'>
                      <img src={logo} alt='logo' className='size-12' />
                    </div>
                  ) : isSubmitting ? (
                    <div className='flex justify-center items-center h-[70px] bg-background rounded-sm'>
                      <span className="loading loading-spinner loading-lg text-primary"></span>
                    </div>
                  ) : (
                    <div className='bg-background h-[50px] text-white w-full md:text-[28px] prose-HeaderMedium font-semibold px-4 rounded-sm text-center flex items-center justify-center'>
                      Shared on Farcaster
                    </div>
                  )}
                </div>
              </div>
            </div>
        }
      </div>
      <CustomModal isOpen={openSignerModal} closeModal={() => handleCloseSignerModal()} modalPositionTop='50' >
        <SignerSignin closeModal={() => handleCloseSignerModal()} />
      </CustomModal>
    </div>
  )
};

export default ConnectPage;