import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { isDesktopDevice, isIOSDevice } from '../../utils/helper';
import { useLocation } from 'react-router-dom';

const CustomModal = ({ isOpen, closeModal, children, showSlideAnimation = false, modalPositionTop, blurValue = 4 }) => {
    const isDesktop = isDesktopDevice();
    const isIos = isIOSDevice();
    const { pathname } = useLocation()
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflow = isOpen ? 'hidden' : 'auto';

        return () => {
            body.style.overflow = 'auto'
        }
      }, [isOpen])

    useEffect(() => {
        window.addEventListener('resize', () => {
            setDeviceWidth(window.innerWidth);
        })

        return () => {
            window.removeEventListener('resize', () => {
                setDeviceWidth(window.innerWidth);
            })
        }
    }, [])

    const handleCloseModal = (event) => {
        event.stopPropagation();
        closeModal(event);
    };

    const initialTransform = `translate(-50%, -${modalPositionTop}%)`;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            shouldReturnFocusAfterClose={false}
            contentLabel="Custom Modal"
            style={{
                overlay: {
                    backgroundColor: `rgba(0, 0, 0, ${(pathname?.includes('tippingarena') && isIos) ? 0.9 : 0.5})`,
                    backdropFilter: `blur(${blurValue}px)`,
                    zIndex: 102
                },
                content: {
                    position: 'absolute',
                    top: modalPositionTop + '%',
                    left: '50%',
                    transform: initialTransform,
                    border: 'none',
                    background: 'none',
                    overflow: 'visible',
                    padding: '0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    width: '100%',
                    minWidth: deviceWidth > 660 ? '32rem' : '100%',
                    maxWidth: deviceWidth > 660 ? 'fit-content' : '100%',
                    height: 'fit-content',
                    transition: showSlideAnimation ? 'transform 0.3s ease-out' : 'none'
                }
            }}
        >
            {children}
        </Modal>
    );
};

export default CustomModal;
