import { CaretRight } from "@phosphor-icons/react";

const QuestSkeletonLoading = ({ i }) => {
    return (
        <div
            key={i}
            className="w-full bg-surface/50 dark:bg-surface mt-4 bg-opacity-80 py-4 px-5 min-h-[81px] cursor-pointer"
        >
            <h2 className="h-6 w-40 animate-pulse bg-surface/50 dark:bg-surface"></h2>
            <div className="flex flex-row mt-1 justify-between items-baseline">
                <div className="h-[21px] animate-pulse bg-surface/50 dark:bg-surface w-20">
                </div>
                <div className="flex items-center gap-[6px]">
                    <span className="h-[21px] w-20 animate-pulse bg-surface/50 dark:bg-surface"></span>
                    <CaretRight size={12} color="#ffffff" />
                </div>
            </div>
        </div>
    )
}

export default QuestSkeletonLoading;