import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { followChannel, getChannelDetailed, unfollowChannel } from '../services/api';
import { Star, Users } from '@phosphor-icons/react';
import { useDispatch } from 'react-redux';
import { displaySnackbar } from '../features/thunkMiddleware';
import Portal from './UI/ReactPortal'; // Make sure to import your Portal component

const ChannelHoverCard = ({ children, data, channelid }) => {
    const params = useParams();
    const { channelId } = params;
    const navigate = useNavigate();

    const [showHoverCard, setShowHoverCard] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const triggerRef = useRef(null);
    const timeoutRef = useRef(null);
    const fadeTimeoutRef = useRef(null);
    const dispatch = useDispatch();

    const [starFill, setStarFill] = useState(false);
    const [ channelInfo, setChannelInfo] = useState(data || {});

    const { data: channelDetails = {}, isLoading: channelLoading, refetch: refetchChannelDetails } = useQuery({
        queryKey: ['channelDetails', channelid],
        queryFn: () => getChannelDetailed(channelid),
        enabled: showHoverCard && !!channelid
    });

    useEffect(() => {
        setChannelInfo(channelDetails?.data?.channel_info || data)
    }, [channelDetails?.data?.channel_info, data])

    useEffect(() => {
        setStarFill(channelDetails?.data?.following)
    }, [channelDetails?.data?.following])


    const [fade, setFade] = useState(false);

    const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        clearTimeout(fadeTimeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setShowHoverCard(true);
        }, 300);
        setTimeout(() => {
            setFade(true);
        }, 500);
    };

    const handleMouseLeave = () => {
        clearTimeout(timeoutRef.current);
        fadeTimeoutRef.current = setTimeout(() => {
            setFade(false);
        }, 300);
        timeoutRef.current = setTimeout(() => {
            setShowHoverCard(false);
        }, 400);
    };

    useEffect(() => {
        const calculatePosition = () => {
            if (showHoverCard && triggerRef.current) {
                const triggerRect = triggerRef.current.getBoundingClientRect();
                const { innerWidth, innerHeight } = window;
                const hoverCardWidth = 300; // Adjust based on your actual hover card width
                const hoverCardHeight = 250; // Adjust based on your actual hover card height

                let top, left;

                // Determine vertical position
                if (triggerRect.bottom + hoverCardHeight <= innerHeight) {
                    top = triggerRect.bottom;
                } else if (triggerRect.top - hoverCardHeight >= 0) {
                    top = triggerRect.top - hoverCardHeight;
                } else {
                    top = triggerRect.top;
                }

                // Determine horizontal position
                if (triggerRect.left + hoverCardWidth <= innerWidth) {
                    left = triggerRect.left;
                } else if (triggerRect.right - hoverCardWidth >= 0) {
                    left = triggerRect.right - hoverCardWidth;
                } else {
                    left = triggerRect.left;
                }

                setPosition({ top, left });
            }
        };

        calculatePosition();

        const timer = setTimeout(calculatePosition, 50);
        window.addEventListener('resize', calculatePosition);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', calculatePosition);
        };
    }, [showHoverCard]);

    const handleFollow = async () => {
        setStarFill(true);
        try {
            const res = await followChannel(channelInfo?.id)
            const data = res?.data
            if (data[1]) {
                dispatch(displaySnackbar('Added to favorites'))
            }
        }
        catch (err) {
            setStarFill(false);
            dispatch(displaySnackbar('Failed to add'))
        }
        finally {
            setTimeout(() => {
                refetchChannelDetails();
            }, 2000)
        }
    }
    const handleUnfollow = async () => {
        setStarFill(false)
        try {
            const res = await unfollowChannel(channelInfo?.id)
            const data = res?.data
            if (data[0] == 1) {
                dispatch(displaySnackbar('Removed from favorites'))
            }
        }
        catch (err) {
            setStarFill(true);
            dispatch(displaySnackbar('Failed to remove'))
        }
        finally {
            setTimeout(() => {
                refetchChannelDetails();
            }, 2000)
        }
    }

    const handleChannelNavigate = () => {
        navigate(`/channel/${channelid}`)
    }

    const handleClose = () => {
        setShowHoverCard(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleClose);
        document.addEventListener('scroll', handleClose);
        return () => {
            document.removeEventListener('click', handleClose);
            document.removeEventListener('scroll', handleClose);
        };
    }, []);

    return (
        <div
            ref={triggerRef}
            className="cursor-pointer inline items-start gap-1 relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                handleChannelNavigate();
            }}
            onScroll={handleClose}
        >
            {children}
            {showHoverCard && channelId !== channelid && (
                <Portal>
                    <div
                        className={`fixed z-[1000] bg-gray-300 dark:bg-surface p-4 rounded-lg shadow-lg min-w-64 max-w-80 w-fit transition duration-300 
                            ${fade ? 'opacity-100' : 'opacity-0'}`}
                        style={{
                            top: `${position.top}px`,
                            left: `${position.left}px`,
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {channelLoading && !data ? (
                            <div className="animate-pulse">
                                <div className="h-10 w-10 bg-surface dark:bg-gray-300 rounded-full mb-2"></div>
                                <div className="h-4 w-3/4 bg-surface dark:bg-gray-300 rounded mb-2"></div>
                                <div className="h-4 w-1/2 bg-surface dark:bg-gray-300 rounded"></div>
                            </div>
                        ) : (
                            <div>
                                <div className='flex justify-between items-center'>
                                    <div className='flex items-center flex-row gap-2'>
                                        <img src={channelInfo?.image_url || logo} alt="base" className='clip-polygon aspect-square bg-center bg-no-repeat bg-cover rounded-full h-10 w-10' />
                                        <div className='flex flex-col'>
                                            <span className='prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground'>{channelInfo?.name || 'Channel does not exist'}</span>
                                            <span className='prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII'>/{channelInfo?.id}</span>
                                        </div>
                                    </div>
                                    {
                                        channelLoading ? <div className='h-6 w-6 rounded-full animate-pulse bg-surface dark:bg-gray-300' /> : <>
                                            {!starFill && <button disabled={channelLoading} onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleFollow() }} className='p-2 rounded-full hover:bg-onBackground/50 trasition duration-[450ms]'>
                                                <Star size={24} className=' fill-primary' />
                                            </button>}

                                            {starFill && <button disabled={channelLoading} onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleUnfollow() }} className='p-2 rounded-full hover:bg-onBackground/50 trasition duration-[450ms]'>
                                                <Star size={24} weight='fill' className=' fill-primary' />
                                            </button>}</>
                                    }
                                </div>
                                <p className=' text-txtOnLightBg dark:text-onBackground prose-BodyLarge my-4 break-words w-full'>{channelInfo?.description}</p>
                                <div className='flex items-center mt-4'>
                                    <span>
                                        <Users size={20} className='fill-gray-500 dark:fill-onBackgroundIII' weight='bold' />
                                    </span>
                                    {channelLoading ? <div className='h-4 w-10 bg-surface dark:bg-gray-300 animate-pulse mx-2' /> : <p className='prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground ps-2'>
                                        {channelInfo?.follower_count_display?.display_value}
                                    </p>}
                                    <p className='text-subTxtOnLightBg dark:text-onBackgroundII prose-BodyMedium px-1'>Followers </p>
                                </div>
                            </div>
                        )}
                    </div>
                </Portal>
            )}
        </div>
    );
};

export default ChannelHoverCard;
