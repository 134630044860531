import { Empty, Plus } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import introJs from 'intro.js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import CastCards from '../../components/Cards/Casts/CastCards';
import CastSkeletonLoading from '../../components/Cards/Casts/CastSkeletonLoading';
import CastModal from '../../components/Modals/CastModal';
import TopHeader from '../../components/Sidebars/TopHeader';
import CustomModal from '../../components/UI/CustomModal';
import { setFeedSelectedTab, setSelectedCurrency } from '../../features/globalStateSlice';
import useAccount from '../../hooks/account';
import { getCastsPersonalized, getCastsTrending, getMostTippedByCurrency } from '../../services/api';
import { FEED_TAB_CHOICES, getSelectedCurrencyLocalStorage } from '../../utils/constants';
import TrendingCard from './components/TrendingCard';

const initialTabs = [
  { name: FEED_TAB_CHOICES.FOLLOWING, isActive: true, id: 'following' },
  // { name: FEED_TAB_CHOICES.CARDS, isActive: false, id: 'cards' },
  { name: FEED_TAB_CHOICES.MOST_TIPPED, isActive: false, id: 'most_tipped' }
]

let tipPage = 2; // for most tipped
let tipMoxieCursor = ""; // for most tipped moxie cursor

const FeedPage = ({ showHeader = false }) => {
  const { tab } = useParams()
  const { selectedCurrency } = useSelector((state) => state.app);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || queryParams.get('currency') || selectedCurrency;
  const { handleSignerValidate } = useAccount();
  const navigate = useNavigate();
  const castRef = useRef(null);
  const observer = useRef();
  const [feedData, setFeedData] = useState([]);
  const [selectedTab, setSelectedTab] = useState('following');
  const [tabs, setTabs] = useState(initialTabs);
  const [isLoading, setIsLoading] = useState(true);
  const [showCastModal, setShowCastModal] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [moreLoading, setMoreLoading] = useState(false);
  const [mostTippedCursor, setMostTippedCursor] = useState("")
  const dispatch = useDispatch();
  const [isCastModalOpen, setIsCastModalOpen] = useState(false);
  const [trendingCursor, setTrendingCursor] = useState(null)
  const [personalizedCursor, setPersonalizedCursor] = useState(null)

  const [isLoadingScoreFollowing, setIsLoadingScoreFollowing] = useState(false)

  const intro = introJs();
  intro.setOptions({
    tooltipClass: 'app-intro-guide',
    exitOnOverlayClick: false,
    showBullets: false,
    showButtons: false,
    disableInteraction: true,
    overlayOpacity: 0.7
  });

  const fetchQueryOptions = {
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10
  };

  const { data: perosnalizedData, isLoading: personalizeIsLoading, refetch: personalizedRefetch } = useQuery({
    queryKey: ['forYouCasts'],
    queryFn: () => getCastsPersonalized({cursor: "", currency: currency}),
    ...fetchQueryOptions,
    enabled: selectedTab === 'following' && !isCastModalOpen
  });

  const {
    data: trendingData,
    isLoading: trendingIsLoading,
    refetch: trendingRefetch
  } = useQuery({
    queryKey: ['castsTrending'],
    queryFn: () => getCastsTrending({cursor: "", currency: currency}),
    ...fetchQueryOptions,
    enabled: selectedTab == 'cards' && !isCastModalOpen
  });

  const {
    data: mostTippedData,
    isLoading: mostTippedIsLoading,
    refetch: mostTippedRefetch
  } = useQuery({
    queryKey: ['mostTipped', selectedCurrency],
    queryFn: () => getMostTippedByCurrency({cursor: "", currency: selectedCurrency, page: 1, channel_id: ''}),
    ...fetchQueryOptions,
    enabled: selectedTab === 'most_tipped' && !isCastModalOpen
  });

  // useEffect(() => {
  //   const prefetchQueriesSequentially = async () => {
  //     await queryClient.prefetchQuery({
  //       queryKey: ['castsTrending'],
  //       queryFn: () => getCastsTrending()
  //     });
  //     await queryClient.prefetchQuery({
  //       queryKey: ['feedPersonalizedFollowing'],
  //       queryFn: () => getCastsFollowing()
  //     });
  //     await queryClient.prefetchQuery({
  //       queryKey: ['mostTipped', selectedCurrency, 1],
  //       queryFn: () => getMostTippedByCurrency(selectedCurrency, 1)
  //     });
  //   };
  //   prefetchQueriesSequentially();
  // }, []);

  useEffect(() => {
    const fetchDataMap = {
      ['following']: perosnalizedData?.data?.casts || [],
      ['cards']: trendingData?.data?.casts || [],
      ['most_tipped']: mostTippedData?.data?.casts || []
    };
    setFeedData(fetchDataMap[selectedTab] || []);
    !!perosnalizedData?.data?.next_cursor && setPersonalizedCursor(perosnalizedData?.data?.next_cursor)
    !!trendingData?.data?.next_cursor && setTrendingCursor(trendingData?.data?.next_cursor)
    !!mostTippedData?.data?.next_cursor && setMostTippedCursor(mostTippedData?.data?.next_cursor)
    tipMoxieCursor = mostTippedData?.data?.next_cursor
  }, [
    perosnalizedData?.data,
    trendingData?.data,
    mostTippedData?.data,
    selectedTab
  ]);

  const fetchMoreData = async () => {
    setMoreLoading(true);
    try {
      const queryFn = {
        ['following']: getCastsPersonalized,
        ['cards']: getCastsTrending,
        ['most_tipped']: getMostTippedByCurrency
      }[selectedTab];

       let response;
   
      if (selectedTab === 'following') {
        response = await queryFn({ cursor: personalizedCursor, currency: currency });
      }
      else if (selectedTab === 'cards') {
        if (trendingCursor === null) return;
        response = await queryFn({ cursor: trendingCursor, currency: currency });
        setTrendingCursor(response?.data?.next_cursor)
      }
      else if (selectedTab === 'most_tipped') {
        response = await queryFn({cursor: tipMoxieCursor, currency: selectedCurrency, page: tipPage, channel_id: ''});
        tipPage = tipPage + 1
        tipMoxieCursor = response?.data?.next_cursor
        setMostTippedCursor(response?.data?.next_cursor)
      }

      if (selectedTab == 'most_tipped') {
        setMostTippedCursor(response?.data?.next_cursor);
      }
      else if (selectedTab === 'cards' && !!response?.data?.next_cursor) {
        setTrendingCursor(response?.data?.next_cursor)
      }
      else if (selectedTab === 'following' && !!response?.data?.next_cursor) {
        setPersonalizedCursor(response?.data?.next_cursor)
      }

      if (response.status === 200 || response.success) {
        let data;
        if (selectedTab === 'most_tipped') {
          data = response?.data?.casts || [];
        } else {
          data = response?.data?.casts || [];
        }
        setFeedData((prevData) => [...prevData, ...data]);
        if (!data.length) setHasMore(false);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setHasMore(false);
    } finally {
      setMoreLoading(false);
    }
  };


  const handleRefetch = () => {
    setIsLoadingScoreFollowing(true)
    switch (selectedTab) {
      case 'following':
        return personalizedRefetch().then(() => setIsLoadingScoreFollowing(false));
      case 'cards':
        return trendingRefetch().then(() => setIsLoadingScoreFollowing(false));
      case 'most_tipped':
        return mostTippedRefetch().then(() => setIsLoadingScoreFollowing(false));
      default:
        return null;
    }
  };


  const handleTabClick = useCallback((id) => {
    // setFeedData([]);
    handleRefetch();
    setTabs(tabs.map(tab => ({ ...tab, isActive: tab.id === id })));
    setSelectedTab(id);
    dispatch(setFeedSelectedTab(id));
    navigate(`/feed/${id}?currency=${selectedCurrency}`);
  }, [tabs, selectedCurrency]);

  useEffect(() => {
    const tabName = tab || initialTabs[0].id;
    const currencyName = currency;
    handleRefetch();
    setTabs(tabs.map(tab => ({ ...tab, isActive: tab.id === tabName })));
    setSelectedTab(tabName);
    dispatch(setFeedSelectedTab(tabName));
    dispatch(setSelectedCurrency(currencyName));
    return () => {
      dispatch(setFeedSelectedTab(''));
    };
  }, [tab, currency]);

  useEffect(() => {
    const tabName = tab || initialTabs[0].id;
    navigate(`/feed/${tabName}?currency=${selectedCurrency}`);
  }, [selectedCurrency, tab]);

  const handleOpenCastModal = async () => {
    const isValid = await handleSignerValidate();
    if (!isValid) return;
    setShowCastModal(true)
  };
  const handleCloseCastModal = () => setShowCastModal(false);

  useEffect(() => {
    setIsLoading(personalizeIsLoading || trendingIsLoading || mostTippedIsLoading);
  }, [personalizeIsLoading, trendingIsLoading, mostTippedIsLoading]);

  const lastElementRef = useCallback((node) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      setIsCastModalOpen(false)
      if (entries[0].isIntersecting && hasMore) {
        fetchMoreData();
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore, selectedTab, mostTippedCursor, trendingCursor, personalizedCursor]);

  const handleTabChange = useCallback((index) => {
    handleTabClick(tabs[index].id);
  }, [handleTabClick, tabs, selectedCurrency]);

  return (
    <>
      <div className={`sticky top-0 z-30  transition-transform duration-1000 ${showHeader ? 'translate-y-0' : 'translate-y-[-55px] md:translate-y-[-61px] lg:translate-y-0'} `}>
        <TopHeader showCastButton PageTitle={'Feed'} tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} showTabsInHeader={true} showLanguageToggle />
      </div>
      <SwipeableViews style={{ height: 'auto' }} index={tabs.findIndex(tab => tab.id === selectedTab)} onChangeIndex={handleTabChange}>
        {initialTabs.map(tab => (
          <div key={tab.id} className="overflow-y-auto removeScrollBar min-h-80">
            {selectedTab === tab.id && (
              <div className={`mt-8`}>
                {isLoading ? selectedTab === 'cards' ? <>
                  {
                    <ResponsiveMasonry
                      columnsCountBreakPoints={{ 350: 1, 640: 2, 768: 3, 1024: 4 }}
                    >
                      <Masonry gutter='8px'>
                        {
                          Array.from({ length: 15 }).map((_, idx) => {
                            const height = Math.floor(Math.random() * (300 - 150 + 1)) + 150;
                            return <div
                              key={idx}
                              style={{ height: `${height}px` }}
                              className={`w-full animate-pulse bg-surface rounded-md`}>

                            </div>
                          })
                        }
                      </Masonry>
                    </ResponsiveMasonry>
                  }
                </> : (
                  Array.from({ length: 4 }).map((_, idx) => <CastSkeletonLoading key={idx} />)
                ) : !feedData?.length ? (
                  <div className='h-12 border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 mx-5'>
                    <Empty size={20} color='#FFFFFF66' />
                    <p className='text-onBackgroundII'>Nothing yet</p>
                  </div>
                ) : (
                  selectedTab === 'cards' ?
                    <div ref={castRef} className="relative pt-4">

                      <div>
                        <ResponsiveMasonry
                          columnsCountBreakPoints={{ 350: 1, 640: 2, 768: 3, 1024: 4 }}
                        >
                          <Masonry gutter='8px'>
                            {feedData.map((feed, idx) => {
                              if (idx + 1 === feedData?.length) {
                                return (
                                  <div
                                    ref={lastElementRef}
                                    key={idx}

                                  >
                                    <TrendingCard
                                      castData={feed}
                                      refetchCasts={handleRefetch}
                                      selectedCurrency={selectedCurrency}
                                      fromNotificationReply={false}
                                      setIsCastModalOpen={setIsCastModalOpen}
                                    />

                                  </div>

                                )
                              }
                              return <div key={idx} >
                                <TrendingCard
                                  castData={feed}
                                  refetchCasts={handleRefetch}
                                  selectedCurrency={selectedCurrency}
                                  fromNotificationReply={false}
                                  setIsCastModalOpen={setIsCastModalOpen}
                                />
                              </div>

                            })}
                          </Masonry>
                        </ResponsiveMasonry>
                      </div>
                      {moreLoading && (
                        <div className="absolute flex bottom-0 w-full justify-center">
                          <span className="loading loading-dots bg-gray-400 dark:bg-white  loading-md"></span>
                        </div>
                      )}
                    </div>
                    :
                    <div ref={castRef} className='relative'>
                      {feedData.map((feed, idx) => {
                        if (feedData.length - 5 === idx + 1) {
                          return <div key={idx} ref={lastElementRef}>
                            <CastCards castData={feed} refetchCasts={handleRefetch} selectedCurrency={selectedCurrency} fromNotificationReply={false} setIsCastModalOpen={setIsCastModalOpen} isLoadingScoreFollowing={isLoadingScoreFollowing}/>
                          </div>
                        } else {
                          return <div key={idx}>
                            <CastCards castData={feed} refetchCasts={handleRefetch} selectedCurrency={selectedCurrency} fromNotificationReply={false} setIsCastModalOpen={setIsCastModalOpen} isLoadingScoreFollowing={isLoadingScoreFollowing}/>
                          </div>
                        }
                      })}
                      {moreLoading && (
                        <div className='absolute bottom-0 md:bottom-0 w-full flex items-center justify-center'>
                          <span className='loading loading-dots bg-gray-400 dark:bg-white  loading-md'></span>
                        </div>
                      )}
                    </div>
                )}
              </div>
            )}
          </div>
        ))}
      </SwipeableViews>
      <div onClick={handleOpenCastModal} className='fixed flex md:hidden bottom-24 right-4  bg-primary rounded-full size-12 justify-center items-center'>
        <Plus size={24} className='fill-white dark:fill-black' />
      </div>
      {showCastModal && (
        <CustomModal isOpen={showCastModal} closeModal={handleCloseCastModal} showSlideAnimation={false} modalPositionTop='20' translatePosition='translate(-50%, -50%)'>
          <CastModal closeModal={handleCloseCastModal} />
        </CustomModal>
      )}
    </>
  );
};

export default FeedPage;