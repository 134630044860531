import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CastCards from '../../../../components/Cards/Casts/CastCards'
import { markNotificationAsRead } from '../../../../services/api'

const ReplyCard = ({ data, unreadTimer }) => {
    const navigate = useNavigate();
    const { actors, created_at, id, is_read, new_cast_hash_details, target_cast_hash, target_cast_hash_details, text } = data;
    const [isRead, setIsRead] = useState(is_read);

    useEffect(() => {
        if (!isRead) {
            markNotificationAsRead([id]);
            const timeoutId = setTimeout(() => {
                setIsRead(true);
            }, unreadTimer);

            return () => clearTimeout(timeoutId);
        }
    }, [isRead, id]);

    const handleClick = () => {
        if (!isRead) {
            setIsRead(true)
        }
        navigate(`/cast/${target_cast_hash}/${target_cast_hash_details?.farcaster_user?.fid}`)
    }

    const parentCast = target_cast_hash_details?.cast

    if(data == undefined) return null;

    return (
        <>
            {target_cast_hash_details?.farcaster_user?.username && <div role='button' onClick={() => handleClick()}
                className={`${isRead ? 'bg-white dark:bg-background':'dark:bg-primary/10'} transition duration-500 flex justify-start border-b dark:border-outline items-start hover:bg-gray-100 dark:hover:bg-surface/40 flex-col`}>
                {/* {
                    target_cast_hash_details && <div className='relative w-full'>
                        <CastCards castData={target_cast_hash_details} fromCastDetails={true} />
                        <div className='absolute h-full w-[1px] bg-white/10 left-10 top-5'></div>
                    </div>
                } */}
                {new_cast_hash_details && <div className='w-full'>
                    <CastCards castData={new_cast_hash_details} fromNotificationReply={data} parentCast={parentCast} refetchCasts={() => { markNotificationAsRead([id]) }} />
                </div>}
            </div>}
        </>
    )
}

export default ReplyCard