import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TopHeader from '../../components/Sidebars/TopHeader'

import { Empty, GlobeHemisphereEast } from '@phosphor-icons/react'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import degenPng from '../../assets/images/degen.png'
import mferPng from '../../assets/images/mfer.png'
import moxiePng from '../../assets/images/moxie.png'
import treePng from '../../assets/images/tree.png'
import OnchainTable from '../../components/Onchain/OnchainTable'
import OnchainTradeSkeleton from '../../components/Onchain/OnchainTradeSkeleton'
import { getOnchainTrades } from '../../services/api'
import { getSelectedCurrencyLocalStorage } from '../../utils/constants'

const tokensList = [
  {id: 0, title: "All Markets", icon: "", tkn_address: ""},
  {id: 1, title: "Degen", icon: degenPng, tkn_address: "0x4ed4E862860beD51a9570b96d89aF5E1B0Efefed"},
  {id: 2, title: "Moxie", icon: moxiePng, tkn_address: "0x8C9037D1Ef5c6D1f6816278C7AAF5491d24CD527"},
  {id: 4, title: "mfer", icon: mferPng, tkn_address: "0xE3086852A4B125803C815a158249ae468A3254Ca"},
  {id: 4, title: "tree", icon: treePng, tkn_address: "0x6888c2409D48222E2CB738eB5a805A522a96CE80"},
  {id: 3, title: "TN100x", icon: 'https://www.farcaster.in/_next/image?url=https%3A%2F%2Fimagedelivery.net%2FBXluQx4ige9GuW0Ia56BHw%2Fe6f48016-65e4-4f9e-8157-9e4f9b233700%2Foriginal&w=64&q=75', tkn_address: "0xf826f7dd2bc1bbe14d4b6ab81099f0ff2c9eecad"},
  {id: 5, title: "Higher", icon: 'https://www.farcaster.in/_next/image?url=https%3A%2F%2Fi.imgur.com%2FbdQcnVI.png&w=64&q=75', tkn_address: "0x0578d8A44db98B23BF096A382e016e29a5Ce0ffe"},
  {id: 6, title: "Build", icon: 'https://www.farcaster.in/_next/image?url=https%3A%2F%2Fimagedelivery.net%2FBXluQx4ige9GuW0Ia56BHw%2F9f99fea6-2aa9-4dc8-72a6-2fe6ee114400%2Foriginal&w=64&q=75', tkn_address: "0x3C281A39944a2319aA653D81Cfd93Ca10983D234"},
]



const elementOffsetToTriggerFetch = 5;


const transformTradesData = (profileTrades) => {
  if (profileTrades == null) return null;
  const allTrades = profileTrades.pages.flatMap(page => page?.data?.trades || []);
  return {
    trades: allTrades,
  };
};

const OnchainPage = ({ showHeader }) => {

  const observer = useRef();

  const [selectedToken, setSelectedToken] = useState(tokensList[0]);
  const [filterFollow, setFilterFollow] = useState(false);
  const [filterWhale, setFilterWhale] = useState(false);
  const [tokenAddress, setTokenAddress] = useState("");
  const lastNextAfterIdRef = useRef(null);

  const queryClient = useQueryClient();
  
  const {
    data: profileTrades,
    isLoading: profileTradesLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['onchaintrades', filterFollow, filterWhale, tokenAddress, selectedToken?.id],
    queryFn: async ({ pageParam = null }) => {
        const response = await getOnchainTrades({
            filter_following: filterFollow,
            filter_whale_txn: filterWhale,
            token_address: tokenAddress,
            after_id: pageParam?.next_after_id,
            before_id: pageParam?.next_before_id
        });
        return response;
    },
    getNextPageParam: (lastPage) => lastPage?.data?.has_more ? {
        next_before_id: lastPage?.data?.next_before_id,
    } : undefined,
  });


  const pollNewTrades = useCallback(async () => {
    const response = await getOnchainTrades({
        filter_following: filterFollow,
        filter_whale_txn: filterWhale,
        token_address: tokenAddress,
        after_id: lastNextAfterIdRef.current,
    });

    if (response?.data?.trades?.length > 0) {
        queryClient.setQueryData(['onchaintrades', filterFollow, filterWhale, tokenAddress, selectedToken?.id], (oldData) => {
            const newPages = oldData?.pages?.map((page, index) => {
                if (index === 0) {
                    return {
                        ...page,
                        data: {
                            ...page.data,
                            trades: [...response.data.trades, ...page.data.trades],
                        },
                    };
                }
                return page;
            });
            return { ...oldData, pages: newPages };
        });
    } 

    if (response?.data?.next_after_id) {
        lastNextAfterIdRef.current = response?.data?.next_after_id;
    }
  }, [filterFollow, filterWhale, tokenAddress, selectedToken, queryClient]);

  useEffect(() => {
    const intervalId = setInterval(pollNewTrades, 2000);
    return () => clearInterval(intervalId);
  }, [pollNewTrades]);


  const tradesData = useMemo(() => transformTradesData(profileTrades), [profileTrades]);

  const handleTokenSelect = useCallback((token) => {
    setSelectedToken(token);
    setTokenAddress(token.tkn_address || "");
    lastNextAfterIdRef.current = null;
    queryClient.invalidateQueries(['onchaintrades']);
  }, [queryClient]);


  const toggleFilter = useCallback((type) => {
    if (type === 'follow') {
        setFilterFollow(prev => !prev);
    } else if (type === 'whale') {
        setFilterWhale(prev => !prev);
    }
    lastNextAfterIdRef.current = null;
    queryClient.invalidateQueries(['onchaintrades']);
  }, [queryClient]);

  const handleTokenAddress = useCallback((event) => {
    const text = event.target.value;
    setTokenAddress(text);
    lastNextAfterIdRef.current = null;
    queryClient.invalidateQueries(['onchaintrades']);
  }, [queryClient]);
 
 

  const triggerElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    });
    if (node) observer.current.observe(node);
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return (
    <div className=''>
        <div className={`sticky top-0 z-30 bg-white dark:bg-[#121212] transition-transform duration-500 ${showHeader ? 'translate-y-0' : 'translate-y-[-90px] lg:translate-y-0'}`}>
          <TopHeader PageTitle={'Onchain'} showTabsInHeader={false} showLanguageToggle={false} showCastButton={false} />
        </div>
      <div className=''>
        <div className='mx-5'>
          <div className={`sticky top-[10%] left-0 z-[80] transition-transform duration-500 pb-2 bg-white dark:bg-[#121212] ${showHeader ? 'translate-y-0' : 'translate-y-[-90px] lg:translate-y-0'}`}>
            <p className='text-subTxtOnLightBg dark:text-onBackgroundII prose-BodyMediumBold pt-2 lg:pt-0'>Tokens</p>
            <div className='flex gap-4 mt-1 pr-2  overflow-x-scroll removeScrollBar'>
              {tokensList.map((token, index) => (
                <div className={`text-start cursor-pointer group  ${selectedToken?.title === token?.title ? "bg-primary" : "bg-gray-300 dark:bg-surface"} min-w-32 h-28 rounded-md p-2 flex flex-col justify-between`} key={index} onClick={() => handleTokenSelect(token)}>
                  <div className={`size-11 rounded-full bg-gray-300 dark:bg-surface flex justify-center items-center transition duration-300 border group-hover:border-primary ${selectedToken?.title === token?.title ? "border-primary" : "border-surface"}`}>
                    {token.icon === "" 
                      ? <GlobeHemisphereEast size={44} className={`fill-[#000000] dark:fill-[#ffffff]`} />
                      : <img src={token.icon} alt={token.title} className='size-10 rounded-full'/>
                    }
                  </div>
                  <p className={`prose-BodyMediumBold transition duration-300 ${selectedToken?.title === token?.title ? "text-onPrimary group-hover:text-onPrimary" : "text-subTxtOnLightBg dark:text-onBackgroundII group-hover: group-hover:text-primary"}`}>{token.title}</p>
                </div>
              ))}
            </div>

            <div className='flex items-center justify-between mt-3'>
              <div className='flex items-center gap-4'>
                  <div className="flex z-10 text-subTxtOnLightBg dark:text-onBackgroundII items-center  dark:bg-[#121212]">
                    <div className="flex flex-row gap-2">
                      <p className="prose-TitleSmall lg:prose-BodyMediumBold">Followings Only</p>
                      <label className="inline-flex items-center cursor-pointer">
                        <input type="checkbox" className="sr-only peer" />
                        <div
                          onClick={() => toggleFilter('follow')}
                          className={`relative w-8 h-4 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-surface peer-checked:after:bg-[white] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1px] after:left-0.5 after:bg-white after:border after:rounded-full after:h-[14px] after:w-[14px] after:transition-all ${getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency == "WILD" ? "peer-checked:bg-[#20A402]" : "peer-checked:bg-primary"}`}
                        ></div>
                      </label>
                    </div>
                  </div>

                  <div className="flex z-10 text-subTxtOnLightBg dark:text-onBackgroundII justify-between items-center h-full dark:bg-[#121212]">
                    <div className="flex flex-row gap-2">
                      <p className="prose-TitleSmall lg:prose-BodyMediumBold">Whale 🐳</p>
                      <label className="inline-flex items-center cursor-pointer">
                        <input type="checkbox" className="sr-only peer" />
                        <div
                          onClick={() => toggleFilter('whale')}
                          className={`relative w-8 h-4 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-surface peer-checked:after:bg-[white] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1px] after:left-0.5 after:bg-white after:border after:rounded-full after:h-[14px] after:w-[14px] after:transition-all ${getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency == "WILD" ? "peer-checked:bg-[#20A402]" : "peer-checked:bg-primary"}`}
                        ></div>
                      </label>
                    </div>
                  </div>

                  {/* {<div className={`transition duration-700 ${isFetchingPreviousPage ? "translate-y-[40px]" : "translate-y-[0px]"} absolute left-1/2 z-[-10]`}>
                    <span className={`loading loading-spinner loading-sm relative z-[-10] ${!isFetchingPreviousPage ? "text-white dark:text-[#121212]" : " text-primary"}`}></span>
                  </div>} */}

              </div>

              <div>
                <input
                  onChange={handleTokenAddress}
                  value={tokenAddress}
                  placeholder='token address'
                  className='bg-gray-400 dark:bg-surface text-black/80 dark:text-white placeholder:text-gray-200 dark:placeholder:text-onBackgroundII py-1 px-2 rounded-md outline-none prose-BodySmallBold'/>
              </div>
            </div>
          </div>
       
          <div className=''>
            <div className='mt-1'>
                {(!profileTradesLoading) &&
                  tradesData?.trades?.length === 0 ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4">
                        <Empty size={20} className='fill-black dark:fill-[#ffffffa6]' />
                        <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                    </div>
                  : 
                  <div className='relative'>
                    {tradesData?.trades?.map((item, idx) => {
                          const idxTrigger = Math.max(0, (tradesData?.trades?.length ?? 0) - elementOffsetToTriggerFetch);
                          if (idxTrigger === idx) {
                            return <div key={idx} ref={triggerElementRef}>
                              <div className='transition duration-500'><OnchainTable item={item} /></div>
                            </div>
                          }

                          return <div key={idx}>
                            <div className='transition duration-500'><OnchainTable item={item} /></div>
                          </div>
                        })}
                  </div>
              }
              {(profileTradesLoading || isFetchingNextPage) ?
               <>
                  {[1, 2, 3, 4, 5].map((_, idx) => (
                    <div key={idx} className='mt-1'>
                      <OnchainTradeSkeleton />
                    </div>
                  ))}
                </> : null
                }
            </div>
          </div>
        </div>
      </div>

      {/* <div className='fixed bottom-0  bg-slate-600 flex justify-center items-end flex-1 z-[100]'>
        <div className=' bg-red-300'>hoandandj</div>
      </div> */}


    </div>
  )
}

export default OnchainPage



