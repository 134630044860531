import { Empty } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import ProfileHoldingCard from '../../../components/Cards/Profile/ProfileHoldingCard';
import TktSkeletonLoading from '../../../components/Cards/Profile/TktSkeletonLoading';
import { Chart } from '../../../components/Chart';
import { getProfileHolders } from '../../../services/api';

const HoldersTab = ({ profileData }) => {
    const { username } = useParams()
    console.log(profileData, 'profileData')
    const { data: profileHolder, isLoading: profileHoldersLoading, refetch: profileHoldersRefetch } = useQuery({
        queryKey: ['currentUserHolders', username],
        queryFn: () => getProfileHolders(username),
        enabled: !!username
    });

    const [shareIntegerPart, shareDecimalPart] = profileData?.data?.view_user_context?.display_value ? profileData?.data?.tips_24h_my_share.display_value.split('.') : ["0", "0"];

    return (
        <div>
            <div className='md:hidden'>
                <div className='flex justify-between mb-2 border-b border-outline px-5 py-4'>
                    <div>
                        <p className='prose-BodySmallBold text-onBackgroundII flex items-center gap-2'>Your Share
                        </p>
                        <p className='text-primary'>
                            <span className="prose-DisplayMedium">{shareIntegerPart || ''}</span>
                            <span className="prose-HeaderMedium">{shareDecimalPart && `.${shareDecimalPart}`}</span>
                        </p>
                    </div>
                    <div className='text-end'>
                        <p className='prose-BodySmallBold text-onBackgroundII'>Tickets owned</p>
                        <p className='text-onBackground'>
                            <span className="prose-DisplayMedium">{(profileData?.data?.view_user_context?.num_tickets_owned?.display_value) || 0}</span>
                        </p>
                    </div>
                </div>
                <div className='border-b border-outline px-5 py-4 mb-2'>
                    <Chart username={profileData?.data?.profile?.username} />
                </div>
            </div>

            <div className=''>
                {profileHoldersLoading ? <div className='relative min-h-20'>
                    <TktSkeletonLoading />
                    <TktSkeletonLoading />
                    <TktSkeletonLoading />
                    <TktSkeletonLoading />
                </div>
                    : !profileHolder?.length ? <div className="h-12 border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-mx-5">
                        <Empty size={20} color="#FFFFFF66" />
                        <p className="text-onBackgroundII">Nothing yet</p>
                    </div>
                        : profileHolder?.map((data, idx) => (
                            <ProfileHoldingCard data={data} i={idx} />
                        ))
                }
            </div>
        </div>
    )
}

export default HoldersTab;