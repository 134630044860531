import { Empty } from '@phosphor-icons/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getWalletTipsTransactions } from '../../../services/api';
import { getSelectedCurrencyLocalStorage } from '../../../utils/constants';

import TipTransactionCard from '../../../components/Cards/Quest/TipTransactionCard';
import TipTransactionSkeletonLoading from '../../../components/Cards/Quest/TipTransactionSkeletonLoading';

const TipsTab = () => {
    const observer = useRef();
    const [tipsData, setTipsData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [moreLoading, setMoreLoading] = useState(false);
    const [page, setPage] = useState(2);

    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || 'WILD';
    const queryClient = useQueryClient()

    const { data: transactionsTipsData, isLoading: transactionsTipsLoading } = useQuery({
        queryKey: ['walletTips', selectedCurrency],
        queryFn: () => getWalletTipsTransactions(1, selectedCurrency),
    });

    useEffect(() => {
        setTipsData(transactionsTipsData?.data)
    }, [transactionsTipsData, selectedCurrency])

    const fetchMoreData = async () => {
        setMoreLoading(true);
        try {
            const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || 'WILD';
            let response = await getWalletTipsTransactions(page, selectedCurrency);
            if (response.status === 200) {
                const data = response?.data;
                setTipsData((prevData) => [...prevData, ...data]);
                setPage(prev => prev + 1)
                if (!data.length) setHasMore(false);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setHasMore(false);
        } finally {
            setMoreLoading(false);
        }
    };

    const lastElementRef = useCallback((node) => {
        if (transactionsTipsLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                fetchMoreData();
            }
        });
        if (node) observer.current.observe(node);
    }, [transactionsTipsLoading, hasMore, page, selectedCurrency]);

    return (
        <div className='relative min-h-40'>{
            transactionsTipsLoading ? (
                <>
                    <TipTransactionSkeletonLoading />
                    <TipTransactionSkeletonLoading />
                    <TipTransactionSkeletonLoading />
                </>
            ) :
                <div className='overflow-y-auto removeScrollBar mt-2'>{
                    tipsData?.length == 0 ? (
                        <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 mx-5">
                            <Empty size={20} color="#FFFFFF66" />
                            <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
                        </div>)
                        : <>
                            <div className=''>
                                {tipsData?.map((tip, i) => {
                                    if (tipsData?.length - 8 === i + 1)
                                        return <div key={i} ref={lastElementRef}>
                                            <TipTransactionCard data={tip} i={i} />
                                        </div>

                                    return <React.Fragment key={i}>
                                        <TipTransactionCard data={tip} i={i} /></React.Fragment>
                                })}
                            </div>
                            {tipsData?.length <= 4 && <div className='min-h-80'></div>}
                        </>
                }
                    {moreLoading && (
                        <div className='absolute -bottom-12 md:bottom-0 w-full flex items-center justify-center'>
                            <span className='loading loading-dots bg-gray-400 dark:bg-white  loading-md'></span>
                        </div>
                    )}
                </div>
        }</div>
    );
}

export default TipsTab;