import { CaretDown, CaretUp, MoonStars, SignOut, Star, Sun, Translate } from '@phosphor-icons/react';
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import LogoPng from '../../assets/images/logo.png';
import { followChannel, getChannelDetailed, getCurrentUserProfile, getFeedTopics, getMyPointBalances, unfollowChannel } from '../../services/api';
import BtnNavBar from './BtmNavBar';

import { useWallets } from '@privy-io/react-auth';
import logo from '../../assets/images/logo.png';
import powerBadge from '../../assets/svg/powerbadge.svg';
import { setCurrentUser, setTheme, setUserSelectedLanguage, resetState } from '../../features/globalStateSlice';
import { displaySnackbar } from '../../features/thunkMiddleware';
import useAccount from '../../hooks/account';
import useMoxieBalance from '../../hooks/useMoxieBalance';
import { LANGUAGES } from '../../utils/constants';
import { getSystemTheme } from '../../utils/helper';
import CurrencyModal from '../Modals/CurrencyModal';
import CustomModal from '../UI/CustomModal';
import { useLocation } from 'react-router-dom';
import { usePrivy } from '@privy-io/react-auth';
const ChannelSkeleton = () => {
  return (
    <div className={`mb-3 flex justify-between items-center gap-1 cursor-pointer rounded-sm p-1`}>
      <label htmlFor="leftSideBar" className='flex items-center gap-1 drawer-button cursor-pointer w-full'>
        <div className='min-w-5 aspect-square rounded-full animate-pulse bg-surface' />
        <p className='text-onBackground h-[13px] animate-pulse bg-surface w-full'></p>
      </label>
      <div>
        <Star className='size-4 rounded-full' color='#ffffff80' />
      </div>
    </div>
  )
}


const LeftSideBar = ({ mobileView }) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { wallets } = useWallets();
  const { user } = useAccount()
  const isUserExists = user?.farcaster_details || false
  const { pathname } = useLocation()
  const { getUserPrivyMoxieBalance } = useMoxieBalance()
  const { logout } = usePrivy()
  const { feedSelectedTab, userSelectedLanguage, selectedCurrency } = useSelector(state => state?.app)
  const [selectLanguageOpen, setSelectLanguageOpen] = useState(false)

  const [showRecentChannel, setShowRecentChannel] = useState(true)
  const [followedChannels, setFollowedChannels] = useState([]);
  const [channelId, setChannelId] = useState('');

  const [showCurrencyModal, setShowCurrencyModal] = useState(false);

  const [moxieBalance, setMoxieBalance] = useState(0)

  const { data: myProfile, isLoading: currUserProfileLoading, refetch: profileRefetch } = useQuery({
    queryKey: ['getCurrentUserProfile'],
    queryFn: getCurrentUserProfile,
  });

  const { data: myPointsBalances } = useQuery({
    queryKey: ['myPointsBalances'],
    queryFn: () => getMyPointBalances(true),
    enabled: pathname !== '/invite'
  });

  const { data: channelsData, isLoading: isChannelsLoading, refetch: refetchChannel } = useQuery({
    queryKey: ['feedChannels'],
    queryFn: getFeedTopics,
  });

  const { refetch: refetchChannelDetails } = useQuery({
    queryKey: ['channelDetails', channelId],
    queryFn: () => getChannelDetailed(channelId),
    enabled: channelId != ''
  });

  useEffect(() => {
    const usernameRegex = /^[a-z0-9][a-z0-9-]{0,15}$/;
    const isDefaultUsername = !!(myProfile?.farcaster_user?.username && !usernameRegex.test(myProfile.farcaster_user.username));
    let intervalId;

    if (isDefaultUsername) {
      intervalId = setInterval(() => {
        profileRefetch();
      }, 3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [myProfile?.farcaster_user, profileRefetch]);

  useEffect(() => {
    const followed = channelsData?.data?.filter((data) => data?.app_follow).map((data) => data?.channel?.id) || [];
    setFollowedChannels(followed);
  }, [channelsData]);

  useEffect(() => {
    getUserPrivyMoxieBalance().then((res) => setMoxieBalance(res))
  }, [wallets])

  const handleLogout = async () => {
    await logout();
    dispatch(resetState());
  }

  const handleFollow = async (e, id) => {
    e.stopPropagation()
    setChannelId(id)
    setFollowedChannels((prev) => [...prev, id]);
    try {
      const res = await followChannel(id);
      const data = res?.data;
    } catch (err) {
      dispatch(displaySnackbar('Failed to add'));
      setFollowedChannels((prev) => prev?.filter((channelId) => channelId !== id));
    } finally {
      refetchChannel();
      refetchChannelDetails();
    }
  };

  const handleUnfollow = async (e, id) => {
    e.stopPropagation()
    setFollowedChannels((prev) => prev?.filter((channelId) => channelId !== id));
    setChannelId(id)
    try {
      const res = await unfollowChannel(id);
      const data = res?.data;
    } catch (err) {
      dispatch(displaySnackbar('Failed to remove'));
      setFollowedChannels((prev) => [...prev, id]);
    } finally {
      refetchChannel();
      refetchChannelDetails();
    }
  };

  const sortChannels = (channels) => {
    return channels.sort((a, b) => {
      const nameA = a?.channel?.name.toUpperCase();
      const nameB = b?.channel?.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };

  const sortedFollowedChannels = sortChannels(channelsData?.data?.filter((data) => followedChannels.includes(data?.channel?.id)) || []);
  const sortedUnfollowedChannels = sortChannels(channelsData?.data?.filter((data) => !followedChannels.includes(data?.channel?.id)) || []);

  const handleNavigate = (username) => {
    if (username == undefined) return
    navigate(`/profile/${username}`)
  }

  const handleChannelNavigate = (e, channel) => {
    e.stopPropagation();
    e.preventDefault();
    const redirectUrl = `channel/${channel?.channel?.id}`;

    if (e.metaKey || e.ctrlKey) {
      // Cmd or Ctrl key is pressed, open in a new tab
      window.open(redirectUrl, '_blank');
    } else {
      // No modifier key, navigate as usual
      navigate(redirectUrl);
    }
  }

  const handleShowCurrencyModal = () => {
    setShowCurrencyModal(true)
  }

  const currencyData = myPointsBalances?.data?.filter((token) => token?.currency_points?.currency_config?.currency === selectedCurrency)
  const { display_allowance_remaining, display_balance, display_todays_allowance } = currencyData?.length != 0 && currencyData != undefined ? currencyData[0] : {
    display_allowance_remaining: '-',
    display_balance: '-',
    display_tips_received: '-',
    display_todays_allowance: '-',
    currency_points: { currency_config: { currency: selectedCurrency } }
  }
  const { farcaster_user, market_cap } = !currUserProfileLoading && myProfile.status == 200 && myProfile?.data

  useEffect(() => {
    if (!!farcaster_user) {
      dispatch(setCurrentUser(farcaster_user))
    }
  }, [myProfile])

  const handleLanguageSelect = (language) => {
    dispatch(setUserSelectedLanguage(language));
  }
  if (!isUserExists) {
    return (
      <div className={`relative py-5 z-60 px-5 md:px-2 lg:ml-20 flex flex-col overflow-hidden h-screen max-h-screen`}>
        {!mobileView &&
          <div className='flex justify-between items-center gap-1 flex-wrap'>
            <img src={LogoPng} alt='logo' className='size-8 cursor-pointer' onClick={() => navigate('/')} />
            <div className='w-fit'>
              <ThemeToggle />
            </div>
          </div>
        }
      </div>
    )
  }
  return (
    <div className={`relative py-5 z-60 px-5 md:px-2 lg:ml-20 flex flex-col overflow-hidden h-screen max-h-screen`}>
      {!mobileView &&
        <div className='flex justify-between items-center gap-1 flex-wrap'>
          <img src={LogoPng} alt='logo' className='size-8 cursor-pointer' onClick={() => navigate('/')} />
          <div className='w-fit'>
            <ThemeToggle />
          </div>
        </div>
      }
      {
        mobileView && <><div className=''>
          <div className='flex items-center justify-between cursor-pointer'>
            <div onClick={() => { handleNavigate(farcaster_user?.username) }} className='flex items-center gap-2'>
              <div className='min-w-10 min-h-10 size-10 md:size-6 lg:size-10 border border-primary p-[2px] rounded-full'>
                <label htmlFor="leftSideBar" className='drawer-button cursor-pointer'>
                  <img src={farcaster_user?.cdn_url || logo} alt='avatar' className='size-full rounded-full' />
                </label>
              </div>
              <p className='prose-TitleLarge md:prose-BottomNav lg:prose-TitleLarge text-black dark:text-onBackground flex items-center gap-1'>
                {farcaster_user?.display_name}
                {farcaster_user?.power_badge && <img src={powerBadge} alt='poweruser' className='size-4' />}
              </p>
            </div>
            <div className='border border-outline bg-surface flex items-center gap-1 relative px-2 uppercase prose-LabelSmall' onClick={() => { setSelectLanguageOpen(prev => !prev) }}>
              <Translate size={16} /> {userSelectedLanguage === "off" ? "OFF" : userSelectedLanguage.slice(0, 2)}
              {selectLanguageOpen && (
                <div className='absolute top-6 right-0 flex flex-col z-50 gap-1 bg-surface w-auto'>
                  {
                    LANGUAGES.map((language, index) => (
                      <div key={index} onClick={() => handleLanguageSelect(language)} className='   prose-LabelSmall cursor-pointer px-2 flex items-center'>
                        {language}
                      </div>
                    ))
                  }
                </div>
              )}
            </div>
          </div>
          <div className='prose-BodyMedium text-black dark:text-onBackgroundII mt-2'>
            @{farcaster_user?.username} \ Mkt. Cap: <span className=' text-onBackgroundI'>{market_cap?.usd_value_display}</span>
          </div>
        </div>
          {/* <div className='border h-[82px] border-outline p-3 pr-[10px] mt-4'>
            <p className='uppercase prose-LabelLarge text-onBackgroundII mb-3'>INVITE FRIENDS</p>
            <div className='bg-[#121214] text-onBackground flex justify-between items-center px-2 py-1'>
              <div className='prose-LabelLarge'>{myInvite?.code}</div>
              <label htmlFor="leftSideBar" className='drawer-button cursor-pointer'>
                <Copy fill='#B4F6A5' size={14} onClick={(e) => copyCodeToClipboard(myInvite?.code)} className='cursor-pointer' />
              </label>
            </div>
          </div> */}
          <div className='mt-4 px-3'>
            <div
              onClick={handleShowCurrencyModal}
              className='flex items-center justify-between'>
              <p className='prose-TitleLarge text-black dark:text-onBackgroundII'>MY {selectedCurrency} POINTS</p>
              <CaretDown size={12} className='cursor-pointer' />
              {/* {showAllowance ? <CaretUp onClick={(e) => setShowAllowance(prev => (!prev))} size={12} className='cursor-pointer' /> : <CaretDown onClick={(e) => setShowAllowance(prev => (!prev))} size={12} className='cursor-pointer' />} */}
            </div>
            {true &&
              <div className='mt-2'>
                <div className=''>
                  <p className='prose-BodyMedium text-txtOnLightBg dark:text-onBackgroundIII mb-[2px]'>Balance</p>
                  <p className='prose-HeaderLarge  text-onBackground'>{selectedCurrency == "MOXIE" ? moxieBalance : display_balance ? display_balance : "-"}</p>
                </div>
                <div className='border-b-[1.5px] text-txtOnLightBg dark:border-outline w-full my-4' />
                <div className='text-txtOnLightBg dark:text-onBackgroundIII prose-BodyMedium'>
                  <p>Today's Allowance</p>
                  <p className='prose-HeaderLarge  text-onBackground'>{display_todays_allowance || '-'}</p>
                  <p className='text-subTxtOnLightBg dark:text-onBackgroundIII'>{display_allowance_remaining || '-'} remaining</p>
                </div>
              </div>
            }
          </div>
          <div className='border-b-[1.5px] text-black dark:border-outline w-full my-4' />
        </>
      }

      <div className='hidden md:block mt-10 xl:mt-3'>
        <BtnNavBar />
      </div>

      <div className='px-1 md:px-0 grow flex flex-col overflow-hidden'>
        <div className='prose-BodyLarge text-[#000000] dark:text-onBackgroundIII flex justify-between items-center px-2'>
          Channels
          {showRecentChannel ? <CaretUp onClick={() => { setShowRecentChannel((prev) => !prev) }} role='button' /> : <CaretDown onClick={() => { setShowRecentChannel((prev) => !prev) }} role='button' />}
        </div>
        {showRecentChannel &&
          <div className='grow drawer-content overflow-y-auto removeScrollBar mt-2 relative'>
            {isChannelsLoading ? <>
              <div className='prose-LabelLarge h-3 bg-surface w-16 mt-2 animate-pulse'></div>
              {[1, 2, 3, 4].map((_, idx) => <div key={idx}>
                <ChannelSkeleton key={idx} />
              </div>)}
            </>
              : !channelsData?.data?.length ? <div className='prose-TitleSmall text-onSurface'>Empty</div>
                : <>
                  {sortedFollowedChannels.length != 0 && <div className='prose-LabelLarge text-subTxtOnLightBg dark:text-onBackgroundII px-2'>Favorites</div>}
                  {sortedFollowedChannels.length != 0 && sortedFollowedChannels?.map((channel, idx) => (
                    <div key={idx} onClick={(e) => handleChannelNavigate(e, channel)} className={`py-[6px] flex justify-between items-center gap-1 cursor-pointer rounded-sm p-1`}>
                      <label htmlFor="leftSideBar" className='flex items-center gap-1 drawer-button cursor-pointer w-full overflow-hidden'>
                        <img src={channel?.channel?.image_url || logo} alt='channel' className='size-5 rounded-full' />
                        <p className='prose-BodySmall text-[#000] dark:text-onBackground truncate text-nowrap'>{channel?.channel?.name}</p>
                      </label>
                      <div className='p-1 rounded-full    trasition duration-[450ms]'>
                        <Star className='size-4 rounded-full fill-btnColorOnLightBg dark:fill-primary' weight='fill' onClick={(e) => { handleUnfollow(e, channel?.channel.id) }} />
                      </div>
                    </div>
                  ))}
                  <div className='prose-LabelLarge text-subTxtOnLightBg dark:text-onBackgroundII px-2'>Others</div>
                  {sortedUnfollowedChannels?.map((channel) => (
                    <div key={channel?.channel?.id} onClick={(e) => handleChannelNavigate(e, channel)} className={`py-[6px] flex justify-between items-center gap-1 cursor-pointer rounded-sm p-1`}>
                      <label htmlFor='leftSideBar' className='flex items-center gap-1 drawer-button cursor-pointer w-full overflow-hidden'>
                        <img src={channel?.channel?.image_url || logo} alt='channel' className='size-5 rounded-full' />
                        <p className='prose-BodySmall text-[#000] dark:text-onBackground truncate text-nowrap'>{channel?.channel?.name}</p>
                      </label>
                      <div className='p-1 rounded-full    trasition duration-[450ms]'>
                        <Star className='size-4 rounded-full fill-black dark:fill-[#ffffffa6]' onClick={(e) => { handleFollow(e, channel?.channel.id) }} />
                      </div>
                    </div>
                  ))}
                </>
            }
          </div>
        }
      </div>

      <div>
        {!mobileView && <>
          {
            currUserProfileLoading ? <>
              <div className='mt-8'>
                <div className='flex items-center gap-2 cursor-pointer'>
                  <div className='min-w-10 min-h-10 size-10 md:size-6 lg:size-10 bg-surface animate-pulse p-[2px] rounded-full'></div>
                  <div>
                    <p className='prose-TitleLarge md:prose-BottomNav lg:prose-TitleLarge h-[14px] w-20 animate-pulse bg-surface'>
                    </p>
                    <p className='prose-TitleLarge md:prose-BottomNav lg:prose-TitleLarge h-[14px] w-20 animate-pulse bg-surface mt-2'>
                    </p>
                  </div>
                </div>
                <div className='prose-BodyMedium h-[14px] w-10 bg-surface animate-pulse text-onBackgroundII mt-4'>

                </div>
                <div className='prose-BodyMedium h-[14px] w-20 bg-surface animate-pulse mt-2'>
                </div>
              </div>
            </> :
              <>
                <div className='hidden lg:block mt-4'>
                  <div onClick={() => { handleNavigate(farcaster_user?.username) }} className='flex justify-between items-start gap-2 cursor-pointer'>
                    <div className='min-w-10 min-h-10 size-10 md:size-6 lg:size-10 border border-primary bg-background p-[2px] rounded-full'>
                      <img src={farcaster_user?.pfp_url || farcaster_user?.cdn_url || logo} alt='avatar' className='size-full object-cover rounded-full' />
                    </div>
                    <div className='flex flex-row gap-1 justify-between items-start flex-1'>
                      {feedSelectedTab !== 'cards' && <div className='prose-TitleLarge md:prose-BottomNav lg:prose-TitleLarge text-txtOnLightBg dark:text-onBackground flex flex-col items-start'>{farcaster_user?.display_name}
                        <div className='prose-BodyMedium text-black dark:text-onBackgroundII flex flex-col lg:flex-row items-center flex-wrap gap-1'>
                          <p className='flex items-center gap-2'>@{farcaster_user?.username} {farcaster_user?.power_badge && <img src={powerBadge} alt='poweruser' className='size-4' />}</p>
                        </div>
                      </div>}
                      <button onClick={handleLogout} title='Sign out'><SignOut size={16} className='fill-red-500' /></button>
                    </div>
                  </div>
                </div>
                <div className='mt-3 block lg:hidden'>
                  <div onClick={() => { handleNavigate(farcaster_user?.username) }} className='flex gap-2 cursor-pointer'>
                    <div className='min-w-10 min-h-10 size-10 md:size-6 lg:size-10 border border-primary bg-background p-[2px] rounded-full'>
                      <img src={farcaster_user?.pfp_url || farcaster_user?.cdn_url || logo} alt='avatar' className='size-full object-cover rounded-full' />
                    </div>
                    {feedSelectedTab !== 'cards' && <p className='prose-TitleLarge md:prose-BottomNav lg:prose-TitleLarge text-txtOnLightBg dark:text-onBackground flex items-center gap-1'>{farcaster_user?.display_name}
                    </p>}
                  </div>
                  {feedSelectedTab !== 'cards' && <div className='prose-BodyMedium text-txtOnLightBg dark:text-onBackgroundII mt-2 flex gap-2 items-center'>
                    @{farcaster_user?.username} {farcaster_user?.power_badge && <img src={powerBadge} alt='poweruser' className='size-4' />}
                  </div>}
                  {feedSelectedTab !== 'cards' && <div className='prose-BodyMedium text-secondary dark:text-primary'>
                    Mkt. Cap: {market_cap?.usd_value_display}
                  </div>}
                </div>
              </>
          }
        </>}
        <div className='md:hidden mt-4'>
          <ThemeToggle />
        </div>
      </div>
      {showCurrencyModal &&
        <CustomModal isOpen={showCurrencyModal} closeModal={() => { setShowCurrencyModal(false) }} modalPositionTop={'50'}>
          <CurrencyModal setShowCurrencyModal={setShowCurrencyModal} />
        </CustomModal>}

    </div>
  )
}

export default LeftSideBar

const ThemeToggle = () => {
  const dispatch = useDispatch();
  const prefferedtheme = localStorage.getItem('theme') || getSystemTheme();

  useEffect(() => {
    if (!localStorage.getItem('theme') && getSystemTheme() == 'dark') {
      document.documentElement.classList.add('dark')
      document.getElementsByTagName("body")[0].style.backgroundColor = "#000";
      localStorage.setItem('theme', 'dark')
      return;
    }

    if (localStorage.getItem('theme') == 'dark') {
      document.documentElement.classList.add('dark')
      document.getElementsByTagName("body")[0].style.backgroundColor = "#121212";
    }
  }, [])

  const toggleTheme = () => {
    if (localStorage.getItem('theme') != 'dark') {
      document.documentElement.classList.add('dark')
      document.getElementsByTagName("body")[0].style.backgroundColor = "#121212";

      localStorage.setItem('theme', 'dark')
      dispatch(setTheme('dark'))
    } else {
      localStorage.setItem('theme', 'light')
      document.documentElement.classList.remove('dark')

      document.getElementsByTagName("body")[0].style.backgroundColor = "#fff";
      dispatch(setTheme('light'))
    }
  }

  return (
    <div className='mt-2 w-full md:mt-0 md:w-fit flex justify-center scale-[0.6]'>
      <div>
        <input type="checkbox" checked={prefferedtheme == 'dark' ? true : false} className="checkbox" id="checkbox" onChange={toggleTheme} />
        <label htmlFor="checkbox" className="checkbox-label">
          <MoonStars weight='fill' className="moon fill-gray-500" />
          <Sun weight='fill' className="sun fill-[#f39c12]" />
          <span className="ball"></span>
        </label>
      </div>

    </div>
  )

}