import { formatNumber } from "../../../utils/helper";

const NFTCard = ({ item, i }) => {
    return (
        <div
        key={i}
        className="flex flex-col justify-between bg-gray-300 dark:bg-surface p-2 overflow-hidden mr-2 min-w-[160px] rounded-md">
            <img
                src={item?.preview_image || item?.image_url}
                alt="Item"
                className="w-full aspect-square rounded-md"
            />
            <p className="text-black dark:text-onBackground prose-BodyMediumBold truncate leading-5 mt-2" >
                {item?.name}
            </p>
            <div className="flex flex-row items-center justify-between prose-BodySmallBold">
                <p className="text-black dark:text-onBackground  py-1 truncate">
                    <span className="prose-LabelSmall text-black dark:text-onBackgroundII">Owned:</span> {formatNumber(item?.balance)}
                </p>
                <p className="text-black dark:text-onBackgroundII prose-LabelSmall">
                    $ {formatNumber(item?.price_usd)}
                </p>
            </div>
        </div>
    );
}

export default NFTCard;