import React, {useState, useEffect} from 'react'
import CastCards from '../../../../components/Cards/Casts/CastCards'
import { markNotificationAsRead } from '../../../../services/api';
import { At } from '@phosphor-icons/react';

const MentionCard = ({ data, unreadTimer }) => {

    const {is_read, id} = data;
    const [ isRead, setIsRead ] = useState(is_read);

    useEffect(() => {
        if (!isRead) {
            markNotificationAsRead([id]);
            const timeoutId = setTimeout(() => {
                setIsRead(true);
            }, unreadTimer);
    
            return () => clearTimeout(timeoutId);
        }
    }, [isRead, id]);

    const handleClick = () => {
        if(isRead) return;
        setIsRead(true)
    }

    if(data?.new_cast_hash_details == null) return null;

    return (
        <>
            {data?.new_cast_hash_details && 
                 <div 
                    role='button'
                    onClick={()=>handleClick()}
                    className={`${isRead?'bg-white dark:bg-background':'dark:bg-primary/10'} transition duration-500 flex justify-start border-y dark:border-outlineII items-start gap-4 hover:bg-gray-100 dark:hover:bg-surface/40 px-5 py-4`}
                >
                 <div className='min-h-12 min-w-12 bg-maintextColorOnLightBg/10 dark:bg-surface rounded-full flex justify-center items-center'>
                    <At size={24} weight='bold' className=' fill-primary' />
                 </div>
                 <div className=''>
                    <CastCards castData={data?.new_cast_hash_details} fromMentionNotification={true}/>
                 </div>
             </div>
            }
        </>
    )
}

export default MentionCard
