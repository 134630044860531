import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { getProfileDetailNew } from '../services/api';
import { getSelectedCurrencyLocalStorage } from '../utils/constants';
import useCastAction from '../hooks/signer';
import { useSelector } from 'react-redux';
import Portal from './UI/ReactPortal';

const ProfileHoverCard = ({ children, username, data }) => {
    const params = useParams();
    const navigate = useNavigate();
    const { followUser, unFollowUser } = useCastAction();
    const [isFollowing, setIsFollowing] = useState(false);
    const { currentUser } = useSelector(state => state.app);
    const currentUsername = currentUser?.username;
    const [showHoverCard, setShowHoverCard] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const hoverCardRef = useRef(null);
    const timeoutRef = useRef(null);
    const fadeTimeoutRef = useRef(null);
    const [profile, setProfile] = useState(data || {});
    const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || 'WILD';
    const { data: profileData, isLoading, refetch: profileRefetch } = useQuery({
        queryKey: ['userProfileNew', username, selectedCurrency],
        queryFn: () => getProfileDetailNew(username, selectedCurrency),
        enabled: showHoverCard && params?.username !== username,
    });
    const [fade, setFade] = useState(false);
    const triggerRef = useRef(null);

    useEffect(() => {
        setProfile(profileData?.data?.profile);
    }, [profileData]);

    useEffect(() => {
        setProfile(data || profileData?.data?.profile);
    }, [data, profileData]);

    useEffect(() => {
        if (profileData?.data?.view_user_context?.follows) {
            setIsFollowing(true);
        } else {
            setIsFollowing(false);
        }
    }, [profileData]);

    const handleFollow = async () => {
        const fid = profileData?.data?.profile?.fid;
        setIsFollowing((prev) => !prev);

        if (!isFollowing) {
            const res = await followUser(fid);
            if (res?.data?.type === 'MESSAGE_TYPE_LINK_ADD') {
                setTimeout(() => {
                    profileRefetch();
                }, 1000);
            }
        } else {
            const res = await unFollowUser(fid);
            if (res?.data?.type === 'MESSAGE_TYPE_LINK_REMOVE') {
                setTimeout(() => {
                    profileRefetch();
                }, 1000);
            }
        }
    };

    const handleClose = () => {
        setShowHoverCard(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleClose);
        document.addEventListener('scroll', handleClose);
        return () => {
            document.removeEventListener('click', handleClose);
            document.removeEventListener('scroll', handleClose);
        };
    }, []);

    const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        clearTimeout(fadeTimeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setShowHoverCard(true);
        }, 300);
        setTimeout(() => {
            setFade(true);
        }, 500);
    };

    const handleMouseLeave = () => {
        clearTimeout(timeoutRef.current);
        fadeTimeoutRef.current = setTimeout(() => {
            setFade(false);
        }, 300);
        timeoutRef.current = setTimeout(() => {
            setShowHoverCard(false);
        }, 400);
    };

    useEffect(() => {
        const calculatePosition = () => {
            if (showHoverCard && triggerRef.current) {
                const triggerRect = triggerRef.current.getBoundingClientRect();
                const { innerWidth, innerHeight } = window;
                const hoverCardWidth = 300; // Adjust based on your actual hover card width
                const hoverCardHeight = 250; // Adjust based on your actual hover card height

                let top, left;

                // Determine vertical position
                if (triggerRect.bottom + hoverCardHeight <= innerHeight) {
                    // Enough space at the bottom
                    top = triggerRect.bottom;
                } else if (triggerRect.top - hoverCardHeight >= 0) {
                    // Not enough space at the bottom, but enough at the top
                    top = triggerRect.top - hoverCardHeight;
                } else {
                    // Not enough space at top or bottom, align with trigger top
                    top = triggerRect.top;
                }

                // Determine horizontal position
                if (triggerRect.left + hoverCardWidth <= innerWidth) {
                    // Enough space to the right
                    left = triggerRect.left;
                } else if (triggerRect.right - hoverCardWidth >= 0) {
                    // Not enough space to the right, but enough to the left
                    left = triggerRect.right - hoverCardWidth;
                } else {
                    // Not enough space on either side, align with trigger left
                    left = triggerRect.left;
                }

                setPosition({ top, left });
            }
        };

        calculatePosition();

        // Recalculate position after a short delay to ensure DOM has updated
        const timer = setTimeout(calculatePosition, 50);

        // Add event listener for window resize
        window.addEventListener('resize', calculatePosition);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', calculatePosition);
        };
    }, [showHoverCard]);

    const handleProfileNavigate = () => {
        navigate(`/profile/${username}`);
    };

    return (
        <div
            ref={triggerRef}
            className="cursor-pointer inline items-start gap-1 relative z-10"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                handleProfileNavigate();
            }}
            onScroll={handleClose}
        >
            {children}
            {showHoverCard && (params?.username !== username) && (
                <Portal>
                    <div
                        className={`fixed z-[1000] bg-gray-300 dark:bg-surface p-4 rounded-lg shadow-lg min-w-64 max-w-80 w-fit transition duration-300 
                            ${fade ? 'opacity-100' : 'opacity-0'}`}
                        style={{
                            top: `${position.top}px`,
                            left: `${position.left}px`,
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {isLoading && !data ? (
                            <div className="animate-pulse">
                                <div className="h-10 w-10 bg-surface dark:bg-gray-300 rounded-full mb-2"></div>
                                <div className="h-4 w-3/4 bg-surface dark:bg-gray-300 rounded mb-2"></div>
                                <div className="h-4 w-1/2 bg-surface dark:bg-gray-300 rounded"></div>
                            </div>
                        ) : (
                            <div className='flex flex-col justify-between items-start gap-2'>
                                <div className='flex justify-between items-center w-full'>
                                    <img
                                        src={profile?.pfp_url || profile?.cdn_url || logo}
                                        alt={profile?.display_name?.slice(0, 1)}
                                        className="w-10 h-10 rounded-full"
                                    />
                                    <>
                                        {isLoading ? (
                                            <div className="animate-pulse">
                                                <div className="h-[22px] w-20 bg-surface dark:bg-gray-300 rounded-sm mb-2"></div>
                                            </div>
                                        ) : (
                                            <>
                                                {currentUsername !== username && <button onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    handleFollow();
                                                }} className={`md:flex prose-BodySmallBold  text-gray-100 dark:text-background bg-gray-500 dark:bg-onBackground px-2 items-center rounded-sm h-[22px]`}>{isFollowing ? 'Following' : 'Follow'}</button>}
                                            </>
                                        )}
                                    </>
                                </div>

                                <h3 className="font-bold text-txtOnLightBg dark:text-onBackground text-left">
                                    {profile?.display_name}
                                </h3>
                                <p className="text-sm text-subTxtOnLightBg dark:text-onBackgroundII text-left">
                                    @{profile?.username}
                                </p>
                                <p className="text-sm text-subTxtOnLightBg dark:text-onBackgroundII mt-2 text-left break-words w-full">
                                    {profile?.profile_bio}
                                </p>
                                <div className="flex justify-between mt-2 text-sm text-subTxtOnLightBg dark:text-onBackground gap-4">
                                    <p className="flex items-center gap-1 prose-BodyMediumBold leading-[14px]">{profile?.follower_count_display?.display_value || profileData?.data?.profile?.follower_count_display?.display_value} <span className="text-xs text-subTxtOnLightBg/80 dark:text-onBackgroundII leading-[14px]">Followers</span></p>
                                    <p className="flex items-center gap-1 prose-BodyMediumBold leading-[14px]">{profile?.following_count_display?.display_value || profileData?.data?.profile?.following_count_display?.display_value} <span className="text-xs text-subTxtOnLightBg/80 dark:text-onBackgroundII leading-[14px]">Following</span></p>
                                </div>
                            </div>
                        )}
                    </div>
                </Portal>
            )}
        </div>
    );
};

export default ProfileHoverCard;