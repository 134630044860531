import { CaretDown, Confetti, Copy, Info, X } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import bgRays from '../assets/images/tippingArena/bgRays.png';
import { getMyPointBalances } from '../services/api';
import { getSelectedCurrencyLocalStorage } from '../utils/constants';
import CustomModal from './UI/CustomModal';
import ToolTip from './UI/Tooltip';

import { useWallets } from "@privy-io/react-auth";

// import { request, gql } from "graphql-request";
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import useMoxieBalance from '../hooks/useMoxieBalance';

import ConfettiExplosion from 'react-confetti-explosion';
import { displaySnackbar } from '../features/thunkMiddleware';

import BaseLogo from '../assets/svg/base_logo_fund.svg';
import CurrencyModal from './Modals/CurrencyModal';
import { useSelector } from 'react-redux';



const formatCash = (amount) => {
  return Intl.NumberFormat('en-US', {
      notation: "compact",
      maximumFractionDigits: 1
  }).format(amount);
}



const UserPoints = ({ renderInPointPage }) => {

  const location = useLocation()
  const queryParams = new URLSearchParams(location?.search);
  const pathname = location?.pathname;
  const selectedCurrency = getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency || queryParams.get('currency')
  const dispatch = useDispatch()
  const { isTippingMoxie } = useSelector((state) => state.app)

  const { 
    moxieBalance,
    userPrivyMoxieBalance,

    getMoxieBalance,
    getUserPrivyMoxieBalance, 

    getUserMoxieVestingContract,
    getUserVestedMoxie,

    
    claimVestedMoxie, 
    claimDailyMoxie, 
    fundMoxie,

    claimStatus, 
    vestedClaimStatus,
    isClaimingDailyRewards, 
    isClaimingVestedAirdrop, 
    isFundingMoxie,

    isLoadingMoxieBalance, 
    isLoadingPrivyMoxieBalance,
    isLoadingUserVestedMoxie
  } = useMoxieBalance()
  // const selectedToken = getSelectedCurrencyLocalStorage()
  const { wallets } = useWallets();

  const embeddedWalletAddress = wallets?.find(wallet => wallet?.connectorType === 'embedded')?.address

  const { address, isConnected } = useWeb3ModalAccount()
  const { open } = useWeb3Modal()

  const [showUserPoints, setShowUserPoints] = useState(false)
  const [toolTipOpen, setToolTipOpen] = useState({ allowance: false, received: false, revenue: false });

  const allowanceRef = useRef(null);
  const receivedRef = useRef(null);
  const revenueRef = useRef(null);

  const allowanceTipRef = useRef(null);
  const receivedTipRef = useRef(null);
  const revenueTipRef = useRef(null);
  const timeoutRefs = useRef({ allowance: null, received: null, revenue: null });

  const [showCurrencyModal, setShowCurrencyModal] = useState(false);

  const [userMoxieDetails, setUserMoxieDetails] = useState({})
  
  const [explodeConfetti, setExploadConfetti] = useState(false)
  const [explodeConfettiVested, setExploadConfettiVested] = useState(false)

  const [showFundMoxieModal, setShowFundMoxieModal] = useState(false)
  const [fundMoxieAmount, setFundMoxieAmount] = useState(1000)

  const { data: myPointsBalances, isLoading: pointsBalancesLoading, refetch: refetchPointsBalances } = useQuery({
    queryKey: ['myPointsBalances'],
    queryFn: () => getMyPointBalances(true),
  });

  const { data: currentTokenData, isLoading: currentTokenDataLoading } = useQuery({
    queryKey: ['myPointsBalances', selectedCurrency],
    queryFn: () => getMyPointBalances(true, selectedCurrency),
  });

  useEffect(() => {
    location?.pathname === '/points' ? setShowUserPoints(true) : setShowUserPoints(false)
  }, [location?.pathname])

  useEffect(() => {
    setFundMoxieAmount(1000)
    if (currentTokenDataLoading)
        return;
    let tokenArr = {};
    currentTokenData?.data?.forEach(element => {
        const curr = element?.currency_points?.currency_config?.currency;
        tokenArr[`${curr}`] = false;
    });
  }, [currentTokenData, currentTokenDataLoading, selectedCurrency])

  useEffect(() => {
    if (pointsBalancesLoading)
      return;
    let tokenArr = {}
    myPointsBalances?.data?.forEach(element => {
      const curr = element?.currency_points?.currency_config?.currency;
      tokenArr[`${curr}`] = false;
    });

  }, [myPointsBalances, pointsBalancesLoading, selectedCurrency])

  useEffect(() => {
    getUserPrivyMoxieBalance()
    getMoxieBalance()
  }, [isTippingMoxie])



  const openToolTip = (type) => {
    closeToolTips();

    setToolTipOpen(prevState => ({ ...prevState, [type]: true }));

    if (timeoutRefs.current[type]) {
      clearTimeout(timeoutRefs.current[type]);
    }

    timeoutRefs.current[type] = setTimeout(() => {
      setToolTipOpen(prevState => ({ ...prevState, [type]: false }));
    }, 6000);
  };

  const closeToolTips = () => {
    setToolTipOpen({ allowance: false, received: false, revenue: false });
    Object.values(timeoutRefs.current).forEach(clearTimeout);
  };


  useEffect(() => {
    document.addEventListener('mousedown', closeToolTips);
    return () => {
      document.removeEventListener('mousedown', closeToolTips);
    };
  }, []);

  const positionToolTip = (type) => {
    let containerRef, tipRef;
    switch (type) {
      case 'allowance':
        containerRef = allowanceRef.current;
        tipRef = allowanceTipRef.current;
        tipRef.style.top = `55%`;
        tipRef.style.left = `${containerRef.offsetLeft - containerRef.parentElement.scrollLeft}px`;
        break;
      case 'received':
        containerRef = receivedRef.current;
        tipRef = receivedTipRef.current;
        tipRef.style.top = `10%`;
        tipRef.style.left = `${containerRef.offsetLeft - containerRef.parentElement.scrollLeft}px`;
        break;
      case 'revenue':
        containerRef = revenueRef.current;
        tipRef = revenueTipRef.current;
        tipRef.style.top = `14%`;
        tipRef.style.left = `${containerRef.offsetLeft - containerRef.parentElement.scrollLeft}px`;
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (toolTipOpen.allowance) {
      positionToolTip('allowance');
    }
    if (toolTipOpen.received) {
      positionToolTip('received');
    }
    if (toolTipOpen.revenue) {
      positionToolTip('revenue');
    }
  }, [toolTipOpen]);

  const handleShowCurrencyModal = () => {
    setShowCurrencyModal(true)
  }

  useEffect(() => {
    document.documentElement.style.setProperty('--color-primary', localStorage.getItem('theme') == 'dark'
      ? getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.app_primary_color
      : selectedCurrency == 'WILD' ? '#20A402' : getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.app_primary_color
    )
    document.documentElement.style.setProperty('--color-onPrimary', localStorage.getItem('theme') != 'dark'
      ? "#FFFFFF"
      : selectedCurrency == 'WILD' ? '#000' : "#FFFFFF"
    )
  }, [selectedCurrency, localStorage.getItem('theme')])

  const fetchMoxieDetails = useCallback(async () => {
    if (isConnected && address) {      
      try {
        getUserMoxieVestingContract(address).then((res) => {
          getUserVestedMoxie(res?.tokenLockWallets[0]?.address).then((res) => {
            setUserMoxieDetails(res)
          })
        })
      } catch (error) {
        return null
      }
    } 
  }, [isConnected, address, embeddedWalletAddress]);

  const fetchMoxieBalance = () => {
    getUserPrivyMoxieBalance()
    getMoxieBalance(address)
  }

  useEffect(() => {
    fetchMoxieBalance();
    fetchMoxieDetails();
  }, [isConnected, address, fetchMoxieDetails, wallets]);


  const handleClaimDailyMoxieReward = async () => {
    try {
      await claimDailyMoxie();
      await refetchPointsBalances();
      setExploadConfetti(true);
    } catch (err) {
      console.log('err', err);
    } finally {
      fetchMoxieDetails();
    }
    
  }

  const handleClaimVestedMoxie = async () => {
    const res = await claimVestedMoxie(userMoxieDetails?.vestedContract)
    refetchPointsBalances();
    setExploadConfettiVested(true);
    fetchMoxieDetails();
  }

  const handleIncrementFundMoxieAmount = () => {
    setFundMoxieAmount(fundMoxieAmount + 1)
  }

  const handleDecrementFundMoxieAmount = () => {
    if (fundMoxieAmount > 1) {
      setFundMoxieAmount(fundMoxieAmount - 1)
    }
  }

  const copyAddressToClipboard = () => {
    navigator.clipboard.writeText(embeddedWalletAddress)
        .then(() => {
            dispatch(displaySnackbar('Address copied to clipboard'))
            setShowFundMoxieModal(false);
        })
        .catch((error) => console.error('Failed to copy:', error));
  };

  const handleFundMoxie = async () => {
    try {
      if(!isConnected) {
        open()
        return;
      }
      setTimeout(() => {
        setShowFundMoxieModal(false);
      }, 3000);
      await fundMoxie(fundMoxieAmount);
      setFundMoxieAmount(0);
      getUserPrivyMoxieBalance()
      getMoxieBalance()
    } catch (err) {
      console.log('err', err);
    }
  }

  let currencyData = myPointsBalances?.data?.filter((token) => token?.currency_points?.currency_config?.currency === selectedCurrency)
  const { display_balance } = currencyData?.length != 0 && currencyData != undefined ? currencyData[0] : {
    display_allowance_remaining: '-',
    display_balance: '-',
    display_tips_received: '-',
    display_todays_allowance: '-',
    currency_points: { currency_config: { currency: selectedCurrency } }
  }
  currencyData = currencyData?.length != 0 && currencyData != undefined ? currencyData[0] : {};

  return (
    <div className='flex flex-col justify-center items-center size-full mt-2'>
      <div className={`relative flex flex-col justify-center items-center border dark:border-outlineIII w-full ${pathname.includes('points') ? "min-h-[250px] py-4 md:h-[250px]" : "min-h-[120px] md:h-[220px] py-4"}  bg-center bg-opacity-100`}>
        <img src={bgRays} alt="" className='absolute top-0 left-0 w-full h-full opacity-100 dark:opacity-80 z-0 object-cover' />
        <div className='text-[13px] text-subTxtOnLightBg dark:text-onBackgroundIII relative flex gap-2 items-center'>Your Points
          <Info size={12} weight='bold' role='button' onClick={() => openToolTip('points')} className='fill-black/40 dark:fill-[#ffffffa6]' />
          <div className='absolute -top-14 -left-2'>
            {toolTipOpen.points && <ToolTip pointer='bottom' text={'All points are yours to keep'} />}
          </div>
        </div>
        <div className='flex flex-row items-center justify-center gap-1 z-10' onClick={() => handleShowCurrencyModal()}>
          <p className='prose-DisplaySmall lg:text-[24px] text-[18px] text-btnColorOnLightBg dark:text-primary text-nowrap truncate'>{display_balance || '-'} ${selectedCurrency}</p>
          <CaretDown size={20} className='fill-btnColorOnLightBg dark:fill-primary cursor-pointer' />
        </div>

        {selectedCurrency == "MOXIE" && 
          <div className='w-full px-3 mt-2 z-10'>
            <div className='flex justify-between items-center mb-1'>
              <p className='prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundII'>Daily rewards</p>
              {!isConnected
               ? <button onClick={() => open()} className='prose-BodyMediumBold bg-primary text-onPrimary px-2 py-[2px] rounded-md cursor-pointer'>Connect Wallet</button>
               : isLoadingUserVestedMoxie ? 
                  <div className='bg-surface animate-pulse h-6 w-20 rounded-md'/> :
                  <button onClick={handleClaimDailyMoxieReward} disabled={parseFloat(currencyData?.currency_points?.daily_rewards) <= 0.001 || claimStatus == 'claimed'} className={`prose-BodyMediumBold bg-primary text-onPrimary px-2 py-[2px] rounded-md ${parseFloat(currencyData?.currency_points?.daily_rewards) <= 0.001 || claimStatus === 'claimed'? 'opacity-50 cursor-default' : 'cursor-pointer'}`}>
                    {isClaimingDailyRewards ? <p>Claiming...</p> :
                      claimStatus === 'claimed' ? <p>Claimed <Confetti fill='yellow' weight='fill' size={14} className='inline-block'/> </p> :
                      <p>Claim {formatCash(parseFloat(currencyData?.currency_points?.daily_rewards || 0))}</p>
                    }
                    {explodeConfetti && <ConfettiExplosion force={0.6} duration={2000} particleCount={100} width={1000} />}
                  </button>
              }
              
            </div>
            <div className='flex justify-between items-center mb-1'>
              <p className='prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundII cursor-pointer'>Vested airdrop</p>
              {isLoadingUserVestedMoxie ? <div className='bg-surface animate-pulse h-6 w-20 rounded-md'/> : <>
                {!isConnected ? null : 
                  <button onClick={handleClaimVestedMoxie} disabled={parseFloat(userMoxieDetails?.vestedAirdrop) <= 0|| vestedClaimStatus === 'claimed'} className={`prose-BodyMediumBold bg-primary text-onPrimary px-2 py-[2px] rounded-md ${parseFloat(userMoxieDetails?.vestedAirdrop) <= 0 || vestedClaimStatus === 'claimed' ? 'opacity-50 cursor-default' : 'cursor-pointer'}`}>
                    {isClaimingVestedAirdrop ? <p>Claiming...</p> :
                      vestedClaimStatus == 'claimed' ? <p>Claimed <Confetti fill='yellow' weight='fill' size={14} className='inline-block'/></p> :
                      <p>Claim {userMoxieDetails?.vestedAirdrop == "0" ? "--" : formatCash(parseFloat(userMoxieDetails?.vestedAirdrop).toFixed(2))}</p>
                    }
                    {explodeConfettiVested && <ConfettiExplosion force={0.6} duration={2000} particleCount={100} width={1000} />}
                  </button>
                }
              </>}
            </div>
            <div className='flex justify-between items-center mb-1'>
              <p className='prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundII'>Wallet balance</p>
              <div className='prose-BodyMediumBold text-primary flex items-center gap-1'>
                {isLoadingMoxieBalance || isTippingMoxie ? <div className='bg-surface animate-pulse h-6 w-10 rounded-md'/> :
                  !moxieBalance ? null : <>
                    <img src={currencyData?.currency_points?.currency_config?.image} alt="" className='size-4' />
                    {moxieBalance == 0 ? 0 :formatCash(parseFloat(moxieBalance))}
                    </>
                }
              </div>
            </div>
            <div className='flex justify-between items-center mb-1'>
              <p className='prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundII'>Unvested airdrop</p>
              <div className='prose-BodyMediumBold text-primary flex items-center gap-1'>
                {isLoadingUserVestedMoxie ? <div className='bg-surface animate-pulse h-4 w-10 rounded-sm'/> : <>
                  {!isConnected ? null : <>
                    <img src={currencyData?.currency_points?.currency_config?.image} alt="" className='size-4' />
                    {userMoxieDetails?.unVestedAirdrop == 0 ? 0 : formatCash(parseFloat(userMoxieDetails?.unVestedAirdrop).toFixed(2))}
                    </>
                  }
                </>}
              </div>
            </div>

            <div className='flex justify-between items-center'>
              <div ref={allowanceTipRef} className='prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundII relative flex gap-2 items-center'>
                Tip Allowance
                <div data-tooltip-id="my-tooltip" data-tooltip-content="Wild Wallet Balance">
                  <Info size={12} weight='bold' role='button' className='fill-black/40 dark:fill-[#ffffffa6]' />
                </div>
              </div>
              {
                <button disabled={isFundingMoxie || isTippingMoxie}  onClick={() => setShowFundMoxieModal(true)} className={`flex items-center gap-1 bg-primary opacity-90 rounded-md h-7 px-1 py-[2px] ${isFundingMoxie || isTippingMoxie ? 'opacity-50 animate-pulse cursor-default' : 'cursor-pointer'}`}>
                  <img src={currencyData?.currency_points?.currency_config?.image} alt="" className='size-4' />
                  <div className='prose-BodyMediumBold text-onPrimary'>{isLoadingPrivyMoxieBalance || isTippingMoxie ? <div className='bg-surface animate-pulse h-4 w-6 rounded-sm'/> : formatCash(parseFloat(userPrivyMoxieBalance))}</div>
                  <div className='h-4 w-[0.5px] bg-onPrimary'/>
                  <div className={`bg-primary opacity-80 hover:opacity-100 text-onPrimary text-xs rounded-md prose-BottomNav translate-y-[1px] ${isFundingMoxie || isTippingMoxie ? 'opacity-50 animate-pulse cursor-default' : 'cursor-pointer'}`}>
                    {isFundingMoxie ? 'Funding...' : 'Fund'}
                  </div>
                </button>
              }
            </div>
          </div>
        }
        <Tooltip id="my-tooltip" clickable style={{ backgroundColor: "var(--color-primary)", color: "var(--color-onPrimary)", zIndex: 100, padding: "5px 10px" }} opacity={1} />
      </div>

      {showCurrencyModal &&
        <CustomModal isOpen={showCurrencyModal} closeModal={() => { setShowCurrencyModal(false) }} modalPositionTop={'50'}>
          <CurrencyModal setShowCurrencyModal={setShowCurrencyModal}/>
        </CustomModal>
      }

      {showFundMoxieModal && 
        <CustomModal isOpen={showFundMoxieModal} closeModal={() => { setShowFundMoxieModal(false) }} modalPositionTop={'50'}>
          <div className='bg-white dark:bg-background min-w-[390px] border dark:border-outline rounded-sm p-5'>
            <div className='flex justify-end items-center'>
              <X onClick={() => setShowFundMoxieModal(false)} className={`cursor-pointer fill-[#000] dark:fill-[#ffffffa6]`} />
            </div>

            <div className='prose-HeaderLarge text-txtOnLightBg dark:text-onBackground mt-4'>
              FUND YOUR <span className=' text-primary'>WILD</span> WALLET WITH <span className=' text-primary'>MOXIE</span> ON <span className='text-[#0029FF] inline-flex items-center gap-2'>BASE <img src={BaseLogo} alt='base' className='size-5'/></span>
            </div>

            <div className='flex flex-col justify-center items-center py-6'>

              <div className='bg-gray-300 dark:bg-surface p-4 rounded-md mt-6 w-full'>
                  <h2 className='prose-HeaderMedium text-txtOnLightBg dark:text-onBackground'>Transfer manually</h2>
                  <div className='h-1 w-full my-3 border-b-[2px] dark:border-outline'/>
                  <div className='prose-BodySmall md:prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII'>{embeddedWalletAddress}</div>
                  <div className='flex gap-2 items-center prose-BodyMediumBold  text-primary mt-1 cursor-pointer' onClick={() => copyAddressToClipboard()}><Copy size={18} className=' fill-primary'/> Copy Address</div>
              </div>

              <div className='flex items-center justify-between w-full gap-2 py-2'>
                <div className='h-[1px] w-full bg-primary opacity-50'/>
                <div className='dark:text-onBackgroundII text-subTxtOnLightBg'>or</div>
                <div className='h-[1px] w-full bg-primary opacity-50'/>
              </div>

              <div className='flex items-center gap-2 mt-4'>
                <div onClick={handleDecrementFundMoxieAmount} className='bg-primary text-onPrimary w-5 h-5 rounded-sm font-bold text-lg flex justify-center items-center cursor-pointer'>-</div>
                <div className='w-fit'>
                  <input type="number" placeholder='1' autoFocus  value={fundMoxieAmount} onChange={(e) => setFundMoxieAmount(e.target.value)} className='w-40 text-center text-txtOnLightBg dark:text-onBackground prose-HeaderLarge outline-none border-none bg-transparent'/>
                </div>
                <div onClick={handleIncrementFundMoxieAmount} className='bg-primary text-onPrimary w-5 h-5 rounded-sm font-bold text-lg flex justify-center items-center cursor-pointer'>+</div>
              </div>

              <div className='flex items-center justify-center gap-3 w-full mt-3'>
                <div onClick={() => setFundMoxieAmount(1000)} className='prose-HeaderSmall text-subTxtOnLightBg dark:text-onBackgroundII bg-gray-200 dark:bg-surface rounded-md px-2 py-1 h-7 w-16 border border-outline flex items-center justify-center cursor-pointer'>1k</div>
                <div onClick={() => setFundMoxieAmount(10000)} className='prose-HeaderSmall text-subTxtOnLightBg dark:text-onBackgroundII bg-gray-200 dark:bg-surface rounded-md px-2 py-1 h-7 w-16 border border-outline flex items-center justify-center cursor-pointer'>10k</div>
                <div onClick={() => setFundMoxieAmount(100000)} className='prose-HeaderSmall text-subTxtOnLightBg dark:text-onBackgroundII bg-gray-200 dark:bg-surface rounded-md px-2 py-1 h-7 w-16 border border-outline flex items-center justify-center cursor-pointer'>100k</div>
              </div>

              <div className='flex items-center justify-between w-full mt-6'>
                <div className='prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundII'>Connected Wallet balance({address?.slice(0, 4)}...{address?.slice(address?.length-4, address?.length-1)}):</div>
                <div>
                  <div className='prose-BodyMediumBold text-primary flex items-center gap-1'>
                    {isLoadingUserVestedMoxie ? <div className='bg-surface animate-pulse h-4 w-10 rounded-sm'/> :
                      !userMoxieDetails?.moxieBalance ? null : <>
                        <img src={currencyData?.currency_points?.currency_config?.image} alt="" className='size-4' />
                        {userMoxieDetails?.moxieBalance == 0 ? 0 : formatCash(parseFloat(userMoxieDetails?.moxieBalance).toFixed(2))}
                        </>
                    }
                  </div>
                </div>
              </div>

              <div className='mt-4 w-full'>
                <button onClick={handleFundMoxie} disabled={isFundingMoxie || fundMoxieAmount == 0 || fundMoxieAmount > parseFloat(userMoxieDetails?.moxieBalance)} className={`bg-primary text-onPrimary px-4 py-[2px] rounded-sm text-[20px] font-[bungee] w-full ${isFundingMoxie || fundMoxieAmount == 0 || fundMoxieAmount > parseFloat(userMoxieDetails?.moxieBalance) ? 'opacity-50 cursor-default' : ''}`} >
                  {!isConnected ? "Connect Wallet" : isFundingMoxie ? 'Funding...' : fundMoxieAmount > parseFloat(userMoxieDetails?.moxieBalance) ? 'Insufficient Balance' : 'Fund'}
                </button>
              </div>
            </div>

          </div>
        </CustomModal>
      }
    </div>
  )
}
export default UserPoints
