import { configureStore } from "@reduxjs/toolkit";
import globalStateSlice from "./features/globalStateSlice";

export const store = configureStore({
  reducer: {
    app: globalStateSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});