import { useSendTransaction, useWallets } from "@privy-io/react-auth";
import { BigNumber, utils } from 'ethers';
import { useEffect, useState } from 'react';
import { ABI, CHAIN_ID, CONTRACT_ADDRESS } from "../utils/constants";
// import { ethers } from "ethers-v6";
const ethers = require("ethers-v6")

const contractAddress = CONTRACT_ADDRESS

const useBuyNFTTransaction = () => {
    const { sendTransaction } = useSendTransaction();
    const { wallets } = useWallets();
    const [address, setAddress] = useState('');

    const wallet = wallets.find(w => w.connectorType === "embedded")

    useEffect(() => {
        const fetchAddress = async () => {
            if (wallet) {
                const provider = await wallet.getEthersProvider();
                const signer = provider.getSigner();
                const addr = await signer.getAddress();
                setAddress(addr);
            }
        };
        fetchAddress();
    }, [wallet]);

    const getNFTPricesBulk = async (fids, quantityRange) => {
        try {
            const provider = await wallet.getEthersProvider();
            const contract = new ethers.Contract(contractAddress, ABI, provider);

            const result = await contract.getSocialIdPurchaseDetailsMulti(
                1, fids, quantityRange
            )
            const tokenIds = result[0]
            const buyPrices = result[1]
            const sellPrices = result[2]
            const buyPricesWithFee = result[3]
            const sellPricesWithFee = result[4]
            const supplies = result[5]

            const details = []
            for (let i = 0; i < tokenIds.length; i++) {
                details.push({
                    tokenId: Number(BigNumber.from(tokenIds[i])),
                    buyPrice: utils.formatEther(BigNumber.from(buyPrices[i])),
                    sellPrice: utils.formatEther(BigNumber.from(sellPrices[i])),
                    buyPriceWithFee: utils.formatEther(BigNumber.from(buyPricesWithFee[i])),
                    sellPriceWithFee: utils.formatEther(BigNumber.from(sellPricesWithFee[i])),
                    supply: Number(BigNumber.from(supplies[i]))
                })
            }
            return details
        } catch (error) {
            console.error('Error sending transaction:', error);
        }
    }

    const getNFTPrice = async (fid, quantity) => {
        try {
            const provider = await wallet.getEthersProvider();
            const contract = new ethers.Contract(contractAddress, ABI, provider);

            const result = await contract.getSocialIdPurchaseDetailsMulti(
                1, fid, [quantity]
            )

            return result;
        } catch (error) {
            console.error('Error sending transaction:', error);
        }
    }

    const buyNFT = async (username, ethValueWithTax, fid, quantity) => {
        try {
            let stramount = ethValueWithTax.toString();
            const functionName = 'buySocialId';
            const args = [wallet.address, 1, fid, quantity];

            const provider = wallet.getEthersProvider();
            const contract = new ethers.Contract(contractAddress, ABI, provider);
            const functionData = contract.interface.encodeFunctionData(functionName, args);

            const requestData = {
                to: contractAddress,
                chainId: CHAIN_ID,
                value: ethers.parseEther(stramount),
                data: functionData
            };

            const uiConfig = {
                header: `Buy Ticket for ${username}`,
                description: '',
                buttonText: 'Buy Now',
            };

            const txReceipt = await sendTransaction(requestData, uiConfig);
            return txReceipt;
        } catch (error) {
            console.error('Error sending transaction:', error);
        }
    };

    const sellNFT = async (username, ethValueWithTax, fid, quantity) => {
        try {
            let stramount = ethValueWithTax.toString();
            const functionName = 'sellSocialId';
            const args = [wallet.address, 1, fid, quantity, ethers.parseEther(stramount)];

            const provider = wallet.getEthersProvider();
            const contract = new ethers.Contract(contractAddress, ABI, provider);
            const functionData = contract.interface.encodeFunctionData(functionName, args);

            const requestData = {
                to: contractAddress,
                chainId: CHAIN_ID,
                value: 0,
                data: functionData,
                gasLimit: 1000000
            };
            const uiConfig = {
                header: `Sell Ticket for ${username}`,
                description: '',
                buttonText: 'Sell Now',
            };

            const txReceipt = await sendTransaction(requestData, uiConfig);
            return txReceipt;
        } catch (error) {
            console.error('Error sending transaction:', error);
        }
    };

    const withdrawEth = async (amount, address) => {
        try {
            const requestData = {
                to: address,
                chainId: CHAIN_ID,
                value: ethers.parseEther(amount.toString()),
            };

            const uiConfig = {
                header: `Withdraw ETH`,
                buttonText: 'Withdraw Now',
            };

            const txReceipt = await sendTransaction(requestData, uiConfig);
            return txReceipt;
        }
        catch (e) {
            console.error('Error sending transaction:', e);
        }
    }

    const getUserMarketCap = async (fids) => {
        if(wallet){
            try {
                const provider = await wallet.getEthersProvider();
                const contract = new ethers.Contract(contractAddress, ABI, provider);

                const result = await contract.getSocialIdPurchaseDetailsMulti(
                    1, fids, [1000]
                )
                const tokenIds = result[0]
                const sellPrices = result[2]
                const sellPricesWithFee = result[4]
                const supplies = result[5]

                const details = []
                for (let i = 0; i < tokenIds.length; i++) {
                    details.push({
                        tokenId: Number(BigNumber.from(tokenIds[i])),
                        sellPrice: utils.formatEther(BigNumber.from(sellPrices[i])),
                        sellPriceWithFee: utils.formatEther(BigNumber.from(sellPricesWithFee[i])),
                        supply: Number(BigNumber.from(supplies[i]))
                    })
                }

                const convertEthToUsd = (ethAmount) => {
                    const rate = localStorage.getItem('ethToUsdRate');
                    const usdAmount = ethAmount * rate;
                    return usdAmount?.toFixed(2);
                }

                const marketcap = parseFloat(convertEthToUsd(details[0]?.sellPrice)) *  parseFloat(details[0]?.supply) / 1000

                return marketcap
            } catch (error) {
                console.error('Error sending transaction:', error);
            }
        }
    }

    return { buyNFT, sellNFT, address, getNFTPrice, withdrawEth, getNFTPricesBulk, getUserMarketCap };
};

export default useBuyNFTTransaction;
