import { axiosInstance, gowildAxiosInstance } from "../lib/axiosInstance";

const handleForbiddenError = async (error) => {
    if (error?.request?.status === 403) {
        return
    }
    // if (error?.request?.status === 401) {
    //     window.location.href = '/onboarding/funds'
    //     return
    // }
    if (error?.request?.status === 402) {
        window.location.href = '/banned'
        return
    }
};

export const getUserAndChannelData = async (search, isChannel = false, isUser = false, isFollowing = false) => {
    try {
        const params = new URLSearchParams({
            with_channel: isChannel.toString(),
            with_user: isUser.toString(),
            send_following: isFollowing.toString(),
        });

        const response = await axiosInstance.get(`/app/search/v2?q=${encodeURIComponent(search)}&${params.toString()}`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        return err;
    }
};

export const getFeedData = async (filterFollowing, filterPowerUser, filterDollar, filterDollarAmount, page_number = 1, currency) => {
    try {
        const params = new URLSearchParams();

        if (filterFollowing) {
            params.append('filter_following', 'true');
        }
        if (filterPowerUser) {
            params.append('filter_power_user', 'true');
        }
        if (filterDollar) {
            params.append('filter_dollar_amount', filterDollarAmount);
        }
        if (currency) {
            params.append('currency', currency);
        }
        params.append('page_number', page_number)
        const queryString = params.toString();

        const response = await axiosInstance.get(`/app/feed${queryString ? `?${queryString}` : ''}`);
        return response;
    }
    catch (err) {
        await handleForbiddenError(err);
        return err;
    }
}

export const getCastsTrending = async ({ cursor, currency }) => {
    const params = new URLSearchParams();
    if (cursor) {
        params.append('cursor', cursor);
    }
    if (currency) {
        params.append('currency', currency);
    }

    params.append('limit', 10);
    const queryString = params.toString();

    try {
        const response = await axiosInstance.get(`/feed/media/?${queryString}`);
        return response
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getCastsPersonalized = async ({ cursor, currency }) => {
    const params = new URLSearchParams();
    if (cursor) {
        params.append('cursor', cursor);
    }
    if (currency) {
        params.append('currency', currency);
    }
    params.append('limit', 10);
    const queryString = params.toString();

    try {
        const response = await gowildAxiosInstance.get(`/api/v1/feed/following/?${queryString}?with_recasts=true`);
        return response.data;
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

// export const getCastsFollowing = async () => {
//     try {
//         const response = await axiosInstance.get(`/app/feed/following`)
//         return response
//     }
//     catch (err) {
//         await handleForbiddenError(err);
//         return err
//     }
// }


export const getFeedTopics = async () => {
    try {
        const response = await axiosInstance.get(`/app/feed/topics/v2`)
        return response
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getCurrentUserProfile = async () => {
    try {
        const response = await axiosInstance.get(`/app/my_profile/v2`)
        return response
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}


export const getRankingUsersByType = async (type, filterFollowing) => {
    try {
        const response = await axiosInstance.get(`/app/ranking/users/${type}${filterFollowing ? '?filter_following=true' : ''}`)
        return response
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getProfileDetailNew = async (username, currency) => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/profile/user/${username}?currency=${currency}`)
        return response?.data
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getProfileNFTs = async (username) => {
    try {
        const response = await axiosInstance.get(`/onchain/portfolio/nfts/${username}`)
        return response?.data
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getProfileTokens = async (username) => {
    try {
        const response = await axiosInstance.get(`/onchain/portfolio/tokens/${username}`)
        return response?.data
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}



export const getCurrentUserRevenue = async () => {
    try {
        const response = await axiosInstance.get(`/app/my_transactions/dividents_aggregated `)
        return response
    }
    catch (err) {
        await handleForbiddenError(err)
        return err
    }
}

export const followChannel = async (id) => {
    try {
        const response = await axiosInstance.post(`/app/follow_channel/${id}`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}
export const unfollowChannel = async (id) => {
    try {
        const response = await axiosInstance.post(`/app/unfollow_channel/${id}`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}


export const getProfileMktCapGraph = async (username) => {
    try {
        const response = await axiosInstance.get(`/app/profile/${username}/market_cap_graph`)
        return response
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getProfileHolders = async (username, filter_following = false, filter_top) => {
    try {
        const response = await axiosInstance.get(`/app/profile_holders/${username}${filter_following ? '?filter_following=true' : ''}${filter_top ? `?filter_top=${filter_top}` : ''}`)
        return response?.data?.holders
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getProfileTips = async (username, page = 1, selectedCurrency = 'WILD') => {
    try {
        const response = await axiosInstance.get(`/app/profile_tips/${username}?page=${page}&currency=${selectedCurrency}`)
        return response
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getProfileOwnedTickets = async (username) => {
    try {
        const response = await axiosInstance.get(`/app/profile_owned/${username}`)
        return response?.data?.owned_tickets
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getProfileTags = async (fid) => {
    try {
        const response = await axiosInstance.get(`/app/v2/profile_tags/${fid}`)
        return response
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getWalletOwnedTickets = async () => {
    try {
        const response = await axiosInstance.get(`/app/my_owned_tickets`)
        return response
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getWalletTipsTransactions = async (page = 1, selectedCurrency = 'WILD') => {
    try {
        const response = await axiosInstance.get(`/app/my_transactions/tips?page=${page}&currency=${selectedCurrency}`)
        return response
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getWalletTradesTransactions = async (page_number) => {
    const params = new URLSearchParams();

    params.append('page_number', page_number)
    const queryString = params.toString();

    try {
        const response = await axiosInstance.get(`/app/my_transactions/trades?${queryString}`)
        return response?.data
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getWalletDetail = async () => {
    try {
        const response = await axiosInstance.get(`/app/wallet`)
        return response?.data
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getCastDetailed = async ({ hash = '', fid = '', currency }) => {
    try {
        // const response = await axiosInstance.get(`/app/cast_details/${hash}/${fid}`)
        const response = await gowildAxiosInstance.get(`/api/v1/feed/cast_thread/${hash}?currency=${currency}`);
        const data = response.data.data
        return {
            data: {
                cast: [data],
                child_casts: data.cast.children ?? [],
                parent_casts: data.cast.parents ?? []
            }
        };
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getProfileCasts = async (username, cursor, with_replies = false, currency) => {
    const params = new URLSearchParams();
    if (cursor) {
        params.append('cursor', cursor);
    }
    if (with_replies) {
        params.append('with_replies', with_replies);
    }
    if (with_replies) {
        params.append('with_recasts', true);
    }

    if (currency) {
        params.append('currency', currency);
    }
    const queryString = params.toString();
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/feed/user_feed/${username}?${queryString}`);
        return response?.data;
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getCastTips = async (hash, page) => {
    try {
        const response = await axiosInstance.get(`/app/cast_tips/${hash}?page_number=${page}`)
        return response?.data
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getSearchRecent = async (isFollowing = false) => {
    try {
        const params = new URLSearchParams();

        if (isFollowing) {
            params.append('send_following', 'true');
        }

        const queryString = params.toString();

        const response = await axiosInstance.get(`/app/search/recent?${queryString}`)
        return response?.data
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const sendTip = async ({ hash, fid, amount, channelUrl, currency }) => {
    try {
        const payload = {
            currency: currency,
            amount: amount,
        };
        if (channelUrl) {
            payload.channelUrl = channelUrl;
        }
        const response = await axiosInstance.post(`/app/tip/cast/${hash}/${fid}`, payload);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getChannelDetailed = async (id) => {
    try {
        const response = await axiosInstance.get(`/app/channel/${id}/details`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getChannelRecents = async (id, page = 1, currency) => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/feed/channel/${id}?page=${page}&currency=${currency}`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getChannelTrending = async (id, page = 1, currency) => {
    const params = new URLSearchParams();

    if (page) {
        params.append('page', page);
    }
    if (currency) {
        params.append('currency', currency);
    }

    const queryString = params.toString();

    try {
        const response = await gowildAxiosInstance.get(`/api/v1/feed/channel/trending/${id}?${queryString}`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getWalletBalance = async (address) => {
    const gasFee = 0.0001;
    try {
        const response = await axiosInstance.get(`/app/wallet/balance/${address}`);
        return response?.data?.value - gasFee
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}
export const getQuests = async (currency) => {
    const params = new URLSearchParams();
    if (currency) {
        params.append('currency', currency);
    }

    const queryString = params.toString();
    try {
        const response = await axiosInstance.get(`/app/quest/list?${queryString}`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getScheduledCasts = async () => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/accounts/hub/cast/scheduled`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const getDrafts = async () => {
    try {
        const response = await axiosInstance.get(`/app/cast/drafts`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const deleteDraft = async (id) => {
    try {
        const response = await axiosInstance.delete(`/app/cast/draft/${id}`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const submitDraft = async (text, selectedChannel, embeds, mentions, mentionedPositions) => {
    try {
        const response = await axiosInstance.post(`/app/cast/draft`,
            {
                text: text,
                selected_channel: selectedChannel || undefined,
                embeds: embeds || [],
                mentions: mentions || [],
                mention_positions: mentionedPositions || [],
            }
        );
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getCurrencyList = async () => {
    try {
        const response = await axiosInstance.get(`/tip/v2/metadata/config/list`);
        const filteredData = response?.data?.filter((currency) => currency?.active)
        return filteredData;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getMyPointBalances = async (showTipStats = false, currency, internal = false) => {
    const params = new URLSearchParams();
    if (showTipStats) {
        params.append('with_tip_stats', showTipStats);
    }
    if (currency) {
        params.append('currency', currency);
    }
    if (internal) {
        params.append('internal', internal);
    }
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/points/balance/?${params.toString()}`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}


export const getMostTippedByCurrency = async ({ cursor, currency, page, channel_id }) => {
    const params = new URLSearchParams();
    if (channel_id) params.append('channel_id', channel_id);

    if (cursor) {
        params.append('cursor', cursor);
    }
    if (page) {
        params.append('page', page);
    }
    if (currency) {
        params.append('currency', currency);
    }

    const queryString = params.toString();

    try {
        const response = await gowildAxiosInstance.get(`api/v1/feed/v2/most_tipped/?${queryString ? queryString : ''}`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getTinderCasts = async (channelId) => {
    try {
        const response = await axiosInstance.get(`/app/casts/view/tinder${channelId ? '?channel_id=' + channelId : ''}`)
        return response?.data?.reverse()
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const removeTinderCasts = async (hash, fid) => {
    try {
        const response = await axiosInstance.post(`/app/casts/action/tinder/view/${hash}/${fid}`)
        return response?.data
    }
    catch (err) {
        await handleForbiddenError(err);
        return err
    }
}

export const getNotifications = async (page = 1, pageSize = 25, currency) => {
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('page_size', pageSize);
    params.append('currency', currency);
    const queryString = params.toString();

    try {
        const response = await gowildAxiosInstance.get(`/api/v1/notification/all/?${queryString}&use_self_db=true`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const markNotificationAsRead = async (id) => {
    try {
        const response = await axiosInstance.post(`/notification/notifications/mark_read/`, id);
        return response;
    }
    catch (error) {
        return false;
    }
}

export const pollAccountStatus = async () => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/accounts/poll_account_status/`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getAddSignerPayload = async () => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/accounts/signer_add_payload/`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getAccountCreationPayload = async () => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/accounts/build_payload/`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        return err;
    }
}

export const createFarcasterAccount = async (payload) => {
    try {
        const response = await gowildAxiosInstance.post('/api/v1/accounts/create_farcaster_account', payload);
        return response.data;
    } catch (err) {
        await handleForbiddenError(err);
        return err
    }

}

export const getUsernameAvailable = async (username) => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/profile/username/available?username=${username}`);
        return response.data;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const checkUsernameStatus = async () => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/profile/username/status`);
        return response.data;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const getUsernameRegisterPayload = async (username) => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/profile/username/proof?username=${username}`);
        return response.data;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const registerUsername = async (payload) => {
    try {
        const response = await gowildAxiosInstance.post(`/api/v1/profile/username/register`, payload);
        return response.data;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const updateProfile = async (payload) => {
    try {
        const response = await gowildAxiosInstance.post(`/api/v1/profile/update`, payload);
        return response.data;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const makeCast = async ({ text = undefined, embeds = undefined, parent = undefined, channel_id = undefined, parent_author_fid = undefined }) => {
    try {
        const response = await gowildAxiosInstance.post(`/api/v1/accounts/hub/cast`, {
            text: text,
            embeds: embeds,
            parent: parent,
            channel_id: channel_id,
            parent_author_fid: parent_author_fid,
        });
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const makeScheduleCast = async ({ cast_request, timestamp, repeat, repeat_count }) => {
    try {
        const response = await gowildAxiosInstance.post(`/api/v1/accounts/hub/cast/schedule`, {
            cast_request: cast_request,
            timestamp: timestamp,
            repeat: repeat,
            repeat_count: parseInt(repeat_count)
        });
        return response;
    } catch (err) {
        await handleForbiddenError(err);
        return err;
    }
}

export const editScheduleCast = async (cast_id, { cast_request, timestamp }) => {
    try {
        const response = await gowildAxiosInstance.put(`/api/v1/accounts/hub/cast/scheduled/${cast_id}`, {
            cast_request: cast_request,
            timestamp: timestamp,
        });
        console.log(response)
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const deleteScheduleCast = async (cast_id) => {
    try {
        const response = await gowildAxiosInstance.delete(`/api/v1/accounts/hub/cast/scheduled/${cast_id}`);
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const deleteCast = async (body) => {
    try {
        const response = await gowildAxiosInstance.delete(`/api/v1/accounts/hub/cast`, { data: body });
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const createFrames = async ({ castHash, castFid, version, title, image, button, state, framesUrl, postUrl, input, transaction, address }) => {
    try {
        const body = {
            cast_hash: castHash,
            cast_fid: castFid,
            action: {
                version: version,
                title: title,
                image: image,
                button: button,
                frames_url: framesUrl,
                post_url: postUrl,
                state: state
                // input: {
                //     "text: string"
                // },
                // transaction: {
                //     "hash: string"
                // },
                // address: string",

            }
        }
        if (input) {
            body.action.input = input;
        }
        if (transaction) {
            body.action.transaction = transaction;
        }

        if (address) {
            body.action.address = address;
        }

        const response = await gowildAxiosInstance.post(`/api/v1/accounts/hub/frame/action`, body);
        return response;
    }
    catch (err) {
        await handleForbiddenError(err);
    }
}

export const likeReactionApi = async (target, target_author_fid) => {
    try {
        const response = await gowildAxiosInstance.post(`/api/v1/accounts/hub/reaction`, {
            reaction_type: "like",
            target: target,
            target_author_fid: target_author_fid
        });
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const unLikeReactionApi = async (target, target_author_fid) => {
    const body = {
        reaction_type: "like",
        target: target,
        target_author_fid: target_author_fid
    }
    try {
        const response = await gowildAxiosInstance.delete(`/api/v1/accounts/hub/reaction`, { data: body });
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const reacastReactionApi = async (target, target_author_fid) => {
    try {
        const response = await gowildAxiosInstance.post(`/api/v1/accounts/hub/reaction`, {
            reaction_type: "recast",
            target: target,
            target_author_fid: target_author_fid
        });
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const unRecastReactionApi = async (target, target_author_fid) => {
    const body = {
        reaction_type: "recast",
        target: target,
        target_author_fid: target_author_fid
    }
    try {
        const response = await gowildAxiosInstance.delete(`/api/v1/accounts/hub/reaction`, { data: body });
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const followUserApi = async (target_fids) => {
    try {
        const response = await gowildAxiosInstance.post(`/api/v1/accounts/hub/follow`, {
            target_fids: [
                target_fids
            ]
        });
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

export const unfollowUserApi = async (target_fids) => {
    const body = {
        target_fids: [
            target_fids
        ]
    }
    try {
        const response = await gowildAxiosInstance.delete(`/api/v1/accounts/hub/follow`, { data: body });
        return response;
    } catch (err) {
        await handleForbiddenError(err);
    }
}

// Farcards
export const getFarcardDetails = async () => {
    try {
        const response = await axiosInstance.get(`/onchain/show_farcards_button/`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        return { show: false };
    }
};

export const startFarcardChat = async () => {
    try {
        const response = await axiosInstance.post(`/onchain/sync_farcards_conversation/`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        return { success: false, message: "No fids found." };
    }
};

// Hypersub
export const getHypersubDetails = async () => {
    try {
        const response = await axiosInstance.get(`/onchain/show_hypersub_button/`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        return { show: false };
    }
};

export const getMoxieDetails = async () => {
    try {
        const response = await axiosInstance.get(`/onchain/show_moxie_button/`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        return { show: false };
    }
};

export const startHypersubChat = async () => {
    try {
        const response = await axiosInstance.post(`/onchain/sync_hypersub_conversation/`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        return { success: false, message: "No fids found." };
    }
};

export const startMoxieChat = async () => {
    try {
        const response = await axiosInstance.post(`/onchain/sync_moxie_conversation/`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        return { success: false, message: "No fids found." };
    }
};

export const pinCast = async (cast_hash) => {
    try {
        const response = await axiosInstance.post(`/app/pin_to_profile/${cast_hash}`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        return { success: false, message: "No Cast hash found." };
    }
};

export const unPinCast = async () => {
    try {
        const response = await axiosInstance.post(`/app/unpin_from_profile`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        return { success: false, message: "No Cast hash found." };
    }
};

export const getUserDetailsFromConnectId = async (connect_id) => {
    try {
        const response = await axiosInstance.get(`/app/profile_by_connect_id/${connect_id}`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        return { success: false, message: "No connect found." };
    }
}

export const claimConnectId = async (connect_id) => {
    try {
        const response = await axiosInstance.post(`/app/claim_connect_id/${connect_id}`)
        return response?.data
    } catch (error) {
        await handleForbiddenError(error);
        return { success: false, message: "claim failed" };
    }
}

export const connectWithUserConnectID = async (with_connect_id) => {
    try {
        const response = await axiosInstance.post(`/app/connect/${with_connect_id}`)
        return response?.data
    } catch (error) {
        await handleForbiddenError(error);
        return { success: false, message: "connect with user failed" };
    }
}

export const getUrlData = async (url) => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/preview/get_preview?url=${url}`);
        return response
    } catch (error) {
        await handleForbiddenError(error);
        return { success: false, message: "connect with user failed" };
    }
}

export const getUserOnChaintrades = async (fid, after_id, before_id) => {
    try {
        if (after_id) {
            const response = await gowildAxiosInstance.get(`/api/v1/onchain/token_trades?&fid=${fid}&after_id=${after_id}`);
            return response;
        } else {
            const response = await gowildAxiosInstance.get(`/api/v1/onchain/token_trades?&fid=${fid}&before_id=${before_id}`);
            return response;
        }
    }
    catch (error) {
        return false;
    }
}

export const getOnchainTrades = async ({
    filter_following,
    filter_whale_txn,
    token_address,
    after_id,
    before_id
}) => {
    const params = new URLSearchParams({
        filter_following: filter_following.toString(),
        filter_whale_tx: filter_whale_txn.toString(),
        token_address: token_address,
    });

    if (after_id) {
        params.append('after_id', after_id);
    } else if (before_id) {
        params.append('before_id', before_id);
    }

    try {
        const response = await gowildAxiosInstance.get(`api/v1/onchain/token_trades?${params.toString()}`, {}, true);
        return response.data;
    } catch (error) {
        if (error) {
            console.error('API Error:', error.response?.data || error.message);
        } else {
            console.error('Unexpected error:', error);
        }
        throw error;
    }
};

export const claimDailyMoxieReward = async () => {
    const response = await gowildAxiosInstance.post(`api/v1/points/claim_daily_moxie_reward?preferred_wallet`);
    return response;
}

export const pollInviteCodeStatus = async (inviteCode) => {
    try {
        const response = await gowildAxiosInstance.get(`api/v1/accounts/poll_invite_code?code=${inviteCode}`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}

export const getInviteCode = async () => {
    try {
        const response = await gowildAxiosInstance.get(`/api/v1/accounts/generate_invite_codes?numCodes=1`);
        return response?.data;
    } catch (err) {
        await handleForbiddenError(err);
        throw err;
    }
}