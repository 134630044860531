/* global BigInt */

import { ArrowLeft, Copy, SignOut } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { useDisconnect, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { BrowserProvider } from 'ethers-v6';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import BaseLogo from '../../assets/svg/base_logo_fund.svg';
import { displaySnackbar } from '../../features/thunkMiddleware';
import { getWalletBalance } from '../../services/api';

const DepositModal = ({ walletDetail, closeModal, refetch }) => {
    const { open } = useWeb3Modal()
    const { disconnect } = useDisconnect()
    const dispatch = useDispatch()

    const { walletProvider } = useWeb3ModalProvider()
    const { address, isConnected } = useWeb3ModalAccount()
    const [amount, setAmount] = useState();
  
    const { data: ethBalance = 0 } = useQuery({
        queryKey: ['ethBalance', address],
        queryFn: () => getWalletBalance(address)
    });
    const stepPercent = 5;
    const minEthValue = (ethBalance * stepPercent / 100).toString();
    const stepAmount = (ethBalance * stepPercent / 100).toString()

    useEffect(() => {
        setAmount(minEthValue)
    }, [ethBalance, minEthValue])

    const handleDepositEth = async () => {
        if(!isConnected){
            open()
            return
        }
        if (ethBalance < 0) {
            dispatch(displaySnackbar('Insufficient balance'))
            return
        }
        const intAmt = Number(amount)
        const value = BigInt((intAmt * 10 ** 18))
        const ethersProvider = walletProvider && new BrowserProvider(walletProvider)
        const signer = await ethersProvider?.getSigner()


        // Switch network to the specified chain ID
        try {
            await signer.provider.send("wallet_switchEthereumChain", [{ chainId: `0x${parseInt(8453).toString(16)}` }]);
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask
            if (switchError.code === 4902) {
                dispatch(displaySnackbar('This network is not available in your wallet. Please add it manually.'));
            } else {
                dispatch(displaySnackbar('Failed to switch network. Please try again.'));
            }
            return;
        }

        try {
            const tx = await signer?.sendUncheckedTransaction({
                to: walletDetail?.address,
                value: value
            })
            if (tx) {
                refetch()
                dispatch(displaySnackbar('Deposit successful'))
                closeModal()
            }
        }
        catch (e) {
            const error = JSON.parse(JSON.stringify(e))
            dispatch(displaySnackbar(error.shortMessage))
        }
    }

    useEffect(() => {
        if (isConnected) {
            setAmount((ethBalance / 2).toString())
        }
    }, [])

    const copyAddressToClipboard = () => {
        const address = walletDetail?.address;
        navigator.clipboard.writeText(address)
            .then(() => {
                dispatch(displaySnackbar('Address copied to clipboard'))
            })
            .catch((error) => console.error('Failed to copy:', error));
    };


    return (
        <div className='bg-white dark:bg-background p-6'>
            <ArrowLeft onClick={() => closeModal()} className='fill-black dark:fill-white cursor-pointer'/>
            <div className='prose-HeaderLarge text-txtOnLightBg dark:text-onBackground mt-4'>
                FUND YOUR <span className=' text-primary'>WILD</span> WALLET WITH ETH ON <span className='text-[#0029FF] inline-flex items-center gap-2'>BASE <img src={BaseLogo} alt='base' className='size-6'/></span>
            </div>

            <div className='bg-gray-300 dark:bg-surface p-4 rounded-md mt-6'>
                <h2 className='prose-HeaderMedium text-txtOnLightBg dark:text-onBackground'>Transfer manually</h2>
                <div className='h-1 w-full my-3 border-b-[2px] dark:border-outline'/>
                <div className='prose-BodySmall md:prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII'>{walletDetail?.address}</div>
                <div className='flex gap-2 items-center prose-BodyMediumBold  text-primary mt-1 cursor-pointer' onClick={() => copyAddressToClipboard(walletDetail?.address)}><Copy size={18} className=' fill-primary'/> Copy Address</div>
            </div>

            <div className='text-center w-full my-5 prose-BodyLargeBold text-subTxtOnLightBg dark:text-onBackgroundII'>or</div>

            <div className='bg-gray-300 dark:bg-surface p-4 rounded-md'>
                <h2 className='prose-HeaderMedium text-txtOnLightBg dark:text-onBackground'>Fund from a mobile wallet</h2>
                <div className='h-1 w-full my-3 border-b-[2px] dark:border-outline'/>
                {isConnected && (
                    <div className='mb-8'>
                        <div className='prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundII flex justify-between items-center'>
                            <p>Connected account: <span className='text-txtOnLightBg dark:text-onBackground inline-flex'>{`${address?.slice(0, 4)}...${address?.slice(address?.length - 4, address?.length - 0)}`}</span></p>
                            <SignOut onClick={disconnect} className='cursor-pointer'/>
                        </div>
                        <p className='prose-BodyMediumBold text-subTxtOnLightBg dark:text-onBackgroundII mt-8'>Set Amount</p>
                        <div className="flex mt-2 items-center slidecontainer relative">
                            <input
                                type="range"
                                max={ethBalance}
                                min={minEthValue}
                                step={stepAmount}
                                value={amount}
                                onChange={(e) => { setAmount(e.target.value) }}
                                onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                                className="w-full mr-4  accent-primary"
                            />
                            <div className='absolute left-0 prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundIII top-3'>{parseFloat(minEthValue)?.toFixed(4)}</div>
                            <div className='absolute right-0 prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundIII top-3'>{ethBalance?.toFixed(4)}</div>
                        </div>
                    </div>
                )}
                {ethBalance <= 0 && isConnected ? <span className='text-error prose-BodyMediumBold'>Insufficient funds</span> : <></>}
                <button onClick={() => {handleDepositEth()}} disabled={ethBalance <= 0 && isConnected} className={`${ethBalance <= 0 && isConnected ? " opacity-60 bg-primary" : " bg-primary"} px-3 py-2 ${isConnected ? "" : ""} prose-HeaderLarge text-white dark:text-onPrimary uppercase w-full`}>
                    {!isConnected ? "Connect Wallet" : `Fund ${amount && amount?.slice(0, 7)} ETH`}
                </button>
            </div>
        </div>
    )
}

export default DepositModal
