import { ChatCircleDots, Repeat, Heart, Coins } from "@phosphor-icons/react";

const CastSkeletonLoading = () => {
    return (
        <div className={`bg-white dark:bg-background px-5 py-4 cursor-default gap-4 border-b-[1px] border-b-surface flex items-start w-full`}>
            <div className="rounded-full min-w-12 h-12 animate-pulse bg-surface/50 dark:bg-surface ">
            </div>
            <div className="flex flex-col mb-4 w-full">
                <div className="flex justify-between items-center mb-4">
                <div className="animate-pulse bg-surface/50 dark:bg-surface mr-0 w-20 h-5"></div>
                <p className="px-2 rounded-full h-6 min-w-20 animate-pulse bg-surface/50 dark:bg-surface "></p>
                </div>
                <p className="overflow-hidden w-full min-h-40 animate-pulse bg-surface/50 dark:bg-surface "></p>
            </div>
        </div>
    )
}

export default CastSkeletonLoading;