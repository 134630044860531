/* global BigInt */

import { Copy } from "@phosphor-icons/react/dist/ssr";
import { useQuery } from "@tanstack/react-query";
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { BrowserProvider } from 'ethers-v6';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { getWalletDetail } from "../../services/api";
import BaseLogo from '../../assets/svg/base_logo_fund.svg'
import Spinner from "../../components/UI/Spinner";
import Snackbar from "../../components/UI/Snackbar";
import { useDispatch } from "react-redux";
import { displaySnackbar } from "../../features/thunkMiddleware";

const Funds = () => {
    const navigate = useNavigate();
    const { open } = useWeb3Modal()
    const { walletProvider } = useWeb3ModalProvider()
    const { isConnected } = useWeb3ModalAccount()
    const dispatch = useDispatch()

    const [fundValue, setFundValue] = useState("")

    const { data: walletDetail, isLoading } = useQuery({
        queryKey: ['walletDetail'],
        queryFn: getWalletDetail,
    });

    const copyAddressToClipboard = () => {
        const address = walletDetail?.address;
        navigator.clipboard.writeText(address)
            .then(() => {
                dispatch(displaySnackbar('Address copied!'))
            })
            .catch((error) => console.error('Failed to copy:', error));
    };

    useEffect(() => {
        if (walletDetail?.eth_holdings?.value > 0) {
            navigate('/')
        }
    }, [walletDetail, navigate]);

    const sendTransaction = async (amount = 0.1) => {
        if (!isConnected) throw Error('User disconnected')
        const value = BigInt('100000000000000000') // 0.1 ETH
        const ethersProvider = new BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()

        // Switch network to the specified chain ID
        try {
            await signer.provider.send("wallet_switchEthereumChain", [{ chainId: `0x${parseInt(8453).toString(16)}` }]);
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask
            if (switchError.code === 4902) {
                dispatch(displaySnackbar('This network is not available in your wallet. Please add it manually.'));
            } else {
                dispatch(displaySnackbar('Failed to switch network. Please try again.'));
            }
            return;
        }

        try {
            const tx = await signer.sendUncheckedTransaction({
                to: walletDetail?.address,
                value: value
            })
            console.log(tx)
        }
        catch (e) {
            const error = JSON.parse(JSON.stringify(e))
            console.log(error);
            dispatch(displaySnackbar(error.shortMessage))
        }
    }

    const handleAction = () => {
        if (isConnected) {
            sendTransaction('0.069');
        } else {
            open();
        }
    };

    useEffect(() => {
        if (isConnected) {
            setFundValue(0.069)
        }
    }, [isConnected])

    if (isLoading) return <Spinner />;

    return (
        <div className='h-screen w-[50%] bg-background text-white p-6'>
        {/* <ArrowLeft onClick={() => {setShowFundWalletModal(false)}} className='mt-3 cursor-pointer'/> */}
            <div className='prose-HeaderLarge text-onBackground mt-4'>
                FUND YOUR <span className='text-primary'>WILD</span> WALLET WITH ETH ON <span className='text-[#0029FF] inline-flex items-center gap-2'>BASE <img src={BaseLogo} alt='base' className='size-6'/></span>
            </div>

            <div className='bg-surface p-4 rounded-md mt-6'>
                <h2 className='prose-HeaderMedium'>Transfer manually</h2>
                <div className='h-1 w-full my-3 border-b-[2px] border-outline'/>
                <div className='prose-BodySmall md:prose-BodyMedium text-onBackgroundII'>{walletDetail?.address}</div>
                <div className='flex gap-2 items-center prose-BodyMediumBold text-primary mt-1 cursor-pointer' onClick={() => copyAddressToClipboard(walletDetail?.address)}><Copy fill='#B4F6A5' size={18}/> Copy Address</div>
            </div>

            <div className='text-center w-full my-5 prose-BodyLargeBold text-onBackgroundII'>or</div>

            <div className='bg-surface p-4 rounded-md'>
                <h2 className='prose-HeaderMedium'>Fund from a mobile wallet</h2>
                <div className='h-1 w-full my-3 border-b-[2px] border-outline'/>
                <button onClick={handleAction} className='bg-primary px-3 py-2 prose-HeaderLarge text-onPrimary uppercase min-w-[250px]'>{fundValue === "" ? "Connect Wallet" : `Fund ${fundValue} ETH`}</button>
            </div>
    </div>
    )
}

export default Funds;