import { useQuery } from "@tanstack/react-query";
import { getAddSignerPayload, pollAccountStatus } from "../services/api";
import { setSignerModal } from "../features/globalStateSlice";
import { displaySnackbar } from "../features/thunkMiddleware";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

export const useAccount = () => {
  const { data: userRawData, isFetching: isFetchingUser, refetch: refetchUser, error } = useQuery({
    queryKey: ['accountStatus'],
    queryFn: pollAccountStatus
  });
  const { data: signerRawPayload, isFetching: isFetchingSignerPayload, refetch: refetchSignerPayload } = useQuery({
    queryKey: ['signerPayload'],
    queryFn: getAddSignerPayload,
    enabled: false
  });
  const user = userRawData?.data?.data;
  const signerPayload = signerRawPayload?.data?.data;

  const hasSignedKeyToken = user?.signer_key_active === true;

  const [signerPollingEnabled, setSignerPollingEnabled] = useState(false);
  const queryWarpcast = signerPollingEnabled && signerPayload?.token != null && user != null && !hasSignedKeyToken
  const { data: signedKeyTokenData, isFetching: isFetchingSignedKeyToken } = useQuery({
    queryKey: ['signedKeyToken', signerPayload?.token],
    queryFn: () => querySignedKeyToken(signerPayload?.token),
    enabled: queryWarpcast,
    refetchInterval: queryWarpcast ? 1000 : false,
  });
  const warpcastSignedKeyData = signedKeyTokenData?.result?.signedKeyRequest;

  useEffect(() => {
    if (signerPollingEnabled && signerPayload == null) {
      refetchSignerPayload();
    }
  }, [signerPollingEnabled, signerPayload, refetchSignerPayload]);

  useEffect(() => {
    if (warpcastSignedKeyData?.state === 'completed') {
      refetchUser();
    }
  }, [warpcastSignedKeyData?.state, refetchUser]);

  const dispatch = useDispatch();

  const handleSignerValidate = useCallback(async () => {
    let isSigner = user?.signer_key_active;
    if (isSigner) {
      dispatch(setSignerModal(false));
      return isSigner;
    } else {
      dispatch(setSignerModal(true));
      dispatch(displaySnackbar('Please set up your signer'));
      return false;
    }
  }, [dispatch, user]);

  return {
    user,
    isFetchingUser,
    refetchUser,

    handleSignerValidate,
    signerPayload: signerRawPayload?.data?.data,
    hasSignedKeyToken,
    warpcastSignedKeyData,
    isFetchingSignerData: isFetchingSignedKeyToken || isFetchingSignerPayload,
    setSignerPollingEnabled,
  };
}

const querySignedKeyToken = async (token) => {
  const response = await fetch(`https://api.warpcast.com/v2/signed-key-request/?token=${token}`);
  return await response.json();
}

export default useAccount;
