import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import useInstallPWA from './hooks/installApp';
import Layout from './Layout';
import { CHAIN_ID, REACT_APP_WALLET_CONNECT_ID, RPC_URL } from './utils/constants';
import { isDesktopDevice, isIOSDevice, isSafariBrowser } from './utils/helper';
import ErrorBoundary from './utils/errorBoundary';
import * as viemChains from "viem/chains";

function App() {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isInstalled } = useInstallPWA();
  const projectId = REACT_APP_WALLET_CONNECT_ID;



  const base = {
    chainId: CHAIN_ID,
    name: 'Base Mainnet',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: RPC_URL,
  };

  const metadata = {
    name: 'Wildcard',
    description: 'Wildcard on Base',
    url: 'https://wildcard.lol',
    icons: ['https://res.cloudinary.com/dm5fwvelv/image/upload/v1720119941/wildcard_logo.png']
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({
      metadata,
      defaultChainId: CHAIN_ID,
      rpcUrl: RPC_URL,
    }),
    chains: [
      base,
      viemChains.mainnet,
      viemChains.optimism,
      viemChains.arbitrum,
      viemChains.degen,
      viemChains.zora,
      viemChains.astar,
      viemChains.avalanche,
      viemChains.blast,
      viemChains.bsc,
      viemChains.cyber,
      viemChains.flowMainnet,
      viemChains.linea,
      viemChains.skaleNebula,
      viemChains.polygonZkEvm,
      viemChains.redstone,
      viemChains.scroll,
      viemChains.xai,
      viemChains.zkSync,
      viemChains.polygon,
    ],
    projectId,
    allowUnsupportedChain: true,
  });

  useEffect(() => {
    const isSafari = isSafariBrowser();
    const isIOS = isIOSDevice();
    const isDesktop = isDesktopDevice();
    const isSupportRoute = pathname?.includes('/support');
    if (isInstalled) {
      if (isSupportRoute) {
        navigate('/');
      }
    } else {
      if (isIOS && !isSafari) {
        navigate('/support/wrongbrowser');
      } else if (isDesktop) {
        if (isSupportRoute) {
          navigate('/');
        }
      } else if (pathname === '/') {
        navigate('/support');
      }
    }
  }, [isInstalled, pathname, navigate]);


  return (
    <ErrorBoundary>
      <Layout />
    </ErrorBoundary>
  );
}

export default App;
