import { ArrowLeft, Browsers, WarningDiamond } from '@phosphor-icons/react';
import React from 'react';

const WrongBrowser = () => {
    return (
        <div className="flex-1 overflow-y-auto removeScrollBar max-h-dvh">
            <div className='mx-5 mb-10 mt-32 relative w-24 h-24 bg-[#161719] rounded-full flex justify-center items-center'>
                <Browsers size={56} />
                <span className='h-8 absolute top-0 right-0 w-8 bg-[#FF9C10] rounded-full flex justify-center items-center'>
                    <WarningDiamond size={24} color={'#000000'} weight="bold" />
                </span>
            </div>
            <OnBoardingTitle backbtn={false} titleStart='Wrong' titleMid='browser' titleEnd='detected' titleWidth={200} />
            <p className='mx-5 text-onBackgroundII tracking-wider'>
                Please open WildCard on Safari browser. To install WildCard, add this website to your home screen via the share button on your Safari browser.
            </p>
            <p className='mx-5 text-onBackgroundII mt-6'>Have questions regarding progressive web apps?</p>
            <button className='mx-5 text-primary mt-2 underline font-semibold'>Click here to Learn more</button>
        </div>
    )
}

export default WrongBrowser


const OnBoardingTitle = ({ titleStart, titleMid, backbtn = true, titleEnd, navFunction, titleWidth }) => {
    return (
        <div className="px-5 mb-3">
            {backbtn && <div className="h-16 flex items-center cursor-pointer ">
                <ArrowLeft size={24} color="#ffffff" onClick={navFunction} />
            </div>}
            <div className="flex items-center justify-between gap-[40px]">

                <h1 className={`font-title text-[30px] ` + `w-[${titleWidth}px]`}>
                    {
                        titleStart
                    }
                    <span className="text-primary"> {titleMid} </span>
                    {
                        titleEnd
                    }
                </h1>
            </div>
        </div>
    )
}
