import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const Snackbar = ({ text = '', isCastLink, isLink }) => {
    const navigate = useNavigate()
    return (
        <div className='z-[103] text-center'>
            <div className="bg-gray-400 dark:bg-onBackground/95 w-fit max-w-fit mx-auto flex gap-2 justify-center items-center border dark:border-outlineII text-white dark:text-background px-5 py-2 rounded relative" role="alert">
                <span className="block sm:inline prose-BodyLarge">{text}</span>
                {isCastLink && <span className='text-txtOnLightBg hover:underline font-bold cursor-pointer' onClick={() => navigate(`/cast/${isCastLink?.hash}/${isCastLink?.fid}`)}>view</span>}
                {isLink && <Link to={isLink} target='__blank' ><span className='text-txtOnLightBg hover:underline font-bold cursor-pointer'>link</span></Link>}
            </div>
        </div>
    )
}

export default Snackbar
