import { ArrowLeft, Users } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

const ChannelSkeletonCard = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='flex z-20 backdrop-blur-md fixed w-screen top-0 bg-white dark:bg-background items-center h-[56px] gap-4'>
                <ArrowLeft size={24} className='cursor-pointer mx-5' onClick={() => navigate(-1)} />
            </div>
            <div className='mx-5 mt-[54px]'>
                <div className='flex justify-between items-center'>
                    <div className='flex items-center flex-row gap-4'>
                        <div className='animate-pulse bg-surface/50 dark:bg-surface clip-polygon aspect-square bg-center bg-no-repeat bg-cover rounded-full h-10 w-10' />
                        <div className='flex flex-col'>
                            <span className='h-6 py-1 w-32 animate-pulse bg-surface/50 dark:bg-surface'></span>
                            <span className='h-4 mt-1 w-20 animate-pulse bg-surface/50 dark:bg-surface'></span>
                        </div>
                    </div>
                </div>
                <p className='text-onBackground h-20 my-4 animate-pulse bg-surface/50 dark:bg-surface'></p>
                <div className='flex items-center mt-4'>
                    <span>
                        <Users size={20} color='rgba(255, 255, 255, 0.65)' weight='bold' />
                    </span>
                    <p className='h-5 animate-pulse bg-surface/50 dark:bg-surface w-28 ml-2'></p>
                </div>
            </div>
        </>
    )
}

export default ChannelSkeletonCard;