import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import SwipeableViews from 'react-swipeable-views';
import { CaretUpDown, Empty } from '@phosphor-icons/react';
import { ACTIVITY_TAB_CHOICES, getSelectedCurrencyLocalStorage } from '../../utils/constants';
import { getFeedData, getRankingUsersByType } from '../../services/api';
import TopHeader from '../../components/Sidebars/TopHeader'
import TransactionCards from '../../components/Cards/Tickets/TransactionCards';
import TransactionSkeletonLoading from '../../components/Cards/Tickets/TransactionSkeletonLoading';
import { useNavigate, useParams } from 'react-router-dom';
import HoldersSkeletonLoading from '../../components/Cards/Ranking/HoldersSkeletonLoading';
import HoldersCard from '../../components/Cards/Ranking/HoldersCard';


const initialTabs = [
  {
    name: ACTIVITY_TAB_CHOICES.ACTIVITY,
    isActive: true,
    id: 'activity'
  },
  {
    name: ACTIVITY_TAB_CHOICES.RANKING,
    isActive: false,
    id: 'ranking'
  },
];

const options = ['Market Cap', 'Price'];

const TicketPage = ({ showHeader }) => {

  const navigate = useNavigate();
  const { tab } = useParams()
  const observer = useRef();
  const feedRef = useRef(null);

  const [selectedTab, setSelectedTab] = useState('activity');
  const [tabs, setTabs] = useState(initialTabs);
  const [followingsOnly, setFollowingsOnly] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Market Cap');

  const [feedData, setFeedData] = useState([]);
  const [hasMoreFeedData, setHasMoreFeedData] = useState(true);
  const [isLoadingfeedData, setIsLoadingFeedData] = useState(false);
  const [feedDataPage, setFeedDataPage] = useState(2);

  const [ethFilter, setEthFilter] = useState(false);
  const queryClient = useQueryClient();

  const fetchQueryOptions = { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 10 };


  const { data: transactionsTradesData = [], isLoading: transactionsTradesLoading } = useQuery({
    queryKey: ['tradesTransactionsAll', ethFilter],
    queryFn: () => getFeedData(false, false, ethFilter, 0.01, 1),
    ...fetchQueryOptions,
    enabled: selectedTab === 'activity'
  });

  const { data: rankingTopData = [], isLoading: rankingTopLoading } = useQuery({
    queryKey: ['topData', followingsOnly],
    queryFn: () => getRankingUsersByType("top", followingsOnly),
    enabled: selectedTab === 'ranking'
  });

  const toggleFollowingsOnly = () => {
    setFollowingsOnly(lastFollowingsOnly => !lastFollowingsOnly);
  };

  const handleOptionClick = () => {
    const currentIndex = options.indexOf(selectedOption);
    const nextIndex = (currentIndex + 1) % options.length;
    setSelectedOption(options[nextIndex]);
  };


  useEffect(() => {
    const prefetchQueriesSequentially = async () => {
      await queryClient.prefetchQuery({
        queryKey: ['topData', followingsOnly],
        queryFn: () => getRankingUsersByType("top", followingsOnly)
      });
    };
    prefetchQueriesSequentially();
  }, [])

  useEffect(() => {
    setFeedData(transactionsTradesData?.data);
  }, [transactionsTradesLoading])


  const handleTabClick = (name) => {
    const newTabs = tabs.map((tab) => ({
      ...tab,
      isActive: tab.id === name
    }));
    setTabs(newTabs);
    setSelectedTab(name);
    navigate(`/tickets/${name}`);
  };

  const handleTabChange = (index) => {
    const newTab = tabs[index];
    handleTabClick(newTab.id);
  };

  useEffect(() => {
    const name = tab || 'activity';
    const newTabs = tabs.map((tab) => ({
      ...tab,
      isActive: tab.id === name
    }));
    setTabs(newTabs);
    setSelectedTab(name);
  }, [tab])

  const toggleDollarFilter = async () => {
    setEthFilter((prev) => !prev);
  }

  useEffect(() => {
    setFeedDataPage(2); //to set the page to 2 when the filter changes / if commented will start from previous pages
    window.scrollTo(0, 0);
    const fetchFeedData = async () => {
      try {
        const newFeedData = await getFeedData(false, false, ethFilter, 0.01, 1);
        if (newFeedData.status === 200) {
          const data = newFeedData?.data;
          setFeedData([...data]);
          setIsLoadingFeedData(false);
        }
      } catch (error) {
        console.log('error', error)
        setIsLoadingFeedData(false);
      }
    }
    fetchFeedData();
  }, [ethFilter])

  const fetchMoreData = useCallback(async () => {
    setIsLoadingFeedData(true);
    try {
      const newFeedData = await getFeedData(false, false, ethFilter, 0.01, feedDataPage);
      if (newFeedData.length === 0) {
        setHasMoreFeedData(false);
      }
      if (newFeedData.status === 200) {
        
        const data = newFeedData?.data;
        setFeedData((prev) => [...prev, ...data]);
        setFeedDataPage((prev) => prev + 1);
        setIsLoadingFeedData(false);
        if (!data.length) setHasMoreFeedData(false);
      } else {
        setHasMoreFeedData(false);
      }
    } catch (error) {
      console.log('error', error)
      setIsLoadingFeedData(false);
    } finally {
      setIsLoadingFeedData(false);
    }
  }, [ethFilter, feedDataPage]);

  const lastElementRef = useCallback(node => {
    if (isLoadingfeedData) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMoreFeedData) {
        fetchMoreData();
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoadingfeedData, hasMoreFeedData, selectedTab, fetchMoreData]);

  return (
    <div className=''>
      <div className={`sticky top-0 z-30 bg-background transition-transform duration-1000 ${showHeader ? 'translate-y-0' : 'translate-y-[-55px] md:translate-y-[-61px] lg:translate-y-0'} `}>
        <TopHeader PageTitle={'Tickets'} tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} showTabsInHeader={true} />
        {selectedTab !== ACTIVITY_TAB_CHOICES.RANKING ?
          <div className='fixed top-[60px] md:top-[7.5vh] lg:top-[10.5vh] pt-3 z-20 w-full flex gap-2 px-5 mt-7 items-center justify-between bg-white dark:bg-background border-b-[1px] border-t-[1px] py-2 border-solid dark:border-outline'>
            <div className="flex z-10 text-subTxtOnLightBg dark:text-onBackgroundII justify-between items-center h-full bg-white dark:bg-background">
              <div className="flex flex-row gap-2">
                <p>Whale 🐳
                </p>
                <label className="inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" />
                  <div
                    onClick={toggleDollarFilter}
                    className={`relative w-8 h-4 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-surface peer-checked:after:bg-[white] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1px] after:left-0.5 after:bg-white after:border after:rounded-full after:h-[14px] after:w-[14px] after:transition-all ${getSelectedCurrencyLocalStorage()?.currency_points?.currency_config?.currency == "WILD" ? "peer-checked:bg-[#20A402]" : "peer-checked:bg-primary"}`}
                  ></div>
                </label>
              </div>
            </div>
          </div>
          :
          <div className='fixed top-[60px] md:top-[7.5vh] lg:top-[10.5vh] pt-3 z-20 w-full flex gap-2 px-5 mt-7 items-center justify-between bg-white dark:bg-background border-b-[1px] border-t-[1px] py-2 border-solid dark:border-outline'>
            <div className="flex w-full z-10 text-subTxtOnLightBg dark:text-onBackgroundII justify-between items-center h-full bg-white dark:bg-background">
              <div className="flex flex-row gap-2">
                <p>Following</p>
                <label className="inline-flex items-center cursor-pointer">
                  <input type="checkbox" value="" className="sr-only peer" />
                  <div
                    onClick={toggleFollowingsOnly}
                    className="relative w-8 h-4 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-surface peer-checked:after:bg-[white] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1px] after:left-0.5 after:bg-white after:border after:rounded-full after:h-[14px] after:w-[14px] after:transition-all peer-checked:bg-[#548C47]"
                  ></div>
                </label>
              </div>
              <div className='flex items-center gap-1 cursor-pointer' onClick={handleOptionClick}>
                <p className='prose-TitleSmall text-subTxtOnLightBg dark:text-onBackgroundII uppercase flex items-center gap-1'>{selectedOption} <CaretUpDown /></p>
              </div>
            </div>
          </div>
        }
      </div>


      <div className='mt-20 pb-14 md:pb-0 lg:mt-24'>
        <SwipeableViews style={{ height: 'auto' }} index={tabs.findIndex(tab => tab.id === selectedTab)} onChangeIndex={handleTabChange}>
          <div>
            {transactionsTradesLoading ? <>
              <TransactionSkeletonLoading />
              <TransactionSkeletonLoading />
              <TransactionSkeletonLoading />
              <TransactionSkeletonLoading />
              <TransactionSkeletonLoading />
            </>
              : !feedData?.length ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-mx-5">
                <Empty size={20} color="#FFFFFF66" />
                <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
              </div>
                : <div ref={feedRef}> {feedData?.map((data, idx) => (
                    <div key={idx} ref={feedData?.length - 5  === idx + 1 ? lastElementRef : null}>
                      <TransactionCards data={data} key={idx} />
                    </div>
                  ))}
                  {isLoadingfeedData && (
                      <div className='absolute bottom-0 md:bottom-0 w-full flex items-center justify-center'>
                          <span className='loading loading-dots bg-gray-400 dark:bg-white  loading-md'></span>
                      </div>
                  )}
                </div>
            }
          </div>
          <div>
            {rankingTopLoading ? <div className='mt-2 lg:mt-2'>
              <HoldersSkeletonLoading />
              <HoldersSkeletonLoading />
              <HoldersSkeletonLoading />
            </div>
              : !rankingTopData?.data?.length ? <div className="h-12 dark:border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 w-mx-5">
                <Empty size={20} color="#FFFFFF66" />
                <p className="text-subTxtOnLightBg dark:text-onBackgroundII">Nothing yet</p>
              </div>
                : rankingTopData?.data?.map((data, idx) => (
                  <div key={idx}>
                    <HoldersCard data={data} i={idx} selectedOption={selectedOption} />
                  </div>
                ))
            }
          </div>
        </SwipeableViews>
      </div>
    </div>
  )
}

export default TicketPage