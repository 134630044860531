import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const Tabs = ({ tabs, selectedTab, onTabClick, type = '', showFriendsOnly, onToggle = () => { }, fullWidthTab, fromCastModal = false }) => {
  const [underlineStyle, setUnderlineStyle] = useState({});
  const tabRefs = useRef([]);

  const { pathname } = useLocation()


  const getPathname = (pathname) => {
    switch (pathname) {
      case '':
        return 'home'
      case 'notification':
        return 'notification'
      case 'tickets':
        return 'tickets'
      case 'ranking':
        return 'ranking'
      case 'points':
        return 'points'
      case 'wallet':
        return 'wallet'
      case pathname?.includes('profile') ? 'profile' : 'profile':
        return 'profile'
      default:
        break;
    }
  }

  useEffect(() => {
    const activeTab = tabRefs.current[tabs.findIndex(tab => tab.isActive)];
    if (activeTab) {
      setUnderlineStyle({
        width: fullWidthTab ? `${activeTab.offsetWidth}px` : 20,
        transform: `translateX(${activeTab.offsetLeft}px)`
      });
    }
  }, [tabs, selectedTab]);

  return (
    <div className={`sticky w-full flex justify-between items-center z-10 ${fromCastModal ? 'bg-white dark:bg-background' : 'bg-white dark:bg-background'} text-onBackgroundII prose-TitleMedium px-5 ${fromCastModal ? '' : 'lg:mt-4'} ${type === 'fullTop' ? 'top-[-1px] pt-4' : type === 'top' ? 'top-0 lg:top-[9vh] pb-1 pt-4 lg:pt-0' : 'top-0 h-10'}`}>
      <div className={`${tabs?.length <= 3 ? "gap-[40px]" : "justify-start"} py-1 relative  flex pb-[10px] gap-12 items-center overflow-x-scroll removeScrollBar w-full`}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            ref={el => tabRefs.current[index] = el}
            onClick={() => { onTabClick(tab.id || tab.name) }}
            data-umami-event={`${getPathname != undefined && getPathname(pathname?.replace('/', ''))}-${tab.name}`}
            className={`uppercase ${tabs?.length < 2 ? 'w-fit' : 'w-full'} lg:w-fit font-bold text-xs leading-[18px] tracking-widest text-nowrap ${tab.isActive ? "text-primary" : "text-subTxtOnLightBg/90 hover:text-subTxtOnLightBg dark:text-onBackgroundII dark:hover:text-white"}`}
          >
            {tab.name}
          </button>
        ))}
        <div className={`absolute bottom-0 h-[3px]  bg-primary transition-all duration-300 `} style={underlineStyle} />
      </div>
      {showFriendsOnly && (
        <div className="flex z-10 text-subTxtOnLightBg dark:text-onBackgroundII justify-end items-center h-full w-full bg-white dark:bg-background pb-2.5">
          <div className="flex flex-row gap-2">
            <p className="translate-y-[2px]">Followings Only</p>
            <label className="inline-flex items-center cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" />
              <div
                onClick={onToggle}
                className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer border border-outline dark:bg-surface peer-checked:after:bg-primary peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-surface"
              ></div>
            </label>
          </div>
        </div>
      )}

    </div>
  );
};

export default Tabs;