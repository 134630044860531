import React from 'react'

const OnchainTradeSkeleton = () => {
  return (
    <div
        className={`relative flex items-center w-full bg-gray-200 dark:bg-surface px-1`}
    >
        <div className='grid grid-cols-12 w-full items-center'>
            <div className="col-span-1 lg:col-span-2 py-2 ps-2 h-[40%] w-10 transition-all duration-700 flex items-center bg-gray-300 dark:bg-black/70 animate-pulse" />
            <div className="col-span-5 lg:col-span-4 py-2 flex items-center gap-1 ps-3 lg:ps-0">
                <div alt='token' className='size-5 lg:size-7 rounded-full bg-gray-300 dark:bg-black/70 animate-pulse cursor-pointer'/>
                <p className='h-[20px] w-20 bg-gray-300 dark:bg-black/70 animate-pulse'></p>
            </div>
            <div className="col-span-5 py-2 flex justify-start md:justify-start items-center gap-1">
                <div className={`flex justify-between items-center bg-gray-300 dark:bg-black/70 animate-pulse border border-outline rounded-full ps-1 pe-[2px] md:ps-2 py-[2px] gap-2 min-w-[76px]`}>
                  <span className='text-[11px] lg:prose-BodyMediumBold font-extrabold'></span>
                  <p alt='token' className='size-4 lg:size-6 rounded-full bg-gray-300 dark:bg-black/70 animate-pulse flex justify-end' />
                </div>
                <p  className='h-[20px] w-10 bg-gray-300 dark:bg-black animate-pulse'></p>
            </div>
            <div className={`col-span-1 h-[20px] w-10 bg-gray-300 dark:bg-black/70 animate-pulse text-end lg:text-start py-2 text-[9px] flex items-center lg:prose-TitleMedium`}></div>
        </div>
    </div>
  )
}

export default OnchainTradeSkeleton