import React from 'react'
import { useNavigate } from 'react-router-dom';
import { pluralizeWord } from '../../../utils/helper';
import logo from '../../../assets/images/logo.png';
import powerBadge from '../../../assets/svg/powerbadge.svg';
import ProfileHoverCard from '../../ProfileHoverCard';

const ProfileHoldingCard = ({ data, i }) => {

    const navigate = useNavigate();
    const { trader, amount } = data;

    return (
        <div key={i} className="flex items-center h-[80px] bg-clip-padding bg-opacity-80 px-5">
            <div className="flex py-6 justify-between gap-4 w-full items-start">
                <div className="flex flex-row justify-start gap-4 items-center">
                    <div role='button' onClick={() => navigate(`/profile/${trader?.username}`)} className={`flex items-center justify-center rounded-full border-2 border-black h-10 w-10`}>
                        <ProfileHoverCard username={trader?.username}>
                            <img
                                src={trader?.cdn_url || trader?.pfp_url || logo}
                            className="w-full h-full object-cover rounded-full"
                            alt="profile img"
                            />
                        </ProfileHoverCard>
                    </div>
                    <div>
                        <ProfileHoverCard username={trader?.username} data={trader}>
                            <h2 className="text-[15px] font-semibold capitalize flex items-center gap-1 dark:text-onBackground text-black">{trader?.display_name} {trader?.power_badge && <img src={powerBadge} alt="" />}</h2>
                            <div className="flex flex-row gap-2 dark:text-onBackgroundII text-subTxtOnLightBg text-sm">
                                <span>@{trader?.username}</span>
                            </div>
                        </ProfileHoverCard>
                    </div>
                </div>
                <div className="flex flex-row gap-1 items-center">
                    <h3 className="text-sm dark:text-white text-black font-bold">{Number(amount) / 1000}</h3>
                    <p className="text-sm dark:text-onBackgroundII text-subTxtOnLightBg">{pluralizeWord('Ticket', Number(amount) / 1000)}</p>
                </div>
            </div>
        </div>
    )
}

export default ProfileHoldingCard