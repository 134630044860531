import { Empty, Plus } from '@phosphor-icons/react';
import { useExperimentalFarcasterSigner, usePrivy } from '@privy-io/react-auth';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import CastCards from '../../components/Cards/Casts/CastCards';
import CastSkeletonLoading from '../../components/Cards/Casts/CastSkeletonLoading';
import ChannelCard from '../../components/Cards/Channel/ChannelCard';
import ChannelSkeletonCard from '../../components/Cards/Channel/ChannelSkeletonCard';
import CastModal from '../../components/Modals/CastModal';
import Tabs from '../../components/Tabs';
import CustomModal from '../../components/UI/CustomModal';
import { changeChannel } from '../../features/globalStateSlice';
import { getChannelDetailed, getChannelRecents, getChannelTrending, getFeedTopics, getMostTippedByCurrency } from '../../services/api';
import { CASTS_TAB_CHOICES } from '../../utils/constants';

const defaultTabs = [
    { name: CASTS_TAB_CHOICES.TRENDING, isActive: true, id: 'trending' },
    { name: CASTS_TAB_CHOICES.RECENT, isActive: false, id : 'recent' },
    { name: CASTS_TAB_CHOICES.HIGHEST_TIPPED, isActive: false, id : 'highest_tipped' },
];

let moxieCursor;

const ChannelPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { requestFarcasterSignerFromWarpcast } = useExperimentalFarcasterSigner();
    const { user } = usePrivy();
    const observer = useRef();
    const castRef = useRef(null);
    const queryClient = useQueryClient();

    const { selectedCurrency } = useSelector(state => state.app);
    const farcasterAccount = user?.linkedAccounts.find(a => a.type === 'farcaster');
    const signerPublicKey = farcasterAccount?.signerPublicKey;
    const { channelId, tab } = useParams();

    const [isBottom, setIsBottom] = useState(false);
    const [showCastModal, setShowCastModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [moreLoading, setMoreLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(CASTS_TAB_CHOICES.TRENDING);
    const [tabs, setTabs] = useState(defaultTabs);
    const [channelData, setChannelData] = useState([]);

    const [page, setPage] = useState(2);
    const [backNavCount, setBackNavCount] = useState(-1);


    const [isCastModalOpen, setIsCastModalOpen] = useState(false);
    const fetchQueryOptions = { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 10 };

    const { data: channelDetails = {}, isLoading: channelLoading, refetch: refetchChannelDetails } = useQuery({
        queryKey: ['channelDetails', channelId],
        ...fetchQueryOptions,
        queryFn: () => getChannelDetailed(channelId),
    });

    const { refetch: refetchChannels } = useQuery({
        queryKey: ['feedChannels'],
        ...fetchQueryOptions,
        queryFn: getFeedTopics,
    });

    const { data: trendingData = [], isLoading: trendingLoading, refetch: refetchTrending } = useQuery({
        queryKey: ['castsTrending', channelId],
        queryFn: () => getChannelTrending(channelId, 1, selectedCurrency),
        ...fetchQueryOptions,
        enabled: selectedTab ==='trending' && !isCastModalOpen,
    });

    const { data: recentData = [], isLoading: recentLoading, refetch: refetchRecent } = useQuery({
        queryKey: ['channelRecent', channelId],
        queryFn: () => getChannelRecents(channelId, 1, selectedCurrency),
        ...fetchQueryOptions,
        enabled: selectedTab === 'recent' && !isCastModalOpen,
    });

    const { data: highestTippedData = [], isLoading: highestTippedLoading, refetch: refetchHighestTipped } = useQuery({
        queryKey: ['channelHighestTipped', channelId, selectedCurrency],
        queryFn: () => getMostTippedByCurrency({cursor: "", currency: selectedCurrency, page: 1, channel_id: channelId}),
        ...fetchQueryOptions,
        enabled: selectedTab === 'highest_tipped' && !isCastModalOpen,
    });

    useEffect(() => {
        const prefetchQueriesSequentially = async () => {
            await queryClient.prefetchQuery({
                queryKey: ['channelRecent', channelId],
                queryFn: () => getChannelRecents(channelId, 1, selectedCurrency)
            });
            await queryClient.prefetchQuery({
                queryKey: ['channelHighestTipped', channelId],
                queryFn: () => getMostTippedByCurrency({cursor: "", currency: selectedCurrency, page: 1, channel_id: channelId})
            });
        };
        prefetchQueriesSequentially();
    }, []);

    const refetchData = useCallback(() => {
        refetchChannelDetails();
        refetchChannels();
    }, [refetchChannelDetails, refetchChannels]);

    const handleTabClick = useCallback((name) => {
        if(selectedTab === name) return;
        setChannelData([]);
        setHasMore(true);
        setPage(2);
        setTabs(tabs.map(tab => ({ ...tab, isActive: tab.id === name })));
        setSelectedTab(name);
        setBackNavCount(prev => prev-1);
        navigate(`/channel/${channelId}/${name}`);
    }, [tabs]);

    const handleTabChange = useCallback((index) => {
        handleTabClick(tabs[index].id);
    }, [handleTabClick, tabs]);

    useEffect(() => {
        const name = tab ||'trending';
        setChannelData([]);
        setTabs(tabs.map(tab => ({ ...tab, isActive: tab.id === name })));
        setSelectedTab(name);
    }, [tab])

    const handleCastSigner = useCallback(() => {
        signerPublicKey ? handleOpenCastModal() : requestFarcasterSignerFromWarpcast();
    }, [signerPublicKey, requestFarcasterSignerFromWarpcast]);

    const handleOpenCastModal = useCallback(() => {
        setShowCastModal(true);
    }, []);

    const handleCloseCastModal = useCallback(() => {
        setShowCastModal(false);
    }, []);

    const getChannelQueryFn = useCallback((id, page) => {
        switch (selectedTab) {
            case 'recent': return getChannelRecents(id, page, selectedCurrency);
            case'trending': return getChannelTrending(id, page, selectedCurrency);
            case 'highest_tipped': return getMostTippedByCurrency({cursor: moxieCursor, currency: selectedCurrency, page: page, channel_id: channelId});
            default: return getChannelTrending(id, page, selectedCurrency);
        }
    }, [selectedTab, selectedCurrency]);

    const fetchMoreData = useCallback(async () => {
        setMoreLoading(true);        
        try {
            setIsCastModalOpen(false)
            const response = await getChannelQueryFn(channelId, page);

            setPage((prevPage) => prevPage + 1)
            let data = [];
            if (response.status === 200 || response.success) {
                if(selectedTab === 'trending'){
                    data = response?.data?.casts;
                } else if(selectedTab === 'recent'){
                    data = response?.data
                }
                else{
                    data = response?.data?.casts;
                    moxieCursor = response?.data?.next_cursor;
                }

                setChannelData(prevData => [...prevData, ...data]);
                if (!data.length) setHasMore(false);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setHasMore(false);
        } finally {
            setMoreLoading(false);
        }
    }, [channelId, getChannelQueryFn, page]);

    

    const lastElementRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            fetchMoreData();
          }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore, selectedTab, fetchMoreData]);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        setIsLoading(trendingLoading || recentLoading || highestTippedLoading || false);
    }, [trendingLoading, recentLoading, highestTippedLoading]);

    useEffect(() => {
        // if (!channelData.length) {
        const dataMap = {
            ['recent']: recentData?.data,
            [CASTS_TAB_CHOICES.TRENDING]: trendingData?.data?.casts,
            ['highest_tipped']: highestTippedData?.data?.casts,
        };
        setChannelData(dataMap[selectedTab] || []);
        moxieCursor = highestTippedData?.data?.next_cursor;
        // }
    }, [selectedTab, recentData?.data, trendingData?.data?.casts, highestTippedData?.data]);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //     setRecentPageNumber(2)
    //     if(selectedTab == 'recent'){
    //         refetchRecent(channelId)
    //     }
    // }, [channelId]);

    useEffect(() => {
        if (channelDetails?.data?.channel_info) {
            dispatch(changeChannel(channelDetails.data.channel_info));
        }
    }, [channelDetails, dispatch]);

    const channelDetail = {
        id: channelDetails?.data?.channel_info?.id,
        url: channelDetails?.data?.channel_info?.url,
        title: channelDetails?.data?.channel_info?.name,
        img: channelDetails?.data?.channel_info?.image_url,
    };

    return (
        <div className='relative'>
            {channelLoading ? (
                <ChannelSkeletonCard />
            ) : (
                <ChannelCard data={channelDetails?.data} handleCast={handleCastSigner} refetchDetails={refetchData} backNavCount={backNavCount}/>
            )}

            <div className='sticky md:top-[10vh] top-9 bg-background z-30 size-full mt-4'>
                <Tabs tabs={tabs} selectedTab={selectedTab} onTabClick={handleTabClick} fullWidthTab type='top' />
            </div>

            <SwipeableViews style={{ height: 'auto' }} index={tabs.findIndex(tab => tab.id === selectedTab)} onChangeIndex={handleTabChange}>
                {[CASTS_TAB_CHOICES.TRENDING, 'recent', 'highest_tipped'].map(tab => (
                    // <div key={tab} onScroll={handleScroll} className="min-h-80">
                    <div key={tab} className="min-h-80 overflow-hidden pb-12">

                        {selectedTab === tab && (
                            <div className='mt-0'>
                                {isLoading ? (
                                    Array.from({ length: 4 }).map((_, idx) => <CastSkeletonLoading key={idx} />)
                                ) : !channelData.length ? (
                                    <div className='h-12 border-outlineII border-solid border-[1px] flex items-center gap-[12px] p-4 mx-5'>
                                        <Empty size={20} color='#FFFFFF66' />
                                        <p className='text-onBackgroundII'>Nothing yet</p>
                                    </div>
                                ) : (
                                    <div ref={castRef} className='relative'>
                                        {channelData.map((feed, idx) => (
                                            <div key={idx} ref={channelData.length - 5  === idx + 1 ? lastElementRef : null} >
                                                <CastCards castData={feed} refetchCasts={tab ==='trending' ? refetchTrending : tab === 'recent' ? refetchRecent : refetchHighestTipped} setIsCastModalOpen={setIsCastModalOpen}/>
                                            </div>
                                        ))}
                                        {moreLoading && (
                                            <div className='absolute bottom-0 md:bottom-0 w-full flex items-center justify-center'>
                                                <span className='loading loading-dots bg-gray-400 dark:bg-white  loading-md'></span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </SwipeableViews>

            <button onClick={handleCastSigner} className={`fixed bottom-[114px] right-5 flex justify-center items-center h-[50px] w-[50px] rounded-full bg-primary transition duration-500 md:hidden ${isBottom ? 'translate-y-[-20px]' : ''}`}>
                <Plus size={24} color='#000' />
            </button>

            {showCastModal && (
                <CustomModal isOpen={showCastModal} closeModal={handleCloseCastModal} showSlideAnimation={false} modalPositionTop='20' translatePosition="translate(-50%, -20%)">
                    <CastModal channelDetail={channelDetail} closeModal={handleCloseCastModal} />
                </CustomModal>
            )}
        </div>
    );
}

export default ChannelPage;
