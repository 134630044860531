import { CheckCircle, Coins, Minus, Plus, X } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import logo from '../../assets/images/logo.png';
import EmptyCheck from '../../assets/svg/tip/EmptyCheck.svg';
import { setIsCastModalOpen } from '../../features/globalStateSlice';
import useCastTipping from '../../hooks/useCastTipping';
import { getMyPointBalances, getProfileDetailNew } from '../../services/api';
import { highlightWord, timeSince } from '../../utils/helper';
import useSubmitCast from './useSubmitCast';

import useMoxieBalance from '../../hooks/useMoxieBalance';
import { getSelectedCurrencyLocalStorage } from '../../utils/constants';

const TipModal = ({ setShowTipModal, closeModal, selectedTip, refetch, refetchUserdetail }) => {

    const inputRef = useRef(null);

    const dispatch = useDispatch();
    const { getUserPrivyMoxieBalance } = useMoxieBalance()
    const selectedCurrency = getSelectedCurrencyLocalStorage();
    const [myPointsBalances, setMyPointsBalances] = useState([]);
    const [pointsBalancesLoading, setPointsBalancesLoading] = useState(false);

    const [minTippingAmount, setMinTippingAmount] = useState(10);
    const [tipQuantity, setTipQuantity] = useState(0);

    const [selectedToken, setSelectedToken] = useState(selectedCurrency);

    const [toTipWalletAddress, setToTipWalletAddress] = useState(null)

    const [tokenAllocation, setTokenAllocation] = useState();
    const [tokenTippingAllowance, setTokenTippingAllowance] = useState();

    const { isTipLoading, isTipSuccess, handleTip } = useCastTipping(selectedTip, selectedToken?.currency_points?.currency_config?.currency, setShowTipModal, refetch, refetchUserdetail, toTipWalletAddress);
    const { isSubmittingCast, handleSubmitCast, isCastSuccess } = useSubmitCast();

    // // const { data: selectedTokenData, isLoading: selectedTokenDataLoading } = useQuery({
    // //     queryKey: ['myPointsBalances', selectedToken?.currency],
    // //     queryFn: () => getMyPointBalances(true, selectedToken?.currency),
    // // });

    const { data: profileData, isLoading: profileDataLoading, refetch: profileRefetch } = useQuery({
        queryKey: ['userProfileNew', selectedTip?.farcaster_user?.username, selectedToken?.currency_points?.currency_config?.currency],
        queryFn: () => getProfileDetailNew(selectedTip?.farcaster_user?.username, selectedToken?.currency_points?.currency_config?.currency),
        enabled: !!selectedTip?.farcaster_user?.username && !!selectedToken?.currency_points?.currency_config?.currency,
    }); 

    const { data: pointsBalancesData, isLoading: pointsBalancesDataLoading } = useQuery({
        queryKey: ['myPointsBalances'],
        queryFn: () => getMyPointBalances(true),
    });

    useEffect(() => {
        if(pointsBalancesDataLoading || !pointsBalancesData) return
        const sortedData = pointsBalancesData?.data?.filter((token) => token?.currency_points?.currency_config?.tippable)
        setMyPointsBalances(sortedData);
        setTipQuantity(minTippingAmount);
        setPointsBalancesLoading(false);
    }, [pointsBalancesDataLoading, pointsBalancesData])

    useEffect(() => {
        const walletAddress = profileData?.data?.wallet_address
        setToTipWalletAddress(walletAddress)
    }, [profileData?.data])

    useEffect(() => {
        setPointsBalancesLoading(true);
     
        const fetchPointsBalances = async () => {
            try {
                const moxieBalance = await getUserPrivyMoxieBalance()
                const res = await getMyPointBalances(true);
                const moxieupdate = res?.data?.map((token) => {
                    if(token?.currency_points?.currency_config?.currency === 'MOXIE'){
                        return {
                            ...token,
                            currency_points: {
                                ...token?.currency_points,
                                allowance_remaining: moxieBalance,
                                balance: moxieBalance
                            }
                        }
                    } else {
                        return token
                    }
                })
                setMyPointsBalances(moxieupdate);
                setPointsBalancesLoading(false);
            }
            catch (err) {
                console.log(err);
                setPointsBalancesLoading(false);
            }
        }
        fetchPointsBalances();
        setTipQuantity(minTippingAmount)
    }, [])


    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 1000);
    }, [])


    useEffect(() => {
        if (!pointsBalancesLoading && myPointsBalances?.length > 0)
            setSelectedToken(myPointsBalances[1]) //default tipping set to DEGEN
    }, [pointsBalancesLoading, myPointsBalances])

    useEffect(() => {
        const tokens = Math.floor(selectedToken?.currency_points?.allowance_remaining) || 0
        const tokensHoldings = Math.floor(selectedToken?.currency_points?.balance) || 0

        setTokenAllocation(tokens)
        if (selectedToken?.currency_points?.currency_config?.internal || selectedToken?.currency_points?.currency_config?.currency === 'HAM'){
            setTokenTippingAllowance(tokens > minTippingAmount ? tokens : tokensHoldings)
        }
        else{
            setTokenTippingAllowance(tokens)
        }
    }, [selectedToken?.currency_points?.currency_config?.currency])

    useEffect(() => {
        if (pointsBalancesLoading) return
        if(tokenTippingAllowance == 0){
            setMinTippingAmount(0)
            setTipQuantity(0)
            return
        }
        if(tokenTippingAllowance <= 10){
            setMinTippingAmount(tokenTippingAllowance)
            setTipQuantity(tokenTippingAllowance)
            return
        } 
        if(tokenTippingAllowance > 10){
            setMinTippingAmount(10)
            setTipQuantity(10)
            return
        }
    }, [tokenTippingAllowance, pointsBalancesLoading, selectedToken?.currency_points?.currency_config?.currency])



    const decrement = (e) => {
        e.stopPropagation()
        if (tipQuantity <= 0) return
        if (tipQuantity <= 10) {
            setTipQuantity((prev) => prev - 1)
            return
        }
        
        if(tokenTippingAllowance < 10){
            setTipQuantity((prev) => prev - 1)
            return
        }
        setTipQuantity((prev) => prev - 10)
    }

    const increment = (e) => {
        e.stopPropagation()
        if (tipQuantity >= tokenTippingAllowance) return
        if (tipQuantity >= tokenTippingAllowance - 10 && tokenTippingAllowance >= 11) {
            setTipQuantity(tokenTippingAllowance)
            return
        }
        if (tokenTippingAllowance <= 10) {
            setTipQuantity(prev => prev + 1)
        } else
            setTipQuantity((prev) => prev + 10)
    }

    const handleTipInputValue = (e) => {
        const value = e.target.value || 0;
        if (value <= tokenTippingAllowance && value >= 0) {
            setTipQuantity(parseInt(value));
        }
    }

    const handleGiveTip = (e) => {
        e.stopPropagation()
        if (tipQuantity === 0 || tipQuantity == "") return

        if (selectedToken?.currency_points?.currency_config?.internal || selectedToken?.currency_points?.currency_config?.currency === 'MOXIE')
            handleTip(tipQuantity)
        else{
            if(selectedToken?.currency_points?.currency_config?.currency === 'HAM')
                handleSubmitCast(`🍖x${tipQuantity}`, undefined, undefined, selectedTip?.cast?.id, selectedTip?.farcaster_user?.fid, undefined, tipQuantity)
            else
                handleSubmitCast(`${tipQuantity} $${selectedToken?.currency_points?.currency_config?.currency}`, undefined, undefined, selectedTip?.cast?.id, selectedTip?.farcaster_user?.fid, undefined, tipQuantity)
        }
    }

    const setPercentTip = (percentage) => {
        setTipQuantity(Math.floor(tokenTippingAllowance * percentage / 100));
    }

    const getStepValue = () => {
        if (tipQuantity > tokenTippingAllowance - 100) {
            return 1;
        } else {
            return 10;
        }
    };

    const handleMultiTokenSelect = (token) => {
        setSelectedToken(token);
    }

    useEffect(() => {
        dispatch(setIsCastModalOpen(true));
        return () => {
            dispatch(setIsCastModalOpen(false));
        }
    }, [])

    if (isTipLoading || isSubmittingCast) return (
        <div className="h-screen flex justify-center items-center relative bg-transparent bg-background rounded-lg sm:max-w-sm sm:mx-auto overflow-y-scroll p-4 removeScrollBar">
            <div className='flex flex-col gap-4 justify-center items-center prose-HeaderLarge text-white'>
                <span>
                    <img src={EmptyCheck} alt="EmptyCheck" />
                </span>
                <span>
                    Tipping...
                </span>
            </div>
        </div>
    )

    if (isTipSuccess || isCastSuccess) return (
        <div className="h-screen flex justify-center items-center relative bg-transparent bg-background rounded-lg sm:max-w-sm sm:mx-auto overflow-y-scroll p-4 removeScrollBar">
            <div className='flex flex-col gap-4 justify-center items-center prose-HeaderLarge text-white'>
                <span>
                    <CheckCircle size={64} color='#B4F6A5' />
                </span>
                <span>
                    Tipped Successfully!
                </span>
            </div>
        </div>
    )

    return (
        <div onClick={(e) => { e.stopPropagation(); }} className={`relative flex flex-col bg-white dark:bg-background rounded-lg w-full md:max-w-[420px] p-4`}>
            <div onClick={(e) => { closeModal(e); e.preventDefault(); e.stopPropagation() }}><X size={24} className='cursor-pointer fill-black dark:fill-[#ffffff66]' /></div>
            <div className='flex items-center justify-between mt-2'>
                <div className='prose-DisplaySmall text-txtOnLightBg dark:text-onBackground'>
                    <h3>TIPPING</h3>
                </div>
                <div>
                    <Coins />
                </div>
            </div>
            <div className='bg-gray-300 dark:bg-surface px-3 py-2 mt-2 rounded-t-lg'>
                <div className="flex items-center gap-4">
                    <button>
                        <img src={selectedTip.farcaster_user?.pfp_url || selectedTip.farcaster_user?.cdn_url || logo} alt="profile image" className="w-10 h-10 rounded-full" />
                    </button>
                    <div>
                        <p role='button' className="prose-BodyLargeBold capitalize text-txtOnLightBg dark:text-onBackground">{selectedTip.farcaster_user?.display_name}<span className="text-subTxtOnLightBg dark:text-onBackgroundII prose-BodyMedium lowercase ms-2"> {timeSince(selectedTip.cast?.timestamp)}</span></p>
                    </div>
                </div>
                <div className='prose-BodyLarge text-txtOnLightBg dark:text-onBackground line-clamp-2 my-2 mt-3'>{highlightWord(selectedTip?.cast?.body)}</div>
            </div>
            {pointsBalancesLoading ? <div className='min-h-[264px] relative'>
               <TipModalSkeletonLoader />
            </div>
                : <>
                    {
                        <>
                            <div className='flex flex-col justify-center items-center mt-3'>
                                <p className='prose-BodyLarge text-subTxtOnLightBg dark:text-onBackgroundII'>Amount</p>
                                <div className='flex items-center gap-6'>
                                    <button onClick={(e) => { decrement(e) }} className='border dark:border-outline dark:bg-outline size-8 flex justify-center items-center'><Minus size={20} className='fill-black dark:fill-[#ffffffa6]'/></button>
                                    <input
                                        onChange={(e) => { handleTipInputValue(e) }}
                                        type='number'
                                        value={tipQuantity == "" ? "" : tipQuantity}
                                        placeholder='0'
                                        min={minTippingAmount}
                                        max={tokenTippingAllowance}
                                        ref={inputRef}
                                        inputMode="decimal"
                                        style={{ "MozAppearance": "textfield" }}
                                        className="font-['Bungee'] max-w-[200px] text-[40px] text-center leading-[83.2px] border-none outline-none focus:border-none bg-transparent text-txtOnLightBg dark:text-onBackground [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                    />
                                    <button onClick={(e) => { increment(e) }} className='border dark:border-outline bg-outline size-8 flex justify-center items-center'><Plus size={20} className='fill-black dark:fill-[#ffffffa6]'/></button>
                                </div>
                            </div>
                            <div className="flex mt-3 items-center slidecontainer relative pl-6">
                                <input
                                    type="range"
                                    max={`${tokenTippingAllowance}`}
                                    min={`${minTippingAmount}`}
                                    step={`${getStepValue()}`}
                                    value={tipQuantity == "" ? 0 : tipQuantity}
                                    onChange={(e) => { setTipQuantity(parseFloat(e.target.value)) }}
                                    onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                                    className="w-full mr-4  accent-primary"
                                />
                                <div className='absolute left-6 prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundIII top-3'>{minTippingAmount}</div>
                                <div className='absolute right-0 prose-BodySmallBold text-subTxtOnLightBg dark:text-onBackgroundIII top-3'>{tokenTippingAllowance}</div>
                            </div>

                            <div className='flex items-center flex-wrap justify-center removeScrollBar gap-2 mt-8 md:mx-5'>
                                {myPointsBalances?.map((token, i) => {
                                    return token?.currency_points?.currency_config?.currency == 'WILD' ? null : 
                                     <div key={i} onClick={() => handleMultiTokenSelect(token)} className={`flex justify-center items-center gap-1 border px-5 py-[6px] rounded-sm prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground ${token?.currency_points?.currency_config?.currency === selectedToken?.currency_points?.currency_config?.currency ? "border-maintextColorOnLightBg dark:border-primary" : "dark:border-outline"} cursor-pointer`}>
                                        <img src={token?.currency_points?.currency_config?.image} alt={token} className='w-4 h-4 rounded-full' />
                                        {token?.currency_points?.currency_config?.currency}
                                    </div>
                                    })}
                                {
                                    pointsBalancesDataLoading && <>{[1, 2, 3, 4].map((_, i) => (
                                        <div key={i} className={`flex items-center gap-1 px-4 py-2 rounded-sm cursor-pointer h-[38.6px] w-full animate-pulse bg-surface/50 dark:bg-surface`}>
                                        </div>
                                    ))}</>
                                }
                            </div>
                            {tokenTippingAllowance < 1 && <div className='text-white flex flex-col justify-center items-center mt-6'>
                                <p className='prose-BodyMediumBold font-[bungee] max-w-fit text-center text-txtOnLightBg dark:text-onBackground'>YOU DON'T HAVE ENOUGH <span className=' text-primary'>{selectedToken?.currency_points?.currency_config?.currency}</span> TO TIP</p>
                            </div>}

                            {tokenAllocation > minTippingAmount && <div className='flex mt-4 mx-5 items-center h-8 justify-between gap-2'>
                                <div
                                    role='button'
                                    onClick={() => setPercentTip(10)}
                                    className='w-full flex items-center justify-center prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground h-8 border dark:border-outline bg-outline'>10%</div>
                                <div
                                    role='button'
                                    onClick={() => setPercentTip(20)}
                                    className='w-full flex items-center justify-center prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground h-8 border dark:border-outline bg-outline'>20%</div>
                                <div
                                    role='button'
                                    onClick={() => setPercentTip(50)}
                                    className='w-full flex items-center justify-center prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground h-8 border dark:border-outline bg-outline'>50%</div>
                                <div
                                    role='button'
                                    onClick={() => setPercentTip(100)}
                                    className='w-full flex items-center justify-center prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground h-8 border dark:border-outline bg-outline'>100%</div>

                            </div>}
                            <div className='mt-4'>
                                {/* <div className='border-t border-outline flex justify-between items-center py-2'> */}
                                {/* <h3 className='prose-BodyLarge text-onBackgroundII'>Tip using</h3> */}
                                {/* <p className='prose-BodyLargeBold text-onBackground'>WILD</p> */}
                                {/* </div> */}
                                <div className='border-t dark:border-outline flex justify-between items-center py-2'>
                                    <h3 className='prose-BodyLarge text-subTxtOnLightBg dark:text-onBackgroundII'>From {selectedToken?.currency_points?.currency_config?.currency === 'MOXIE' ? "Wallet" : tokenAllocation >= minTippingAmount ? 'Daily Allowance' : 'Points Balance' }</h3>
                                    <p className='prose-BodyLargeBold  text-primary flex items-center gap-1'>{tipQuantity} / {tokenTippingAllowance} {selectedToken?.currency_points?.currency_config?.currency}</p>
                                </div>
                            </div>
                            <div className='mt-2 mb-2'>
                                <button onClick={(e) => { handleGiveTip(e) }} disabled={tipQuantity === 0 || tokenTippingAllowance <= 0} className={`prose-HeaderLarge ${tipQuantity === 0 || tokenTippingAllowance <= 0 ? " opacity-60 bg-primary" : " bg-primary custom-hover"} drop-shadow-sm text-white dark:text-onPrimary py-[9px] px-10 w-full`}>TIP {tipQuantity} {selectedToken.currency_points?.currency_config?.currency || ''}</button>
                            </div>
                        </>}
                </>}
        </div>
    )
}

export default TipModal

const TipModalSkeletonLoader = () => {
    return <>
        <div className='flex flex-col justify-center items-center mt-8'>
            <p className='prose-BodyLarge text-onBackgroundII'>Amount</p>
            <div className='flex items-center gap-6'>
                <button className='border border-outline bg-outline size-8 flex justify-center items-center'><Minus size={20} fill='#FFFFFFA6' /></button>
                <p className='h-16 my-2 w-20 animate-pulse bg-surface/50 dark:bg-surface'></p>
                <button className='border border-outline bg-outline size-8 flex justify-center items-center'><Plus size={20} fill='#FFFFFFA6' /></button>
            </div>
        </div>
        <div className="flex mt-12 items-center slidecontainer relative pl-6">
            <input
                type="range"
                className="w-full mr-4 accent-surface/50 dark:accent-surface"
            />
            <div className='absolute left-6 prose-BodySmallBold text-onBackgroundIII top-3'></div>
            <div className='absolute right-0 prose-BodySmallBold text-onBackgroundIII top-3'></div>
        </div>

        <div className='flex items-center overflow-x-auto removeScrollBar gap-2 mt-8 mx-5'>
            {[1, 2, 3, 4].map((_, i) => (
                <div key={i} className={`flex items-center gap-1 px-4 py-2 rounded-sm cursor-pointer h-[38.6px] w-full animate-pulse bg-surface/50 dark:bg-surface`}>
                </div>
            ))}
        </div>

       <div className='flex mt-6 mx-5 items-center h-10 justify-between gap-2'>
            <div
                role='button'
                className='w-full flex items-center justify-center prose-BodyMediumBold text-onBackground h-10 border border-outline bg-outline animate-pulse'></div>
        </div>
        <div className='mt-4'>
            {/* <div className='border-t border-outline flex justify-between items-center py-2'> */}
            {/* <h3 className='prose-BodyLarge text-onBackgroundII'>Tip using</h3> */}
            {/* <p className='prose-BodyLargeBold text-onBackground'>WILD</p> */}
            {/* </div> */}
            <div className='border-t border-outline flex justify-between items-center py-2'>
                <h3 className='prose-BodyLarge text-onBackgroundII  h-6 w-16 animate-pulse bg-surface/50 dark:bg-surface'></h3>
                <p className='prose-BodyLargeBold text-primary flex items-center gap-1 h-5 w-24 animate-pulse bg-surface/50 dark:bg-surface'></p>
            </div>
        </div>
        <div className='mt-10 mb-2'>
           

        </div>
    </>

}