import { CaretDown, Minus, Plus, X } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.png';
import useBuyNFTTransaction from '../../hooks/buySellNFT';
import { getWalletDetail } from '../../services/api';
import { buyTicketRange } from '../../utils/constants';

import { ethers } from 'ethers';
import { useDispatch } from 'react-redux';
import { displaySnackbar } from '../../features/thunkMiddleware';
import { roundOff } from '../../utils/helper';


const SellModal = ({ isOpen, closeModal, data, refetch }) => {

    const { getNFTPrice, } = useBuyNFTTransaction();
    const { sellNFT } = useBuyNFTTransaction();
    const dispatch = useDispatch()


    const [rangeValue, setRangeValue] = useState(0);
    const [costExceedBal, setCostExceedBal] = useState(false);
    const [remainingBal, setRemainingBal] = useState(0)

    const [sellTicketQuantity, setSellTicketQuantity] = useState(1);

    const [sellTicketLoading, setSellTicketLoading] = useState(false);
    const [sellTicketSuccess, setSellTicketSuccess] = useState(false);
    const [sellTicketFail, setSellTicketFail] = useState(false);

    const [ethSellPrice, setEthSellPrice] = useState(0);

   
    const { data: walletDetails, isLoading: isLoadingWalletDetails, refetch: refetchWalletDetails } = useQuery({
        queryKey: ['walletDetail'],
        queryFn: getWalletDetail,
    });


    useEffect(() => {
        if (!isLoadingWalletDetails) {
            setRemainingBal(walletDetails?.eth_holdings?.value)
            setRangeValue(1)
        }
        // if (!isOpen) {
        //     setSellTicketQuantity(1)
        // }
        setSellTicketQuantity(data?.num_tickets / 1000)
        handleGetPrice()
    }, [isLoadingWalletDetails, isOpen])

    useEffect(() => {
        handleGetPrice()
    }, [sellTicketQuantity, ethSellPrice, walletDetails])

    
    const handleChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const index = Math.round(e.target.value);
        setRangeValue(buyTicketRange[index]);
        setSellTicketQuantity(buyTicketRange[index]);
    };

    const handleDecrement = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (sellTicketQuantity > 5) {
            setSellTicketQuantity(parseFloat((sellTicketQuantity - 0.5).toFixed(1)));
            setRangeValue(sellTicketQuantity - 0.5);
        } else if (sellTicketQuantity > 1) {
            setSellTicketQuantity(parseFloat((sellTicketQuantity - 0.1).toFixed(2)));
            setRangeValue(sellTicketQuantity - 0.1);
        } else if (sellTicketQuantity > 0.1 && sellTicketQuantity <= 1) {
            setSellTicketQuantity(parseFloat((sellTicketQuantity - 0.1).toFixed(2)));
            setRangeValue(parseFloat((sellTicketQuantity - 0.1).toFixed(2)));
        } else if (sellTicketQuantity > 0.01 && sellTicketQuantity <= 0.1) {
            setSellTicketQuantity(parseFloat((sellTicketQuantity - 0.01).toFixed(3)));
            setRangeValue(parseFloat((sellTicketQuantity - 0.01).toFixed(3)));
        } else if (sellTicketQuantity > 0.001 && sellTicketQuantity <= 0.01) {
            setSellTicketQuantity(parseFloat((sellTicketQuantity - 0.001).toFixed(4)));
            setRangeValue(parseFloat((sellTicketQuantity - 0.001).toFixed(4)));
        }
    };

    const handleIncrement = (e) => {
        e.stopPropagation();
        e.preventDefault();
        
        if (sellTicketQuantity >= 1) {
            if(sellTicketQuantity+1 > data?.num_tickets){
                setSellTicketQuantity(data?.num_tickets)
                return
            }
            setSellTicketQuantity(sellTicketQuantity + 1);
            setRangeValue(sellTicketQuantity + 1)
        } else if (sellTicketQuantity >= 0.1 && sellTicketQuantity < 1) {
            if(sellTicketQuantity+0.1 > data?.num_tickets){
                setSellTicketQuantity(data?.num_tickets)

                return}
            setSellTicketQuantity(parseFloat((sellTicketQuantity + 0.1).toFixed(2)));
            setRangeValue(parseFloat((sellTicketQuantity + 0.1).toFixed(2)));
        } else if (sellTicketQuantity >= 0.01 && sellTicketQuantity < 0.1) {
            if(sellTicketQuantity+0.01 > data?.num_tickets){
                setSellTicketQuantity(data?.num_tickets)

                return}
            setSellTicketQuantity(parseFloat((sellTicketQuantity + 0.01).toFixed(3)));
            setRangeValue(parseFloat((sellTicketQuantity + 0.01).toFixed(3)));
        } else if (sellTicketQuantity >= 0.001 && sellTicketQuantity < 0.01) {
            if(sellTicketQuantity+0.001 > data?.num_tickets){
                setSellTicketQuantity(data?.num_tickets)

                return}
            setSellTicketQuantity(parseFloat((sellTicketQuantity + 0.001).toFixed(3)));
            setRangeValue(parseFloat((sellTicketQuantity + 0.001).toFixed(3)));
        }
    };

    
    const handleGetPrice = () => {
        if(data?.num_tickets === 0){
            setSellTicketQuantity(0)
            return
        }
        // const convertBigNumberArray = (arr) => arr.map(value => Number(BigNumber.from(value)));
        try {
            getNFTPrice(data?.farcaster_user.fid, sellTicketQuantity * 1000).then((res) => {
                const weiValue = ethers.BigNumber.from(res?.sellPricesWithFee[0]);
                const ethValue = ethers.utils.formatEther(weiValue);

                setEthSellPrice(ethValue)

                // setPurchaseDetails({
                //     tokenIds: convertBigNumberArray(res?.tokenIds),
                //     buyPrices: convertBigNumberArray(res?.buyPrices),
                //     sellPrices: convertBigNumberArray(res?.sellPrices),
                //     buyPricesWithFee: convertBigNumberArray(res?.buyPricesWithFee),
                //     sellPricesWithFee: convertBigNumberArray(res?.sellPricesWithFee),
                //     supplies: convertBigNumberArray(res?.supplies),
                // });
            })
    
        } catch (error) {
            console.error("Error fetching NFT price:", error);
        }
    };


    const handleSell = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        setSellTicketLoading(true)

        closeModal(e)

        const ethValueWithTax = ethSellPrice;
        const fid = data?.farcaster_user?.fid;
        const username = data?.farcaster_user?.username;
        const res = await sellNFT(username, ethValueWithTax, fid, sellTicketQuantity * 1000);
        
        if (res) {
            setSellTicketSuccess(true)
            dispatch(displaySnackbar(`Sold ${sellTicketQuantity} tickets of ${data?.farcaster_user?.username}`))
            refetch()
            refetchWalletDetails()
            setSellTicketLoading(false)
        } else {
            setSellTicketFail(true)
            setSellTicketLoading(false)
            dispatch(displaySnackbar(`Failed to sell ticket of ${username}, Try Again.`))
            setTimeout(() => {closeModal(e)}, 2000)
        }
    };
 
    return (
        <div onClick={(e) => {e.stopPropagation(); e.preventDefault()}} className={`relative flex flex-col bg-white dark:bg-background rounded-lg overflow-y-scroll p-4 w-full max-w-[480px]`}>
            <div onClick={(e) => { closeModal(e); e.preventDefault(); e.stopPropagation() }} className='flex justify-end'><X size={24} className='cursor-pointer fill-black dark:fill-white' /></div>
            <div className='flex items-center justify-between mt-4'>
                <div className='prose-DisplaySmall text-txtOnLightBg dark:text-onBackground'>
                    <h3>SELL TICKET OF</h3>
                    <h3 className=' text-primary'>{data?.farcaster_user?.display_name}</h3>
                </div>
                <div>
                    <button
                        onClick={() => { }}
                        className="size-14 flex justify-center items-center rounded-full self-end overflow border-primary border-solid border-[1.5px]">
                        <img
                            src={data?.farcaster_user?.pfp_url || logo}
                            alt="avatar"
                            className="size-full rounded-full object-cover"
                        />
                    </button>
                </div>
            </div>
            <div className='border dark:border-outline mt-2'></div>
            <div className='flex flex-col justify-center items-center mt-8'>
                <p className='prose-BodyLarge text-subTxtOnLightBg dark:text-onBackgroundII'>No. of tickets</p>
                <div className='flex items-center gap-6'>
                    <button onClick={(e) => { handleDecrement(e) }} className='border dark:border-outline dark:bg-outline size-8 flex justify-center items-center'><Minus size={20} className='fill-black dark:fill-white'/></button>
                    <p className='prose-DisplayLarge text-txtOnLightBg dark:text-onBackground'>{sellTicketQuantity}</p>
                    <button onClick={(e) => { handleIncrement(e) }} className='border dark:border-outline dark:bg-outline size-8 flex justify-center items-center'><Plus size={20} className='fill-black dark:fill-white'/></button>
                </div>
            </div>
            {/* <div className="flex mt-12 items-center slidecontainer relative">
                <input
                    type="range"
                    defaultValue={buyTicketRange.indexOf(1)}
                    max={data?.view_user_context?.num_tickets_owned?.value / 1000}
                    value={buyTicketRange.indexOf(rangeValue)}
                    onChange={(e) => handleChange(e)}
                    onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
                    className="w-full mr-4 accent-primary"
                />
                <div className='absolute left-0 prose-BodySmallBold text-onBackgroundIII top-3'>{buyTicketRange[0]}</div>
                <div className='absolute right-0 prose-BodySmallBold text-onBackgroundIII top-3'>{data?.view_user_context?.num_tickets_owned?.value / 1000}</div>
            </div> */}
            <div className='mt-14'>
                <div className='border-t dark;border-outline flex justify-between items-center py-2'>
                    <h3 className='prose-BodyLarge text-subTxtOnLightBg dark:ext-onBackgroundII'>Wallet Balance</h3>
                    <p className='prose-BodyLargeBold text-txtOnLightBg dark:text-onBackground'>{isLoadingWalletDetails ? <div className='animate-pulse w-14 h-4 dark:bg-outline rounded-sm'></div> : <span>{walletDetails?.eth_holdings?.display_value} {walletDetails?.eth_holdings?.currency}</span>}</p>
                </div>
                <div className='border-t border-outline flex justify-between items-center py-2'>
                    <h3 className='prose-BodyLarge text-subTxtOnLightBg dark:text-onBackgroundII'>Sell price of {sellTicketQuantity}x Tickets</h3>
                    <p className='prose-BodyLargeBold  text-primary flex items-center gap-1'>+{roundOff(ethSellPrice)}ETH <CaretDown size={14} className='fill-black dark:fill-white'/></p>
                </div>
                {/* <div className='border-t border-outline flex justify-between items-center py-2'>
                    <h3 className='prose-BodyLarge text-onBackgroundII'>Remaining Balance</h3>
                    <p className={`prose-BodyLargeBold ${costExceedBal ? "text-error" : "text-onBackground"}`}>{costExceedBal ? "Insufficient Balance" :  remainingBal?.toFixed(4) + " ETH"}</p>
                </div> */}
            </div>
            <div className='mt-10 mb-2 w-full flex justify-center items-center'>
                <button onClick={(e) => handleSell(e)} disabled={costExceedBal} className={`prose-HeaderLarge ${costExceedBal ? ' opacity-60 bg-primary' : ' bg-primary '} drop-shadow-sm text-white dark:text-onPrimary py-[9px] w-full`}>SELL</button>
            </div>
        </div>
    )
}

export default SellModal