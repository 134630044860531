import React from 'react'
import { useNavigate } from 'react-router-dom';

import { pluralizeWord, timeSince } from '../../../utils/helper';
import logo from '../../../assets/images/logo.png'
import powerBadge from '../../../assets/svg/powerbadge.svg'
import ProfileHoverCard from '../../ProfileHoverCard';

const TransactionCards = ({ data }) => {
    const { display_amount, price, subject, time, trader, type} = data

    const navigate = useNavigate();
    const traderName = trader?.display_name.split(" ");
    const subjectName = subject?.display_name.split(" ");

    const getType = (type) => {
        switch (type) {
          case 'buy':
            return 'bought';
          case 'sell':
            return 'sold';
          case 'release':
            return 'unlocked';
          default:
            return type;
        }
      }
    
      const handleNavigate = (username) => {
        navigate(`/profile/${username}`)
      }

  return (
    <div className="mb-8 flex items-center bg-white dark:bg-background px-5">
        <div className="flex items-center">
          <button onClick={() => handleNavigate(trader?.username)} className={`flex items-center justify-center rounded-full border-[2.44px] h-[38.44px] w-[38.44px] border-outlineII`}>
            <ProfileHoverCard username={trader?.username}>
                <img
                    src={trader?.cdn_url || trader?.pfp_url || logo}
              className="w-10 h-10 object-cover rounded-full"
              alt="profile img"
            />
            </ProfileHoverCard>
          </button>
          <button onClick={() => handleNavigate(subject?.username)} className={`flex items-center justify-center -mx-4 rounded-full border-[2.44px] h-[38.44px] w-[38.44px] border-outlineII`}>
            <ProfileHoverCard username={subject?.username}>
                <img
                    src={subject?.cdn_url || subject?.pfp_url || logo}
              className="w-10 h-10 object-cover rounded-full"
              alt="profile img"
            />
            </ProfileHoverCard>
          </button>
        </div>
        <div className='flex flex-col md:flex-row ms-5 w-full'>
            <div className='flex flex-col lg:flex-row items-center w-full flex-wrap'>
                <div className='flex items-center justify-start gap-x-1 w-full lg:w-auto flex-wrap'>
                    <span role="button" onClick={() => handleNavigate(trader?.username)} className="text-nowrap prose-BodyMediumBold text-txtOnLightBg dark:text-onBackground">
                      <ProfileHoverCard username={trader?.username} data={trader}>
                        {traderName[0]?traderName[0]:""} {traderName[1]?traderName[1]:''}
                      </ProfileHoverCard>
                    </span>
                    {trader?.power_badge && <img src={powerBadge} className="w-4 h-4" alt="" />}
                    <span className={`uppercase text-xs leading-[12px] font-normal tracking-[0.05em] py-1 px-2 flex items-center justify-center ${type == 'sell'?'text-[#E95844] bg-[#E9584433]':' text-[#A5F6AD] bg-[#44E95533]'}`}> {getType(type)}</span>
                    <span className="flex prose-BodyMedium text-txtOnLightBg dark:text-onBackground">
                        {display_amount} {pluralizeWord('Ticket', Number.parseFloat(display_amount))}
                    </span>
                </div>
                <div className='flex justify-start items-center gap-x-2 md:ms-1 w-full lg:w-auto flex-wrap'>
                    <span className="prose-BodyMedium text-subTxtOnLightBg dark:text-onBackgroundII">of</span>
                    <p role="button" onClick={() => handleNavigate(subject?.username)} className="prose-BodyMediumBold flex items-center gap-2 text-txtOnLightBg dark:text-onBackground text-nowrap">
                      <ProfileHoverCard username={subject?.username} data={subject}>
                        {subjectName[0]?subjectName[0]:""} {subjectName[1]?subjectName[1]:''}
                      </ProfileHoverCard>
                        {subject?.power_badge && <img src={powerBadge} className="w-4 h-4" alt="" />}
                    </p>
                </div>
            </div>
            <div>
                <p className="flex flex-row md:flex-col-reverse md:items-end items-baseline gap-2">
                    <span className={`prose-BodyMedium text-txtOnLightBg dark:text-onBackground tracking-[0.01em] text-nowrap`}>{price?.usd_value_display} ({`${price?.display_value || ''} ${price?.currency || ''}`})</span>
                    <span className="text-subTxtOnLightBg dark:text-onBackgroundIII font-normal leading-[15px] tracking-[0.01em] text-[10px]">{timeSince(time?.unix_timestamp)}</span>
                </p>
            </div>
        </div>
    
    </div>
  )
}

export default TransactionCards  